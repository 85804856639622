import React from 'react'
import { useDispatch } from 'react-redux'
import {
  allNotificationsUserLikeRead,
  getAllNotifications,
} from '../../../app/modules/Admin/_redux/system/systemActions'

export const DropdownTopbarItemNotificationToggler = React.forwardRef((props, ref) => {
  const dispatch = useDispatch()
  return (
    <div
      ref={ref}
      className="topbar-item"
      onClick={(e) => {
        e.preventDefault()
        dispatch(allNotificationsUserLikeRead()).then(() => dispatch(getAllNotifications()))

        props.onClick(e)
      }}
    >
      {props.children}
    </div>
  )
})

DropdownTopbarItemNotificationToggler.displayName = 'DropdownTopbarItemNotificationToggler'
