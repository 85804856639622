import React from 'react'
import { Form, InputGroup } from "react-bootstrap";

const SearchBar = props => (

    <InputGroup>
        <InputGroup.Prepend>
        <InputGroup.Text id="inputGroupSearchTxt">
            <i className="fas fa-search"></i>
        </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
            type="text"
            name={props.name}
            placeholder={props.placeholder}
            aria-describedby="inputGroupSearch"
            onChange={e => props.handleSearchText(e.target.value)}
            
        />
    </InputGroup>
)

export default SearchBar