import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

export const getTaskRegisterData = async () => await axios.get(`${API_BASE_URL}/api/task/register/data`)

export const getTaskUpdateData = async (takId) => await axios.get(`${API_BASE_URL}/api/task/${takId}/edit_data`)

export const createTask = async (obj) => {
  const data = new FormData()
  if (obj.solicitationId) {
    data.append('solicitation', obj.solicitationId)
  }
  data.append('description', obj.description)
  data.append('executant', obj.executantTask)
  data.append('type', obj.taskType)

  for (let i = 0; i < obj.attachments.length; i++) {
    data.append('attachments[]', obj.attachments[i])
  }

  return await axios.post(`${API_BASE_URL}/api/task/new`, data, {
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  })
}

export const updateTask = async (obj) => {
  const data = new FormData()
  data.append('description', obj.description)
  data.append('type', obj.taskType)
  data.append('deletedTaskAttachments', JSON.stringify(obj.deletedTaskAttachments))

  for (let i = 0; i < obj.attachments.length; i++) {
    data.append('attachments[]', obj.attachments[i])
  }

  return await axios.post(`${API_BASE_URL}/api/task/${obj.taskId}/update`, data, {
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  })
}

export const cancelTask = async (obj) =>
  await axios.post(`${API_BASE_URL}/api/task/${obj.taskId}/cancel`, {
    justification: obj.description,
  })

export const getReceivedTask = async (userId, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/task/received/user/${userId}`, {
    params: { per_page, page, search, sort_field, ordering },
  })
export const getCreatedTask = async (userId, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/task/created/user/${userId}`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getTaskInteractionsData = async (taskId) => await axios.get(`${API_BASE_URL}/api/task/${taskId}`)

export const createTaskInteraction = async (obj) => {
  const data = new FormData()
  data.append('description', obj.description)
  for (let i = 0; i < obj.attachments.length; i++) {
    data.append('attachments[]', obj.attachments[i])
  }

  return await axios.post(`${API_BASE_URL}/api/task/${obj.taskId}/interaction`, data, {
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  })
}

export const deleteTaskInteraction = async (taskInteractionId) =>
  await axios.delete(`${API_BASE_URL}/api/task/task_interaction/${taskInteractionId}`)

export const finishTask = async (taskId) => await axios.post(`${API_BASE_URL}/api/task/${taskId}/task_like_done`)
