import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Alert, Col, Row, Button, Form, Spinner } from 'react-bootstrap'
import { useSubheader } from '../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../routes/Helpers'
import { toast } from 'react-toastify'
import { getTaskUpdateData, clearSaveError, cleanSaveStatus, updateTask } from '../_redux/tasks/tasksActions'
import { getSolicitationManagement } from '../../Solicitation/_redux/attendances/attendanceActions'
import { validationTask } from './validationSchema'
import { AlertGT } from '../../../components'
import LoadingSpinner from '../../../components/LoadingSpinner'
import BasicSausegeCard from '../../../components/Card/BasicSausegeCard'
import { Formik } from 'formik'
import Dropzone from '../../../components/Dropzone'
import AttachmentsOnEditForm from '../components/AttachmentsOnEditForm'
import { Link } from '../../../components/Card/BasicSausegeCard/BasicSausegeCard.styles'
import SolicitationDataModal from '../components/SolicitationDataModal'
import AlertTask from '../../../components/AlertTask'

const TasksUpdatePage = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const hasSolicitationPath = window.location.pathname.startsWith('/solicitacao')
  const taskId = hasSolicitationPath ? params.id2 : params.id1
  const solicitationId = hasSolicitationPath ? params.id1 : params.id2
  const { saveError, saveStatus, listLoading, statusMessage, taskUpdateData } = useSelector((state) => state.tasks)

  const [taskData, setTaskData] = React.useState(taskUpdateData)
  const [hasTaskData, setHasTaskData] = React.useState(!!taskData)

  useEffect(() => {
    if (solicitationId) {
      dispatch(getSolicitationManagement(solicitationId))
    }
    dispatch(getTaskUpdateData(taskId))
  }, [taskId, solicitationId, dispatch])

  const { user } = useSelector((state) => state.auth)
  const { solicitationSelected } = useSelector((state) => state.attendance)

  const subheader = useSubheader()
  const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  const [configAlert, setConfigAlert] = useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 800,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
    closeToast: false,
  })

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(clearSaveError())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(statusMessage, {})
      dispatch(cleanSaveStatus())
    }
    // eslint-disable-next-line
  }, [saveStatus])

  useEffect(() => {
    setTaskData(taskUpdateData)
  }, [taskUpdateData, taskId])

  useEffect(() => {
    setHasTaskData(!!taskData)
  }, [taskData])

  const [attachments, setAttachments] = useState([])
  const [deletedTaskAttachments, setDeletedTaskAttachments] = useState([])
  const [showAlertTask, setShowAlertTask] = React.useState(false)

  const showAlertSolicitationData = () => {
    const content = () => (
      <SolicitationDataModal
        solicitationCode={taskData.solicitation ? taskData.solicitation.code : solicitationSelected.code}
        serviceCode={taskData.solicitation ? taskData.solicitation.service.code : solicitationSelected.service.code}
        serviceTitle={taskData.solicitation ? taskData.solicitation.service.title : solicitationSelected.service.title}
        solicitationSlug={taskData.solicitation ? taskData.solicitation.stage.slug : solicitationSelected.stage.slug}
        solicitationStage={taskData.solicitation ? taskData.solicitation.stage.stage : solicitationSelected.stage.stage}
        description={taskData.solicitation ? taskData.solicitation.description : solicitationSelected.description}
        organizationInitials={
          taskData.solicitation
            ? taskData.solicitation.solicitation_organization.initials
            : solicitationSelected.solicitation_organization.initials
        }
        createdIn={taskData.solicitation ? taskData.solicitation.moment_by_user : solicitationSelected.moment_by_user}
      />
    )
    setConfigAlert({
      show: true,
      title: null,
      text: content(),
      width: 800,
      confirmButtonText: 'Fechar',
      confirm: () => setConfigAlert({ ...configAlert, show: false }),
      toastSuccess: null,
    })
  }

  const handleSubmitForm = (values) => {
    if (taskId) {
      dispatch(
        updateTask({
          ...values,
          solicitationId: solicitationId,
          attachments: attachments,
          taskId: taskId,
          deletedTaskAttachments: deletedTaskAttachments,
        })
      )
        .then((resp) => {
          if (hasSolicitationPath) {
            history.push(`/solicitacao/${solicitationId}/tarefas`)
          } else {
            history.push(`/tarefas/criadas/${user.id}`)
          }
        })
        .catch((error) => console.log(error))
    }
  }

  const showAlertUpdateData = (values) => {
    setConfigAlert({
      show: true,
      icon: 'question',
      title: null,
      text: `Deseja realmente alterar os dados da tarefa <strong>${taskData.task_code}</strong>? `,
      width: 400,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        handleSubmitForm(values)
        setConfigAlert({ ...configAlert, show: false })
      },
      toastSuccess: null,
    })
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      <AlertTask
        show={showAlertTask}
        onConfirm={() => setShowAlertTask(false)}
        onDismissed={() => setShowAlertTask(false)}
      />
      <Card border="secondary">
        <Card.Body>
          {hasTaskData ? (
            <>
              {saveError.length > 0 && (
                <Alert variant="danger">
                  {Object.values(saveError[0]).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                  ))}
                </Alert>
              )}
              <Row>
                <Col className="text-right">
                  <Link onClick={() => setShowAlertTask(true)}>O que é uma Tarefa?</Link>
                </Col>
              </Row>
              {(hasSolicitationPath || taskData.associationWithSolicitation) && (
                <Row>
                  <Col sm={12}>
                    <BasicSausegeCard
                      subText={'Associação'}
                      titleText={`Solicitação ${
                        taskData.solicitation ? taskData.solicitation.code : solicitationSelected.code
                      }`}
                      textLink={'Visualizar dados da Solicitação'}
                      handleClick={showAlertSolicitationData}
                    />
                  </Col>
                </Row>
              )}
              <Formik
                validationSchema={validationTask}
                onSubmit={(values) => {
                  if (taskId) showAlertUpdateData(values)
                  else handleSubmitForm(values)
                }}
                initialValues={{
                  description: taskId ? taskData.task_description : '',
                  executantTask: taskId ? taskData.executantSelected.id : '',
                  taskType: taskId ? taskData.actualType.id : '',
                }}
              >
                {(formik) => (
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col sm={12}>
                        <Form.Group md="12">
                          <Form.Label>Descrição da Tarefa</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="5"
                            name="description"
                            maxLength="1000"
                            {...formik.getFieldProps('description')}
                            className={formik.touched.description && formik.errors.description ? 'is-invalid' : null}
                            disabled={hasTaskData && taskId && !taskData.descriptionFieldEnabled}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.description && formik.errors.description && (
                            <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                              {formik.errors.description}
                            </Form.Control.Feedback>
                          )}
                          <Form.Text className="text-right">
                            {formik.values.description.length !== 0
                              ? `${formik.values.description.length}/1000 caracteres`
                              : 'Máximo de 1000 caracteres.'}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group md="12">
                          <Form.Label>Executante da Tarefa</Form.Label>
                          <Form.Control
                            as="select"
                            name="executantTask"
                            id="executantTask"
                            value={taskId ? taskData.executantSelected.id : ''}
                            {...formik.getFieldProps('executantTask')}
                            className={
                              formik.touched.executantTask && formik.errors.executantTask ? 'is-invalid' : null
                            }
                            disabled={!!taskId}
                          >
                            <option value="">Selecione o executante da tarefa</option>
                            {taskId ? (
                              <option
                                key={`executant-${taskData.executantSelected.id}`}
                                value={taskData.executantSelected.id}
                              >
                                {taskData.executantSelected.displayFullNameExecutantSelected}
                              </option>
                            ) : (
                              taskData.executantList.map((executant) => (
                                <option key={`executant-${executant.id}`} value={executant.id}>
                                  {executant.displayFullNameExecutantSelected}
                                </option>
                              ))
                            )}
                          </Form.Control>
                          {formik.touched.executantTask && formik.errors.executantTask && (
                            <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '100%' }}>
                              {formik.errors.executantTask}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group md="12">
                          <Form.Label>Tipo da Tarefa</Form.Label>
                          <Form.Control
                            as="select"
                            name="taskType"
                            id="taskType"
                            {...formik.getFieldProps('taskType')}
                            onChange={formik.handleChange}
                            className={formik.touched.taskType && formik.errors.taskType ? 'is-invalid' : null}
                          >
                            <option value="">Selecione o tipo de tarefa</option>
                            {taskData.taskTypesList.map((taskType) => (
                              <option key={`taskType-${taskType.id}`} value={taskType.id}>
                                {taskType.type}
                              </option>
                            ))}
                          </Form.Control>
                          {formik.touched.taskType && formik.errors.taskType && (
                            <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '100%' }}>
                              {formik.errors.taskType}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Label>Arquivos da Tarefa</Form.Label>
                        <Dropzone
                          setAttachments={setAttachments}
                          maxAmountFiles={taskId ? 5 - taskData.attachments.length + deletedTaskAttachments.length : 5}
                          formByFile="UPLOAD_DEFAULT"
                          maxSizeFileMB={20}
                        />
                        {taskId && (
                          <AttachmentsOnEditForm
                            taskId={taskId}
                            uploadedAttacnments={taskData.attachments}
                            deletedTaskAttachments={deletedTaskAttachments}
                            setDeletedTaskAttachments={setDeletedTaskAttachments}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-0">
                      <Col className="text-left">
                        <Button type="submit" className="font-weight-bold" active={!listLoading} disabled={listLoading}>
                          {listLoading && <Spinner animation="border" variant="light" />}
                          {!listLoading ? (taskId ? `Atualizar` : `Salvar`) : ''}
                        </Button>
                      </Col>
                      <Col className="text-right">
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() => history.goBack()}
                          className="font-weight-bold"
                        >
                          <i className="fa fa-chevron-left"></i>Voltar
                        </Button>{' '}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default TasksUpdatePage
