import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

/**
 * Retorna da api objeto com todos os Ativos Intangíveis que compoe :
 * a primeira aba da interface Administração de Ativos e Solicitações
 * @returns
 */

export const getIntangibleAssetsAdminUnit = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/admin/units/user/intangible_assets`, {
    params: { per_page, page, search, sort_field, ordering },
  })

/**
 * Retorna da api objeto com todas as Unidades administradas pelo Administrador.
 * @returns
 */

export const getAdminUnitsByUser = async () =>
  await axios.get(`${API_BASE_URL}/api/admin/units/user/adminlUnits/allUnits`)

/**
 * Retorna da api objeto com todos as Solicitações que cestão sob a supervisão do :
 * Administrador de Unidade e que compõe a
 * segunda aba da interface Administração de Ativos e Solicitações
 * @returns
 */

export const getSolicitationsUnderAdminUnitResponsibility = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/admin/units/user/solicitations`, {
    params: { per_page, page, search, sort_field, ordering },
  })

/**
 * Retorna da api objeto com os quantitativos de solicitações agrupadas por estágios que estão sob a supervisão
 *  Administrador de Unidade (NÃO LEGADAS DE OUTRAS UNIDADES) e que compõe a
 * terceira aba da interface Administração de Ativos e Solicitações.
 * @returns
 */

export const getQtdSolicitationsFromStagesAdminUnitNoLegacy = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/admin/units/user/solicitations_by_stage/no_legacy`, {
    params: { per_page, page, search, sort_field, ordering },
  })
/**
 * Retorna da api objeto com os quantitativos de solicitações agrupadas por estágios que estão sob a supervisão
 *  Administrador de Unidade (LEGADAS DE OUTRAS UNIDADES) e que compõe a
 * terceira aba da interface Administração de Ativos e Solicitações.
 * @returns
 */

export const getQtdSolicitationsFromStagesAdminUnitLegacy = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/admin/units/user/solicitations_by_stage/legacy`, {
    params: { per_page, page, search, sort_field, ordering },
  })
