import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Alert, Col, Row, Button, Container } from 'react-bootstrap'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { getSolicitationManagement } from '../../_redux/attendances/attendanceActions'
import Accordion from '../../../../components/Accordion'
import RequirementsContent from '../../components/attendances/RequirementsContent'
import HeaderSolicitationManagement from '../../components/attendances/HeaderSolicitationManagement'
import formObject from '../../../../Utils/formObject'
import { cleanFormsHash, getHash, systemFormsLogin } from '../../_redux/services/requirement/requirementActions'

const SolicitationRequirementsPage = () => {
  const { id1: solicitationId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { saveError, solicitationSelected } = useSelector((state) => state.attendance)
  const { systemFormsToken } = useSelector((state) => state.requirements)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getSolicitationManagement(solicitationId))

    if (systemFormsToken == null) {
      dispatch(
        systemFormsLogin(
          process.env.REACT_APP_API_FORMS_LOGIN_USEREMAIL,
          process.env.REACT_APP_API_FORMS_LOGIN_PASSWORD
        )
      )
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (solicitationSelected !== null && systemFormsToken !== null) {
      if (solicitationSelected.requirements.length > 0) {
        dispatch(cleanFormsHash())
        solicitationSelected.requirements.map((item) => {
          if (item.type.slug === 'formulario-eletronico') {
            dispatch(
              getHash(
                formObject(
                  solicitationSelected.user.email,
                  solicitationSelected.id,
                  solicitationSelected.code,
                  item.code,
                  'http://localhost:3000',
                  item.form_hash,
                  solicitationSelected.intangibleAssetOriginParent.code,
                  solicitationSelected.service.code
                ),
                systemFormsToken,
                item.code
              )
            )
          }
          return true
        })
      }
    }

    // eslint-disable-next-line
  }, [solicitationSelected])

  // useEffect(() => {

  // }, [])

  const subheader = useSubheader()
  const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  const hasSolicitationSelected = !!solicitationSelected

  const requirements = solicitationSelected !== null ? solicitationSelected.requirements : []

  const mountAccordionRequirements = () => {
    if (!requirements) {
      return
    }

    return requirements.map((requirement) => {
      return {
        headerText: `${requirement.code} - ${requirement.description}`,
        bodyComponent: () => RequirementsContent({ requirement }),
      }
    })
  }

  return (
    <>
      {hasSolicitationSelected && (
        <Card border="secondary">
          <Card.Body>
            <Container>
              {saveError.length > 0 && (
                <Alert variant="danger">
                  {Object.values(saveError[0]).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                  ))}
                </Alert>
              )}
              <Row>
                <Col className="text-right"></Col>
              </Row>
              <Row className="m-0" style={{ width: '100%' }}>
                <HeaderSolicitationManagement
                  code={solicitationSelected.code}
                  stage={solicitationSelected.stage}
                  actual_attendant={solicitationSelected.actual_attendant}
                  organizationsInitials={solicitationSelected.solicitation_organization.initials}
                  itemsLittleSausage={[
                    solicitationSelected.parentType === 'Solicitation'
                      ? {
                          type: 'solicitation',
                          code: solicitationSelected.parent.code,
                          title:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description.length > 70
                                ? `${solicitationSelected.parent.description.substring(0, 70)} ...`
                                : solicitationSelected.parent.description
                              : solicitationSelected.parent.title.length > 70
                              ? `${solicitationSelected.parent.title.substring(0, 70)}...`
                              : solicitationSelected.parent.title,
                          titleModal:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.title,
                          description:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.description,
                          serviceCode: solicitationSelected.service.code,
                          serviceTitle: solicitationSelected.service.title,
                          solicitationSlug:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.slug
                              : '',
                          solicitationStage:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.stage
                              : '',
                          solicitationOrganizationInitials:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.organization.initials
                              : '',
                          momentByUser:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.momentByUser
                              : '',
                          solicitationDescription: solicitationSelected.description,
                        }
                      : {
                          type: 'intangible-asset',
                          code: solicitationSelected.intangibleAssetOriginParent.code,
                          title: solicitationSelected.intangibleAssetOriginParent.title,
                          titleModal: solicitationSelected.service.title,
                          description: solicitationSelected.intangibleAssetOriginParent.description,
                          momentByUser: solicitationSelected.intangibleAssetOriginParent.moment_by_user,
                          organizationInitials: solicitationSelected.intangibleAssetOriginParent.organization_initials,
                        },

                    {
                      type: 'service',

                      code: solicitationSelected.service.code,
                      title:
                        solicitationSelected.service.title.length > 70
                          ? `${solicitationSelected.service.title.substring(0, 70)}...`
                          : solicitationSelected.service.title,
                      serviceCode: solicitationSelected.service.code,
                      serviceTitle: solicitationSelected.service.title,
                      titleModal: solicitationSelected.service.title,
                      serviceDescription: solicitationSelected.service.description,
                    },
                  ]}
                />
              </Row>
              <Row>
                <Col sm={12}>
                  <h4>Requisitos atendidos para realização do serviço.</h4>
                  <br />
                  {requirements && requirements.length > 0 ? (
                    <Accordion items={mountAccordionRequirements()} />
                  ) : (
                    <Alert variant="alert alert-custom alert-light-primary alert-dismissible">
                      Não existem requisitos para esta solicitação.
                    </Alert>
                  )}
                </Col>
              </Row>

              <Row>
                <Col sm={12}></Col>
              </Row>
              <Row className="mt-0">
                <Col className="text-right">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => history.push(`/solicitacao/gerencia-da-solicitacao/${solicitationId}`)}
                    className="font-weight-bold"
                  >
                    <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                  </Button>{' '}
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default SolicitationRequirementsPage
