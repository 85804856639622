import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

/**
 * Retorna dados de um usuário do sistema
 * @param {*} id
 * @returns
 */
export async function getAudit(id) {
  return axios.get(`${API_BASE_URL}/api/admin/auditing/audit/${id}/view`)
}

export const getAuditList = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/admin/auditing/logs`, {
    params: { per_page, page, search, sort_field, ordering },
  })
