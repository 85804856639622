import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { BadgeGT } from '../../../../components'
import TooltipCustom from '../../../../components/TooltipCustom'

const SolicitationDataModal = ({
  solicitationCode,
  serviceCode,
  serviceTitle,
  serviceDescription,
  solicitationSlug,
  solicitationStage,
  organizationInitials,
  description,
  createdIn,
}) => {
  return (
    <div style={{ textAlign: 'left', overflow: 'hidden' }}>
      <Row>
        <Col>
          <strong>Solicitação</strong>
        </Col>
      </Row>
      <Row style={{ marginLeft: '2px' }}>
        <h3>
          <strong>{solicitationCode}</strong>
        </h3>
      </Row>
      <Row>
        <span className="btn btn-sm font-weight-bold text-center">
          <BadgeGT
            variant={solicitationSlug}
            text={solicitationStage}
            style={{ marginLeft: '3px', width: 'auto', fontSize: '12px' }}
          />
        </span>
        &nbsp;&nbsp;
        {organizationInitials !== null && (
          <>
            <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
              <span className="btn btn-sm font-weight-bold text-center">
                <BadgeGT
                  variant={'secondary'}
                  text={organizationInitials}
                  style={{ width: 'auto', fontSize: '12px', marginLeft: '0px' }}
                />
              </span>
            </TooltipCustom>
          </>
        )}
      </Row>

      <Row>
        <Col>
          <strong className="mt-5">Descrição da Solicitação</strong>
          <p>{description}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong className="mt-5">Serviço</strong>
          <p>
            <strong>{serviceCode}</strong>&nbsp;-&nbsp;{serviceTitle}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>
            <p>
              <small>
                <span>{createdIn}</span>
              </small>
            </p>
          </strong>
        </Col>
      </Row>
    </div>
  )
}

export default SolicitationDataModal
