import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { Table, Button } from '../../../../components'
import { getAttendantsQualifiedForService } from '../../_redux/services/serviceActions'

const orderByOptions = [
  { value: 'attendant', text: 'Nome do Atendente' },
  { value: 'assistanceInThisService', text: 'Atendimentos em Andamento neste Serviço' },
  { value: 'generalAssistance', text: 'Atendimentos em Andamento em todos os Serviços' },
  { value: 'initialsUnit', text: 'Sigla da Unidade do Atendente' },
  { value: 'unit', text: 'Nome da Unidade do Atendente' },
]

export function AttendantsQualifiedForService() {
  const dispatch = useDispatch()
  const { id1 } = useParams()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('attendant_id')
  const [ordering, setOrdering] = React.useState('asc')
  const { attendantsList, listLoading } = useSelector((state) => state.services)

  const history = useHistory()

  const arrBreadCrumb = [
    {
      pathname: '/dashboard',
      title: 'Home',
      subTitle: '',
      icon: '',
    },
    {
      pathname: '/servico/meus/administrados',
      title: 'Meus Serviços',
      subTitle: '',
      icon: '',
    },
  ]

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(
    `${getCurrentPageTitleSubTitle(pathname).subTitle} ${
      attendantsList.service ? attendantsList.service.code + ' - ' + attendantsList.service.title : '...'
    }`
  )
  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getAttendantsQualifiedForService(id1, perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [id1, perPage, page, search, sortField, ordering])

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      id: 'bt_01',
      text: <i className="fa fa-search"></i>,
      title: 'Consultar Atendimentos em Andamento',
      onClick: (row) => history.push(`/solicitacao/atendimentos/atendente/${row.attendant_id}/em_curso`),
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'attendant',
      text: 'Atendente',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.attendant}
          <br />
          <span style={{ fontSize: '0.85rem' }}>{row.email}</span>
        </>
      ),
      headerStyle: { width: '50%', textAlign: 'center', verticalAlign: 'middle' },
    },
    {
      dataField: 'assistanceInThisService',
      text: 'Atendimento em andamento neste Serviço',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { verticalAlign: 'middle' },
    },
    {
      dataField: 'generalAssistance',
      text: 'Atendimento em Geral',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { verticalAlign: 'middle' },
    },
    {
      dataField: 'initialsUnit',
      text: 'Unidade',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { verticalAlign: 'middle' },
    },
  ]

  return (
    <Card border="secondary">
      <Card.Body>
        <Table
          data={attendantsList.data}
          noDataMessage="Não existem atendentes habilitados para este serviço."
          columns={columns}
          page={attendantsList.current_page}
          perPage={perPage}
          totalSize={attendantsList.total}
          orderByOptions={orderByOptions}
          orderByOnChange={(field) => setSortField(field)}
          orderByDirectionChange={(direction) => setOrdering(direction)}
          onSizePerPageChange={(perPage) => {
            setPerPage(perPage)
            setPage(1)
          }}
          onPageChange={(page) => setPage(page)}
          onTableChange={() => null}
          searchPlaceholder="Pesquise aqui"
          perPagePosition="top"
          actions={actionsTable}
          onSearch={(term) => setSearch(term)}
          searchValue={search}
          loading={listLoading}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col className="text-right">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <i className="fa fa-chevron-left"></i>&nbsp;Voltar
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}
