import { createSlice } from '@reduxjs/toolkit'

const initialAdminUnitState = {
  listLoading: true,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  intangibleAssetList: [],
  adminUnits: [],
  solicitationsList: [],
  qtdSolByStageNoLegacyList: [],
  qtdSolByStageLegacyList: [],
  formData: {},
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const adminUnitSlice = createSlice({
  name: 'adminUnit',
  initialState: initialAdminUnitState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.statusMessage = action.payload ? action.payload.message : null
    },

    getAdminUnitsByUser: (state, action) => {
      state.adminUnits = action.payload.map((obj) => {
        return { label: obj.initials + ' - ' + obj.name, value: obj.id }
      })

      // PAREI AQUI = FORMATO ACEITAVEL NO REACT SELECT ({value: '', label: ''})
      state.error = null
    },

    getIntangibleAsset: (state, action) => {
      state.intangibleAssetList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
    getSolicitations: (state, action) => {
      state.solicitationsList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
    getQtdSolNoLegacyByStage: (state, action) => {
      state.qtdSolByStageNoLegacyList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
    getQtdSolLegacyByStage: (state, action) => {
      state.qtdSolByStageLegacyList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    clearLastError: (state) => {
      state.lastError = null
    },

    clearSaveError: (state) => {
      state.saveError = null
    },

    setStatusMessage: (state, action) => {
      state.saveStatus = action.payload ? action.payload.status : null
      state.statusMessage = action.payload ? action.payload.message : null
    },

    saveUserStatusSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.statusMessage = action.payload
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },
  },
})
