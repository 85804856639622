import * as requestFromServer from './requirementEndpoints'
import { requirementSlice } from './requirementSlice'

const { actions } = requirementSlice

/**
 * Objeto com um Requisito específico do serviço
 * @param {*} id //id do serviço
 * @returns
 */
export const getRequirement = (idService, idRequirement) => (dispatch) => {
  dispatch(actions.setStatusMessage('Requisito do serviço'))
  return requestFromServer
    .getRequirementObj(idService, idRequirement)
    .then((response) => {
      dispatch(actions.getRequirementObj(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto com um Requisito de versão específico do serviço
 * @param {*} id //id do serviço
 * @returns
 */
export const getRequirementVersion = (idService, idRequirementVersion) => (dispatch) => {
  dispatch(actions.setStatusMessage('Versão do requisito do serviço'))
  return requestFromServer
    .getRequirementVersionObj(idService, idRequirementVersion)
    .then((response) => {
      dispatch(actions.getRequirementVersionObj(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto com dados do formulario (cadastro/edição) de requisito do serviço
 * @param {*} id //id do serviço
 * @returns
 */
export const getRequirementSupport = (idService) => (dispatch) => {
  dispatch(actions.setStatusMessage('Dados de suporte para formulário de Cadastro/edição de requisito'))
  return requestFromServer
    .getRequirementSupport(idService)
    .then((response) => {
      dispatch(actions.getRequirementSupport(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto com  Requisitos do serviço
 * @param {*} id //id do serviço
 * @returns
 */
export const getRequirements = (idService) => (dispatch) => {
  //dispatch(actions.setStatusMessage('Service .....'))
  return requestFromServer
    .getRequirementsObj(idService)
    .then((response) => {
      dispatch(actions.getAllRequirementsObj(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**Arquiva um requisito
 * @param {*} id
 * @returns
 */
export const archiveRequirementRegister = (idRequirement, userId) => (dispatch) => {
  dispatch(actions.setStatusMessage('Arquivando Requisito'))
  return requestFromServer
    .archiveRequirement(idRequirement, userId)
    .then((response) => {
      dispatch(actions.archiveRequirement(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**Arquiva um requisito
 * @param {*} idRequirement // Identificador do requerimento
 * @returns json com mensagem e status
 */
export const unarchiveRequirementRegister = (idRequirement) => (dispatch) => {
  dispatch(actions.setStatusMessage('Desarquivando Requisito'))
  return requestFromServer
    .unarchiveRequirement(idRequirement)
    .then((response) => {
      dispatch(actions.unarchiveRequirement(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**Excluir um requisito
 * @param {*} idService
 * @param {*} idRequirement
 * @returns
 */
export const deleteRequirementRegister = (idService, idRequirement) => (dispatch) => {
  dispatch(actions.setStatusMessage('Excluindo Requisito'))
  return requestFromServer
    .deleteRequirement(idService, idRequirement)
    .then((response) => {
      dispatch(actions.deleteRequirement(response.data))
      dispatch(actions.setSuccessOnDelete(response.data.message))
      dispatch(actions.clearErrorOnDelete())
      dispatch(actions.clearSuccessOnDelete())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setErrorOnDelete(error.response.data.message))
      dispatch(actions.clearSuccessOnDelete())
      dispatch(actions.clearErrorOnDelete())
    })
}

/**Excluir uma versão do Anexo do requisito
 * @param {*} idService
 * @param {*} idAttachmentVersion
 * @returns
 */
export const deleteAttachmentRequirementVersion = (idService, idAttachmentVersion) => (dispatch) => {
  dispatch(actions.setStatusMessage('Excluindo versão do Anexo do requisito'))
  return requestFromServer
    .deleteAttachmentVersion(idService, idAttachmentVersion)
    .then((response) => {
      dispatch(actions.deleteAttachmentRequirementVersion(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/** Setar no estado o o viewRequirement (objeto de consulta do requisito)
 * @param {*} objViewRequirement
 * @returns
 */
export const setViewRequirementRegister = (objViewRequirement) => (dispatch) => {
  dispatch(actions.setStatusMessage('Setando registro de requisito para consulta'))
  return dispatch(actions.setViewRequirement(objViewRequirement))
}
/** Limpar no estado o o requirementToEdit (objeto de dados para edição do requisito)
 * @returns
 */
export const clearViewRequirementToEditRegister = () => (dispatch) => {
  return dispatch(actions.clearRequirementDataToEdit())
}
/** Limpar no estado o o viewRequirement (objeto de consulta do requisito)
 * @returns
 */
export const clearViewRequirementRegister = () => (dispatch) => {
  return dispatch(actions.clearRequirementObj())
}

/** Criar requisito
 * @param {*} idService
 * @param {*} newRequirementObj
 * @returns
 */
export const createRequirement = (idService, newRequirementObj) => (dispatch) => {
  return requestFromServer
    .newRequirement(idService, newRequirementObj)
    .then((response) => {
      dispatch(actions.setStatusMessage(response.data.requirement))
      dispatch(actions.saveRequirementStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/** Dados de Edição do requisito
 * @param {*} idService
 * @param {*} idRequirement
 * @returns
 */
export const getRequirementDataToEdit = (idService, idRequirement) => (dispatch) => {
  return requestFromServer
    .getRequirementToEdit(idService, idRequirement)
    .then((response) => {
      dispatch(actions.getRequirementDataToEdit(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/** Atualizar requisito
 * @param {*} idService
 * @param {*} idRequirement
 * @param {*} newRequirementObj
 * @returns
 */
export const updateRequirement = (idService, idRequirement, newRequirementObj) => (dispatch) => {
  return requestFromServer
    .editRequirement(idService, idRequirement, newRequirementObj)
    .then((response) => {
      dispatch(actions.setStatusMessage(response.data.requirement))
      dispatch(actions.saveRequirementStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

// INÍCIO REQUISITOS ARQUIVADOS| REQUIREMENT DISCONTINUED.

/**
 * Lista de requisitos arquivados
 * @param {*} idService
 * @returns redux action
 */
export const getRequirementsDiscontinued = (idService) => (dispatch) => {
  return requestFromServer
    .getAllRequirementsDiscontinued(idService)
    .then((response) => {
      dispatch(actions.getAllRequirementsDiscontinued(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

// FIM REQUISITOS ARQUIVADOS| REQUIREMENT DISCONTINUED.

/**
 * Autenticação no Sistema de Formulário eletrônico
 *
 * @param {*} userEmail
 * @param {*} password
 * @returns
 */
export const systemFormsLogin = (userEmail, password) => (dispatch) => {
  return requestFromServer
    .systemFormsLoginEndpoint(userEmail, password)
    .then((response) => {
      dispatch(actions.setSystemFormsToken(response.data.authorisation.token))

      // eslint-disable-next-line
      let arrayResult = response.data.clients.filter((item) => {
        if (item.name.toLowerCase().indexOf('gestec')) {
          return item
        }
      })

      dispatch(actions.setSystemFormsHash(arrayResult[0].hash))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Listar Formularios Eletrônicos
 * @param {*} hash //hash do client
 * @param {*} token //token de autenticação no sistema de Formulário eletrônico
 * @returns
 */
export const getEletronicForms = (hash, token) => (dispatch) => {
  return requestFromServer
    .getAllForms(hash, token)
    .then((response) => {
      dispatch(actions.setSystemFormsList(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Pegar Hash
 * @param {*} object //objeto
 * @param {*} token //token de autenticação no sistema de Formulário eletrônico
 * @returns
 */
export const getHash = (object, token, requirementCode) => (dispatch) => {
  return requestFromServer
    .getHashForm(object, token)
    .then((response) => {
      //dispatch(actions.setEncryptedListFormHash({ hash: response.data, requirement_code: requirementCode }))
      const textLinkForm = `${process.env.REACT_APP_API_FORMS_URL}/form/${response.data}/block`
      const textLinkFormView = `${process.env.REACT_APP_API_FORMS_URL}/form/${response.data}/view`
      dispatch(
        actions.setEncryptedListFormHash({
          hash: response.data,
          requirement_code: requirementCode,
          linkForm: textLinkForm,
          linkFormView: textLinkFormView,
        })
      )
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Pegar HashDemo
 * @param {*} object //objeto
 * @param {*} token //token de autenticação no sistema de Formulário eletrônico
 * @returns
 */
export const getHashDemo = (object, token, form_hash) => (dispatch) => {
  return requestFromServer
    .getHashForm(object, token)
    .then((response) => {
      const urlDemoForm = `${process.env.REACT_APP_API_FORMS_URL}/form/${response.data}/block`
      dispatch(
        actions.setEncryptedListFormHashDemo({
          hash: response.data,
          form_hash: form_hash,
          linkFormDemo: urlDemoForm,
        })
      )
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Setar Link de visualização de formulário
 * @param {*} requirements //objeto
 * @returns
 */
export const getFormsLinksViews = (requirements) => (dispatch) => {
  return dispatch(actions.setFormsLinksViews(requirements))
}

export const cleanFormsHash = () => (dispatch) => {
  return dispatch(actions.clearEncryptedListFormHash())
}

export const clearSystemForms = () => (dispatch) => {
  return dispatch(actions.clearSystemFormsList())
}

export const setReloadForms = (value) => (dispatch) => {
  return dispatch(actions.setReloadListEletronicForms(value))
}
