import * as requestFromServer from './tasksEndpoints'
import { taskSlice } from './tasksSlice'

const { actions } = taskSlice

/**
 * Pegar dados da basicos para criação de tarefas
 */
export const getTaskRegisterData = () => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getTaskRegisterData()
    .then((response) => {
      dispatch(actions.setTaskRegisterData(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Pegar dados da basicos para edição de tarefas
 */
export const getTaskUpdateData = (taskId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getTaskUpdateData(taskId)
    .then((response) => {
      dispatch(actions.setTaskUpdateData(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Salvar uma tarefa
 */
export const createTask = (obj) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .createTask(obj)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      throw error
    })
}

/**
 * Editar uma tarefa
 */
export const updateTask = (obj) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .updateTask(obj)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      throw error
    })
}

/**
 * Cancelar uma tarefa
 */
export const cancelTask = (obj) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .cancelTask(obj)
    .then((response) => {
      if (response.data.status === 'success') {
        dispatch(actions.successMessage(response.data.message))
      } else {
        dispatch(actions.catchError({ message: [response.data.message] }))
      }
      dispatch(getTaskUpdateData(obj.taskId))
      dispatch(getTaskInteractionsData(obj.taskId))
      dispatch(actions.successMessage(response.data.message))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      throw error
    })
}

export const cleanLastError = () => (dispatch) => {
  dispatch(actions.setLastError(null))
}

export const cleanSaveStatus = () => (dispatch) => {
  dispatch(actions.cleanSaveStatus())
}

export const clearSaveError = () => (dispatch) => {
  dispatch(actions.clearSaveError())
}

export const setTaskRegisterData = (task) => (dispatch) => {
  dispatch(actions.setTaskRegisterData(task))
}

export const setTaskUpdateData = (task) => (dispatch) => {
  dispatch(actions.setTaskUpdateData(task))
}

/**
 * Tarefas Recebidas
 */
export const getReceivedTasks = (idUser, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getReceivedTask(idUser, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getReceivedTask(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Tarefas Criadas
 */
export const getCreatedTasks = (idUser, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getCreatedTask(idUser, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getCreatedTask(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Pegar dados de uma tarefa
 */
export const getTaskInteractionsData = (taskId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getTaskInteractionsData(taskId)
    .then((response) => {
      dispatch(actions.setTaskData(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Criar interação de tarefas
 */
export const createTaskInteraction = (obj) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .createTaskInteraction(obj)
    .then((response) => {
      if (response.data.status === 'success') {
        dispatch(actions.successMessage(response.data.message))
      } else {
        dispatch(actions.catchError({ message: [response.data.message] }))
      }

      dispatch(getTaskInteractionsData(obj.taskId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Excluir interação de tarefa
 */
export const deleteTaskInteraction = (taskId, taskInteractionId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .deleteTaskInteraction(taskInteractionId)
    .then((response) => {
      if (response.data.status === 'success') {
        dispatch(actions.successMessage(response.data.message))
      } else {
        dispatch(actions.catchError({ message: [response.data.message] }))
      }
      dispatch(getTaskInteractionsData(taskId))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Finalizar tarefa
 */
export const finishTask = (taskId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .finishTask(taskId)
    .then((response) => {
      dispatch(getTaskInteractionsData(taskId))
      dispatch(actions.successMessage(response.data.message))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}
