import React, { useEffect } from 'react'
import { Card, Col, NavLink, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { ReadMore, Table, Button, BadgeGT, AlertDotStage } from '../../../../components'
import { getAttendanceOngoing } from '../../_redux/attendances/attendanceActions'
import TooltipCustom from '../../../../components/TooltipCustom'

const orderByOptions = [
  { value: 'solicitation_code', text: 'Código da Solicitação' },
  { value: 'solicitation_description', text: 'Descrição da Solicitação' },
  { value: 'service_code', text: 'Código do Serviço' },
  { value: 'service_title', text: 'Título do Serviço' },
  { value: 'attendance_start_date', text: 'Início do Atendimento' },
  { value: 'stage', text: 'Estágio da Solicitação' },
  { value: 'unitInitials', text: 'Sigla da Unidade' },
  { value: 'unit', text: 'Nome da Unidade' },
  { value: 'completion_prediction', text: 'Previsão de Conclusão' },
]

export function AttendanceOngoingPage() {
  const { id1 } = useParams()
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('id')
  const [ordering, setOrdering] = React.useState('desc')
  const { attendancesOngoing, listLoading } = useSelector((state) => state.attendance)

  const [showAlertDotStage, setShowAlertDotStage] = React.useState(false)

  const history = useHistory()

  const pathname = window.location.pathname

  const arrBreadCrumb = [
    {
      pathname: '/servico/meus/administrados',
      title: 'Meus Serviços',
      subTitle: '',
      icon: '',
    },
  ]

  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(
    `Panorama atual dos atendimentos em andamento de ${attendancesOngoing.attendant} (${attendancesOngoing.attandant_email}).`
  )

  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getAttendanceOngoing(id1, perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  const handleViewSolicititation = (solicitation_id) => {
    // history.push(`/solicitacao/${solicitation_id}/gerenciamento`)
    history.push(`/solicitacao/gerencia-da-solicitacao/${solicitation_id}`)
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-search fa-5x"></i>,
      title: 'Consultar Solicitação',
      onClick: (row) => handleViewSolicititation(row.solicitation_id),
      disabled: () => null,
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'solicitation_code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      formatter: (cell, row) => <strong style={{ fontSize: '0.85rem' }}>{row.solicitation_code}</strong>,
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'originFile',
      text: 'Dados da Solicitação',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <p>
            <ReadMore text={row.solicitation_description} maxCharacters={70} />
          </p>
          <p>{`${row.service_code} - ${row.service_title}`}</p>
        </>
      ),
      headerStyle: { width: '60%', textAlign: 'center' },
    },
    {
      dataField: 'attendance_start_date',
      text: 'Início do Atendimento',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <p>{row.attendance_start_date}</p>
          <strong style={{ fontSize: '0.85rem' }}>{row.attendance_start_data_for_humans}</strong>
        </>
      ),
      headerStyle: { width: '15%', textAlign: 'center' },
    },
    // {
    //   dataField: 'completion_prediction',
    //   text: 'Previsão de Conclusão',
    //   align: 'center',
    //   sort: false,
    //   classes: 'align-middle',
    //   formatter: (cell, row) => (
    //     <>
    //       <p>{row.completion_prediction}</p>
    //       <strong style={{ fontSize: '0.85rem' }}>{row.completion_prediction_for_humans}</strong>
    //     </>
    //   ),
    //   headerStyle: { width: '15%', textAlign: 'center' },
    // },
    {
      dataField: 'stage_slug',
      text: 'Estágio',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.stage} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={row.stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '5%', textAlign: 'right' },
    },
    {
      dataField: 'unitInitials',
      text: 'Unidade',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
  ]
  // const hasAttendancesOngoing = Object.keys(attendancesOngoing).length > 0

  return (
    <>
      <AlertDotStage
        show={showAlertDotStage}
        onConfirm={() => setShowAlertDotStage(false)}
        onDismissed={() => setShowAlertDotStage(false)}
      />
      <Card border="secondary">
        <Card.Body>
          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotStage(true)}>
                Legenda dos Estágios da Solicitação
              </NavLink>
            </Col>
          </Row>
          <Table
            data={attendancesOngoing.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Não existem atendimentos em andamento no momento.`
            }
            columns={columns}
            page={attendancesOngoing.current_page}
            perPage={perPage}
            totalSize={attendancesOngoing.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={8}></Col>
            <Col md={4} className="text-right">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
