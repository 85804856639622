import React, { useEffect } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle, getAllBreadcrumbs } from '../../../routes/Helpers'
import { AlertGT, BadgeGT, BigSausage, BlockInfo, Button, ReadMore } from '../../../components'
import { getSolicitationHistory, emptyHistory } from '../_redux/solicitation/solicitationActions'

import { Chrono } from 'react-chrono'
import TooltipCustom from '../../../components/TooltipCustom'
import { Code } from '../components/solicitation/ListBigSausage/ListBigSausage.styles'
import IntangibleAssetDataModal from '../../Tasks/components/IntangibleAssetDataModal'
import ServiceDataModal from '../../Tasks/components/ServiceDataModal'
import SolicitationDataModal from '../../Tasks/components/SolicitationDataModal'

function SolicitationHistoryPage() {
  const dispatch = useDispatch()
  const { id1 } = useParams()
  const pathname = window.location.pathname

  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(emptyHistory())
    dispatch(getSolicitationHistory(id1))
    // eslint-disable-next-line
  }, [dispatch, id1, pathname])

  const { solicitationHistoryObj } = useSelector((state) => state.solicitations)
  const history = useHistory()

  const items = []
  if (typeof solicitationHistoryObj.historyList !== 'undefined') {
    solicitationHistoryObj.historyList.map((item) => {
      return items.push({ title: item.moment })
    })
  }
  const itemsActions = []
  if (typeof solicitationHistoryObj.historyList !== 'undefined') {
    solicitationHistoryObj.historyList.map((item) => {
      return itemsActions.push({ cardTitle: item.action })
    })
  }

  const Info = ({ data, icon, title }) => {
    const [configAlert, setConfigAlert] = React.useState({
      show: false,
      title: '',
      text: '',
    })

    return (
      <>
        <AlertGT
          show={configAlert.show}
          title={configAlert.title}
          text={configAlert.text}
          width={800}
          onConfirm={() => setConfigAlert({ ...configAlert, show: false })}
          onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
          confirmButtonText="Fechar"
          showCancelButton={false}
        />
        <div className="d-flex align-items-center mb-3">
          <BlockInfo>
            <TooltipCustom title={title} placement="left" arrow>
              {icon}
            </TooltipCustom>{' '}
            <strong>{data.code + ' - '}</strong> {data.title || data.description.substring(0, 80) + '...'}
          </BlockInfo>
          <div style={{ width: 32, textAlign: 'center' }}>
            <TooltipCustom title="Mais Informações" placement="top" arrow>
              <i
                style={{ fontSize: '1.5rem' }}
                className="fas fa-info-circle"
                onClick={() => {
                  if (data.type === 'intangilbleAsset') {
                    setConfigAlert({
                      //title: data.code + (data.title ? ' - ===> ' + data.title : ''),
                      text: (
                        <IntangibleAssetDataModal
                          intangibleAssetCode={data.code}
                          intangibleAssetTitle={data.title}
                          intangibleAssetDescription={data.description}
                          intangibleAssetUserMomentCreate={data.moment_by_user}
                          intangibleAssetUnitInitInitials={data.organization_initials}
                        />
                      ),
                      show: true,
                    })
                  } else if (data.type === 'service') {
                    setConfigAlert({
                      //title: data.code + (data.title ? ' - ===> ' + data.title : ''),
                      text: (
                        <ServiceDataModal
                          ServiceCode={data.code}
                          ServiceTitle={data.title}
                          ServiceDescription={data.description}
                          ServiceUserMomentCreate={null}
                        />
                      ),
                      show: true,
                    })
                  } else {
                    setConfigAlert({
                      //title: data.code + (data.title ? ' - ===> ' + data.title : ''),
                      text: (
                        <SolicitationDataModal
                          solicitationCode={data.code}
                          serviceCode={data.service.code}
                          serviceTitle={data.service.title}
                          serviceDescription={data.service.description}
                          solicitationSlug={data.stage.slug}
                          solicitationStage={data.stage.stage}
                          organizationInitials={data.solicitation_organization.initials}
                          description={data.description}
                          createdIn={data.moment_by_user}
                        />
                      ),
                      show: true,
                    })
                  }
                }}
              ></i>
            </TooltipCustom>
          </div>
        </div>
      </>
    )
  }

  const Header = ({ code, stage, solicitationOrganization }) => {
    return (
      <Row style={{ width: '100%' }}>
        <Col className="col-12 d-flex align-items-center">
          {code && <Code>{code}</Code>}
          <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
            <span>
              <BadgeGT variant={'secondary'} text={solicitationOrganization.initials} />
            </span>
          </TooltipCustom>
          &nbsp;&nbsp;
          <BadgeGT variant={stage.slug} text={stage.stage} />
        </Col>
      </Row>
    )
  }
  const Body = ({ intangibleAsset, service, parent, description }) => {
    return (
      <>
        <Row style={{ width: '100%' }}>
          <Col className="col-12">
            {intangibleAsset && (
              <Info
                data={{ ...intangibleAsset, type: 'intangilbleAsset' }}
                icon={<i className="fas fa-atom"></i>}
                title="Ativo Intangível"
              />
            )}
            {service && (
              <Info
                data={{ ...service, type: 'service' }}
                icon={<i className="fas fa-concierge-bell"></i>}
                title="Serviço Solicitado"
              />
            )}
            {parent && <Info data={parent} icon={<i className="fas fa-project-diagram"></i>} title="Origem" />}
            {description && <ReadMore text={description} maxCharacters={180} />}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Row className="mb-5 ml-2">
            {typeof solicitationHistoryObj.id !== 'undefined' && (
              <>
                <BigSausage
                  key={0}
                  header={
                    <Header
                      code={solicitationHistoryObj.code}
                      stage={solicitationHistoryObj.stage}
                      solicitationOrganization={solicitationHistoryObj.solicitation_organization}
                    />
                  }
                  body={
                    <Body
                      intangibleAsset={solicitationHistoryObj.intangibleAssetOriginParent}
                      service={solicitationHistoryObj.service}
                      parent={solicitationHistoryObj.solicitationParent}
                      description={solicitationHistoryObj.description}
                    />
                  }
                />
              </>
            )}
          </Row>
          <hr />
          <Row className="mb-5 ml-1 w-100">
            {items.length > 0 && (
              <>
                <Chrono
                  items={items}
                  mode="VERTICAL"
                  scrollable={{ scrollbar: true }}
                  useReadMore={false}
                  cardHeight={38}
                  enableOutline
                >
                  {typeof itemsActions !== 'undefined' &&
                    itemsActions.map((dataValue, i) => (
                      <div key={i} className="text-left w-100">
                        <p className="text-justify w-100 pt-3" style={{ fontSize: '13px', lineHeight: '1.8' }}>
                          {dataValue.cardTitle}
                        </p>
                      </div>
                    ))}
                </Chrono>
              </>
            )}
          </Row>
        </Card.Body>
        <Card.Footer className="text-right">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default SolicitationHistoryPage
