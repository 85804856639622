import { validateCPF } from 'validations-br'
import * as yup from 'yup'

export const validationSchemaUser = () =>
  yup.object().shape({
    name: yup
      .string()
      .required('O campo "Nome do Usuário" é de preenchimento obrigatório.')
      .min(3, 'O campo "Nome do Usuário" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Nome do Usuário" deve ter no máximo 1000 caracteres.'),
    email: yup
      .string()
      .email('O campo "E-mail do Usuário" deve ser um e-mail válido.')
      .required('O campo "E-mail do Usuário" é de preenchimento obrigatório.'),
    cpf: yup
      .string()
      .required('O campo "Telefone do Usuário" é de preenchimento obrigatório.')
      .test('is-cpf', 'O campo "CPF do Usuário" deve ser um CPF válido.', (value) => validateCPF(value)),
    date_of_birth: yup.string().required('O campo "Data de nascimento" é de preenchimento obrigatório.'),
    phone: yup
      .string()
      .required('O campo "Telefone do Usuário" é de preenchimento obrigatório.')
      .matches(
        /^[0-9 +-\\(\\)]+$/,
        'O campo "Telefone do usuário" aceita somente os caracteres 0 1 2 3 4 5 6 7 8 9 () +- .'
      ),

    organization: yup.string().required('O campo "Unidade de alocação do usuário" é de preenchimento obrigatório.'),
  })

export const validationSchemaUserPhone = () =>
  yup.object().shape({
    phone: yup
      .string()
      .required('Este preenchimento é obrigatório.')
      .max(20, 'Digite no máximo 20 caracteres.')
      .matches(/^(?!.*(\W)\1{1}).*$/, 'Não use caracteres especiais repetidos em sequência. Ex.: ++ // (( )) -- .')

      .matches(/(?=.*[0-9])/, 'Você deve informar números. Ex.: 0 1 2 3 4 5 6 7 8 9.')
      .matches(/^(?!.*(\w)\1{7}).*$/, 'Não digite mais do que 7 números repetidos em sequência. Ex.: 11111111.')
      .matches(/^[0-9 +-\\(\\)]+$/, 'Utilize somente os caracteres: 0 1 2 3 4 5 6 7 8 9 ( ) / . + -.'),
  })

export const validationSchemaFinishUser = () =>
  yup.object().shape({
    password: yup
      .string()
      .required('A senha é de preenchimento obrigatório.')
      .min(8, 'A senha deve conter no mínimo 8 caracteres.')
      .max(16, 'A senha deve conter no máximo 16 caracteres.')
      .matches(/(?=.*[a-z])/, 'A senha requer pelo menos uma letra minúscula em sua composição.')
      .matches(/(?=.*[A-Z])/, 'A senha requer pelo menos uma letra maiúscula em sua composição.')
      .matches(/(?=.*[0-9])/, 'A senha requer pelo menos um número em sua composição.')
      .matches(
        /^(?!.*(01234|12345|23456|34567|45678|56789|67890)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(
        // eslint-disable-next-line
        /^(?!.*(\*\*\*\*\*|\&\&\&\&\&|\%\%\%\%\%|\$\$\$\$\$|\#\#\#\#\#|\@\@\@\@\@|\!\!\!\!\!|\?\?\?\?\?)).*$/,
        'A senha não pode conter mais do que 4 caracteres repetidos em sequência.'
      )
      .matches(
        /^(?!.*(98765|87654|76543|65432|54321|43210)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(/^(?!.*(\d)\1{4}).*$/, 'A senha não pode conter mais do que 4 números sequenciais.')
      .matches(/^(?!.*(\w)\1{4}).*$/, 'A senha não pode conter mais do que 4 caracteres repetidos em sequência.'),
    c_password: yup
      .string()
      .required('O campo "Confirmar Senha" é de preenchimento obrigatório.')
      .oneOf([yup.ref('password'), null], 'Repita a senha informada.'),
  })

export const validationSchemaChangePasswordUser = () =>
  yup.object().shape({
    password: yup
      .string()
      .required('A senha é de preenchimento obrigatório.')
      .min(8, 'A senha deve conter no mínimo 8 caracteres.')
      .max(16, 'A senha deve conter no máximo 16 caracteres.')
      .matches(/(?=.*[a-z])/, 'A senha requer pelo menos uma letra minúscula em sua composição.')
      .matches(/(?=.*[A-Z])/, 'A senha requer pelo menos uma letra maiúscula em sua composição.')
      .matches(/(?=.*[0-9])/, 'A senha requer pelo menos um número em sua composição.')
      .matches(
        /^(?!.*(01234|12345|23456|34567|45678|56789|67890)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(
        // eslint-disable-next-line
        /^(?!.*(\*\*\*\*\*|\&\&\&\&\&|\%\%\%\%\%|\$\$\$\$\$|\#\#\#\#\#|\@\@\@\@\@|\!\!\!\!\!|\?\?\?\?\?)).*$/,
        'A senha não pode conter mais do que 4 caracteres repetidos em sequência.'
      )
      .matches(
        /^(?!.*(98765|87654|76543|65432|54321|43210)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(/^(?!.*(\d)\1{4}).*$/, 'A senha não pode conter mais do que 4 números sequenciais.')
      .matches(/^(?!.*(\w)\1{4}).*$/, 'A senha não pode conter mais do que 4 caracteres repetidos em sequência.'),
    actual_password: yup.string().required('A senha atual é de preenchimento obrigatório.'),
    c_password: yup
      .string()
      .required('O campo "Confirmar Senha" é de preenchimento obrigatório.')
      .oneOf([yup.ref('password'), null], 'A confirmação de senha precisa ser a mesma digitada no campo Nova senha.'),
  })
