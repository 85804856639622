import React, { useState } from 'react'
import { OptionWrapper, SeeDetails } from './SelectService.styles'
import AlertGT from '../../../../../components/AlertGT'

const Option = ({ children, index, item, getItemProps, highlightedIndex, openMenu, handlerSelectItem }) => {
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [alertConfirm, setAlertConfirm] = useState(null)

  const handleSeeDetails = (e) => {
    setAlertText(
      `<p align="justify">
        <b>${item.label}</b><br><br>
        ${item.description}
      </p>`
    )
    setAlertConfirm(() => () => {
      setShowAlert(false)
      handlerSelectItem(item)
    })
    setShowAlert(true)
  }

  return (
    <li
      key={index}
      {...getItemProps({ item, index })}
      style={highlightedIndex === index ? { backgroundColor: '#f5f5f5' } : null}
    >
      <OptionWrapper>
        {item.label}
        <SeeDetails
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleSeeDetails(e)
          }}
        >
          Ver detalhes
        </SeeDetails>
      </OptionWrapper>
      <AlertGT
        show={showAlert}
        title={''}
        text={alertText}
        icon={null}
        showCancelButton={false}
        confirmButtonText={'Selecionar este Serviço'}
        onConfirm={alertConfirm}
        onDismissed={() => {
          setShowAlert(false)
          openMenu()
        }}
        toastSuccess={null}
        showCloseButton
      />
    </li>
  )
}

export default Option
