import React from 'react'
import {
  Wrapper,
  Label,
  Content,
  FileList,
  FileMandatory,
  FileWrapper,
  FileUploaded,
  FileDescription,
  FileLink,
} from './RequirementsContent.styles'
import axios from 'axios'
import { useSelector } from 'react-redux'

const RequirementsContentForm = ({ requirement }) => {
  //const dispatch = useDispatch()
  const { encryptedListFormHash } = useSelector((state) => state.requirements)

  const mandatoryDownloads = requirement.attachments.mandatoryDownloads
  const optionalDownloads = requirement.attachments.optionalDownloads
  const filesMadeAvailable = mandatoryDownloads.concat(optionalDownloads)

  let linkFormView = ''
  if (encryptedListFormHash.length > 0) {
    let result = encryptedListFormHash.find((item) => item.requirement_code === String(requirement.code))

    if (typeof result !== 'undefined') {
      linkFormView = result.linkFormView
    }
  }

  const handleDownload = (downloadId, originalName) => {
    const API_BASE_URL =
      `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_API_URL_HOMOL}`
        : `${process.env.REACT_APP_API_URL_PROD}`

    axios({
      url: `${API_BASE_URL}/api/attachment/${downloadId}/download`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', originalName)
      document.body.appendChild(link)
      link.click()
    })
  }
  return (
    <Wrapper>
      <Label>
        {/* {requirement.type.slug === 'informativo' && 'Leia com atenção o informativo abaixo:'} */}
        {requirement.type.slug === 'formulario-eletronico' && 'Acesse o link abaixo para consultar o formulário:'}
      </Label>

      {requirement.type.slug === 'informativo' && (
        <>
          <Content>{requirement.info_message}</Content> <hr />
        </>
      )}
      {requirement.type.slug === 'formulario-eletronico' && (
        <>
          <Content>
            <a href={linkFormView} target="_blank" rel="noopener noreferrer">
              {requirement.code} - {requirement.description}
            </a>
          </Content>
          <hr />
        </>
      )}

      {requirement.type.slug === 'envio-arquivos' && (
        <>
          {requirement.uploadedRequirementFiles.map((file) => (
            <FileUploaded key={`file-uploaded-${file.id}`}>
              <strong>Arquivo: </strong>
              <FileLink
                onClick={() => {
                  handleDownload(file.id, file.original_name)
                }}
              >
                {file.original_name}
              </FileLink>
              <FileDescription>{file.description}</FileDescription>
              <hr />
            </FileUploaded>
          ))}
          <br />
        </>
      )}

      {filesMadeAvailable.length > 0 && (
        <>
          <Label>Arquivos disponibilizados para o solicitante:</Label>
          <FileList>
            {filesMadeAvailable.map((file, index) => (
              <FileWrapper key={`file-made-available-${index}`}>
                <FileMandatory
                  onClick={() => {
                    handleDownload(file.id, file.original_name)
                  }}
                >
                  {file.original_name}
                </FileMandatory>
              </FileWrapper>
            ))}
          </FileList>
        </>
      )}
    </Wrapper>
  )
}

export default RequirementsContentForm
