import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../routes/Helpers'

import { Button, Table } from '../../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { getAuditList, clearSaveError } from '../../_redux/audit/auditActions'

const AuditPage = () => {
  const { user } = useSelector((state) => state.auth)

  const history = useHistory()
  const dispatch = useDispatch()

  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('')
  const [ordering, setOrdering] = React.useState('')

  const { loading, auditList } = useSelector((state) => state.audit)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    dispatch(getAuditList(perPage, page, search, sortField, ordering))
    dispatch(clearSaveError())

    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-search"></i>,
      title: 'Dados',
      onClick: (row) => history.push(`/auditoria/${row.audit_id}`),
      visible: (row) => !row.task_editable,
    },
  ]

  const orderByOptions = [
    { value: 'audit_formated_date', text: 'Momento' },
    { value: 'audit_user_name', text: 'Usuário' },
    { value: 'audit_unit_initials', text: 'Sigla da Unidade' },
    { value: 'audit_event', text: 'Ação Realizada' },
    { value: 'audit_friendly_entity', text: 'Entidade (BD)' },
    { value: 'audit_ip_address', text: 'Endereço de Ip' },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'audit_formated_date',
      text: 'Momento',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '10%' }
      },
    },
    {
      dataField: 'audit_user_name',
      text: 'Usuário',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.audit_user_name}
          <br />
          <strong style={{ fontSize: '0.85rem', textAlign: 'left' }}>{row.audit_user_email}</strong>
        </>
      ),
      headerStyle: { width: '30%', textAlign: 'center' },
    },
    {
      dataField: 'audit_unit_initials',
      text: 'Sigla da Unidade',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '7%', textAlign: 'center' },
    },
    {
      dataField: 'audit_event',
      text: 'Ação Realizada',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },

    {
      dataField: 'audit_friendly_entity',
      text: 'Entidade (BD)',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '25%', textAlign: 'center' },
    },

    {
      dataField: 'audit_ip_address',
      text: 'IP',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
  ]

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Table
            data={auditList.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Não possui logs registrados.`
            }
            columns={columns}
            page={auditList.current_page}
            perPage={perPage}
            totalSize={auditList.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={loading}
          />
        </Card.Body>

        <Card.Footer className="text-right">
          <Button
            variant="secondary"
            onClick={() => {
              history.goBack()
            }}
          >
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default AuditPage
