import styled from 'styled-components'

export const RequiredDownloadWrapper = styled.span`
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-size: 14px;
`

export const FileDescriptionWrapper = styled.div`
  position: relative;
  margin-top: 0px;
  display: flex;

  input {
    min-width: 320px;
    width: 100%;
  }

  .check-marck-file {
    margin-left: 5px;
    margin-top: -5px;
  }
  .file-description-error {
    border: 1px solid red;
  }
`

export const FileErrorMessage = styled.p`
  color: #9aa9bb;
`
