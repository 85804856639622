import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Button, Container, Row, Col, InputGroup } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import TooltipCustom from '../../../components/TooltipCustom'

import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../app/routes/Helpers'

import { ActionButtonsWrapper } from './User.styles'

import { AlertMessage } from '../../Solicitation/components/services/AlertMessage'
import { AlertGT } from '../../../components'

import {
  allIntangibleAssets,
  reportDossierIntangibleAssetExport,
  setLoadingReportBuild,
  setLoadReportBuildRead,
  setReportBuildFalse,
} from '../_redux/reports/reportActions'
import { useSubheader } from '../../../../_metronic/layout'
import { toast } from 'react-toastify'

const DossierIntangibleAssetPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { listLoading, intangibleAssetList, reportRead, buildingReport } = useSelector((state) => state.reports)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    confirm: null,
    toastSuccess: '',
  })

  const [searchEmpty, setSearchEmpty] = useState(false)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(allIntangibleAssets())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (reportRead) {
      dispatch(setReportBuildFalse())
      dispatch(setLoadReportBuildRead())
    }
    // eslint-disable-next-line
  }, [reportRead])

  const { SearchBar } = Search

  const handleDownload = (downloadId, iacode) => {
    dispatch(reportDossierIntangibleAssetExport(downloadId, iacode))
    dispatch(setLoadingReportBuild())
  }

  const intangibleAssetCodeFormatter = (cell, row) => {
    return (
      <>
        <strong>{row.aicode}</strong> <br />
      </>
    )
  }

  if (reportRead) {
    toast.success(`Sucesso! Relatório criado.`, {})
  }
  const userActionButtons = (cell, row) => (
    <ActionButtonsWrapper>
      <TooltipCustom title="Processar relatório" placement="top" arrow style={{ marginRight: 5 }}>
        <Button variant="outline-secondary" size="sm" id={row.id} onClick={() => handleDownload(row.id, row.aicode)}>
          <i className="fas fa-cogs"></i>
        </Button>
      </TooltipCustom>
    </ActionButtonsWrapper>
  )

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'aicode',
      text: 'Código',
      sort: true,
      classes: 'align-middle',
      isDummyField: false,
      headerAttrs: {
        hidden: false,
      },
      formatter: intangibleAssetCodeFormatter,
    },
    {
      dataField: 'title',
      text: 'Título',
      sort: true,
      classes: 'align-middle text-left',
      headerAttrs: {
        hidden: false,
      },
      filterValue: (cell, row) => {
        return [row.title]
      },
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      classes: 'align-middle',
      headerAttrs: {
        hidden: false,
      },
      headerStyle: () => {
        return { minWidth: '60px', with: '50px' }
      },
      isDummyField: true,
      csvExport: false,
      formatter: userActionButtons,
    },
  ]
  /**
   * Alterando o texto de exibição de registros.
   *
   * @param {*} from
   * @param {*} to
   * @param {*} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;&nbsp;Registros de <strong>{from}</strong> a <strong>{to}</strong> de um total de <strong>{size}</strong>.
    </span>
  )

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
          style={{ height: 30, paddingTop: 5 }}
        >
          {option.text}
        </button>
      ))}
    </div>
  )
  /**
   * Parâmetros para paginação
   */
  const pageOptions = {
    page: 1,
    sizePerPage: 5,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: intangibleAssetList.length,
      },
    ],
    sizePerPageRenderer,
  }

  const qtdInSearch = (data, itemSearchText) => {
    const searcheredData = data.filter(function (item) {
      let arrRoleLabels = []
      const searchRolesLabel = arrRoleLabels.join(', ')
      if (searchRolesLabel.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1) {
        return item
      }

      if (
        item.aicode.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.title.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1
      ) {
        return item
      }
      return false
    })
    searcheredData.length > 0 ? setSearchEmpty(false) : setSearchEmpty(true)
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            <>
              {intangibleAssetList.length === 0 && listLoading && (
                <>
                  <Row>
                    <Col className="text-center">
                      <p className="overlay-layer bg-transparent mb-5">
                        <span className="spinner spinner-lg spinner-success" />
                      </p>
                      <p className="h4 mt-10">Carregando Ativos Intangíveis ...</p>
                    </Col>
                  </Row>
                </>
              )}
              {buildingReport && (
                <>
                  <Row>
                    <Col className="text-center">
                      <p className="overlay-layer bg-transparent mb-5">
                        <span className="spinner spinner-lg spinner-success" />
                      </p>
                      <p className="h4 mt-10">Criando relatório, por favor aguarde ...</p>
                    </Col>
                  </Row>
                </>
              )}

              <>
                <ToolkitProvider
                  keyField="id"
                  data={intangibleAssetList}
                  columns={columns}
                  search={{
                    searchFormatted: false,
                  }}
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}></Col>
                        <Col sm={3}>
                          <div className="d-grid"></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="mb-4">
                          <InputGroup>
                            <InputGroup.Text
                              style={{ height: 36, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                              className="input-group-prepend"
                            >
                              <i className="fas fa-search"></i>
                            </InputGroup.Text>
                            <SearchBar
                              {...props.searchProps}
                              placeholder="Digite o alvo de sua busca"
                              className="w-100 input-group-prepend"
                              style={{ height: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: '100%' }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      {qtdInSearch(intangibleAssetList, props.searchProps.searchText)}

                      {searchEmpty && props.searchProps.searchText && (
                        <Row>
                          <Col>
                            <AlertMessage
                              variant="alert alert-custom alert-light-primary alert-dismissible"
                              message={`Não foram encontrados resultados para o termo ${props.searchProps.searchText}`}
                            />
                          </Col>
                        </Row>
                      )}

                      {!searchEmpty && (
                        <Row>
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              classes="bordered"
                              keyField="id"
                              data={intangibleAssetList}
                              columns={columns}
                              rowStyle={{ color: 'black' }}
                              headerClasses="text-center bg-light"
                              rowClasses="text-center"
                              bordered={false}
                              noDataIndication="Não foi localizado registros de Ativo Intangível"
                              pagination={paginationFactory(pageOptions)}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </ToolkitProvider>
              </>
              <Row className="mt-0">
                <Col className="text-right">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => history.push('/')}
                    className="font-weight-bold"
                  >
                    <i className="fa fa-chevron-left"></i>
                    &nbsp;&nbsp;Voltar
                  </Button>{' '}
                </Col>
              </Row>
            </>
          </Container>
        </Card.Body>
      </Card>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        onConfirm={configAlert.confirm}
        confirmButtonText={configAlert.confirmButtonText}
        showCancelButton={configAlert.cancelButton}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        toastSuccess={configAlert.toastSuccess}
      />
    </>
  )
}

export default DossierIntangibleAssetPage
