import React, { useState, memo } from 'react'
import { Col, Row, Button, Form, Container } from 'react-bootstrap'
import Swal from 'sweetalert2'

const CancelAccessForm = ({ userName = 'Fulano de tal', rowContent = null, token }) => {
  const [acceptTerm, setAcceptTerm] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(true)

  return (
    <Container>
      <Row>
        <Col>
          <p className="text-justify">
            Verifique a procedência do usuário <strong>{userName}</strong> com base nos dados fornecidos antes de
            liberar o acesso ao sistema. Essa é uma medida de segurança essencial para garantir que apenas usuários
            legítimos acessem nossos recursos.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <Form.Check
            inline
            label="Estou ciente da minha responsablidade."
            name="group1"
            type="checkbox"
            id={`inline-1`}
            onChange={() => {
              acceptTerm ? setAcceptTerm(false) : setAcceptTerm(true)
              acceptTerm ? setButtonStatus(true) : setButtonStatus(false)
            }}
            checked={acceptTerm}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Button variant="primary" type="submit" disabled={buttonStatus} onClick={() => Swal.clickConfirm()}>
            Liberar Acesso
          </Button>
        </Col>
        <Col>
          <Button
            variant="danger"
            type="button"
            onClick={() => {
              Swal.closeModal()
              return false
            }}
          >
            Cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default memo(CancelAccessForm)
