import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import validationNewEditSchema from './validationNewEditSchema'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { toast } from 'react-toastify'
import ServiceForm from '../../components/services/ServiceForm'
import { createService } from '../../_redux/services/serviceActions'

export function ServiceCreatePage() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { saveError, saveStatus, statusMessage } = useSelector((state) => state.services)

  const subheader = useSubheader()
  const pathname = window.location.pathname
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])

  if (saveStatus === 'success') {
    history.push('/servico')
    toast.success(`Sucesso! ${statusMessage}`, {})
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          {saveError.length !== 0 && (
            <Alert variant="danger" className="pt-5">
              {Object.values(saveError[0]).map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </Alert>
          )}
          <Formik
            validationSchema={validationNewEditSchema}
            onSubmit={(values) => {
              dispatch(createService(values))
            }}
            initialValues={{
              title: '',
              description: '',
            }}
          >
            {(formik) => (
              <ServiceForm
                handleSubmit={formik.handleSubmit}
                onChangeTitle={formik.handleChange}
                onBlueTitle={formik.handleBlur}
                getFieldPropsTitle={formik.getFieldProps('title')}
                touchedTitle={formik.touched.title}
                errorsTitle={formik.errors.title}
                onChangeDescription={formik.handleChange}
                onBlueDescription={formik.handleBlur}
                errorsDescription={formik.errors.description}
                touchedDescription={formik.touched.description}
                valuesDescription={formik.values.description}
                getFieldPropsDescription={formik.getFieldProps('description')}
              />
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  )
}
