import React, { useState } from 'react'
import { AlertGT, BadgeGT } from '../../../../../components'
import {
  TitleHeader,
  Code,
  ContentRow,
  HeaderWrapper,
  ItemLittleSausage,
  ItemTitle,
  ContentRowItemLittleSausage,
} from './HeaderSolicitationManagement.styles'
import TooltipCustom from '../../../../../components/TooltipCustom'
import ServiceDataModal from '../../../../Tasks/components/ServiceDataModal'
import SolicitationDataModal from '../../../../Tasks/components/SolicitationDataModal'
import IntangibleAssetDataModal from '../../../../Tasks/components/IntangibleAssetDataModal'

const HeaderSolicitationManagement = ({
  code,
  stage,
  itemsLittleSausage,
  actual_attendant = null,
  organizationsInitials,
}) => {
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [alertConfirm, setAlertConfirm] = useState(null)

  const handleSeeDetails = (item) => {
    if (typeof item.type !== 'undefined') {
      if (item.type === 'service') {
        setAlertText(
          <ServiceDataModal
            ServiceCode={item.serviceCode}
            ServiceTitle={item.serviceTitle}
            ServiceDescription={item.serviceDescription}
            ServiceUserMomentCreate={null}
          />
        )
      } else if (item.type === 'solicitation') {
        setAlertText(
          <SolicitationDataModal
            solicitationCode={item.code}
            serviceCode={item.serviceCode}
            serviceTitle={item.serviceTitle}
            solicitationSlug={item.solicitationSlug}
            solicitationStage={item.solicitationStage}
            organizationInitials={item.solicitationOrganizationInitials}
            description={item.description}
            createdIn={item.momentByUser}
          />
        )
      } else if (item.type === 'intangible-asset') {
        setAlertText(
          <IntangibleAssetDataModal
            intangibleAssetCode={item.code}
            intangibleAssetTitle={item.title}
            intangibleAssetDescription={item.description}
            intangibleAssetUserMomentCreate={item.momentByUser}
            intangibleAssetUnitInitInitials={item.organizationInitials}
          />
        )
      }
    } else {
      setAlertText(
        `<p align="justify">
          <strong>${item.code} - ${item.titleModal}</strong><br><br>
          ${item.description}
        </p>`
      )
    }

    setAlertConfirm(() => () => {
      setShowAlert(false)
    })
    setShowAlert(true)
  }

  const textAttendant =
    stage.slug === 'cancelada' || stage.slug === 'encerrada' ? `Último Atendente ` : `Atual Atendente `

  return (
    <HeaderWrapper>
      <ContentRow>
        <TitleHeader>Solicitação</TitleHeader>
        <Code>{code}</Code>
        <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
          <span>
            <BadgeGT variant={'secondary'} text={organizationsInitials} />
          </span>
        </TooltipCustom>
        &nbsp;&nbsp;
        <BadgeGT variant={stage.slug} text={stage.stage} />
        {actual_attendant !== null && (
          <>
            &nbsp;&nbsp;&nbsp;
            <BadgeGT
              variant={'secondary'}
              text={
                actual_attendant !== 'Sem atendente' ? (
                  <>
                    {textAttendant} &nbsp;<strong>{actual_attendant}</strong>
                  </>
                ) : (
                  'Sem atendente'
                )
              }
            />
          </>
        )}
      </ContentRow>
      <ContentRowItemLittleSausage>
        {itemsLittleSausage.map((item, index) => (
          <ItemLittleSausage key={index}>
            <strong>{item.code}</strong>
            <ItemTitle>- {item.title}</ItemTitle>
            <TooltipCustom title="Mais informações" placement="top" arrow>
              <i
                style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                className="fas fa-info-circle ml-5 position-relative"
                onClick={() => handleSeeDetails(item)}
              ></i>
            </TooltipCustom>
          </ItemLittleSausage>
        ))}
      </ContentRowItemLittleSausage>
      <AlertGT
        show={showAlert}
        title={''}
        text={alertText}
        icon={null}
        showCancelButton={false}
        onConfirm={alertConfirm}
        confirmButtonText={'Fechar'}
        onDismissed={() => {
          setShowAlert(false)
        }}
        toastSuccess={null}
      />
    </HeaderWrapper>
  )
}

export default HeaderSolicitationManagement
