import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Card, Button, Row, Col, Form } from 'react-bootstrap'

import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../routes/Helpers'

import { toast } from 'react-toastify'

import { LinkMailTo, BlockSpace, TextLink, ListTwoColumns } from './User.styles'
import { useHistory, useParams } from 'react-router-dom'
import {
  allRolesAccessServicesByUser,
  editAllRolesAccessServicesByUser,
  clearLastError,
} from '../../_redux/admin/adminActions'
import AlertGT from '../../../../components/AlertGT'
import { AlertMessage } from '../../../Solicitation/components/services/AlertMessage'

const UserPermissionsEditPage = () => {
  const pathname = window.location.pathname
  const subheader = useSubheader()
  const history = useHistory()

  const dispatch = useDispatch()
  const { id1 } = useParams()

  const { user } = useSelector((state) => state.auth)
  const { accessRolesAndServicesUserList, saveStatus, lastError } = useSelector((state) => state.admin)

  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)
  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  const [userData, setUserData] = useState()
  const [userForm, setUserForm] = useState()
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    confirm: null,
    toastSuccess: '',
  })

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(allRolesAccessServicesByUser(id1))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(allRolesAccessServicesByUser(id1))
    // eslint-disable-next-line
  }, [lastError, id1])

  useEffect(() => {
    setUserData(accessRolesAndServicesUserList)
    setUserForm(accessRolesAndServicesUserList)
  }, [accessRolesAndServicesUserList])

  const userRolesFormat = () => {
    if (typeof userData.roles !== 'undefined') {
      // eslint-disable-next-line
      const arrHasRoles = userData.roles.filter((item) => {
        if (item.hasThisRole) {
          return item
        }
      })

      return arrHasRoles.map((role) => role.label).join(', ')
    }
  }

  /**
   * função uncheckServices
   *
   * Função limpar os checkbox de Serviço associado ao usuário
   */
  const uncheckServices = (type) => {
    const handleConfirm = () => {
      if (type === 'attendants') {
        const serviceAttendant = userForm.attendantsservices.map((service) => {
          return { ...service, isAttendant: false }
        })
        setUserForm({ ...userForm, attendantsservices: serviceAttendant })
      } else if (type === 'admin') {
        const servicesAdmin = userForm.adminservices.map((service) => {
          return { ...service, isAdministrator: false }
        })
        setUserForm({ ...userForm, adminservices: servicesAdmin })
      } else {
        const servicesAdmin = userForm.adminunits.map((unit) => {
          return { ...unit, isAdministratorUnit: false }
        })
        setUserForm({ ...userForm, adminunits: servicesAdmin })
      }
      setConfigAlert({ ...configAlert, show: false })
    }

    setConfigAlert({
      show: true,
      title: `Desmarcar ${type === 'adminunits' ? ' todas as Unidades ' : ' todos os Serviços '}`,
      text: `Deseja desmarcar todos ${type === 'adminunits' ? ' as unidades ' : ' os serviços '} ${
        type === ('admin' || 'adminunits') ? ' administrados ' : ' atendidos '
      } por ${userData.name}?`,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      confirm: () => handleConfirm(),
      toastSuccess: null,
    })
  }

  /**
   * função handleClickCheckbox
   *
   * Função atualiza o estado do userForm quando é clicado em um checkbox
   */
  const handleClickCheckbox = (event, type) => {
    if (type === 'roles') {
      const roles = userForm.roles.map((role) => {
        if (`checkbox-role-${role.name}` === event.target.id) {
          return { ...role, hasThisRole: event.target.checked }
        }

        return role
      })

      setUserForm({ ...userForm, roles: roles })
    } else if (type === 'attendants') {
      const serviceAttendant = userForm.attendantsservices.map((service) => {
        if (`checkbox-att-${service.id}` === event.target.id) {
          return { ...service, isAttendant: event.target.checked }
        }
        return service
      })

      setUserForm({ ...userForm, attendantsservices: serviceAttendant })
    } else if (type === 'admin') {
      const serviceAttendant = userForm.adminservices.map((service) => {
        if (`checkbox-admin-${service.id}` === event.target.id) {
          return { ...service, isAdministrator: event.target.checked }
        }
        return service
      })

      setUserForm({ ...userForm, adminservices: serviceAttendant })
    } else {
      const serviceAttendant = userForm.adminunits.map((unit) => {
        if (`checkbox-admin-units-${unit.id}` === event.target.id) {
          return { ...unit, isAdministratorUnit: event.target.checked }
        }
        return unit
      })

      setUserForm({ ...userForm, adminunits: serviceAttendant })
    }
  }
  /**
   * Usar o estado userForm para o envio dos dados pra API
   */
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoadingProcess(true)

    if (parseInt(user.id) === parseInt(userForm.id)) {
      const content = () => (
        <>
          <div style={{ width: '95%' }}>
            <Row>
              <Col>
                <h3>Atenção!</h3>
              </Col>
            </Row>
            <Row className="pt-10">
              <Col className="text-left ml-5">
                <p>
                  Para que as alterações tenham efeito, é necessário que o usuário selecionado realize logoff e login no
                  sistema.
                </p>
              </Col>
            </Row>
          </div>
        </>
      )
      setConfigAlert({
        show: true,
        title: null,
        icon: 'info',
        text: content(),
        width: 400,
        cancelButton: false,
        confirmButtonText: 'OK',

        confirm: () => {
          dispatch(editAllRolesAccessServicesByUser(id1, userForm)).then(() => {
            setLoadingProcess(false)
          })
        },
        toastSuccess: null,
      })
      setLoadingProcess(false)
    } else {
      dispatch(editAllRolesAccessServicesByUser(id1, userForm)).then(() => {
        setLoadingProcess(false)
      })
    }
  }

  if (saveStatus === 'success') {
    toast.success(
      `Sucesso! As atribuições do usuário foram redefinidas.
    O usuário será comunicado sobre as ações realizadas.`,
      {}
    )
    dispatch(clearLastError())
    history.push('/administracao/usuarios/gerencia')
  }

  return (
    <>
      <Card border="secondary">
        <Form onSubmit={handleSubmit}>
          <Card.Body>
            {userForm ? (
              <>
                <Row>
                  <Col sm={9}></Col>
                  <Col sm={3}>
                    <div className="d-grid"></div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4">
                    <Card border="secondary" bg="light-secondary" style={{ width: '100%' }}>
                      <Card.Body className="">
                        <h3>{userData.name}</h3>
                        <LinkMailTo href={`mailto:`}>{userData.email}</LinkMailTo>| {userRolesFormat()} em&nbsp;
                        {userData.organization && userData.organization.name}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {lastError !== null && (
                      <AlertMessage
                        variant="alert alert-custom alert-danger alert-dismissible"
                        message={lastError.message}
                      />
                    )}
                    {lastError !== null && document.getElementById('kt_scrolltop').click()}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <BlockSpace>
                      <h5>Perfis do usuário</h5>
                      <div key={`checkbox-role`} className="mb-3">
                        {userForm.roles &&
                          userForm.roles.map((role) => (
                            <Form.Check
                              key={`checkbox-role-${role.name}`}
                              id={`checkbox-role-${role.name}`}
                              inline
                              label={role.label}
                              name="checkbox-roles"
                              type="checkbox"
                              checked={role.hasThisRole}
                              onClick={(e) => handleClickCheckbox(e, 'roles')}
                            />
                          ))}
                      </div>
                    </BlockSpace>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BlockSpace>
                      <h5>Serviços Atendidos por {userData.name}</h5>
                      <ListTwoColumns key={`checkbox-att`} className="mb-3">
                        {userForm.attendantsservices &&
                          userForm.attendantsservices.map((service) => (
                            <Form.Check
                              key={`checkbox-att-${service.id}`}
                              id={`checkbox-att-${service.id}`}
                              label={`${service.code} - ${service.title}`}
                              name="checkbox-att"
                              type="checkbox"
                              checked={service.isAttendant}
                              onClick={(e) => handleClickCheckbox(e, 'attendants')}
                              disabled={!userForm.roles.some((role) => role.name === 'atendente' && role.hasThisRole)}
                            />
                          ))}
                      </ListTwoColumns>
                      <TextLink onClick={() => uncheckServices('attendants')}>Desmarcar todas as opções acima</TextLink>
                    </BlockSpace>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BlockSpace>
                      <h5>Serviços Administrados por {userData.name}</h5>
                      <ListTwoColumns key={`checkbox-admin`} className="mb-3">
                        {userForm.adminservices &&
                          userForm.adminservices.map((service) => (
                            <Form.Check
                              key={`checkbox-admin-${service.id}`}
                              id={`checkbox-admin-${service.id}`}
                              label={`${service.code} - ${service.title}`}
                              name="checkbox-admin"
                              type="checkbox"
                              checked={service.isAdministrator}
                              onClick={(e) => handleClickCheckbox(e, 'admin')}
                              disabled={
                                !userForm.roles.some((role) => role.name === 'admin-servico' && role.hasThisRole)
                              }
                            />
                          ))}
                      </ListTwoColumns>
                      <TextLink onClick={() => uncheckServices('admin')}>Desmarcar todas as opções acima</TextLink>
                    </BlockSpace>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BlockSpace>
                      <h5>Unidades Administradas por {userData.name}</h5>
                      <ListTwoColumns key={`checkbox-admin-units`} className="mb-3">
                        {userForm.adminunits &&
                          userForm.adminunits.map((unit) =>
                            unit.name !== 'Vago' ? (
                              <Form.Check
                                key={`checkbox-admin-units-${unit.id}`}
                                id={`checkbox-admin-units-${unit.id}`}
                                label={`${unit.initials} - ${unit.name}`}
                                name="checkbox-admin"
                                type="checkbox"
                                checked={unit.isAdministratorUnit}
                                onClick={(e) => handleClickCheckbox(e, 'adminunits')}
                                disabled={
                                  !userForm.roles.some((role) => role.name === 'admin-unidade' && role.hasThisRole)
                                }
                              />
                            ) : (
                              ''
                            )
                          )}
                      </ListTwoColumns>
                      <TextLink onClick={() => uncheckServices('adminunits')}>Desmarcar todas as opções acima</TextLink>
                    </BlockSpace>
                  </Col>
                </Row>
              </>
            ) : (
              <div>Carregando...</div>
            )}
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col sm={8} className="text-left">
                <Button className="font-weight-bold" type="submit" disabled={loadingProcess ? 'disabled' : ''}>
                  Salvar Configurações
                </Button>
                {loadingProcess && saveStatus === null && (
                  <>
                    <span className="spinner spinner-lg spinner-success ml-3" />
                    &nbsp;&nbsp;&nbsp;
                    <span className="h4 mt-10 ml-10">Por favor aguarde ...</span>
                  </>
                )}
              </Col>
              <Col sm={4} className="text-right">
                <Button
                  className="font-weight-bold"
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    dispatch(clearLastError())
                    history.push('/administracao/usuarios/gerencia')
                  }}
                >
                  <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Form>
      </Card>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        onConfirm={configAlert.confirm}
        confirmButtonText={configAlert.confirmButtonText}
        showCancelButton={configAlert.cancelButton}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        toastSuccess={configAlert.toastSuccess}
      />
    </>
  )
}

export default UserPermissionsEditPage
