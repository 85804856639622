import React from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../routes/Helpers'

import { AlertGT, Button, Table } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { getFaq } from '../_redux/faqActions'

function FaqsPage() {
  const history = useHistory()
  const dispatch = useDispatch()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const { faqData, loading } = useSelector((state) => state.faq)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const [search, setSearch] = React.useState('')
  const [perPage, setPerPage] = React.useState(5)
  const [page, setPage] = React.useState(1)
  const [ordering, setOrdering] = React.useState('')

  React.useEffect(() => {
    dispatch(getFaq(search, page, perPage, ordering))
  }, [search, page, perPage, ordering, dispatch])

  /* React.useEffect(() => {
    if (statusMessage) {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
    }
    dispatch(cleanStatus())
    // eslint-disable-next-line
  }, [saveStatus, statusMessage]) */

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'faq_code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',

      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'faq_title',
      text: 'Título da FAQ',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      /* formatter: (cell, row) => (
        <>
          <ReadMore text={row.description} maxCharacters={70} />
          <strong style={{ fontSize: '0.85rem' }}>
            {row.service_code}-{row.service_title}
          </strong>
        </>
      ), */
      headerStyle: { width: '120ch', textAlign: 'left' },
    },
  ]

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-search fa-5x"></i>,
      title: 'Ver Resposta',
      onClick: (row) => history.push(`faq/${row.faq_id}`),
      //disabled: (row) => !row.canDelete,
      //visible: (row) => row.enable,
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Table
            data={faqData.data}
            keyField="faq_code"
            noDataMessage="Não existem FAQs cadastradas."
            columns={columns}
            page={page}
            perPage={perPage}
            totalSize={faqData.total}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={loading}
          />
        </Card.Body>

        <Card.Footer className="text-right">
          <Button
            variant="secondary"
            onClick={() => {
              history.goBack()
            }}
          >
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default FaqsPage
