import React from "react";
import { Alert, Button, Row, Col } from "react-bootstrap";

export const ManagerServiceList = (props) => {
  return (
    <div className="mt-5">
      {props.listagem.length === 0 && (
        <Row>
          <Col>
            <Alert variant="alert alert-custom alert-light-primary alert-dismissible">
              {props.msgOnEmptyListAdminOrAttendants}
            </Alert>
          </Col>
        </Row>
      )}

      {props.listagem.length !== 0 &&
        props.listagem.map((item, i) => (
          <div key={i}>
            <Row className="mt-1 align-items-center">
              <Col sm={10}>{`${item.name} (${item.email})`}</Col>
              <Col sm={2} className="text-right">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => props.handleClick(item.id, item.name)}
                  className="font-weight-bold"
                  style={{ width: 70 }}
                >
                  Excluir
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
          </div>
        ))}
    </div>
  );
};
