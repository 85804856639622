import React, { useEffect } from 'react'
import { Alert, Card, Col, NavLink, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { ReadMore, Table, Button, AlertGT, AlertDotStage, BadgeGT } from '../../../../components'
import { getSolicitationsMyServices } from '../../_redux/attendances/attendanceActions'
import TooltipCustom from '../../../../components/TooltipCustom'

const orderByOptions = [
  { value: 'solicitation_code', text: 'Código da Solicitação' },
  { value: 'solicitation_description', text: 'Descrição da Solicitação' },
  { value: 'service_code', text: 'Código do Serviço' },
  { value: 'service_title', text: 'Título do Serviço' },
  { value: 'solicitation_create_date', text: 'Data de Criação' },
  { value: 'solicitation_creator_user', text: 'Nome do Solicitante' },
  { value: 'solicitation_creator_user_unit_initials', text: 'Sigla da Unidade do Solicitante' },
  { value: 'solicitation_creator_user_unit', text: 'Nome da Unidade do Solicitante' },
  { value: 'actual_attendance_name', text: 'Nome do Atendente' },
  { value: 'actual_attendance_unit', text: 'Nome da Unidade do Atendente' },
  { value: 'actual_attendance_unit_initials', text: 'Sigla da Unidade do Atendente' },
  { value: 'solicitation_stage', text: 'Estágio da Solicitação' },
]

export function SolicitationsMyServicesPage() {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('')
  const [ordering, setOrdering] = React.useState('desc')
  const { solicitaionsMyServices, listLoading } = useSelector((state) => state.attendance)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 500,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const arrBreadCrumb = [
    {
      pathname: '/dashboard',
      title: 'Home',
      subTitle: '',
      icon: '',
    },
  ]
  const [showAlertDotStage, setShowAlertDotStage] = React.useState(false)

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle}`)

  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getSolicitationsMyServices(perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  const handleViewSolicititation = (solicitation_id) => {
    // history.push(`/solicitacao/${solicitation_id}/gerenciamento`)
    history.push(`/solicitacao/gerencia-da-solicitacao/${solicitation_id}`)
  }
  const handleForwardToAttendant = (solicitation_id, service_id) => {
    history.push(`/solicitacao/${solicitation_id}/encaminhamento/service/${service_id}`)
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-search fa-5x"></i>,
      title: 'Consultar Solicitação',
      onClick: (row) => handleViewSolicititation(row.solicitation_id),
      disabled: (row) => (row.solicitation_stage_slug === 'incompleta' ? true : null),
    },
    {
      text: <i className="fas fa-arrow-right"></i>,
      title: 'Encaminhar para Atendente',
      onClick: (row) => handleForwardToAttendant(row.solicitation_id, row.service_id),
      disabled: (row) =>
        row.solicitation_stage_slug === 'encerrada' ||
        row.solicitation_stage_slug === 'incompleta' ||
        row.solicitation_stage_slug === 'cancelada'
          ? true
          : null,
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'solicitation_code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <strong style={{ fontSize: '0.85rem' }}>{row.solicitation_code}</strong>
        </>
      ),
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'solicitation_description',
      text: 'Dados da Solicitação',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <p>
            <ReadMore text={row.solicitation_description} maxCharacters={70} />
          </p>
          <p>{`${row.service_code} - ${row.service_title}`}</p>
          {row.receive_time_expired === 1 && row.has_attendant > 0 && (
            <Alert
              variant={'warning'}
              style={{ height: '25px', marginBottom: '1px', minWidth: '380px', paddingTop: '4px' }}
            >
              <p className="text-center" style={{ color: 'black' }}>
                <i className="fas fa-exclamation-triangle" style={{ color: 'black' }}></i>
                &nbsp;&nbsp;&nbsp;<strong>Aguardando acolhimento a mais de 5 dias.</strong>
              </p>
            </Alert>
          )}
          {row.has_attendant === 0 && row.solicitation_stage_slug === 'aguardando-acolhimento' && (
            <Alert
              variant={'warning'}
              style={{ height: '25px', marginBottom: '1px', minWidth: '380px', paddingTop: '4px' }}
            >
              <p className="text-center" style={{ color: 'black' }}>
                <i className="fas fa-exclamation-triangle" style={{ color: 'black' }}></i>
                &nbsp;&nbsp;&nbsp;<strong>Não há atendentes habilitados na unidade.</strong>
              </p>
            </Alert>
          )}
        </>
      ),
      headerStyle: { width: '40%', textAlign: 'center' },
    },
    {
      dataField: 'solicitation_create_date',
      text: 'Criação/Solicitante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <strong style={{ fontSize: '0.85rem' }}>{row.solicitation_create_date}</strong>
          <p>{`${row.solicitation_creator_user} (${row.solicitation_creator_user_unit_initials})`}</p>
        </>
      ),
      headerStyle: { width: '20%', textAlign: 'center' },
    },
    {
      dataField: 'actual_attendance_name',
      text: 'Atendente',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.actual_attendance_name === null && (
            <>
              <p>{'Sem Atendente'}</p>
            </>
          )}
          {row.actual_attendance_name !== null && (
            <>
              {row.actual_attendance_name !== null && (
                <>{`${row.actual_attendance_name} (${row.actual_attendance_unit_initials})`}</>
              )}
            </>
          )}
        </>
      ),
      headerStyle: { width: '20%', textAlign: 'center' },
    },
    {
      dataField: 'solicitation_stage',
      text: 'Estágio',
      align: 'right',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.solicitation_stage} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={row.solicitation_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '5%', textAlign: 'right' },
    },
  ]
  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <AlertDotStage
        show={showAlertDotStage}
        onConfirm={() => setShowAlertDotStage(false)}
        onDismissed={() => setShowAlertDotStage(false)}
      />
      <Card border="secondary">
        <Card.Body>
          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotStage(true)}>
                Legenda dos Estágios da Solicitação
              </NavLink>
            </Col>
          </Row>
          <Table
            data={solicitaionsMyServices.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Não existem solicitações dos serviços que você administra.`
            }
            columns={columns}
            page={solicitaionsMyServices.current_page}
            perPage={perPage}
            totalSize={solicitaionsMyServices.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={8}></Col>
            <Col md={4} className="text-right">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
