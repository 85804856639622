import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Button, Col, Row, Alert } from 'react-bootstrap'

import { getCurrentPageTitleSubTitle } from '../../../routes/Helpers'
import { useSubheader } from '../../../../_metronic/layout'

function WarninPage() {
  const history = useHistory()

  const [show, setShow] = useState(true)
  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  // React.useEffect(() => {
  //   dispatch(getUpdateFormData(id))
  //   // eslint-disable-next-line
  // }, [id])

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Row>
            <Col sm={12} className="d-flex justify-content-center">
              <Alert show={show} variant="warning" className="w-100">
                <Alert.Heading>Atenção!</Alert.Heading>
                <p>Este link não é mais válido.</p>
                <p>Isso ocorre por alguns dos motivos abaixo:</p> <br />
                <ul>
                  <li>O link já foi utilizado</li>
                  <li>Passaram-se 30 minutos depois de sua criação</li>
                  <li>O hash utilizado não é mais válido</li>
                </ul>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShow(false)} variant="outline-success">
                    Close me y'all!
                  </Button>
                </div>
              </Alert>

              {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                variant="secondary"
                onClick={() => {
                  history.goBack()
                }}
              >
                <i className="fa fa-chevron-left"></i>&nbsp;Ok
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default WarninPage
