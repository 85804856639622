import React from 'react'
import { Block } from './BlockInfo.styles'

function BlockInfo({ children, className, style }) {
  return (
    <Block className={className} style={style}>
      {children}
    </Block>
  )
}

export default BlockInfo
