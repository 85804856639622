import styled from 'styled-components'

export const Wrapper = styled.div`
  display: block;
`

export const Label = styled.p`
  display: block;
`

export const Content = styled.div`
  display: block;
  background-color: #f3fafe;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
`

export const ContentYellow = styled.div`
  display: block;
  background-color: #f5e049;
  border-radius: 5px;
  padding: 10px 10px;
  width: 100%;
`

export const CheckWrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
  margin-left: 5px;
  .check-marck {
    margin-left: 5px;
  }
`

export const FileList = styled.ul`
  display: flex;
  padding: 0px 10px;
  flex-wrap: wrap;
`

export const FileWrapper = styled.div`
  position: relative;
  display: flex;

  .check-marck {
    position: absolute;
    top: -5px;
    right: -10px;
  }
`

export const FileMandatory = styled.a`
  background-color: #f3fafe;
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #333;
`
export const FileOptional = styled.a`
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #333;
`

export const LinkVerify = styled.a`
  cursor-pointer: pointer;
`
