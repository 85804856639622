import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Typography } from '../../../../../components'
import LabelUserRole from '../../../../../components/Label/LabelUserRole'
import { AlertMessage } from '../../services/AlertMessage'

const Functionalities = () => {
  const { hasServicesEnabled } = useSelector((state) => state.solicitations)
  const { user } = useSelector((state) => state.auth)
  const { temporary_block } = useSelector((state) => state.dashboard)

  const history = useHistory()

  return (
    <>
      {!hasServicesEnabled && (
        <AlertMessage
          variant="alert alert-custom alert-light-primary alert-dismissible"
          message="Não existem serviços habilitados no momento. Caso queira criar uma solicitação, por favor,
          entre em contato com o administrador do sistema."
        />
      )}

      <Row className="py-5">
        <Col>
          <Typography>Funcionalidades Disponíveis</Typography>
        </Col>
      </Row>
      {temporary_block === false && (
        <>
          {/* FUNCIONALIDADES ADMINSTRADOR DO SISTEMA */}
          {user.roles && Object.values(user.roles).indexOf('admin') > -1 && (
            <Row className="mb-5">
              <Col className="d-flex flex-row">
                <LabelUserRole>Como Administrador do Sistema</LabelUserRole>
                <Button
                  className="mr-5"
                  variant="horizontal"
                  icon={<i className="fas fa-users-cog"></i>}
                  onClick={() => history.push('/administracao/usuarios/gerencia')}
                  //disabled={!hasServicesEnabled}
                >
                  Gerência de Usuários
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-concierge-bell"></i>}
                  onClick={() => history.push('/servico')}
                >
                  Serviços
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-chart-pie"></i>}
                  onClick={() => history.push('/relatorios/intangible_assets_tabular')}
                >
                  Relatório de Solicitações
                </Button>
              </Col>
            </Row>
          )}

          {/* FUNCIONALIDADES ADMINSTRADOR DE UNIDADE */}
          {user.roles && Object.values(user.roles).indexOf('admin-unidade') > -1 && (
            <Row className="mb-5">
              <Col className="d-flex flex-row">
                <LabelUserRole>Como Administrador de Unidade</LabelUserRole>
                <Button
                  className="mr-5"
                  variant="horizontal"
                  icon={<i className="fas fa-building"></i>}
                  onClick={() => history.push(`/administracao/unidades/gerencia`)}
                  //disabled={!hasServicesEnabled}
                >
                  Gerência das Minhas Unidades
                </Button>
              </Col>
            </Row>
          )}
          {/* FUNCIONALIDADES ADMINSTRADOR DO SERVIÇO */}
          {user.roles && Object.values(user.roles).indexOf('admin-servico') > -1 && (
            <Row className="mb-5">
              <Col className="d-flex flex-row">
                <LabelUserRole>Como Administrador de Serviço</LabelUserRole>
                <Button
                  className="mr-5"
                  variant="horizontal"
                  icon={<i className="fas fa-folder-open"></i>}
                  onClick={() => history.push('/solicitacao/atendimentos/admin_servico/solicitacoes/meus_servicos')}
                  //disabled={!hasServicesEnabled}
                >
                  Solicitações dos Meus Serviços
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-concierge-bell"></i>}
                  onClick={() => history.push('/servico/meus/administrados')}
                >
                  Serviços que Administro
                </Button>
              </Col>
            </Row>
          )}

          {/* FUNCIONALIDADES ATENDENTE */}
          {user.roles && Object.values(user.roles).indexOf('atendente') > -1 && (
            <Row className="mb-5">
              <Col className="d-flex flex-row">
                <LabelUserRole>Como Atendente</LabelUserRole>
                <Button
                  className="mr-5"
                  variant="horizontal"
                  icon={<i className="fas fa-calendar-alt"></i>}
                  onClick={() => history.push('/solicitacao/disponiveis')}
                  //disabled={!hasServicesEnabled}
                >
                  Solicitações Disponíveis em {user.departmentInitials}
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-concierge-bell"></i>}
                  onClick={() => history.push('/servico/meus/atendidos')}
                >
                  Serviços que Atendo
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-calendar-day"></i>}
                  onClick={() => history.push('/solicitacao/atendimentos')}
                >
                  Meus Atendimentos
                </Button>
              </Col>
            </Row>
          )}
          {/* FUNCIONALIDADES SOLICITANTE */}
          {user.roles && Object.values(user.roles).indexOf('solicitante') > -1 && (
            <Row className="mb-5">
              <Col className="d-flex flex-row">
                <LabelUserRole>Como Solicitante</LabelUserRole>
                <Button
                  className="mr-5"
                  variant="horizontal"
                  icon={<i className="fas fa-folder-plus"></i>}
                  onClick={() => history.push('/solicitacao/novo/etapa-um')}
                  disabled={!hasServicesEnabled}
                >
                  Criar Solicitação
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-folder-open"></i>}
                  onClick={() => history.push('/solicitacoes')}
                >
                  Minhas Solicitações
                </Button>
                <Button
                  className="mr-5"
                  variant="vertical"
                  icon={<i className="fas fa-lightbulb"></i>}
                  onClick={() => history.push('/ativo-intangivel/list')}
                >
                  Meus Ativos Intangíveis
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  )
}

export default Functionalities
