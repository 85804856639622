import React, { useEffect } from 'react'
import { Card, Col, Row, NavLink } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import TooltipCustom from '../../../../components/TooltipCustom'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle, hasPermission } from '../../../../routes/Helpers'
import { ReadMore, Table, Button, BadgeGT, AlertGT, AlertDotTaskStage } from '../../../../components'
import { getTasksVisibleAdmin } from '../../_redux/admin/adminActions'
import AlertTask from '../../../../components/AlertTask'

const orderByOptions = [
  { value: 'task_code', text: 'Código da Tarefa' },
  { value: 'task_description', text: 'Descrição da Tarefa' },
  { value: 'task_create_date', text: 'Criação' },
  { value: 'task_creator_name', text: 'Criador' },
  { value: 'task_executant_name', text: 'Executante' },
  { value: 'performed_by_me', text: 'Realizadas por mim' },
  { value: 'canceled_by_me', text: 'Canceladas por mim' },
  { value: 'task_stage', text: 'Estágio' },
  { value: 'task_solicitation_code', text: 'Código da Solicitação Associada' },
]

export function ManipulatingTasks() {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('')
  const [ordering, setOrdering] = React.useState('')
  const [showAlertDotTaskStage, setShowAlertDotTaskStage] = React.useState(false)
  const [showAlertTask, setShowAlertTask] = React.useState(false)

  const { user } = useSelector((state) => state.auth)

  const { taskList, listLoading } = useSelector((state) => state.admin)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle} `)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    dispatch(getTasksVisibleAdmin(perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fa fa-search"></i>,
      title: 'Consultar Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}`),
      visible: (row) => !row.task_editable,
    },
    {
      text: <i className="fas fa-hand-point-up"></i>,
      title: 'Interagir com a Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}`),
      visible: (row) => row.task_editable,
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'task_code',
      text: 'Código',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'task_description',
      text: 'Descrição da Tarefa',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.task_description} maxCharacters={70} />
          {row.task_associated_with_solicitation && (
            <>
              <strong style={{ fontSize: '0.85rem' }}>Associação: Solicitação {row.task_solicitation_code}</strong>
            </>
          )}
        </>
      ),
      headerStyle: { width: '50ch', textAlign: 'left' },
    },
    {
      dataField: 'task_create_date',
      text: 'Criação',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.task_create_date}
          <br />
          <strong style={{ fontSize: '0.85rem' }}>{row.task_create_date_for_humans}</strong>
        </>
      ),
      headerStyle: { width: 200, textAlign: 'center' },
    },
    {
      dataField: 'task_creator_name',
      text: 'Criador',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.task_creator_name}
          <br />
          <strong style={{ fontSize: '0.85rem' }}>{row.task_creator_email}</strong>
        </>
      ),
      headerStyle: { width: 300, textAlign: 'center' },
    },
    {
      dataField: 'task_executant_name',
      text: 'Executante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.task_executant_name}
          <br />
          <strong style={{ fontSize: '0.85rem' }}>{row.task_executant_email}</strong>
        </>
      ),
      headerStyle: { width: 300, textAlign: 'center' },
    },

    {
      dataField: 'task_stage',
      text: 'Estágio',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.task_stage} placement="top" arrow>
          <div>
            <BadgeGT variant={row.task_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: 40, textAlign: 'center' },
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <AlertDotTaskStage
        show={showAlertDotTaskStage}
        onConfirm={() => setShowAlertDotTaskStage(false)}
        onDismissed={() => setShowAlertDotTaskStage(false)}
      />
      <AlertTask
        show={showAlertTask}
        onConfirm={() => setShowAlertTask(false)}
        onDismissed={() => setShowAlertTask(false)}
      />
      <Card border="secondary">
        <Card.Body>
          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertTask(true)}>
                O que é uma Tarefa?
              </NavLink>
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotTaskStage(true)}>
                Legenda dos Estágios da Tarefa
              </NavLink>
            </Col>
          </Row>
          <Table
            data={taskList.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Não existem tarefas em andamento ou que foram manipuladas por administradores do sistema.`
            }
            columns={columns}
            page={taskList.current_page}
            perPage={perPage}
            totalSize={taskList.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={8}></Col>
            <Col md={4} className="text-right">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
