import React from 'react'
import { Text } from './ReadMore.style'

function ReadMore({ text, maxCharacters = 100, className, style, showLink = true }) {
  const [show, setShow] = React.useState(false)

  const handleToggle = () => setShow(!show)

  if (text.length <= maxCharacters)
    return (
      <div className={className} style={style}>
        {text}
      </div>
    )
  else if (show)
    return (
      <div className={className} style={style}>
        {text} <Text onClick={handleToggle}>Leia menos</Text>
      </div>
    )
  else
    return (
      <div className={className} style={style}>
        {text.substring(0, maxCharacters)}... {showLink && <Text onClick={handleToggle}>Leia mais</Text>}
      </div>
    )
}

export default ReadMore
