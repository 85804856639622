import { createSlice } from '@reduxjs/toolkit'

const initialDashboardState = {
  listLoading: true,
  widgets: [],
  first_user_login: null,
  temporary_block: null,
  count: 0,

  statusMessage: null,
  error: null,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.statusMessage = 'error'
      state.error = action.payload
    },
    unitSet: (state, action) => {
      state.first_user_login = false
      state.temporary_block = false
      state.listLoading = false
      state.statusMessage = null
      state.error = null
    },
    getDashboard: (state, action) => {
      state.widgets = action.payload.widgets
      state.first_user_login = action.payload.first_user_login || false
      state.temporary_block = action.payload.temporary_block || false
      state.count = action.payload.widgets.length
      state.listLoading = false
      state.statusMessage = null
      state.error = null
    },

    setFirstLogin: (state, action) => {
      state.first_user_login = action.payload
    },
  },
})
