import React from 'react'
import { Card } from '../../../../_metronic/_partials/controls'
import { CardBody, CardFooter, CardHeader } from './BigSausage.styles'

function BigSausage({ header, body, footer }) {
  return (
    <Card className="bg-white border border-secondary w-100 p-5">
      {header && <CardHeader>{header}</CardHeader>}
      <CardBody>{body}</CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  )
}

export default BigSausage
