import { createSlice } from '@reduxjs/toolkit'

const initialFaqState = {
  loading: true,
  faqData: {},
  faqSelected: {},
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const faqSlice = createSlice({
  name: 'tasks',
  initialState: initialFaqState,
  reducers: {
    catchError: (state, action) => {
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    setLastError: (state, action) => {
      state.lastError = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    successMessage: (state, action) => {
      state.statusMessage = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
    },

    cleanSaveStatus: (state, action) => {
      state.saveStatus = null
      state.statusMessage = null
    },

    clearSaveError: (state, action) => {
      state.saveError = []
    },

    setFaqData: (state, action) => {
      state.faqData = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.lastError = null
    },

    setFaqSelected: (state, action) => {
      state.faqSelected = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.lastError = null
    },
  },
})
