import React from 'react'
import { NavLink } from 'react-router-dom'

const FaqLink = () => {
  return (
    <div className="topbar-item">
      <div className="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_faq">
        <NavLink to="/faq" title="FAQ" className="svg-icon svg-icon-xl svg-icon-primary">
          <i className="fas fa-question"></i>
        </NavLink>
      </div>
    </div>
  )
}

export default FaqLink
