import React, { useState, memo } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { validationSchemaCancelSolicitation } from '../../../../pages/attendances/validationSchema'
import { CheckWrapper } from '../ComponentModalForm.styles'

const CancelSolicitationForm = ({ configAlert, setConfigAlert, solicitationSelected, handleDispatch }) => {
  const [checkboxConfirm, setCheckboxConfirm] = useState()
  const [description, setDescription] = useState(localStorage.getItem('cancelDescription'))

  return (
    <div style={{ textAlign: 'left', overflow: 'hidden' }}>
      <p>
        Preencha o campo abaixo com a justificativa para o cancelamento da solicitação{' '}
        <strong>{solicitationSelected.code}.</strong>
      </p>
      <Formik
        validationSchema={validationSchemaCancelSolicitation}
        onSubmit={(values) => {
          if (values.cancelDescription.length >= 3) {
            localStorage.setItem('cancelDescription', '')
            setConfigAlert({ ...configAlert, closeToast: true })
            handleDispatch(values.cancelDescription)
          }
        }}
        initialValues={{
          cancelDescription: description,
        }}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col sm={12}>
                <Form.Group md="12" style={{ marginBottom: '0px' }}>
                  <Form.Control
                    as="textarea"
                    type="textarea"
                    rows="5"
                    name="cancelDescription"
                    maxLength="1000"
                    {...formik.getFieldProps('cancelDescription')}
                    className={
                      formik.touched.cancelDescription && formik.errors.cancelDescription ? 'is-invalid' : null
                    }
                    value={description}
                    onChange={(e) => {
                      formik.handleChange(e)
                      setDescription(e.target.value)
                      localStorage.setItem('cancelDescription', e.target.value)
                    }}
                  />
                  {formik.touched.cancelDescription && formik.errors.cancelDescription && (
                    <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                      {formik.errors.cancelDescription}
                    </Form.Control.Feedback>
                  )}
                  <Form.Text className="text-right">
                    {formik.values.cancelDescription.length !== 0
                      ? `${formik.values.cancelDescription.length}/1000 caracteres`
                      : 'Máximo de 1000 caracteres.'}
                  </Form.Text>
                  <Row>
                    <Col>
                      <CheckWrapper>
                        <Form.Check
                          id="checkbox-confirmation-cancel"
                          label="Estou ciente de que esta ação é irreversivel."
                          name="checkbox-confirmation"
                          type="checkbox"
                          className="checkbox-confirm"
                          checked={checkboxConfirm}
                          onClick={(e) => {
                            if (checkboxConfirm) {
                              setCheckboxConfirm(false)
                            } else {
                              setCheckboxConfirm(true)
                            }
                          }}
                        />
                      </CheckWrapper>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-0">
              <Col className="text-right">
                <Button type="submit" className="font-weight-bold" disabled={!checkboxConfirm}>
                  Cancelar Solicitação
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    localStorage.setItem('cancelDescription', '')
                    setConfigAlert({ ...configAlert, closeToast: true })
                  }}
                  className="font-weight-bold"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default memo(CancelSolicitationForm)
