import React, { useEffect } from 'react'
import { Card, Col, Row, NavLink } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import TooltipCustom from '../../../components/TooltipCustom'
import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle, hasPermission } from '../../../routes/Helpers'
import { ReadMore, Table, Button, BadgeGT, AlertGT, AlertDotTaskStage } from '../../../components'
import {
  cancelTask,
  cleanSaveStatus,
  clearSaveError,
  getCreatedTasks,
  setTaskRegisterData,
  setTaskUpdateData,
} from '../_redux/tasks/tasksActions'
import CancelTaskForm from '../components/CancelTaskForm'
import { toast } from 'react-toastify'
import AlertTask from '../../../components/AlertTask'

const orderByOptions = [
  { value: 'task_code', text: 'Código da Tarefa' },
  { value: 'task_description', text: 'Descrição da Tarefa' },
  { value: 'task_create_date', text: 'Criação' },
  { value: 'task_executant_name', text: 'Executante' },
  { value: 'task_stage', text: 'Estágio' },
  { value: 'task_solicitation_code', text: 'Código da Solicitação Associada' },
]

export function CreatedTasksPage() {
  const { id1 } = useParams()
  const { user } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('')
  const [ordering, setOrdering] = React.useState('')
  const [showAlertDotTaskStage, setShowAlertDotTaskStage] = React.useState(false)
  const [showAlertTask, setShowAlertTask] = React.useState(false)

  const { createdTasksList, listLoading, statusMessage, saveStatus, lastError } = useSelector((state) => state.tasks)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const history = useHistory()
  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle} `)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    dispatch(setTaskRegisterData(null))
    dispatch(setTaskUpdateData(null))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getCreatedTasks(id1, perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [id1, perPage, page, search, sortField, ordering])

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(statusMessage, {})
      dispatch(cleanSaveStatus())
    }
    if (saveStatus === 'error') {
      if (typeof lastError.stage !== 'undefined') {
        const errorList = lastError.stage.join(', ')
        toast.error(`Erro: ${errorList}`, {})
      }
      dispatch(cleanSaveStatus())
    }
    // eslint-disable-next-line
  }, [saveStatus, lastError])

  /**
   * Verifica se há permissão de acesso
   */

  if (user.id !== parseInt(id1)) {
    history.push(`/403-forbidden`)
  }

  const handleCancelTaskModal = (task_id, task_data) => {
    const handleDispatch = (description) => {
      dispatch(
        cancelTask({
          taskId: task_id,
          description: description,
        })
      )
      dispatch(getCreatedTasks(id1, perPage, page, search, sortField, ordering))
    }
    const content = () => (
      <CancelTaskForm
        configAlert={configAlert}
        setConfigAlert={setConfigAlert}
        task={task_data}
        handleDispatch={handleDispatch}
      />
    )
    setConfigAlert({
      show: true,
      title: 'Cancelamento da Tarefa',
      text: content(),
      width: 800,
      icon: null,
      cancelButton: true,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {},
      toastSuccess: null,
      showConfirmButton: false,
    })
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-edit"></i>,
      title: 'Atualizar Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}/editar`),

      disabled: (row) => !row.task_editable,
      visible: (row) => row.task_editable,
    },
    {
      text: <i className="fas fa-ban"></i>,
      title: 'Cancelar Tarefa',
      onClick: (row) => {
        handleCancelTaskModal(row.task_id, row)
      },
      visible: (row) => row.task_editable, //true ,
    },
    {
      text: <i className="fas fa-search"></i>,
      title: 'Consultar Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}`),
      visible: (row) => !row.task_editable,
    },
    {
      text: <i className="fas fa-hand-point-up"></i>,
      title: 'Interagir com a Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}`),
      visible: (row) => row.task_editable, //true
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'task_code',
      text: 'Código',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'task_description',
      text: 'Descrição da Tarefa',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.task_description} maxCharacters={70} />
          {row.task_associated_with_solicitation && (
            <>
              <strong style={{ fontSize: '0.85rem' }}>Associada à Solicitação: {row.task_solicitation_code}</strong>
            </>
          )}
        </>
      ),
      headerStyle: { width: '50ch', textAlign: 'left' },
    },
    {
      dataField: 'task_create_date',
      text: 'Criação',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.task_create_date}
          <br />
          <strong style={{ fontSize: '0.85rem' }}>{row.task_create_date_for_humans}</strong>
        </>
      ),
      headerStyle: { width: 200, textAlign: 'center' },
    },
    {
      dataField: 'task_executant_name',
      text: 'Executante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.task_executant_name}
          <br />
          <strong style={{ fontSize: '0.85rem' }}>{row.task_executant_email}</strong>
        </>
      ),
      headerStyle: { width: 300, textAlign: 'center' },
    },

    {
      dataField: 'task_stage',
      text: 'Estágio',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.task_stage} placement="top" arrow>
          <div>
            <BadgeGT variant={row.task_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: 40, textAlign: 'center' },
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      <AlertDotTaskStage
        show={showAlertDotTaskStage}
        onConfirm={() => setShowAlertDotTaskStage(false)}
        onDismissed={() => setShowAlertDotTaskStage(false)}
      />
      <AlertTask
        show={showAlertTask}
        onConfirm={() => setShowAlertTask(false)}
        onDismissed={() => setShowAlertTask(false)}
      />
      <Card border="secondary">
        <Card.Body>
          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertTask(true)}>
                O que é uma Tarefa?
              </NavLink>
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotTaskStage(true)}>
                Legenda dos Estágios da Tarefa
              </NavLink>
            </Col>
          </Row>
          <Table
            data={createdTasksList.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Você não possui tarefas criadas.`
            }
            columns={columns}
            page={createdTasksList.current_page}
            perPage={perPage}
            totalSize={createdTasksList.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>

        <Row className={'ml-6 mb-7'}>
          <Col md={4} className="text-left">
            <Button type="submit" className="font-weight-bold block-level" onClick={() => history.push(`/tarefa/novo`)}>
              Criar Tarefa
            </Button>
          </Col>
          <Col md={8} className="text-right"></Col>
        </Row>

        <Card.Footer>
          <Row>
            <Col md={3} className="text-left mb-0">
              <Button
                variant="secondary"
                className={'block-level'}
                onClick={() => history.push(`/tarefas/recebidas/${id1}`)}
              >
                Tarefas Recebidas
              </Button>
            </Col>
            <Col md={9} className="text-right">
              <Button
                variant="secondary"
                onClick={() => {
                  dispatch(clearSaveError())
                  dispatch(cleanSaveStatus())
                  history.goBack()
                }}
              >
                <i className="fa fa-chevron-left"></i>Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
