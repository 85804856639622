import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SweetAlert = withReactContent(Swal)

const AlertDotStage = ({ show, onConfirm, onDismissed, onDenied }) => {
  if (show) {
    SweetAlert.fire({
      title: 'Legenda dos Estágios da Solicitação',
      html: `
    <div class="container">
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #d9d9d9"></i></div>
        <div class="col-8 text-left"><strong>Incompleta</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #f88405"></i></div>
        <div class="col-8 text-left"><strong>Aguardando Acolhimento</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #fffc03"></i></div>
        <div class="col-8 text-left"><strong>Aguardando Atendimento</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #a3c1fb"></i></div>
        <div class="col-8 text-left"><strong>Em Atendimento</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #1753c3"></i></div>
        <div class="col-8 text-left"><strong>Atendimento Concluído</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #67a94d"></i></div>
        <div class="col-8 text-left"><strong>Encerrada</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: red"></i></div>
        <div class="col-8 text-left"><strong>Atendimento com Pendência</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: black"></i></div>
        <div class="col-8 text-left"><strong>Cancelada</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #9503f6"></i></div>
        <div class="col-8 text-left"><strong>Aguardando Novo Atendente</strong></div>
      </div>
    </div>
      `,
      icon: 'info',
      width: 600,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) if (onConfirm) onConfirm()
      if (result.isDismissed) onDismissed()
      if (result.isDenied) onDenied()
      SweetAlert.close()
    })
  }
  return null
}

export default AlertDotStage
