import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { useParams, Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { resetPassword } from '../_redux/authCrud'
import '../../../../toast.css'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
}

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

function ResetPassword(props) {
  const { intl } = props
  const { token } = useParams()
  const history = useHistory()
  const [responseApi, setResponseApi] = useState({
    status: null,
    message: null,
    loading: false,
  })
  const [isRequested, setIsRequested] = useState(false)
  const [show, setShow] = useState(false)
  const [verify, setVerify] = useState(true)

  const handleClose = () => {
    history.push(`/logout`)
    setShow(false)
  }

  const verifyHashMail = async (hashToken) => {
    const authAxios = axios.create()
    await authAxios
      .get(`${API_BASE_URL}/api/adm/user/hashvalidation/unlogged/hashmail/${hashToken}`)
      .then((response) => {
        setVerify(false)
      })
      .catch((error) => {
        setVerify(false)
        handleShow()
      })
  }
  const handleShow = () => setShow(true)
  useEffect(() => {
    if (verify && typeof token !== 'undefined') {
      verifyHashMail(token)
      setVerify(false)
    }
    // eslint-disable-next-line
  }, [verify])

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Mínimo de 8 caracteres')
      .max(16, 'Máximo de 16 caracteres')
      .matches(/(?=.*[a-z])/, 'A senha requer pelo menos uma letra minúscula em sua composição.')
      .matches(/(?=.*[A-Z])/, 'A senha requer pelo menos uma letra maiúscula em sua composição.')
      .matches(/(?=.*[0-9])/, 'A senha requer pelo menos um número em sua composição.')
      .matches(
        /^(?!.*(01234|12345|23456|34567|45678|56789|67890)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(
        // eslint-disable-next-line
        /^(?!.*(\*\*\*\*\*|\&\&\&\&\&|\%\%\%\%\%|\$\$\$\$\$|\#\#\#\#\#|\@\@\@\@\@|\!\!\!\!\!|\?\?\?\?\?)).*$/,
        'A senha não pode conter mais do que 4 caracteres repetidos em sequência.'
      )
      .matches(
        /^(?!.*(98765|87654|76543|65432|54321|43210)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(/^(?!.*(\d)\1{4}).*$/, 'A senha não pode conter mais do que 4 números sequenciais.')
      .matches(/^(?!.*(\w)\1{4}).*$/, 'A senha não pode conter mais do que 4 caracteres repetidos em sequência.')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'A senha e a confirmação da senha não coincidem'),
      }),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setResponseApi({
        status: null,
        message: null,
        loading: true,
      })
      setIsRequested(true)
      resetPassword(values.password, token)
        .then((response) => {
          setResponseApi({
            status: response.data.status,
            message: response.data.message,
            loading: false,
          })
          if (response.data.message === 'O token não foi localizado.') {
            toast.error(`Erro!  ${response.data.message}`, {})
          } else {
            toast.success(`Sucesso!  ${response.data.message}`, {})
          }
          history.push(`/auth/login`)
        })
        .catch((error) => {
          setIsRequested(false)
          setSubmitting(false)
          setResponseApi({
            status: 'error',
            message: error.response,
            loading: false,
          })
          setStatus(intl.formatMessage({ id: 'SYSTEM.GERAL.ERROR' }))
        })
    },
  })

  return (
    <>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Link Inválido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Este link não é mais válido.</p>
          <p>Isso ocorre por alguns dos motivos abaixo:</p> <br />
          <ul>
            <li>O link já foi utilizado.</li>
            <li>Passaram-se 30 minutos depois de sua criação.</li>
            <li>O token utilizado não é mais válido.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* isRequested && <Redirect to="/auth" /> */}
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Redefinir Senha</h3>
          <div className="text-muted font-weight-bold">Insira sua nova senha e repita em confirmação de senha</div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <label className="font-weight-bold">Nova senha:</label>
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
              name="password"
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div style={{ color: 'red' }} className="fv-help-block">
                  {formik.errors.password}
                </div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="font-weight-bold">Redigite sua nova senha:</label>
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password_confirmation')}`}
              name="password_confirmation"
              {...formik.getFieldProps('password_confirmation')}
            />
            {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
              <div className="fv-plugins-message-container">
                <div style={{ color: 'red' }} className="fv-help-block">
                  {formik.errors.password_confirmation}
                </div>
              </div>
            ) : null}
          </div>

          {isRequested && (
            <div>
              {responseApi.loading && (
                <div className="overlay-layer bg-transparent mb-5">
                  <div className="d-flex justify-content-center">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                  <span className="h4">Por favor aguarde ...</span>
                </div>
              )}

              {responseApi.status !== null && (
                <div style={{ display: 'none' }}>
                  {responseApi.status === 'error' && toast.error(`Erro!  ${responseApi.message}.`, {})}
                </div>
              )}
            </div>
          )}

          <div className="form-group d-flex flex-wrap flex-center">
            {(responseApi.status === null || responseApi.status === 'error') && !responseApi.loading && (
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Enviar
              </button>
            )}
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                {isRequested && responseApi.status === 'error' ? 'Cancelar' : 'Voltar'}
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ResetPassword))
