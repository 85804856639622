import React from 'react'
import { ListItem } from '..'
import { ListContainer } from './List.styles'
import { AlertMessage } from '../../../modules/Solicitation/components/services/AlertMessage'

const List = ({ itens = [], noItensMessage }) => {
  return (
    <ListContainer>
      {itens.length === 0 && (
        <AlertMessage variant="alert alert-custom alert-light-primary alert-dismissible" message={noItensMessage} />
      )}
      {itens.map((item, index) => {
        const background = item.backgroundColor
        const Content = () => (
          <div className="d-flex justify-content-start">
            <span className="d-flex align-items-center" style={{ minWidth: '15%' }}>
              <strong> {item[Object.keys(item)[0]] || '-'} </strong>
            </span>
            {Object.entries(item).map(([key, value]) => {
              if (item[Object.keys(item)[0]] !== value && key !== 'action' && key !== 'backgroundColor')
                return (
                  <span
                    key={key}
                    className="d-flex align-items-center"
                    style={{
                      //minWidth: 88 / (itens.length - 1) + '%',
                      marginRight: '2rem',
                      maxWidth: 'max-content',
                    }}
                  >
                    {value}
                  </span>
                )
              else return null
            })}
          </div>
        )
        return <ListItem key={index} content={<Content />} actions={item.action || ''} backgroundColor={background} />
      })}
    </ListContainer>
  )
}

export default List
