import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .required('O campo "Título do Serviço" é de preenchimento obrigatório.')
      .min(3, 'O campo "Título do Serviço" deve ter no mínimo 03 caracteres.')
      .max(120, 'O campo "Título do Serviço" deve ter no máximo 120 caracteres.'),
    description: yup
      .string()
      .required('O campo "Descrição do Serviço" é de preenchimento obrigatório.')
      .min(3, 'O campo "Descrição do Serviço" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Descrição do Serviço" deve ter no máximo 1000 caracteres.'),
  })

export default validationSchema
