import * as requestFromServer from './dashboardEndpoints'
import { dashboardSlice } from './dashboardSlice'

const { actions } = dashboardSlice

/**
 * .
 */
export const getDashboard = () => (dispatch) => {
  return requestFromServer
    .getDashboard()
    .then((response) => {
      dispatch(actions.getDashboard(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Atualiza Unidade do Usuário que fez seu primeiro login pelo Login Único da Fiocruz.
 */
export const setUnitFromUserAction = (id, idUnit) => (dispatch) => {
  return requestFromServer
    .setUnitFromUser(id, idUnit)
    .then(() => {
      dispatch(actions.unitSet())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const clearFirstLogin = () => (dispatch) => {
  dispatch(actions.setFirstLogin(null))
}
