import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

// Novo serviço
export const newService = async (obj) =>
  await axios.post(`${API_BASE_URL}/api/service`, {
    title: obj.title,
    description: obj.description,
    enable: true,
  })
// Adicionar Administrador ao serviço
export const setServiceAdminitrator = async (idService, idUser) =>
  await axios.post(`${API_BASE_URL}/api/service/${idService}/add-administrator/user/${idUser}`)
// Adicionar Atendente ao serviço
export const setServiceAttendant = async (idService, idUser) =>
  await axios.post(`${API_BASE_URL}/api/service/${idService}/add-attendant/user/${idUser}`)
// Remover Administrador ao serviço
export const removeAdministratorFromService = async (idService, idUser) =>
  await axios.post(`${API_BASE_URL}/api/service/${idService}/remove-administrator/user/${idUser}`)
// Remover Atendente ao serviço
export const removeAttendantFromService = async (idService, idUser) =>
  await axios.post(`${API_BASE_URL}/api/service/${idService}/remove-attendant/user/${idUser}`)

export const editService = async (id, obj) =>
  await axios.put(`${API_BASE_URL}/api/service/${id}`, {
    title: obj.title,
    description: obj.description,
  })

export const enableService = async (id) => await axios.post(`${API_BASE_URL}/api/service/${id}/enable`)

export const disableService = async (id) => await axios.post(`${API_BASE_URL}/api/service/${id}/disable`)

export const deleteService = async (id) => await axios.delete(`${API_BASE_URL}/api/service/${id}`)

export const getService = async (id) => await axios.get(`${API_BASE_URL}/api/service/${id}`)

export const getAllServices = async () => await axios.get(`${API_BASE_URL}/api/service`)

export const getUsersSimplified = async () => await axios.get(`${API_BASE_URL}/api/user-simplified`)

export const getMyServicesAdmin = async (per_page, page, search, sort_field, ordering) => {
  return await axios.get(`${API_BASE_URL}/api/services/my_services/user/administrator`, {
    params: { per_page, page, search, sort_field, ordering },
  })
}

export const getMyServicesAttendant = async (per_page, page, search, sort_field, ordering) => {
  return await axios.get(`${API_BASE_URL}/api/services/my_services/user/attendant`, {
    params: { per_page, page, search, sort_field, ordering },
  })
}

export const getAttendantsQualifiedForService = async (id, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/service/${id}/qualified/attendants`, {
    params: { per_page, page, search, sort_field, ordering },
  })
