import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Button, Row, Col, InputGroup } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { toast } from 'react-toastify'
import TooltipCustom from '../../../../components/TooltipCustom'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { enableUser, disableUser } from '../../_redux/admin/adminActions'
import { allUsers } from '../../_redux/admin/adminActions'
import { GroupUserInfo, ActionButtonsWrapper } from './User.styles'
import AlertGT from '../../../../components/AlertGT'
import { AlertMessage } from '../../../Solicitation/components/services/AlertMessage'

const UserListPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { saveStatus, listLoading, userList, saveError } = useSelector((state) => state.admin)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    confirm: null,
    toastSuccess: '',
  })

  const [showLoadingEnableDisableUser, setshowLoadingEnableDisableUser] = useState(false)
  const [saveAccessUserType, setSaveAccessUserType] = useState(null)
  const [chosenUser, setChosenUser] = useState(null)
  const [searchEmpty, setSearchEmpty] = useState(false)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(allUsers())
    // eslint-disable-next-line
  }, [])

  const { SearchBar } = Search

  const toggleEnabled = (id, enable, username) => {
    const toggleActionTitle = enable ? 'Desabilitar o usuário' : 'Habilitar o usuário'
    const toggleActionText = enable ? 'Deseja desabilitar o usuário' : 'Deseja habilitar o usuário '

    const handleConfirm = () => {
      if (enable) {
        setSaveAccessUserType('enable')
        setChosenUser(username)
        setshowLoadingEnableDisableUser(true)
        dispatch(disableUser(id))
      } else {
        setSaveAccessUserType('disable')
        setChosenUser(username)
        setshowLoadingEnableDisableUser(true)
        dispatch(enableUser(id))
      }
      setConfigAlert({ ...configAlert, show: false })
    }

    setConfigAlert({
      show: true,
      title: toggleActionTitle,
      text: `${toggleActionText} <strong>${username}</strong>?`,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      confirm: () => handleConfirm(),
      toastSuccess: null,
    })
  }

  const userIdFormatter = (cell, row) => {
    return (
      <GroupUserInfo>
        <strong>{row.numberId}</strong> <br />
        {row.enabledDescription}
      </GroupUserInfo>
    )
  }

  const userDataFormatter = (cell, row) => (
    <GroupUserInfo>
      <strong>{row.name}</strong> <br />
      <span>{row.organization.initials}</span> {'>'} {row.email}
    </GroupUserInfo>
  )

  const userRolesFormat = (cell, row) => {
    const userRoles = row.roles
    let rolesList = userRoles.filter((role) => {
      if (role.hasThisRole) {
        return role
      }
      return role.hasThisRole ? role : false
    })

    const dataFormat = rolesList.map((role) => role.label).join(', ')

    return (
      <GroupUserInfo title={[dataFormat]}>
        <i className="fas fa-cogs"></i>
        {dataFormat}
      </GroupUserInfo>
    )
  }

  const userActionButtons = (cell, row) => (
    <ActionButtonsWrapper>
      <TooltipCustom title="Consultar Dados do Usuário" placement="top" arrow>
        <Button
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => history.push(`/administracao/usuarios/${row.id}/view`)}
        >
          <i className="fas fa-search"></i>
        </Button>
      </TooltipCustom>
      <TooltipCustom title="Atualizar Dados do Usuário" placement="top" arrow>
        <Button
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => history.push(`/administracao/usuarios/${row.id}/edit`)}
        >
          <i className="fas fa-edit fa-sm"></i>
        </Button>
      </TooltipCustom>
      <TooltipCustom title="Unidades do Usuário" placement="top" arrow>
        <Button
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => history.push(`/administracao/usuarios/${row.id}/historico/unidades`)}
        >
          <i className="fas fa-history fa-sm"></i>
        </Button>
      </TooltipCustom>
      <TooltipCustom
        title={row.isEnabled ? 'Desabilitar Acesso do Usuário' : 'Habilitar Acesso do Usuário'}
        placement="top"
        arrow
      >
        <Button
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => toggleEnabled(row.id, row.isEnabled, row.name)}
        >
          {row.isEnabled ? <i className="fas fa-lock fa-sm"></i> : <i className="fas fa-lock-open fa-sm"></i>}
        </Button>
      </TooltipCustom>
      <TooltipCustom title="Gerenciar Acessos e Permissões" placement="top" arrow>
        <Button
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => history.push(`/administracao/usuarios/${row.id}/permissoes`)}
        >
          <i className="fas fa-user"></i>
        </Button>
      </TooltipCustom>
    </ActionButtonsWrapper>
  )

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'numberId',
      text: 'Código e Status',
      sort: true,
      classes: 'align-middle',
      align: 'center',
      isDummyField: false,
      headerAttrs: {
        hidden: false,
      },
      filterValue: (cell, row) => {
        return [row.numberId, row.enabledDescription]
      },
      formatter: userIdFormatter,
    },
    {
      dataField: 'name',
      text: 'Dados do Usuário',
      sort: true,
      classes: 'align-middle',
      headerAttrs: {
        hidden: false,
      },
      filterValue: (cell, row) => {
        return [row.name, row.organization.initials, row.email]
      },
      formatter: userDataFormatter,
    },
    {
      dataField: 'roles',
      text: 'Perfis de Acesso',
      sort: true,
      classes: 'align-middle',
      headerAttrs: {
        hidden: false,
      },
      filterValue: (cell, row) => {
        let rolesArr = []
        cell.map((role) => {
          if (role.hasThisRole) {
            rolesArr.push(role.label)
          }
          return true
        })

        return rolesArr.join()
      },
      formatter: userRolesFormat,
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      classes: 'align-middle',
      align: 'center',
      headerAttrs: {
        hidden: false,
      },
      headerStyle: () => {
        return { minWidth: '50px', width: '220px' }
      },
      isDummyField: true,
      csvExport: false,
      formatter: userActionButtons,
    },
  ]
  /**
   * Alterando o texto de exibição de registros.
   *
   * @param {*} from
   * @param {*} to
   * @param {*} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;&nbsp;Exibindo registros de <strong>{from}</strong> a <strong>{to}</strong> de um total de{' '}
      <strong>{size}</strong> registros.
    </span>
  )

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
          style={{ height: 30, paddingTop: 5 }}
        >
          {option.text}
        </button>
      ))}
    </div>
  )
  /**
   * Parâmetros para paginação
   */
  const pageOptions = {
    page: 1,
    sizePerPage: 5,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: userList.length,
      },
    ],
    sizePerPageRenderer,
  }

  if (saveStatus === 'success' && showLoadingEnableDisableUser) {
    toast.success(
      `Sucesso!  Usuário ${chosenUser} teve o seu acesso ao sistema ${
        saveAccessUserType === 'enable' ? ' desabilitado' : ' habilitado'
      }.`,
      {}
    )
    setshowLoadingEnableDisableUser(false)
  }

  if (showLoadingEnableDisableUser && saveStatus === 'error') {
    toast.error(`Erro! ${saveError.message}.`, {})
    setshowLoadingEnableDisableUser(false)
  }

  const qtdInSearch = (data, itemSearchText) => {
    const searcheredData = data.filter(function(item) {
      let arrRoleLabels = []
      const searchRolesLabel = arrRoleLabels.join(', ')
      if (searchRolesLabel.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1) {
        return item
      }

      if (
        item.numberId.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.enabledDescription.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.email.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.listRolesThisUser.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.organization.initials.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        searchRolesLabel.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.enabledDescription
          .toString()
          .toLowerCase()
          .indexOf(itemSearchText.toLowerCase()) !== -1
      ) {
        return item
      }
      return false
    })
    searcheredData.length > 0 ? setSearchEmpty(false) : setSearchEmpty(true)
  }
  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <>
            <>
              {userList.length === 0 && listLoading && (
                <>
                  <Row>
                    <Col className="text-center">
                      <p className="overlay-layer bg-transparent mb-5">
                        <span className="spinner spinner-lg spinner-success" />
                      </p>
                      <p className="h4 mt-10">Carregando usuários ...</p>
                    </Col>
                  </Row>
                </>
              )}

              {showLoadingEnableDisableUser && saveStatus !== 'error' && (
                <>
                  <Row>
                    <Col className="text-center">
                      <p className="overlay-layer bg-transparent mb-5">
                        <span className="spinner spinner-lg spinner-success" />
                      </p>
                      <p className="h4 mt-10">
                        {`${saveAccessUserType === 'enable' ? 'Desabilitando' : 'Habilitando'}`} usuário no sistema ...
                      </p>
                    </Col>
                  </Row>
                </>
              )}

              <>
                <ToolkitProvider
                  keyField="id"
                  data={userList}
                  columns={columns}
                  search={{
                    searchFormatted: false,
                  }}
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}></Col>
                        <Col sm={3}>
                          <div className="d-grid"></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={10} className="mb-4">
                          <InputGroup>
                            <InputGroup.Text
                              style={{ height: 36, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                              className="input-group-prepend"
                            >
                              <i className="fas fa-search"></i>
                            </InputGroup.Text>
                            <SearchBar
                              {...props.searchProps}
                              placeholder="Digite o alvo de sua busca"
                              className="w-100 input-group-prepend"
                              style={{ height: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: '100%' }}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={2} className="text-right">
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() => history.push('/administracao/usuarios/novo')}
                            className="font-weight-bold btn-block"
                          >
                            Cadastrar Usuário
                          </Button>{' '}
                        </Col>
                      </Row>
                      {qtdInSearch(userList, props.searchProps.searchText)}

                      {searchEmpty && props.searchProps.searchText && (
                        <Row>
                          <Col>
                            <AlertMessage
                              variant="alert alert-custom alert-light-primary alert-dismissible"
                              message={`Não foram encontrados resultados para o termo ${props.searchProps.searchText}`}
                            />
                          </Col>
                        </Row>
                      )}

                      {!searchEmpty && (
                        <Row>
                          <Col>
                            <BootstrapTable
                              {...props.baseProps}
                              classes="table-striped "
                              keyField="id"
                              data={userList}
                              columns={columns}
                              rowStyle={{ color: 'black' }}
                              headerClasses="text-center bg-light"
                              bordered={true}
                              noDataIndication="Não foi localizado registros de usuário"
                              pagination={paginationFactory(pageOptions)}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </ToolkitProvider>
              </>
            </>
          </>
        </Card.Body>
        <Card.Footer>
          <Row className="mt-0">
            <Col className="text-right">
              <Button variant="secondary" type="button" onClick={() => history.push('/')} className="font-weight-bold">
                <i className="fa fa-chevron-left"></i>
                &nbsp;&nbsp;Voltar
              </Button>{' '}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        onConfirm={configAlert.confirm}
        confirmButtonText={configAlert.confirmButtonText}
        showCancelButton={configAlert.cancelButton}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        toastSuccess={configAlert.toastSuccess}
      />
    </>
  )
}

export default UserListPage
