import React from 'react'
import { useHistory } from 'react-router'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useEffect } from 'react'

const ErrorUnauthorized = () => {
  const history = useHistory()

  const CLIENT_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_CLIENT_URL_HOMOL}`
      : `${process.env.REACT_APP_CLIENT_URL_PROD}`

  useEffect(() => {
    console.clear()
    const timerNotifications = window.setTimeout(() => {
      window.localStorage.removeItem('CDTS-GESTECV2')
      localStorage.clear()
      window.location = `${CLIENT_BASE_URL}/logout`
    }, 8000)

    return () => {
      window.clearInterval(timerNotifications)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/error/bg7.jpg')})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column text-center p-12" style={{ height: '85vh' }}>
          <h1 className="font-weight-boldest text-info  display-3 mt-5 mt-md-0 mb-3">Sua sessão expirou!</h1>
          <p className="font-weight-boldest display-4">Por favor, faça login novamente.</p>

          <Container>
            <Row className="text-right">
              <Col className="text-right">
                <Button onClick={() => history.push('/auth/login')}>
                  Acessar tela de Login &nbsp;&nbsp;<span className="spinner spinner-sm spinner-warnin"></span>
                  &nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default ErrorUnauthorized
