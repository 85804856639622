import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { requestPassword } from '../_redux/authCrud'
import { Alert } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '../css/styles.css'

//import "../../../../toast.css";

const initialValues = {
  email: '',
}

function ForgotPassword(props) {
  const { intl } = props
  const [responseApi, setResponseApi] = useState({
    status: null,
    message: null,
    loading: false,
  })
  const [isRequested, setIsRequested] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  // const [proceedButton, setProceedButton] = useState(true)

  const history = useHistory()
  const MySwal = withReactContent(Swal)
  const { pageStatus } = useParams()

  useEffect(() => {
    if (responseApi.status === 'success') {
      setTimeout(() => {
        displayModal()
      }, 500)
    }
    // eslint-disable-next-line
  }, [responseApi])

  function handleOnChange(value) {
    setIsVerified(true)
  }

  const displayModal = () => {
    MySwal.fire({
      title: 'Atenção!',
      heightAuto: false, //ajusta layout quando display-flex é usado
      width: 600,
      html:
        '<p>Se o e-mail informado constar em nossa base de dados, você receberá uma mensagem com instruções de acesso.</p>',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok!',
    }).then((result) => {
      if (result.isConfirmed) {
        // setProceedButton(false)
      }
      if (result.isDismissed) {
        // setProceedButton(false)
      }
    })
  }

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail com formato errado')
      .min(3, 'O campo "E-mail" deve ter no mínimo 03 caracteres.')
      .max(50, 'O campo "E-mail" deve ter no máximo 50 caracteres.')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setResponseApi({
        status: null,
        message: null,
        loading: true,
      })
      setIsRequested(true)
      requestPassword(values.email)
        .then((response) => {
          setResponseApi({
            status: response.data.status,
            message: response.data.message,
            loading: false,
          })

          setIsRequested(false)
        })
        .catch((error) => {
          setIsRequested(false)
          setSubmitting(false)
          setResponseApi({
            status: 'error',
            message: error,
            loading: false,
          })
          setStatus(intl.formatMessage({ id: 'AUTH.VALIDATION.NOT_FOUND' }, { name: values.email }))
        })
    },
  })

  if (pageStatus === 'GgvbG9naW4iLCJpYXQiOjE2ODM2MzU2MDcsImV4c') {
    setTimeout(() => history.push(`/auth/login`), 10000)
  }
  return (
    <>
      {/* isRequested && <Redirect to="/auth" /> */}
      <div className="" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Não conseguiu acessar o sistema?</h3>
          <div className="font-weight-bold">
            Caso tenha esquecido sua senha ou o serviço
            <br />
            Login Único Fiocruz esteja inacessível, preencha o formulário abaixo!
          </div>
          <div className="mb-4" style={{ width: '300px', margin: '0 auto' }}>
            {pageStatus === 'GgvbG9naW4iLCJpYXQiOjE2ODM2MzU2MDcsImV4c' && (
              <Alert variant={'danger'}>
                <Alert.Heading>Atenção!</Alert.Heading>
                <p>Há um problema de comunicação. Tente novamente em alguns segundos.</p>
              </Alert>
            )}

            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              <div className="form-group fv-plugins-icon-container mb-4">
                <Form.Label>E-mail</Form.Label>
                <input
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
                  name="email"
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>

              {isRequested && (
                <div>
                  {responseApi.loading && (
                    <div className="overlay-layer bg-transparent mb-5">
                      <div className="d-flex justify-content-center">
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                      <span className="h4">Por favor aguarde ...</span>
                    </div>
                  )}

                  {responseApi.status !== null && (
                    <Alert variant={responseApi.status === 'success' ? 'success' : 'danger'}>
                      <Alert.Heading>{responseApi.status === 'success' ? 'Sucesso' : 'Erro'}!</Alert.Heading>
                      <p>
                        {responseApi.message}.
                        {isRequested &&
                          responseApi.status === 'error' &&
                          toast.error(`Erro!  ${responseApi.message}.`, {})}
                      </p>
                    </Alert>
                  )}
                </div>
              )}

              <div className="d-flex justify-content-center">
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleOnChange} />
              </div>

              <div className=" mt-10">
                {(responseApi.status === null || responseApi.status === 'error') && !responseApi.loading && (
                  <Button
                    id="kt_login_forgot_submit"
                    type="submit"
                    disabled={!isVerified}
                    className="btn btn-primary font-weight-bold"
                    md="auto"
                    block
                  >
                    Prosseguir
                  </Button>
                )}
              </div>
              <div className="mt-5">
                <Link to="/auth">
                  <Button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold"
                    block
                  >
                    Voltar
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
