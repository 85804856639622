import React from 'react'

export const RequirementViewDataTypeInfo = (props) => (
  <>
    <dl className="row">
      <dt className="col-sm-3 text-right">Mensagem informativa</dt>
      <dd className="col-sm-9 text-justify">{props.messageInfo}</dd>

      <dt className="col-sm-3 text-right">Confirmação de Leitura do informativo</dt>
      <dd className="col-sm-9">{props.readConfirmation}</dd>
    </dl>
  </>
)
