import styled from 'styled-components'

export const Block = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 30px 4px;
  font-size: 1.2rem;
  flex-grow: 2;
  border-radius: 0.5rem;

  i {
    color: var(--dark);
    margin-right: 0.5rem;
  }
`
