import styled from 'styled-components'

export const HorizontalLabel = styled.div`
  padding: 8px 16px;
  border: 0;
  border-radius: 16px;
  height: 120px;
  min-width: 180px;
  max-width: 180px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  opacity: 1;
  transition: all 0.5s;
  text-align: left;
  font-weight: 600;
  color: var(--gray);

  * {
    color: var(--gray);
  }

  .iconButton-icon {
    margin-right: 1rem;
    * {
      font-size: 3rem;
    }
  }
`
