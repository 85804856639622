import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

export const getAllIntangibleAssets = async () => await axios.get(`${API_BASE_URL}/api/reports/get_intangible_assets`)
export const getAllServices = async () => await axios.get(`${API_BASE_URL}/api/reports/get_services`)
export const getAllUnits = async () => await axios.get(`${API_BASE_URL}/api/reports/get_units`)

//Gerar relatṍrio tabular (excel)
export const reportTabular = async (arrUnits, arrServices, startDate, endDate) =>
  await axios({
    url: `${API_BASE_URL}/api/reports/tabulated_report`, //your url
    method: 'post',
    data: {
      units: arrUnits,
      services: arrServices,
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    const actualDate = new Date()
    let year = actualDate.getFullYear()
    let day = actualDate.getDate()
    let month = ('00' + (actualDate.getMonth() + 1)).slice(-2)
    let hour = ('00' + (actualDate.getUTCHours() + 1)).slice(-2)
    let minutes = ('00' + (actualDate.getUTCMinutes() + 1)).slice(-2)
    let seconds = ('00' + (actualDate.getUTCSeconds() + 1)).slice(-2)
    let filtered =
      arrUnits.length === 0 && arrServices.length === 0 && startDate == null && endDate == null ? '' : '_filtrado'
    const relName = `${year}${month}${day}_${hour}${minutes}${seconds}_relatorio${filtered}.xlsx`
    link.setAttribute('download', relName) //or any other extension
    document.body.appendChild(link)
    link.click()
  })

//Gerar relatṍrio dossie Ativo Itangível (pdf)
export const reportDossierIntangibleAsset = async (id_intangible_asset, iacode) =>
  await axios({
    url: `${API_BASE_URL}/api/reports/dossier_intangible_asset`, //your url
    method: 'post',
    data: {
      id: id_intangible_asset,
      code: iacode,
    },
    withCredentials: false,
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    const actualDate = new Date()
    let year = actualDate.getFullYear()
    let day = actualDate.getDate()
    let month = ('00' + (actualDate.getMonth() + 1)).slice(-2)
    let hour = ('00' + (actualDate.getUTCHours() + 1)).slice(-2)
    let minutes = ('00' + (actualDate.getUTCMinutes() + 1)).slice(-2)
    let seconds = ('00' + (actualDate.getUTCSeconds() + 1)).slice(-2)

    const relName = `${year}${month}${day}_${hour}${minutes}${seconds}_relatorio.pdf`
    link.setAttribute('download', relName) //or any other extension
    document.body.appendChild(link)
    link.click()
  })

export const reportTabularCount = async (arrUnits, arrServices, startDate, endDate) =>
  await axios.post(`${API_BASE_URL}/api/reports/tabulated_report_count`, {
    units: arrUnits,
    services: arrServices,
    start_date: startDate,
    end_date: endDate,
  })
