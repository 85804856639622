import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import validationNewEditSchema from './validationNewEditSchema'
import {
  updateService,
  addAdminToService,
  addAttendantToService,
  removeAdminFromService,
  removeAttendantFromService,
  allServices,
  clearErrorsOnDelete,
} from '../../_redux/services/serviceActions'
import { Card, Tabs, Tab, Row, Col, Button, Alert } from 'react-bootstrap'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle } from '../../../../../app/routes/Helpers'
import ServiceForm from '../../components/services/ServiceForm'
import { toast } from 'react-toastify'
import { ManagerServiceForm } from '../../components/services/ManagerServiceForm'
import { ManagerServiceList } from '../../components/services/ManagerServiceList'
import { AlertMessage } from '../../components/services/AlertMessage'
import AlertGT from '../../../../components/AlertGT'

export function ServiceManagementPage() {
  const history = useHistory()
  const { id1 } = useParams()
  const dispatch = useDispatch()

  const subheader = useSubheader()
  const pathname = window.location.pathname
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  //subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle);

  const [showAlert, setShowAlert] = React.useState(false)
  const [alertTitle, setAlertTitle] = React.useState('')
  const [alertText, setAlertText] = React.useState('')
  const [alertConfirm, setAlertConfirm] = React.useState(null)
  const [alertShowCancelButton, setAlertShowCancelButton] = React.useState(false)
  const [alertConfirmButtonText, setAlertConfirmButtonText] = React.useState(null)
  const [alertIcon, setAlertIcon] = React.useState(null)
  const [toastSuccess, setToastSuccess] = React.useState(null)
  const [displayAlertError, setDisplayAlertError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const {
    serviceList,
    saveError,
    saveStatus,
    statusMessage,
    userList,
    errorOnUpdate,
    errorOnDeleteAttendant,
  } = useSelector((state) => state.services)

  /**
   * Definir hooks para gravar chave da Tab. (iniciando pela tab information)
   */
  const [keyTab, setKeyTab] = useState('information')

  /**
   * Se a tab for Informações e submit do formulario de ediçaõ, com suceso
   * exibe toast de gração bem suced1a
   */
  if (keyTab === 'information' && saveStatus === 'success') {
    toast.success(`Sucesso! ${statusMessage}`, {})
    history.push('/servico')
  }
  /**
   * Se carragar a tab for informações,  e a ServiceList estiver vazi0 (recarremento forçado da page)
   * redireciona para page de lista de Serviços
   */
  if (keyTab === 'information' && serviceList.length === 0) {
    history.push('/servico')
  }

  //Recuperar o serviço para edição:
  const service = serviceList.find((item) => item.id === parseInt(id1))

  if (keyTab === 'information' && typeof service !== 'undefined') {
    subheader.setSubtitle(`${service.code} - ${service.title}`)
  }

  const administrators = typeof service !== 'undefined' ? service.administrators : []
  const attendants = typeof service !== 'undefined' ? service.attendants : []

  /**
   * Prepara lista de usuarios para adicionar administradores ao serviço
   * @returns
   */
  const usersAdminToDropdown = () => {
    let listAdminItens = []
    userList.filter((userItem) => {
      if (typeof administrators.find((finder) => finder.id === userItem.id) === 'undefined') {
        //verifica se o usuário tem o perfil de Administrador de Serviço
        if (userItem.isServiceAdmin) {
          listAdminItens.push({ value: userItem.id, label: userItem.name })
        }
      }
      return listAdminItens
    })
    return listAdminItens
  }
  /**
   * Prepara lista de usuarios para adicionar atendentes ao serviço
   * @returns
   */
  const usersAttendantToDropdown = () => {
    let listAttendantItens = []
    userList.filter((userItem) => {
      if (typeof attendants.find((finder) => finder.id === userItem.id) === 'undefined') {
        //verifica se o usuário tem o perfil de Atendente
        if (userItem.isServiceAttendant) {
          listAttendantItens.push({ value: userItem.id, label: userItem.name })
        }
      }
      return listAttendantItens
    })
    return listAttendantItens
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])

  const userType = keyTab === 'administrators' ? 'administrador' : 'atendente'
  useEffect(() => {
    if (errorOnDeleteAttendant) {
      setDisplayAlertError(true)
      setErrorMessage(errorOnDeleteAttendant)
    } else {
      setDisplayAlertError(false)
      setErrorMessage(null)
    }
    // eslint-disable-next-line
  }, [errorOnDeleteAttendant])

  useEffect(() => {
    dispatch(allServices())
    // eslint-disable-next-line
  }, [saveStatus])

  const handleClickDeleteUserFromService = (item, userName) => {
    setShowAlert(true)
    setAlertTitle(`Exclusão de ${userType}`)
    setAlertText(`Deseja excluir o(a) ${userType}  <strong>${userName}</strong> deste serviço?`)
    setAlertIcon('question')
    setAlertShowCancelButton(true)
    setAlertConfirmButtonText('Sim')

    if (errorOnDeleteAttendant == null) {
      setToastSuccess(`Sucesso!  ${userType === 'administrador' ? 'Administrador' : 'Atendente'} excluído do serviço.`)
    }

    const handleConfirm = () => {
      if (userType === 'administrador') {
        setSelectedOption(null) //limpa dropdawnlist
        dispatch(removeAdminFromService(service.id, item))
      } else {
        dispatch(clearErrorsOnDelete())
        setSelectedOption(null) //limpa dropdawnlist
        dispatch(removeAttendantFromService(service.id, item))
      }
      setShowAlert(false)
    }

    setAlertConfirm(() => handleConfirm)
  }

  /**
   * Grava no estado local  seleçao do combox de Administradores ou atendentes.
   */
  const [selectedOption, setSelectedOption] = useState(null)

  const buttonAddAdministrator = () => {
    dispatch(clearErrorsOnDelete())
    if (selectedOption !== null) {
      const idUser = selectedOption.value
      const idService = service.id
      dispatch(addAdminToService(idService, idUser))
      setSelectedOption(null)
      const userType = keyTab === 'administrators' ? 'Administrador' : 'Atendente'
      toast.success(`Sucesso!  ${userType} selecionado para o serviço.`)
    } else {
      setShowAlert(true)
      setAlertTitle(`Oops`)
      setAlertText(`Você precisa escolher um nome na lista!`)
      setAlertIcon('error')
      setAlertShowCancelButton(false)
      setAlertConfirmButtonText('Fechar')
      setToastSuccess(null)
      setAlertConfirm(() => () => setShowAlert(false))
    }
  }

  const buttonAddAttendant = () => {
    dispatch(clearErrorsOnDelete())
    if (selectedOption !== null) {
      const idUser = selectedOption.value
      const idService = service.id
      dispatch(addAttendantToService(idService, idUser))
      setSelectedOption(null)
      const userType = keyTab === 'attendants' ? 'Atendente' : 'Administrador'
      toast.success(`Sucesso!  ${userType} selecionado para o serviço.`)
    } else {
      setShowAlert(true)
      setAlertTitle(`Oops`)
      setAlertText(`Você precisa escolher um nome na lista!`)
      setAlertIcon('error')
      setAlertShowCancelButton(false)
      setAlertConfirmButtonText('Fechar')
      setToastSuccess(null)
      setAlertConfirm(() => () => setShowAlert(false))
    }
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Row>
            <Col>
              {displayAlertError && (
                <AlertMessage variant="alert alert-custom alert-danger alert-dismissible" message={errorMessage} />
              )}
            </Col>
          </Row>

          <Tabs
            id="controlled-tab-example"
            activeKey={keyTab}
            onSelect={(k) => {
              switch (k) {
                case 'information':
                  subheader.setSubtitle(`${service.code} - ${service.title}`)
                  break
                case 'administrators':
                  subheader.setSubtitle(`${service.code} - ${service.title}`)
                  break
                case 'attendants':
                  subheader.setSubtitle(`${service.code} - ${service.title}`)
                  break

                default:
                  break
              }
              setKeyTab(k)
            }}
            className="mb-3"
          >
            <Tab eventKey="information" title="Informações" className="pt-6">
              {keyTab === 'information' && errorOnUpdate && saveError.length !== 0 && (
                <Alert variant="danger" className="pt-5">
                  {Object.values(saveError[0]).map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}
                </Alert>
              )}
              <Formik
                validationSchema={validationNewEditSchema}
                onSubmit={(values) => {
                  dispatch(updateService(id1, values))
                }}
                initialValues={{
                  title: typeof service !== 'undefined' ? service.title : '',
                  description: typeof service !== 'undefined' ? service.description : '',
                }}
              >
                {(formik) => (
                  <ServiceForm
                    handleSubmit={formik.handleSubmit}
                    onChangeTitle={formik.handleChange}
                    onBlueTitle={formik.handleBlur}
                    getFieldPropsTitle={formik.getFieldProps('title')}
                    touchedTitle={formik.touched.title}
                    errorsTitle={formik.errors.title}
                    onChangeDescription={formik.handleChange}
                    onBlueDescription={formik.handleBlur}
                    errorsDescription={formik.errors.description}
                    touchedDescription={formik.touched.description}
                    valuesDescription={formik.values.description}
                    getFieldPropsDescription={formik.getFieldProps('description')}
                  />
                )}
              </Formik>
            </Tab>
            <Tab eventKey="administrators" title="Administradores">
              <Row>
                <Col>
                  <AlertMessage
                    variant="alert alert-custom alert-light-primary alert-dismissible"
                    message="É necessária a seleção de pelo menos um administrador para habilitar 
                                        o serviço no sistema."
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={8} className="mt-2">
                  <ManagerServiceForm
                    setSelectedOption={selectedOption}
                    options={usersAdminToDropdown()}
                    onChange={setSelectedOption}
                  />
                </Col>
                <Col sm={4} style={{ minWidth: '270px' }} className="mt-2">
                  <Button
                    variant="primary"
                    type="button"
                    className="btn-block font-weight-bold"
                    onClick={buttonAddAdministrator}
                  >
                    Adicionar Administrador
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ManagerServiceList
                    listagem={administrators}
                    msgOnEmptyListAdminOrAttendants="Este serviço não possui nenhum administrador no momento."
                    handleClick={handleClickDeleteUserFromService}
                  />
                </Col>
              </Row>
              <Row className="mt-0">
                <Col className="text-right">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => history.push('/servico')}
                    className="font-weight-bold"
                  >
                    <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                  </Button>{' '}
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="attendants" title="Atendentes">
              <Row>
                <Col>
                  <AlertMessage
                    variant="alert alert-custom alert-light-primary alert-dismissible"
                    message="É necessária a seleção de pelo menos um atendente para viabilizar 
                                        o atendimento de solicitações  deste serviço no sistema."
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={8} className="mt-2">
                  <ManagerServiceForm
                    setSelectedOption={selectedOption}
                    options={usersAttendantToDropdown()}
                    onChange={setSelectedOption}
                  />
                </Col>
                <Col sm={4} style={{ minWidth: '270px' }} className="mt-2">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={buttonAddAttendant}
                    className="btn-block font-weight-bold"
                  >
                    Adicionar Atendentes
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ManagerServiceList
                    listagem={attendants}
                    msgOnEmptyListAdminOrAttendants="Este serviço não possui nenhum atendente no momento."
                    handleClick={handleClickDeleteUserFromService}
                  />
                </Col>
              </Row>
              <Row className="mt-0">
                <Col className="text-right">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      dispatch(clearErrorsOnDelete())
                      history.push('/servico')
                    }}
                    className="font-weight-bold"
                  >
                    <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                  </Button>{' '}
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <AlertGT
        show={showAlert}
        title={alertTitle}
        text={alertText}
        icon={alertIcon}
        showCancelButton={alertShowCancelButton}
        confirmButtonText={alertConfirmButtonText}
        onConfirm={alertConfirm}
        onDismissed={() => setShowAlert(false)}
        toastSuccess={toastSuccess}
      />
    </>
  )
}
export default ServiceManagementPage
