import React, { useRef, useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import FileBarWithDescription from './FileBar/FileBarWithDescription'
import FileBarWithRequiredDownload from './FileBar/FileBarWithRequiredDownload'
import FileBarDefault from './FileBar/FileBarDefault'
import { Form } from 'react-bootstrap'

import {
  DropzoneWrapper,
  DropContainer,
  DropMessage,
  FileInput,
  FileDisplayContainer,
  FilesInfoMessage,
} from './Dropzone.styles'

const defaultValidTypes = [
  'pdf',
  'doc',
  'docx',
  'csv',
  'xls',
  'xlsx',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'webp',
  'ppt',
  'pptx',
  'txt',
]

const Dropzone = ({
  attachments,
  setAttachments,
  requiredDownloadsAtt,
  setRequiredDownloadsAtt,
  formByFile,
  onAutoSave,
  onDeleteFile,
  uploadedFiles,
  acceptableFileTypes = defaultValidTypes,
  maxAmountFiles = 10,
  filesInfoMessage,
  actionType = 'CREATE',
  maxSizeFileMB = 20,
  getInValidFiles,
}) => {
  const fileInputRef = useRef()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [validFiles, setValidFiles] = useState([])
  const [unsupportedFiles, setUnsupportedFiles] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [exceededLimitFile, setExceededLimitFile] = useState(false)
  const [descriptionIsEmpty, setDescriptionIsEmpty] = useState(false)
  const [errorCharacterCounter, setErrorCharacterCounter] = useState(false)

  const maxSizeFile = 1000000 * maxSizeFileMB

  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name || item.uuid === current.uuid)
      const existInValidFiles = validFiles.some((el) => el.uuid === current.uuid)
      if (!x && !existInValidFiles) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
    setValidFiles([...validFiles.concat(filteredArr)])
    // eslint-disable-next-line
  }, [selectedFiles])

  useEffect(() => {
    let loadValidFiles = []
    if (uploadedFiles) {
      uploadedFiles.forEach((uploadedFile, index) => {
        let fileExistInValidFile = false
        validFiles.forEach((validFile) => {
          if (validFile.uuid === uploadedFile.uuid) {
            fileExistInValidFile = true
            validFile.isSaved = true
            validFile.original_name = uploadedFile.original_name
            validFile.solicitation_upload_files_id = uploadedFile.solicitation_upload_files_id
            validFile.description = uploadedFile.description
          }
        })

        const hasSeletedFilesIndex = selectedFiles.findIndex((el) => el.uuid === uploadedFile.uuid)
        if (hasSeletedFilesIndex > -1) {
          selectedFiles.splice(hasSeletedFilesIndex, 1)
          setSelectedFiles(selectedFiles)
        }

        if (!fileExistInValidFile) {
          const setSaveFile = {
            ...uploadedFile,
            isSaved: true,
            name: uploadedFile.original_name,
          }
          loadValidFiles.push(setSaveFile)
        }
      })

      setValidFiles([...loadValidFiles.concat(validFiles)])
      setAttachments([...loadValidFiles.concat(validFiles)])
    }
    // eslint-disable-next-line
  }, [uploadedFiles])

  useEffect(() => {
    let filter = validFiles.filter((file) => {
      return file.invalid
    })
    if (getInValidFiles && typeof getInValidFiles === 'function') getInValidFiles(filter)
    // eslint-disable-next-line
  }, [validFiles])

  const preventDefault = (e) => {
    e.preventDefault()
    // e.stopPropagation();
  }

  const dragOver = (e) => {
    preventDefault(e)
  }

  const dragEnter = (e) => {
    preventDefault(e)
  }

  const dragLeave = (e) => {
    preventDefault(e)
  }

  const fileDrop = (e) => {
    preventDefault(e)
    const files = e.dataTransfer.files
    if (files.length) {
      handleFiles(files)
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files)
    }
  }

  const fileInputClicked = () => {
    fileInputRef.current.click()
  }

  const handleFiles = (files) => {
    const totalFileCount = validFiles.length + files.length
    if (maxAmountFiles && totalFileCount > maxAmountFiles) {
      setExceededLimitFile(true)
    } else {
      setExceededLimitFile(false)
      for (let i = 0; i < files.length; i++) {
        files[i].uuid = uuidv4()
        files[i].isSaved = false

        if (!validateTypeFile(files[i])) {
          files[i]['invalid'] = true
          files[i]['errorMessage'] = 'Tipo de arquivo não permitido'

          setSelectedFiles((prevArray) => [...prevArray, files[i]])
          setErrorMessage('Tipo de arquivo não permitido')
          setUnsupportedFiles((prevArray) => [...prevArray, files[i]])
        } else if (files[i].size > maxSizeFile) {
          files[i]['invalid'] = true
          files[i]['errorMessage'] = `Tamanho máximo de arquivo excedido "${maxSizeFileMB}MB"`

          setSelectedFiles((prevArray) => [...prevArray, files[i]])
          setErrorMessage('Tamanho máximo de arquivo excedido')
          setUnsupportedFiles((prevArray) => [...prevArray, files[i]])
        } else {
          setSelectedFiles((prevArray) => [...prevArray, files[i]])
          setAttachments((prevArray) => [...prevArray, files[i]])
        }
      }
    }
  }

  const validateTypeFile = (file) => {
    const extensionsFile = file.name
      .split('.')
      .pop()
      .toLowerCase()

    if (acceptableFileTypes.indexOf(extensionsFile) < 0) {
      return false
    }

    return true
  }

  const removeFile = (file) => {
    const index2 = selectedFiles.findIndex((e) => e.name === file.name)
    const index3 = unsupportedFiles.findIndex((e) => e.name === file.name)
    selectedFiles.splice(index2, 1)
    setSelectedFiles([...selectedFiles])
    setExceededLimitFile(false)

    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1)
      setUnsupportedFiles([...unsupportedFiles])
    }

    if (formByFile === 'REQUIRED_DOWNLOAD') {
      setAttachments(selectedFiles)
      const index = validFiles.findIndex((e) => e.name === file.name)
      validFiles.splice(index, 1)
      setValidFiles([...validFiles])

      requiredDownloadsAtt.splice(index2, 1)
      setRequiredDownloadsAtt([...requiredDownloadsAtt])
    }

    if (
      !formByFile ||
      formByFile === 'UPLOAD_IN_LIGHTBOX' ||
      formByFile === 'FILE_DESCRIPTION' ||
      formByFile === 'UPLOAD_DEFAULT'
    ) {
      const index = validFiles.findIndex((e) => e.uuid === file.uuid)
      validFiles.splice(index, 1)
      setValidFiles([...validFiles])
      setAttachments([...validFiles])
    }
  }

  return (
    <DropzoneWrapper>
      {unsupportedFiles.length ? <p>Por favor, remova todos os arquivos não suportados.</p> : ''}
      {exceededLimitFile && <p>A quantidade máxima de arquivos permitida é: {maxAmountFiles}.</p>}
      {formByFile === 'FILE_DESCRIPTION' && descriptionIsEmpty && (
        <p>O campo de descrição do arquivo é de preenchimento obrigatório.</p>
      )}
      {formByFile === 'FILE_DESCRIPTION' && errorCharacterCounter && (
        <p>O campo de descrição do arquivo deve ter no mínimo 03 caracteres.</p>
      )}
      {filesInfoMessage && (
        <FilesInfoMessage>
          {filesInfoMessage.map((infoMessage) => (
            <p>{infoMessage}</p>
          ))}
        </FilesInfoMessage>
      )}
      <DropContainer
        className="drop-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <DropMessage>Clique aqui ou arraste para cá o(s) arquivo(s) que deseja enviar.</DropMessage>
        <FileInput
          ref={fileInputRef}
          className="file-input"
          type="file"
          name="attachments"
          multiple
          onChange={filesSelected}
        />
      </DropContainer>
      {(formByFile === 'UPLOAD_IN_LIGHTBOX' || formByFile === 'UPLOAD_DEFAULT') && (
        <Form.Text className="text-right">
          Máximo de {maxAmountFiles} arquivos com até {maxSizeFileMB}MB
        </Form.Text>
      )}
      <FileDisplayContainer>
        {(!formByFile || formByFile === 'UPLOAD_IN_LIGHTBOX' || formByFile === 'UPLOAD_DEFAULT') &&
          validFiles.map((data, index) => (
            <FileBarDefault file={data} index={index} handleClickRemoveFiles={removeFile} />
          ))}
        {formByFile === 'REQUIRED_DOWNLOAD' &&
          validFiles.map((data, index) => (
            <FileBarWithRequiredDownload
              file={data}
              index={index}
              handleClickRemoveFiles={removeFile}
              requiredDownloadsAtt={requiredDownloadsAtt}
              setRequiredDownloadsAtt={setRequiredDownloadsAtt}
              errorMessage={errorMessage}
            />
          ))}
        {formByFile === 'FILE_DESCRIPTION' &&
          validFiles.map((data, index) => (
            <FileBarWithDescription
              file={data}
              index={index}
              handleClickRemoveFiles={removeFile}
              onAutoSave={onAutoSave}
              onDeleteFile={onDeleteFile}
              errorMessage={errorMessage}
              descriptionIsEmpty={descriptionIsEmpty}
              setDescriptionIsEmpty={setDescriptionIsEmpty}
              errorCharacterCounter={errorCharacterCounter}
              setErrorCharacterCounter={setErrorCharacterCounter}
            />
          ))}
      </FileDisplayContainer>
    </DropzoneWrapper>
  )
}

export default Dropzone
