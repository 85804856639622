import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import UserWelcome from '../UserWelcome'

const SweetAlert = withReactContent(Swal)

const AlertWelcome = ({
  show,
  userName = '',
  onConfirm,
  onDismissed,
  onDenied,
  messageType = 'message',
  objUser,
  stateToken,
}) => {
  if (show) {
    SweetAlert.fire({
      title: null,
      html: (
        <>
          <UserWelcome messageType={messageType} welcomeUser={userName} objUser={objUser} token={stateToken} />
        </>
      ),
      icon: null,
      width: 600,
      allowEscapeKey: false,
      allowOutsideClick: false,
      closeOnEsc: false,
      showConfirmButton: false,
    }).then((result) => {
      console.log('onConfirm', onConfirm)
      if (result.isConfirmed) if (onConfirm) onConfirm()
      if (result.isDismissed) onDismissed()
      if (result.isDenied) onDenied()
      SweetAlert.close()
    })
  }
  return null
}

export default AlertWelcome
