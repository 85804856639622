import React from 'react'
import TooltipCustom from '../TooltipCustom'
import { Button } from 'react-bootstrap'

const ActionsButton = ({ title, text, disabled, classButton, id, onClick, disableHoverListener, visible = true }) => {
  if (!visible) return null
  else {
    return (
      <TooltipCustom title={title} placement="top" disableHoverListener={disableHoverListener} arrow>
        <span className={classButton}>
          <Button
            variant="outline-secondary"
            id={id}
            onClick={onClick}
            disabled={disabled}
            style={disabled ? { pointerEvents: 'none' } : {}}
            size="sm"
          >
            {text}
          </Button>
        </span>
      </TooltipCustom>
    )
  }
}

export default ActionsButton
