import { createSlice } from '@reduxjs/toolkit'

const initialServiceState = {
  listLoading: true,
  lastRegisteredService: {},
  lastDeletedService: {},
  lastUpdatedService: {},
  lastEnabledService: {},
  lastDisabledService: {},
  lastAddedAdministratorService: {},
  lastAddedAttendantService: {},
  lastRemovedAdministratorService: {},
  lastRemovedAttendantService: {},
  serviceToEdit: {},
  lastError: null,
  service: [],
  serviceList: [],
  userList: [],
  attendantsList: [],
  myServicesAdmin: {},
  myServicesAttendant: {},
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  errorOnDeleteAttendant: null,
  errorOnDelete: null,
  successOnDelete: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const serviceSlice = createSlice({
  name: 'services',
  initialState: initialServiceState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    errorOnDeleteAttendant: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.errorOnDeleteAttendant = action.payload
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    getServices: (state, action) => {
      state.serviceList = action.payload
      state.listLoading = false
      //state.saveError = []
      state.saveStatus = null
      state.error = null
      state.successOnDelete = null
    },

    setErrorOnDelete: (state, action) => {
      state.errorOnDelete = action.payload
    },

    setSuccessOnDelete: (state, action) => {
      state.successOnDelete = action.payload
    },

    clearErrorOnDelete: (state) => {
      state.errorOnDelete = null
      state.errorOnDeleteAttendant = null
    },

    deleteService: (state, action) => {
      state.lastDeletedService = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
      state.errorOnDelete = null
    },

    enabledService: (state, action) => {
      state.lastEnabledService = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    disableService: (state, action) => {
      state.lastDisabledService = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    getService: (state, action) => {
      state.service = action.payload
      //state.saveError = []
      //state.saveStatus = null
      state.error = null
    },

    getUserListSimplified: (state, action) => {
      state.userList = action.payload
      //state.saveError = []
      //state.saveStatus = null
      state.error = null
    },

    setStatusMessage: (state, action) => {
      //state.saveStatus = null
      state.statusMessage = action.payload
    },

    setAdministratorToService: (state, action) => {
      state.lastAddedAdministratorService = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    removeAdministratorFromService: (state, action) => {
      state.lastRemovedAdministratorService = action.payload
      state.saveStatus = 'success'
      state.error = null
    },
    setAttendantToService: (state, action) => {
      state.lastAddedAttendantService = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },
    removeAttendantFromService: (state, action) => {
      state.lastRemovedAttendantService = action.payload
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.errorOnDeleteAttendant = null
    },

    saveServiceStatusSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.statusMessage = action.payload
    },

    updatedService: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.lastUpdatedService = action.payload
      state.errorOnUpdate = false
    },

    editService: (state, action) => {
      state.actionsLoading = false
      state.error = null
    },

    getMyServicesAdmin: (state, action) => {
      state.myServicesAdmin = action.payload
      state.error = null
      //state.saveError = []
      //state.saveStatus = null
      state.error = null
    },

    getMyServicesAttendant: (state, action) => {
      state.myServicesAttendant = action.payload
      state.error = null
      //state.saveError = []
      //state.saveStatus = null
      state.error = null
    },

    getAttendants: (state, action) => {
      state.attendantsList = action.payload
      state.error = null
    },
  },
})
