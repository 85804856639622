import React from "react";
//import { TilesWidget13, TilesWidget14 } from "../widgets";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_helpers";

export function Demo2Dashboard() {
    const { user } = useSelector((state) => state.auth);
    return <>
        {/* begin::Dashboard */}

        {/* begin::Row */}
        <div className="row">
            <div className="col-xl-12">


            { /* Inicio card */}
                <div className={`card card-custom gutter-b card-stretch}`}>
                    {/* begin::Header */}
                    <div className="card-header border-0 pt-5">
                        <div className="card-title">
                            <div className="card-label">
                                <h3>Bem-vindo { user.info.firstname }!</h3><hr />
                                <div className="font-weight-bolder">Página inicial do Sistema</div>
                                <div className="font-size-sm text-muted mt-2">Logado a # segundos</div>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body d-flex flex-column px-0">

                        {/* begin::Items */}
                        <div className="flex-grow-1 card-spacer-x">
                            {/* begin::Item */}
                            <div className="d-flex align-items-center justify-content-between mb-10">
                                <div className="d-flex align-items-center mr-2">

                                    <div>
                                        <p
                                            className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
                                        >
                                            Usuário atualmente logado no sistema
                                        </p>
                                        <dl className="row font-size-sm text-muted font-weight-bold mt-1">
                                            <dt className="col-sm-3"><strong>Nome:</strong></dt>
                                            <dd className="col-sm-9">{ user.name }</dd>

                                            <dt className="col-sm-3"><strong>E-mail</strong></dt>
                                            <dd className="col-sm-9">{ user.email }</dd>

                                            <dt className="col-sm-3"><strong>Primeiro nome:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.firstname }</dd>

                                            <dt className="col-sm-3"><strong>Sobrenome:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.lastname }</dd>

                                            <dt className="col-sm-3"><strong>Cargo:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.occupation }</dd>



                                            <dt className="col-sm-3"><strong>Empresa:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.companyName }</dd>

                                            <dt className="col-sm-3"><strong>Linguagem:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.language }</dd>

                                            <dt className="col-sm-3"><strong>Website:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.website }</dd>

                                        </dl>

                                        <h5>Informações de Contato</h5>  
                                        <dl className="row font-size-sm text-muted font-weight-bold mt-1">
                                            
                                            <dt className="col-sm-3"><strong>Telefone:</strong></dt>
                                            <dd className="col-sm-9">{ user.info.phone }</dd>                                            

                                            <dt className="col-sm-3"><strong>Endereço:</strong></dt>
                                            <dd className="col-sm-9">{ user.address.addressline }</dd>

                                            <dt className="col-sm-3"><strong>Cidade:</strong></dt>
                                            <dd className="col-sm-9">{ user.address.city }</dd>

                                            <dt className="col-sm-3"><strong>Estado:</strong></dt>
                                            <dd className="col-sm-9">{ user.address.state }</dd>

                                            <dt className="col-sm-3"><strong>Codigo postal:</strong></dt>
                                            <dd className="col-sm-9">{ user.address.postCode }</dd>

                                        </dl>

                                    </div>
                                </div>
                                <div className="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">
                                    
                                <div className="symbol-100 bg-light-primary mr-7">
                                    <img  width="120" height="120" src={toAbsoluteUrl(user.pic)} alt="" className="rounded" />
                                </div>

                                </div>
                            </div>
                            {/* end::Item */}

                            {/* end::Item */}

                            {/* end::Item */}
                        </div>
                        {/* end::Items */}
                    </div>
                    {/* end::Body */}
                </div>

                { /* Fim card */}

            </div>
        </div>

        {/* end::Dashboard */}
    </>;
}
