import { createSlice } from '@reduxjs/toolkit'

const initialServiceState = {
  listLoading: true,
  userToEdit: {},
  lastError: null,
  userList: [],
  userOrganizations: [],
  lastEnabledUser: [],
  lastDisabledUser: [],
  lastPermissionsChange: [],
  accessRolesAndServicesUserList: [],
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  passResetTokenError: [],
  taskList: [],
  formData: {},
  userViewData: {},
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialServiceState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.statusMessage = action.payload ? action.payload.message : null
    },
    catchResetPassTokenError: (state, action) => {
      state.passResetTokenError = action.payload
    },
    clearPassResetTokenErrorr: (state) => {
      state.passResetTokenError = []
    },

    getUsers: (state, action) => {
      state.userList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    getUsersOrganizations: (state, action) => {
      state.userOrganizations = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    getTaks: (state, action) => {
      state.taskList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    clearLastError: (state) => {
      state.lastError = null
    },

    clearSaveError: (state) => {
      state.saveError = []
    },

    getPermissionsRolesAndService: (state, action) => {
      state.accessRolesAndServicesUserList = action.payload
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    setStatusMessage: (state, action) => {
      state.saveStatus = action.payload ? action.payload.status : null
      state.statusMessage = action.payload ? action.payload.message : null
    },

    saveUserStatusSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.statusMessage = action.payload
    },
    enabledUser: (state, action) => {
      state.lastEnabledUser = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },
    disabledUser: (state, action) => {
      state.lastDisabledUser = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    editUserPermissionsRolesServiceUser: (state, action) => {
      state.actionsLoading = false
      state.lastPermissionsChange = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    setFormData: (state, action) => {
      state.formData = action.payload
    },

    setUserViewData: (state, action) => {
      state.userViewData = action.payload
    },
  },
})
