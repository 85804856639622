import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import ErrorWithoutPermission from "../components/ErrorWithoutPermission";
import { getCurrentPageTitleSubTitle, getAllBreadcrumbs } from "../../../routes/Helpers";


export function ErrorWithoutPermissionPage() {

    const pathname = window.location.pathname
    const subheader = useSubheader();
    subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title);
    subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle);

    useEffect(() => {
        subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ErrorWithoutPermission />
        </>
    )
}