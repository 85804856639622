import React from 'react'

export const RequirementViewDataTypeFile = (props) => (
  <>
    <dl className="row">
      <dt className="col-sm-3 text-right">Quantidade máxima de arquivos</dt>
      <dd className="col-sm-9 text-justify">{props.qtdMaxFileUpload}</dd>

      <dt className="col-sm-3 text-right">Tipos de arquivos aceitáveis</dt>
      <dd className="col-sm-9">{props.acceptablefileType}</dd>
    </dl>
  </>
)
