import React from 'react'
import { Col, Row } from 'react-bootstrap'
const ServiceDataModal = ({ ServiceCode, ServiceTitle, ServiceDescription, ServiceUserMomentCreate = null }) => {
  return (
    <div style={{ textAlign: 'left', overflow: 'hidden' }}>
      <Row>
        <Col>
          <strong className="mt-5">Serviço</strong>
          <h3>
            <strong>{ServiceCode}</strong>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong className="mt-5">Título do Serviço</strong>
          <p>{ServiceTitle}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Descrição do Serviço</strong>
          <p>{ServiceDescription}</p>
        </Col>
      </Row>
      {ServiceUserMomentCreate !== null && (
        <Row>
          <Col>
            <small>
              <span className="font-weight-bold">{ServiceUserMomentCreate}</span>
            </small>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ServiceDataModal
