import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const FileInput = styled.input`
  display: none;
`

export const DropzoneWrapper = styled.div`
  transform: translateY(0%);

  p {
    color: red;
    text-align: center;
  }
`
export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 80px;
  background-color: #eee;
  cursor: pointer;
`

export const DropMessage = styled.div`
  text-align: center;
  color: #bdbdbd;
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
`
export const FileDisplayContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`
export const FileRemove = styled(Button)`
  /* position: absolute; */
  /* top: 20px;
  right: 10px; */
  line-height: 15px;
  cursor: pointer;
  margin-right: -10px;
  margin-left: -1px;
  margin-top: -10px;
`
export const FileStatusBar = styled.div`
  width: 100%;
  vertical-align: top;
  position: relative;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px solid #bdbdbd;

  .file-name {
    display: inline-block;
    vertical-align: top;
    color: #3f4254;
  }

  .file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
  }
`
export const FileErrorMessage = styled.span`
  color: red;
`
export const FileSize = styled.span`
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
`

export const FileType = styled.span`
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
`

export const FilesInfoMessage = styled.div`
  background-color: #f3fafe;
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 10px;
  color: #333;

  p {
    color: #333;
    text-align: left;
    margin-bottom: 5px;
  }
`
