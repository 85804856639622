import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { actions } from '../../Auth/_redux/authRedux'
import { useDispatch } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import { useSubheader } from '../../../../_metronic/layout'
import { Notice } from '../../../../_metronic/_partials/controls'
import { Spinner, Jumbotron } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const PageRedirectLogin = () => {
  const suhbeader = useSubheader()
  suhbeader.setTitle('My Custom title')

  const initialAuthState = {
    loginType: 'Sistema Interno',
    user: undefined,
    authToken: undefined,
  }

  const [auth, setAuth] = useState(initialAuthState)

  const { sessionid, token } = useParams()

  const dispatch = useDispatch()

  const getUserByToken = useCallback(async () => {
    const authAxios = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    await authAxios
      .get(`${API_BASE_URL}/api/auth/profile`)
      .then((response) => {
        setAuth({
          ...auth,
          authToken: token,
          user: response.data,
          loginType: 'Login Único Fiocruz',
          sessionId: sessionid,
        })
      })
      .catch((error) => {
        if (sessionid === 'locked' && token === 'x2yx10xu37aYYJqHxlYPNjCT9Z9ae7WxKBBUBGVZ1WqKvIv2xQeyeueLGnli') {
          window.location = '/auth/login/xu37aYYJqHxlYPNjCT9Z9ae7WxKBBUBGVZ1WqKvIv2xQeyeueLGnli'
        } else if (sessionid === 'locked' && token === 'x2yx10xxxCcxLfeFoWxHQTCua9dxOAbaEYZIRxpjGyFM89smFhPrBxED7Ncy') {
          window.location = '/auth/login/smFhPrBxED7Ncy'
        } else if (
          sessionid === 'locked' &&
          token === 'okvGExR53yA4gEBaHMe9NrTxP7Vksd4dxK4NBWfUNwijkh92mDtfsu3rbMugT'
        ) {
          window.location = '/auth/login/JZWatbnEi7p983'
        } else {
          window.location = '/auth/login'
        }
      })
  }, [auth, token, sessionid])

  /**
   * Executar a função getUserByToken
   */
  useEffect(
    (token) => {
      if (token !== '') {
        getUserByToken()
      }
    },
    [getUserByToken]
  )

  /**
   * Disparar a action loginByToken quando o estado local for alterado
   */
  useEffect(() => {
    dispatch(actions.loginByToken(auth))
  }, [auth, dispatch])

  /**
   * Se o estado global não for undefined, redireciona para o /
   */
  if (auth.authToken !== undefined) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xl-12">
            <Notice icon="flaticon-warning font-danger mt-5">
              <span>Aguarde enquando verificamos sua identidade no sistema.</span>
            </Notice>
            <Jumbotron>
              <h1>
                <FormattedMessage id="SYSTEM.LOADING" />
              </h1>
              <div className="text-center">
                <Spinner animation="border" variant="primary" size="lg" />
              </div>
            </Jumbotron>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageRedirectLogin
