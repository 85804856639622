import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Col, Row, Card, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import { getActionIntangibleAsset, editIntangibleAsset } from '../../../_redux/intangible_asset/intangibleAssetActions'
import { toast } from 'react-toastify'
import validationSchema from './validationSchema'
import { useSubheader } from '../../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../routes/Helpers'

export function EditIntangibleAssetPage() {
  const { id1 } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { lastError, saveStatus, statusMessage, intangibleAssetToEdit } = useSelector((state) => state.intangibleAssets)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  const initialValues = {
    aicode: typeof intangibleAssetToEdit.aicode !== 'undefined' ? intangibleAssetToEdit.aicode : '',
    title: typeof intangibleAssetToEdit.title !== 'undefined' ? intangibleAssetToEdit.title : '',
    description: typeof intangibleAssetToEdit.description !== 'undefined' ? intangibleAssetToEdit.description : '',
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getActionIntangibleAsset(id1))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(`Sucesso!  ${statusMessage}`, {})
      history.push('/ativo-intangivel/list')
    }
    // eslint-disable-next-line
  }, [saveStatus])

  return (
    <>
      <Card border="secondary" bg="light-secondary">
        <Card.Body>
          {lastError !== null && (
            <Alert variant="danger" className="pt-5">
              {Object.values(lastError[0]).map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </Alert>
          )}
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => dispatch(editIntangibleAsset(id1, values))}
            initialValues={initialValues}
            enableReinitialize
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="2" controlId="validationFormik01a">
                    <Form.Label>Código</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Código do Ativo Intangível"
                      name="aicode"
                      value={formik.values.aicode}
                      disabled
                      style={{ width: 90 }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    <Form.Label>Título do Ativo Intangível</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Título do Ativo Intangível"
                      onChange={formik.handleChange}
                      {...formik.getFieldProps('title')}
                      className={formik.touched.title && formik.errors.title ? 'is-invalid' : null}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationFormik02">
                    <Form.Label>Descrição do Ativo Intangível</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      placeholder="Descrição do Ativo Intangível"
                      name="description"
                      onChange={formik.handleChange}
                      maxLength="1000"
                      {...formik.getFieldProps('description')}
                      className={formik.touched.description && formik.errors.description ? 'is-invalid' : null}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    ) : null}

                    <Form.Text className="text-right">
                      {formik.values.description.length !== 0
                        ? `${formik.values.description.length}/1000 caracteres`
                        : 'Máximo de 1000 caracteres.'}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row>
                  <Col className="text-left">
                    <Button className="font-weight-bold" type="submit">
                      Salvar
                    </Button>{' '}
                  </Col>
                  <Col className="text-right">
                    <Button
                      className="font-weight-bold"
                      variant="secondary"
                      type="button"
                      onClick={() => history.push('/ativo-intangivel/list')}
                    >
                      <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                    </Button>{' '}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  )
}
