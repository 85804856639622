import React, { useState } from 'react'
import axios from 'axios'
import { Col, Row, Button } from 'react-bootstrap'

const handleDownload = (idDownload, original_name) => {
  const API_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  axios({
    url: `${API_BASE_URL}/api/attachment/${idDownload}/download`,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', original_name)
    document.body.appendChild(link)
    link.click()
  })
}

const AttachmentsOnEditForm = ({ taskId, uploadedAttacnments, deletedTaskAttachments, setDeletedTaskAttachments }) => {
  let [attachments, setAttachments] = useState(uploadedAttacnments)

  const handleRemoveItem = (attachmentId) => {
    let updateUploadAttachments = []
    attachments.forEach((attachment) => {
      if (attachment.id !== attachmentId) {
        updateUploadAttachments.push(attachment)
      }
    })

    setAttachments(updateUploadAttachments)
  }

  const listAttachments = attachments.map((attachment, i) => {
    return (
      <div key={i} id={`item-${i}`}>
        <Row className="mb-1">
          <Col sm={10}>
            <p style={{ cursor: 'pointer' }} className="">
              <strong>Arquivo:</strong> {attachment.original_name}
            </p>
          </Col>
          <Col sm={2} className="text-right">
            <Button
              className="mb-0 mt-0"
              variant="outline-secondary"
              size="sm"
              onClick={() => handleDownload(attachment.id, attachment.original_name)}
            >
              Baixar
            </Button>
            {'  '}
            <Button
              className="mb-0 mt-0"
              variant="outline-secondary"
              size="sm"
              onClick={(e) => {
                handleRemoveItem(attachment.id)
                setDeletedTaskAttachments([
                  ...deletedTaskAttachments,
                  {
                    id: attachment.task_attachment_id,
                    original_name: attachment.original_name,
                  },
                ])
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
        <hr />
      </div>
    )
  })

  return listAttachments
}

export default AttachmentsOnEditForm
