import * as requestFromServer from './faqEndpoints'
import { faqSlice } from './faqSlice'

const { actions } = faqSlice

export const cleanLastError = () => (dispatch) => {
  dispatch(actions.setLastError(null))
}

export const cleanSaveStatus = () => (dispatch) => {
  dispatch(actions.cleanSaveStatus())
}

export const clearSaveError = () => (dispatch) => {
  dispatch(actions.clearSaveError())
}

export const getFaq = (search, page, per_page, ordering, sort_field) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .getFaq(search, page, per_page, ordering, sort_field)
    .then((response) => {
      dispatch(actions.setFaqData(response.data))
      dispatch(actions.setLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setLoading(false))
    })
}

export const viewFaq = (faq_id) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .showFaq(faq_id)
    .then((response) => {
      dispatch(actions.setFaqSelected(response.data))
      dispatch(actions.setLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setLoading(false))
    })
}
