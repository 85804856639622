import React from 'react'
import { HorizontalLabel } from './LabelUserRole.styles'

const LabelUserRole = ({ children }) => {
  return (
    <HorizontalLabel>
      <span className="iconButton-icon">
        <i className="fas fa-user-tie"></i>
      </span>
      {children}
    </HorizontalLabel>
  )
}

export default LabelUserRole
