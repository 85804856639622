import * as requestFromServer from './systemEndpoints'
import { systemSlice } from './systemSlice'

const { actions } = systemSlice

/**
 * Retorna da api um objeto com todos
 * usuarios (não deletado logicamente) do sistema
 * @returns
 */
export const getAllNotifications = () => (dispatch) => {
  //dispatch(actions.setStatusMessage('Carregando lista de usuarios'))
  return requestFromServer
    .getApiAllNotifications()
    .then((response) => {
      dispatch(actions.getNotifications(response.data))
      //dispatch(allSimplifiedUserList())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Marcar notificação como lida
 * @param {*} id
 * @returns
 */
export const readNotification = (id) => (dispatch) => {
  return requestFromServer
    .apiNotificationLikeRead(id)
    .then((response) => {
      dispatch(actions.setNotificationLikRead(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Marcar notificações de um Usuário como lida
 * @param {*} id
 * @returns
 */
export const allNotificationsUserLikeRead = () => (dispatch) => {
  return requestFromServer
    .apiNotificationsUserLikeRead()
    .then((response) => {
      //dispatch(actions.setNotificationLikRead(response.data))
      console.log('marcou perfeitamente as notificações como lidas')
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * SetActualPageName
 * @param {*} pathname
 * @returns
 */
export const setActualPagePathname = (pathname) => (dispatch) => {
  return dispatch(actions.setSystemActualPage(pathname))
}
