import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSubheader } from '../../../../../_metronic/layout'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { Card, Row, Col, Button, Badge } from 'react-bootstrap'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { userOrganizationHistoryList } from '../../_redux/admin/adminActions'

function UserOrganizationHistory() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id1 } = useParams()
  const { user } = useSelector((state) => state.auth)
  const { userOrganizations, listLoading } = useSelector((state) => state.admin)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(userOrganizationHistoryList(id1))
    // eslint-disable-next-line
  }, [])

  const userOrderOrganization = (cell, row) => (
    <>
      <strong>
        {row.order}
        <sup>o</sup>
      </strong>
    </>
  )
  const userOrganizationFormatter = (cell, row) => (
    <>
      <Row>
        <Col>
          <strong>{row.organization_initials}</strong>{' '}
          <span className="ml-5">
            {row.actual_organization && <Badge style={{ backgroundColor: 'green', color: 'white' }}>Atual</Badge>}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{row.organization_name}</span>
        </Col>
      </Row>
    </>
  )

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'order',
      text: '#',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%' }
      },
      formatter: userOrderOrganization,
    },
    {
      dataField: 'organization_name',
      text: 'Unidade',
      align: 'left',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '55%', textAlign: 'left', overflowWrap: 'anywhere' }
      },
      formatter: userOrganizationFormatter,
    },
    {
      dataField: 'moment_in',
      text: 'Data de entrada',
      sort: true,
      classes: 'align-middle',
      isDummyField: false,
      headerStyle: () => {
        return { width: '20%' }
      },
    },
    {
      dataField: 'moment_out',
      text: 'Data de Saída',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '20%' }
      },
    },
  ]
  /**
   * Alterando o texto de exibição de registros.
   *
   * @param {*} from
   * @param {*} to
   * @param {*} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;&nbsp;Exibindo registros de <strong>{from}</strong> a <strong>{to}</strong> de um total de{' '}
      <strong>{size}</strong> registros.
    </span>
  )
  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
          style={{ height: 30, paddingTop: 5 }}
        >
          {option.text}
        </button>
      ))}
    </div>
  )

  /**
   * Parâmetros para paginação
   */
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: userOrganizations.length,
      },
    ],
    sizePerPageRenderer,
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <>
            <Row className="mt-0">
              <Col className="text-center"></Col>
            </Row>
            {typeof userOrganizations.organization_list !== 'undefined' && listLoading && (
              <>
                <Row>
                  <Col className="text-center">
                    <p className="overlay-layer bg-transparent mb-5">
                      <span className="spinner spinner-lg spinner-success" />
                    </p>
                    <p className="h4 mt-10">Carregando histórico de unidades ...</p>
                  </Col>
                </Row>
              </>
            )}
            <>
              <ToolkitProvider
                keyField="id"
                data={
                  typeof userOrganizations.organization_list !== 'undefined' ? userOrganizations.organization_list : []
                }
                columns={columns}
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <span className="h5">
                          <strong>Usuário:</strong>
                          &nbsp; {userOrganizations.user_name} &nbsp;({userOrganizations.user_email})
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col>
                        <BootstrapTable
                          {...props.baseProps}
                          classes="table-striped"
                          keyField="index"
                          data={
                            typeof userOrganizations.organization_list !== 'undefined'
                              ? userOrganizations.organization_list
                              : []
                          }
                          columns={columns}
                          rowStyle={{ color: 'black' }}
                          headerClasses="text-center bg-light"
                          rowClasses="text-center"
                          bordered={true}
                          noDataIndication="Não existem serviços cadastrados por você no sistema."
                          pagination={paginationFactory(pageOptions)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </>
          </>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="text-right">
              <Button
                variant="secondary"
                type="button"
                onClick={() => history.push('/administracao/usuarios/gerencia')}
                className="font-weight-bold"
              >
                <i className="fa fa-chevron-left"></i>
                &nbsp;&nbsp;Voltar
              </Button>{' '}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
export default UserOrganizationHistory
