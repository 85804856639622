import { createSlice } from '@reduxjs/toolkit'

const initialIntangibleAssetState = {
  listLoading: true,
  lastRegisteredIngibleAsset: {},
  lastDeletedIngibleAsset: {},
  lastUpdatedIngibleAsset: {},
  intangibleAssetToEdit: {},
  intangibleAssetVersions: {},
  lastError: null,
  ingibleAsset: [],
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  errorOnDelete: null,
  successOnDelete: null,
  listIntangibleAssetsByUser: [],
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const intangibleAssetSlice = createSlice({
  name: 'intangibleAsset',
  initialState: initialIntangibleAssetState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    setStatusMessage: (state, action) => {
      //state.saveStatus = null
      state.statusMessage = action.payload
    },

    setErrorOnDelete: (state, action) => {
      state.errorOnDelete = action.payload
    },
    setSuccessOnDelete: (state, action) => {
      state.successOnDelete = action.payload
    },

    clearSuccessOnDelete: (state) => {
      state.successOnDelete = null
      state.statusMessage = null
      state.statusMessage = null
    },

    clearErrorOnDelete: (state) => {
      state.errorOnDelete = null
    },

    getIntangibleAssetsbyUser: (state, action) => {
      state.listIntangibleAssetsByUser = action.payload
      state.listLoading = false
      state.intangibleAssetVersions = {}
      state.lastError = null
      state.saveStatus = null
      state.error = null
    },

    getIntangibleAsset: (state, action) => {
      state.intangibleAssetToEdit = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
    getIntangibleAssetVersions: (state, action) => {
      state.intangibleAssetVersions = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    deleteIntangibleAsset: (state, action) => {
      state.lastDeletedIngibleAsset = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
      state.errorOnDelete = null
      state.successOnDelete = null
    },

    newIntangibleAsset: (state, action) => {
      state.lastRegisteredIngibleAsset = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
      state.error = null
      state.statusMessage = 'Ativo Intangível cadastrado com sucesso.'
    },

    editIntangibleAsset: (state, action) => {
      state.lastUpdatedIngibleAsset = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
      state.error = null
      state.statusMessage = 'Ativo Intangível atualizado.'
    },
  },
})
