import TasksTypes from '../modules/Tasks/TasksTypes'
import TasksTypesCreate from '../modules/Tasks/TasksTypesCreate'
import TasksTypesUpdate from '../modules/Tasks/TasksTypesUpdate'
import { ManipulatingTasks } from '../modules/Admin/pages/Task/ManipulatingTasks'
import { ReceivedTasksPage } from '../modules/Tasks/pages/ReceivedTasksPage'
import { CreatedTasksPage } from '../modules/Tasks/pages/CreatedTasksPage'
import TasksRegisterPage from '../modules/Tasks/pages/TasksRegisterPage'
import TasksUpdatePage from '../modules/Tasks/pages/TasksUpdatePage'
import TaskInteractionsPage from '../modules/Tasks/pages/TaskInteractionsPage'

export default [
  /**
   * Tasks
   * pathname principal: /tarefas/tipos
   */
  {
    pathName: '/tarefas/tipos',
    exact: true,
    title: 'Tipos de Tarefas',
    subTitle: 'Tipos de tarefas cadastrados pelo administrador do sistema.',
    icon: '',
    component: TasksTypes,
    //component: DashboardPage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/tarefas/tipos/new',
    exact: true,
    title: 'Cadastro de Tipo de Tarefa',
    subTitle: 'Formulário para cadastro de tipo de tarefa.',
    icon: '',
    component: TasksTypesCreate,
    //component: DashboardPage,
    permission: ['admin'],
  },
  {
    pathName: '/tarefas/tipos/edit',
    exact: true,
    title: 'Atualização de Tipos de Tarefa',
    subTitle: '',
    icon: '',
    component: TasksTypesUpdate,
    //component: DashboardPage,
    permission: ['admin'],
  },
  {
    pathName: '/tarefas/visiveis/admin',
    exact: true,
    title: 'Tarefas Visíveis aos Administradores',
    subTitle: 'Listagem de todas as tarefas em andamento ou que foram manipuladas por  administradores do sistema.',
    icon: '',
    component: ManipulatingTasks,
    permission: ['admin'],
  },

  {
    pathName: '/tarefas/recebidas/:id1',
    exact: true,
    title: 'Tarefas Recebidas',
    subTitle: 'Listagem de tarefas direcionadas para você realizar.',
    icon: '',
    component: ReceivedTasksPage,
    permission: ['admin', 'admin-servico', 'admin-unidade', 'atendente', 'solicitante'],
  },

  {
    pathName: '/tarefas/criadas/:id1',
    exact: true,
    title: 'Tarefas Criadas',
    subTitle: 'Listagem de tarefas criadas por você.',
    icon: '',
    component: CreatedTasksPage,
    permission: ['admin', 'atendente', 'admin-servico'],
  },

  {
    pathName: '/tarefas',
    exact: true,
    title: 'Página de Tarefas',
    subTitle: 'Lista de tarefas.',
    icon: '',
    component: TasksRegisterPage,
    permission: ['admin', 'atendente', 'admin-servico', 'admin-unidade'],
  },
  {
    pathName: '/tarefa/novo',
    exact: true,
    title: 'Cadastro de Tarefa',
    subTitle: 'Formulário para cadastro de tarefas.',
    icon: '',
    component: TasksRegisterPage,
    permission: ['admin', 'atendente', 'admin-servico'],
  },
  {
    pathName: '/tarefa/:id1/editar',
    exact: true,
    title: 'Atualização da Tarefa',
    subTitle: 'Formulário para edição da tarefa.',
    icon: '',
    component: TasksUpdatePage,
    permission: ['admin', 'atendente', 'admin-servico', 'admin-unidade'],
  },
  {
    pathName: '/tarefa/:id1',
    exact: true,
    title: 'Tarefa',
    subTitle: 'Dados da tarefa e ferramentas para interação e resolução.',
    icon: '',
    component: TaskInteractionsPage,
    permission: ['admin', 'atendente', 'admin-servico', 'admin-unidade', 'solicitante'],
  },
]
