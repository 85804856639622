import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import axios from 'axios'
import validationNewEditSchema from './validationNewEditSchema'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { toast } from 'react-toastify'
import { Col, Row, Button, Form, Card, Alert } from 'react-bootstrap'
import { HeaderTextInline } from './ServiceRequirements.styles'
import {
  getRequirementDataToEdit,
  getRequirementSupport,
  systemFormsLogin,
  getEletronicForms,
  getHashDemo,
  clearSystemForms,
  setReloadForms,
} from '../../_redux/services/requirement/requirementActions'
import { RequirementAttachmentsOnEditForm } from '../../components/services/requirements/RequirementAttachmentsOnEditForm'
import Dropzone from '../../../../components/Dropzone'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.min.css'
import FormMessageError from '../../../../components/FormMessageError'
import AlertGT from '../../../../components/AlertGT'
import formObjectDemo from '../../../../Utils/formObjectDemo'

const ServiceRequirementsCreatePage = () => {
  const { id1: serviceId, id2: requirementId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const CLIENT_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_CLIENT_URL_HOMOL}`
      : `${process.env.REACT_APP_CLIENT_URL_PROD}`

  const { user } = useSelector((state) => state.auth)
  const {
    saveStatus,
    statusMessage,
    dataRequirements,
    support,
    systemFormsToken,
    systemFormsHash,
    systemFormsList,
    encryptedListFormHashDemo,
  } = useSelector((state) => state.requirements)

  const { requirements, acceptable_file_types } = dataRequirements
  const subheader = useSubheader()
  const pathname = window.location.pathname

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))

    if (typeof requirementId !== 'undefined') {
      dispatch(getRequirementDataToEdit(serviceId, requirementId))
    }
    if (systemFormsToken == null) {
      dispatch(
        systemFormsLogin(
          process.env.REACT_APP_API_FORMS_LOGIN_USEREMAIL,
          process.env.REACT_APP_API_FORMS_LOGIN_PASSWORD
        )
      )
    } else {
      // 351f63d0-9c35-479c-8673-b19c03bd912e
    }
    dispatch(getRequirementSupport(serviceId))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (systemFormsHash !== null && systemFormsList.length === 0) {
      dispatch(getEletronicForms(systemFormsHash, systemFormsToken))
      dispatch(setReloadForms(false))
    }

    // eslint-disable-next-line
  }, [systemFormsHash])

  useEffect(() => {
    if (systemFormsList.length > 0 && encryptedListFormHashDemo.length === 0) {
      systemFormsList.map((item) => {
        /**
         * Dispara a action getFormDemo para cada item de formulário eletrônico.
         * Isso vai criar o estado um array de links para exemplo de formulários (encryptedListFormHashDemo)
         */
        let randomNumber = parseInt(Math.random() * 100)
        let randomString = String.fromCharCode(65 + Math.floor(Math.random() * 26))
        let baseUrlString = String(CLIENT_BASE_URL)
        dispatch(
          getHashDemo(
            formObjectDemo(`teste_${randomString}@fiocruz.br`, randomNumber, baseUrlString, item.hash, true),
            systemFormsToken,
            item.hash
          )
        )
        return true
      })
    }
    // eslint-disable-next-line
  }, [systemFormsList])

  /**
   * Caso não tenha dados de requisito já populados dataRequirements.requirements
   * o redireciona para listagem de requisitos
   */
  if (typeof requirementId !== 'undefined' && typeof requirements === 'undefined') {
    history.push(`/servico/${serviceId}/requisitos`)
  }

  // let service
  let requirement
  if (requirements) {
    const requirementObj = requirements.find((item) => item.id === parseInt(requirementId))
    requirement = requirementObj ? requirementObj.currentVersion : null
    subheader.setTitle('Atualização de Requisito')
  }

  if (saveStatus === 'success') {
    history.push(`/service/${serviceId}/requisitos`)
    toast.success(`Sucesso! ${statusMessage}`, {})
  }

  subheader.setTitle(requirement ? 'Atualização de Requisito' : getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(
    requirement ? `${requirement.code} - ${requirement.description}` : getCurrentPageTitleSubTitle(pathname).subTitle
  )

  const serviceCode =
    typeof support.service !== 'undefined' ? `${support.service.code} - ${support.service.title}` : null
  const serviceDescription = typeof support.service !== 'undefined' ? `${support.service.description}` : null

  const [requirementType, setRequirementType] = useState(requirement ? requirement.type.id : '')
  const [readInformative, setReadInformative] = useState(requirement ? requirement.read_confirmation : 0)
  const [showComboForm, setShowComboForm] = useState(requirement ? requirement.type.slug : '')
  const [attachments, setAttachments] = useState([])
  const [errorsSave, setErrorsSave] = useState([])
  const [updateAttachment, setUpdateAttachment] = useState([])
  const [deleteAttachmentVersion, setDeleteAttachmentVersion] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  const [triedSendForm, setTriedSendForm] = useState(false)
  const [requiredDownloadsAtt, setRequiredDownloadsAtt] = useState([])

  const [showAlert, setShowAlert] = React.useState(false)
  const [alertTitle, setAlertTitle] = React.useState('')
  const [alertText, setAlertText] = React.useState('')
  const [alertConfirm, setAlertConfirm] = React.useState(null)
  const [alertShowCancelButton, setAlertShowCancelButton] = React.useState(false)
  const [alertConfirmButtonText, setAlertConfirmButtonText] = React.useState(null)
  const [alertIcon, setAlertIcon] = React.useState(null)
  const [toastSuccess, setToastSuccess] = React.useState(null)

  const [maxAmountFiles, setMaxAmountFiles] = useState(
    requirement && requirement.max_amount_files ? requirement.max_amount_files : ''
  )
  const [eletronicForm, setEletronicForm] = useState(
    requirement && requirement.form_hash ? requirement.form_hash : undefined
  )

  const transformSugentionsTag = (suggestions) => {
    return suggestions.map((item, index) => {
      return {
        id: item.id ? item.id : index + 1,
        label: item.type ? item.type : item,
      }
    })
  }

  const formatSugestionList = (fileTypes) => {
    if (fileTypes) {
      const filesArr = fileTypes.split(',').filter((n) => n !== null && n !== 'null')
      return transformSugentionsTag(filesArr)
    }
    return []
  }

  const [tags, setTags] = useState({
    selected:
      requirement && requirement.acceptable_file_types
        ? formatSugestionList(requirement.acceptable_file_types.toUpperCase())
        : [],
  })

  /**
   * função deleteAttachment
   *
   * Função para disparar action de exclusão de serviço
   * @param {*} idAttachmentVersionId
   * @param {*} code
   * @param {*} title
   */
  const deleteAttachment = (idAttachmentVersionId, idElement, originalName) => {
    setAlertTitle('Excluir Anexo')
    setAlertShowCancelButton(true)
    setAlertText(
      `Deseja excluir o arquivo <strong>${originalName}</strong> do requisito? (Esta ação somente será efetivada ao clicar no botão Atualizar)`
    )
    setAlertIcon('question')
    setAlertConfirmButtonText('Sim')
    setToastSuccess(null)

    const ConfirmDeleteAttachment = () => {
      document.getElementById(idElement).remove()
      const item = { id: idAttachmentVersionId }
      setDeleteAttachmentVersion([...deleteAttachmentVersion, item])
    }

    setAlertConfirm(() => ConfirmDeleteAttachment)
    setShowAlert(true)
  }
  const handleInfoAtthachmentsOfRequirementInfo = (e) => {
    e.preventDefault()

    setAlertTitle('')
    setAlertText(
      `<p align="justify">
        <b>Anexos do Requisito</b><br><br>
        É neste campo que devem ser inseridos os arquivos que ficarão disponíveis para o solicitante enquanto
        procede com a criação da solicitação. Cada arquivo poderá ser de download opcional ou obrigatório para o
        solicitante, neste último caso, a solicitação não poderá ser criada enquanto o arquivo não for baixado.<br>
        - O limite para o número de arquivos enviados é de 10 arquivos por requisito.<br>
        - O limite para o tamanho de cada arquivo enviado é de 15 megabytes.<br>
        - Os tipos (extensões) de arquivos permitidas são pdf, doc, docx, csv, xls, xlsx, jpg, jpeg,
        png, svg, webp, ppt e pptx.</p>`
    )
    setAlertIcon(null)
    setToastSuccess(null)
    setAlertShowCancelButton(false)
    setAlertConfirmButtonText('Fechar')
    setAlertConfirm(() => () => setShowAlert(false))
    setShowAlert(true)
  }

  const handleServiceInfo = (e) => {
    e.preventDefault()

    setAlertTitle('')
    setAlertText(
      `<p align="justify">
            Serviço <br/>
            <span><b>${serviceCode}</b></span><br /><br /> 
            ${serviceDescription}</p>`
    )
    setAlertIcon(null)
    setToastSuccess(null)
    setAlertShowCancelButton(false)
    setAlertConfirmButtonText('Fechar')
    setAlertConfirm(() => () => setShowAlert(false))
    setShowAlert(true)
  }
  const handleReadConfirmationInfo = (e) => {
    e.preventDefault()

    setAlertTitle('')
    setAlertText(
      `<p align="justify">
            <b>Confirmar leitura do Informativo?</b><br/><br/>
            Este campo serve para configurar a obrigatoriedade da confirmação de leitura do Informativo por parte do
            solicitante, ou seja, assim que o solicitante ler o Requisito Informativo, se for configurada a confirmação de
            leitura, ele deverá apertar o botão “Li e entendi o informativo acima” para prosseguir com a solicitação.</p>`
    )
    setAlertIcon(null)
    setToastSuccess(null)
    setAlertShowCancelButton(false)
    setAlertConfirmButtonText('Fechar')
    setAlertConfirm(() => () => setShowAlert(false))
    setShowAlert(true)
  }
  const handleTypeRequirementInfo = (e) => {
    e.preventDefault()

    setAlertTitle('')
    setAlertText(
      `<p align="justify">
        <b>TIPOS DE REQUISITO</b><br/><br/>
          Os tipos de requisito são formas diferentes de comportamento e formato em que cada requisito pode ter. Os
          requisitos são interessantes quando, na solicitação de um serviço, há a necessidade de uma comunicação
          direcionada, envio de arquivos ou detalhamentos visando o melhor atendimento à solicitação. Os tipos de
          requisito são: <br/><br/>
          <b>Informativo</b><br/>
          Quando o requisito é somente um texto que deve ser lido pelo solicitante, tornando-o ciente de alguma
          particularidade do processo de atendimento. Neste tipo de requisito pode-se solicitar a confirmação da leitura e
          anexar arquivos para que o solicitante tenha acesso e ciência.<br/><br/>
          <b>Formulário Eletrônico</b><br/>
          Quando o requisito é um formulário eletrônico (previamente configurado) que será disponibilizado para o
          solicitante, sendo necessário o seu preenchimento para que o serviço seja atendido.<br/><br/>
          <b>Somente Anexos</b><br/>
          Quando a finalidade do requisito é somente dispor de arquivos que sejam interessantes ao solicitante antes
          que este finalize o seu pedido.<br/><br/>
          <b>Envio de Arquivos</b><br/>
          Quando é necessário que o solicitante envie um ou mais arquivos como requisito para o início do atendimento
          de sua solicitação.<br/><br/>
        </p>`
    )
    setAlertIcon(null)
    setToastSuccess(null)
    setAlertShowCancelButton(false)
    setAlertConfirmButtonText('Fechar')
    setAlertConfirm(() => () => setShowAlert(false))
    setShowAlert(true)
  }

  const handleRemoveItem = (idRequirementAttachmentVersion, idElement, attachmentOriginalName) => {
    deleteAttachment(idRequirementAttachmentVersion, idElement, attachmentOriginalName)
  }

  const handleCheckRequirementAttachment = (idAttachmentVersion, varValue) => {
    const item = { id: idAttachmentVersion, value: varValue }
    setUpdateAttachment([...updateAttachment, item])
  }

  const handleInfoFormDemonstrationFail = () => {
    setAlertTitle('Oops...')
    setAlertText(`Não foi possível exibir a demonstração do fomulário. Por favor escolha outro!`)
    setAlertIcon('warning')
    setToastSuccess(null)
    setAlertShowCancelButton(false)
    setAlertConfirmButtonText('Fechar')
    setAlertConfirm(() => () => setShowAlert(false))
    setShowAlert(true)
  }

  const handleBeforeLinkAction = (event) => {
    event.preventDefault()
    let selectEletronicForm = document.getElementById('eletronicForm')

    if (selectEletronicForm !== null) {
      if (selectEletronicForm.value === 'Selecione um formulário eletrônico') {
        handleInfoFormDemonstrationFail()
      } else {
        if (
          typeof encryptedListFormHashDemo.find((item) => item.form_hash === selectEletronicForm.value) !== 'undefined'
        ) {
          const linkToDemo = encryptedListFormHashDemo.find((item) => item.form_hash === selectEletronicForm.value)
          window.open(linkToDemo.linkFormDemo)
        }
      }
    }
  }

  const handleClick = () => true
  return (
    <>
      <Card border="secondary">
        <Card.Body>
          {errorsSave.length > 0 && (
            <>
              <Alert variant="danger">
                {errorsSave.map((item, i) => (
                  <span key={i}>{item}</span>
                ))}
              </Alert>
            </>
          )}
          <Formik
            validationSchema={validationNewEditSchema}
            onSubmit={(values) => {
              if (requirementType === 4 && tags.selected.length === 0) return null
              //Se for do tipo somente anexo e não houver pelo menos um anexo... não submete
              if (requirementType === 3 && attachments.length === 0) {
                setErrorsSave(['Inclua pelo menos um anexo válido.'])
                return null
              }

              setSaveLoading(true)

              values.requirementType = parseInt(values.requirementType)
              values.maxAmountFiles = isNaN(parseInt(values.maxAmountFiles)) ? 0 : parseInt(values.maxAmountFiles)
              values.attachments = attachments
              values.acceptableFileTypes = tags.selected
              values.userId = user.id

              const API_BASE_URL =
                `${process.env.NODE_ENV}` === 'development'
                  ? `${process.env.REACT_APP_API_URL_HOMOL}`
                  : `${process.env.REACT_APP_API_URL_PROD}`

              let arrAcceptableFileTypes = []
              if (values.acceptableFileTypes.length > 0) {
                values.acceptableFileTypes.filter((fileType) => {
                  return arrAcceptableFileTypes.push(fileType.label.toLowerCase())
                })
              }

              const data = new FormData()
              data.append('requirement_type', values.requirementType)
              data.append('description', values.description)
              data.append('info_message', values.msgInformative)
              data.append('read_confirmation', values.readInformative)
              data.append('max_amount_files', values.maxAmountFiles)
              data.append('form_hash', values.eletronicForm)
              data.append(
                'acceptable_file_types',
                arrAcceptableFileTypes.length > 0 ? arrAcceptableFileTypes.join(',') : null
              )
              data.append('requiredDownload', JSON.stringify(requiredDownloadsAtt))
              //Se houver download obritatório alterado..
              if (updateAttachment.length > 0) {
                data.append('updateAttachmentCheck', JSON.stringify(updateAttachment))
              }
              //Se houver exclusão de anexos
              if (deleteAttachmentVersion.length > 0) {
                data.append('deletedAttachments', JSON.stringify(deleteAttachmentVersion))
              }

              for (let i = 0; i < attachments.length; i++) {
                data.append('attachments[]', attachments[i])
              }

              const apiUrl = requirement
                ? `${API_BASE_URL}/api/service/${serviceId}/requirement/${requirementId}/new_version`
                : `${API_BASE_URL}/api/service/${serviceId}/requirement`

              axios
                .post(apiUrl, data, {
                  'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                })
                .then((response) => {
                  toast.success(`${response.data.message}`, {})
                  setSaveLoading(false)
                  setErrorsSave([])

                  if (requirementType === 2) {
                    dispatch(clearSystemForms())
                    dispatch(getEletronicForms(systemFormsHash, systemFormsToken))
                    dispatch(setReloadForms(false))
                  }

                  return history.push(`/servico/${serviceId}/requisitos`)
                })
                .catch((error) => {
                  setSaveLoading(false)
                  if (typeof error.response.data.message !== 'undefined') {
                    setErrorsSave([error.response.data.message + '.'])
                    document.getElementById('kt_scrolltop').click()
                  } else {
                    if (error.response && typeof error.response.data[0] !== 'undefined') {
                      const arrErrors = []
                      Object.values(error.response.data[0]).map((item) => {
                        return arrErrors.push(item)
                      })
                      setErrorsSave(arrErrors)
                      document.getElementById('kt_scrolltop').click()
                    } else {
                      setErrorsSave([
                        `Não foi possível realizar o upload de um ou mais arquivos (${error.name} - ${error.message}).`,
                      ])
                    }
                  }
                })
            }}
            initialValues={{
              description: requirement ? requirement.description : '',
              msgInformative: requirement && requirement.info_message ? requirement.info_message : '',
              requirementType: requirementType,
              readInformative: readInformative,
              attachments: [],
              eletronicForm: requirement ? requirement.form_hash : '',
              requiredDownload: requiredDownloadsAtt,
              acceptableFileTypes: requirement ? requirement.acceptable_file_types : [],
              maxAmountFiles: requirement ? requirement.max_amount_files : '',
              fileTypes: '',
            }}
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row>
                  <Col className="text-left">
                    <div>
                      <p>Serviço</p>
                      <HeaderTextInline>
                        <h6>{serviceCode}</h6>
                        &nbsp;&nbsp;
                        <a href="/" onClick={handleServiceInfo}>
                          Mais detalhes
                        </a>
                      </HeaderTextInline>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Descrição do Requisito</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      placeholder="Descrição do Requisito"
                      name="description"
                      onChange={formik.handleChange}
                      maxLength="300"
                      {...formik.getFieldProps('description')}
                      className={formik.touched.description && formik.errors.description ? 'is-invalid' : null}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    )}
                    <Form.Text className="text-right">
                      {formik.values.description.length !== 0
                        ? `${formik.values.description.length}/300 caracteres`
                        : 'Máximo de 300 caracteres.'}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row>
                  <Col md={!!showComboForm ? 3 : 12}>
                    <HeaderTextInline>
                      <Form.Label>Tipo do Requisito</Form.Label>
                      &nbsp;&nbsp;
                      <a href="/" onClick={handleTypeRequirementInfo}>
                        Mais detalhes
                      </a>
                    </HeaderTextInline>
                    <Form.Group as={Col} md="12">
                      <Form.Check
                        id={`radio-informativo`}
                        label={'Informativo'}
                        name="requirementType"
                        type="radio"
                        value="1"
                        onChange={formik.handleChange}
                        onClick={(e) => {
                          setShowComboForm('informativo')
                          setRequirementType(1)
                        }}
                        checked={requirementType === 1}
                        isInvalid={triedSendForm && !!formik.errors.requirementType}
                      />
                      <Form.Check
                        id={`radio-formulatio-eletronico`}
                        label={'Formulário Eletrônico'}
                        name="requirementType"
                        type="radio"
                        value="2"
                        onChange={formik.handleChange}
                        onClick={(e) => {
                          setShowComboForm('formulario-eletronico')
                          setRequirementType(2)
                        }}
                        checked={requirementType === 2}
                        isInvalid={triedSendForm && !!formik.errors.requirementType}
                      />
                      <Form.Check
                        id={`radio-anexos`}
                        label={'Somente Anexos'}
                        name="requirementType"
                        type="radio"
                        value="3"
                        onChange={formik.handleChange}
                        onClick={(e) => {
                          setShowComboForm('somente-anexos')
                          setRequirementType(3)
                        }}
                        checked={requirementType === 3}
                        isInvalid={triedSendForm && !!formik.errors.requirementType}
                      />
                      <Form.Check
                        id={`radio-envio-arquivos`}
                        label={'Envio de Arquivos'}
                        name="requirementType"
                        type="radio"
                        value="4"
                        onChange={formik.handleChange}
                        onClick={(e) => {
                          setShowComboForm('envio-arquivos')
                          setRequirementType(4)
                        }}
                        checked={requirementType === 4}
                        isInvalid={triedSendForm && !!formik.errors.requirementType}
                      />
                      {triedSendForm && formik.errors.requirementType && (
                        <FormMessageError>{formik.errors.requirementType}</FormMessageError>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="9">
                    {showComboForm === 'informativo' && (
                      <Form.Group as={Col} md="12">
                        <HeaderTextInline>
                          <Form.Label>Confirmar leitura do informativo?</Form.Label>
                          &nbsp;&nbsp;
                          <a href="/" onClick={handleReadConfirmationInfo}>
                            {' '}
                            Mais detalhes
                          </a>
                        </HeaderTextInline>
                        <Form.Check
                          id={`radio-read-informative`}
                          label={'Sim'}
                          name="readInformative"
                          type="radio"
                          value={1}
                          onChange={formik.handleChange}
                          onClick={(e) => {
                            setReadInformative(true)
                          }}
                          checked={readInformative}
                        />
                        <Form.Check
                          id={`radio-not-read-informative`}
                          label={'Não'}
                          name="readInformative"
                          type="radio"
                          value={0}
                          onChange={formik.handleChange}
                          onClick={(e) => {
                            setReadInformative(false)
                          }}
                          checked={!readInformative}
                        />
                      </Form.Group>
                    )}

                    {showComboForm === 'envio-arquivos' && (
                      <Row>
                        <Col md="5">
                          <Form.Group as={Col} md="9">
                            <Form.Label>Quantidade máxima de arquivos</Form.Label>
                            <Form.Control
                              as="select"
                              name="maxAmountFiles"
                              id="maxAmountFiles"
                              onChange={(e) => {
                                setMaxAmountFiles(e.target.value)
                                formik.handleChange(e)
                              }}
                              className={
                                formik.touched.maxAmountFiles && formik.errors.maxAmountFiles ? 'is-invalid' : null
                              }
                              value={maxAmountFiles}
                            >
                              <option value="">Selecione a quantidade</option>
                              <option value="1">01 Arquivo</option>
                              <option value="2">02 Arquivos</option>
                              <option value="3">03 Arquivos</option>
                              <option value="4">04 Arquivos</option>
                              <option value="5">05 Arquivos</option>
                              <option value="6">06 Arquivos</option>
                              <option value="7">07 Arquivos</option>
                              <option value="8">08 Arquivos</option>
                              <option value="9">09 Arquivos</option>
                              <option value="10">10 Arquivos</option>
                              <option value="11">11 Arquivos</option>
                              <option value="12">12 Arquivos</option>
                              <option value="13">13 Arquivos</option>
                              <option value="14">14 Arquivos</option>
                              <option value="15">15 Arquivos</option>
                              <option value="16">16 Arquivos</option>
                              <option value="17">17 Arquivos</option>
                              <option value="18">18 Arquivos</option>
                              <option value="19">19 Arquivos</option>
                              <option value="20">20 Arquivos</option>
                            </Form.Control>
                            {formik.touched.maxAmountFiles && formik.errors.maxAmountFiles && (
                              <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '100%' }}>
                                {formik.errors.maxAmountFiles}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="7">
                          <Form.Group as={Col} md="12">
                            <Form.Label>Tipos de Arquivos Aceitáveis</Form.Label>
                            <Typeahead
                              id="file-types"
                              isInvalid={
                                formik.touched.fileTypes && requirementType === 4 && tags.selected.length === 0
                                  ? true
                                  : false
                              }
                              inputProps={{ name: 'fileTypes' }}
                              multiple
                              //defaultSelected={formatSugestionList(requirement && requirement.acceptable_file_types)}
                              defaultSelected={tags.selected}
                              onChange={(selected) => {
                                setTags({ selected })
                              }}
                              onFocus={formik.handleBlur}
                              options={transformSugentionsTag(acceptable_file_types)}
                              placeholder="Selecione os tipos de arquivo"
                              renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}>
                                  {results.map((result, index) => {
                                    return (
                                      <MenuItem key={`file-type-${index}`} option={result} position={index}>
                                        {result.label}
                                      </MenuItem>
                                    )
                                  })}
                                </Menu>
                              )}
                            />
                            {formik.touched.fileTypes && requirementType === 4 && tags.selected.length === 0 && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="float-left"
                                style={{ width: '100%', display: 'block' }}
                              >
                                O campo "Tipos de Arquivos Aceitáveis" é de preenchimento obrigatório.
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  {showComboForm === 'informativo' && (
                    <Form.Group as={Col} md="12">
                      <Form.Label>Mensagem Informativa</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        placeholder="Mensagem Informativa"
                        name="msgInformative"
                        onChange={formik.handleChange}
                        maxLength="1000"
                        {...formik.getFieldProps('msgInformative')}
                        className={formik.touched.msgInformative && formik.errors.msgInformative ? 'is-invalid' : null}
                      />
                      {formik.touched.msgInformative && formik.errors.msgInformative ? (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.msgInformative}
                        </Form.Control.Feedback>
                      ) : null}
                      <Form.Text className="text-right">
                        {formik.values.msgInformative.length !== 0
                          ? `${formik.values.msgInformative.length}/1000 caracteres`
                          : 'Máximo de 1000 caracteres.'}
                      </Form.Text>
                    </Form.Group>
                  )}
                  {showComboForm === 'formulario-eletronico' && (
                    <Form.Group as={Col} md="12">
                      <Form.Label>Formulário Eletrônico</Form.Label>
                      <Form.Control
                        as="select"
                        name="eletronicForm"
                        id="eletronicForm"
                        onChange={(e) => {
                          setEletronicForm(e.target.value)
                          formik.handleChange(e)
                        }}
                        value={eletronicForm}
                      >
                        <option>Selecione um formulário eletrônico</option>
                        {systemFormsList.length !== 0 && (
                          <>
                            {systemFormsList.map((item) => (
                              <option value={item.hash}>
                                {item.code} - {item.title}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                      {typeof eletronicForm !== 'undefined' && (
                        <>
                          {eletronicForm !== 'Selecione um formulário eletrônico' && (
                            <>
                              <a href="/" target="_blank" onClick={handleBeforeLinkAction} className="float-right">
                                Ver prévia do formulário eletrônico
                              </a>
                            </>
                          )}
                        </>
                      )}
                    </Form.Group>
                  )}
                </Row>
                <Row>
                  <Col>
                    <p>
                      Anexos do Requisito &nbsp;&nbsp;&nbsp;
                      <span onClick={handleInfoAtthachmentsOfRequirementInfo}>
                        <a href="/">Mais detalhes</a>
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Dropzone
                      setAttachments={setAttachments}
                      requiredDownloadsAtt={requiredDownloadsAtt}
                      setRequiredDownloadsAtt={setRequiredDownloadsAtt}
                      formByFile="REQUIRED_DOWNLOAD"
                    />
                  </Col>
                </Row>

                {requirement && requirement.attachments.length > 0 && (
                  <RequirementAttachmentsOnEditForm
                    attachments={requirement.attachments}
                    handleRemoveItem={handleRemoveItem}
                    handleCheckRequirementAttachment={handleCheckRequirementAttachment}
                    setChecked={handleClick}
                  />
                )}
                {saveLoading && (
                  <>
                    <Row>
                      <Col className="text-center mt-5">
                        <p className="overlay-layer bg-transparent mb-5">
                          <span className="spinner spinner-lg spinner-success" />
                        </p>
                        <p className="h4 mt-10">Por favo aguarde ...</p>
                      </Col>
                    </Row>
                  </>
                )}
                {!saveLoading && (
                  <Row className="mt-3">
                    <Col className="text-left">
                      <Button type="submit" className="font-weight-bold" onClick={() => setTriedSendForm(true)}>
                        {typeof requirementId !== 'undefined' ? 'Atualizar' : 'Salvar'}
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => history.push(`/servico/${serviceId}/requisitos`)}
                        className="font-weight-bold"
                      >
                        <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <AlertGT
        show={showAlert}
        title={alertTitle}
        text={alertText}
        icon={alertIcon}
        showCancelButton={alertShowCancelButton}
        confirmButtonText={alertConfirmButtonText}
        onConfirm={alertConfirm}
        onDismissed={() => setShowAlert(false)}
        toastSuccess={toastSuccess}
      />
    </>
  )
}

export default ServiceRequirementsCreatePage
