import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { FileDescriptionWrapper } from './FormByFile.styles'
import CheckMarck from '../../CheckMarck'

const FileDescription = ({
  onAutoSave,
  file,
  isSaved,
  descriptionIsEmpty,
  setDescriptionIsEmpty,
  errorCharacterCounter,
  setErrorCharacterCounter,
}) => {
  const [isEmpty, setIsEmpty] = useState(!file.description)
  const [hasErrorCharacterCounter, setHasErrorCharacterCounter] = useState(
    file.description && file.description.length < 3
  )

  if (!file.description && isEmpty) {
    setDescriptionIsEmpty(true)
  }

  if (file.description && file.description.length < 3 && hasErrorCharacterCounter) {
    setErrorCharacterCounter(true)
  }

  return (
    <FileDescriptionWrapper key={`description-${file.uuid}`}>
      <Form.Group>
        <Form.Control
          className={isEmpty || hasErrorCharacterCounter ? 'file-description-error' : 'file-description'}
          style={{ float: 'left', width: '40px' }}
          type="text"
          placeholder="Informe uma descriçao para o arquivo"
          defaultValue={file.description}
          onBlur={(e) => {
            if (e.target.value === '') {
              setIsEmpty(true)
              setDescriptionIsEmpty(true)
            } else if (e.target.value.length < 3) {
              setHasErrorCharacterCounter(true)
              setErrorCharacterCounter(true)
            }

            if (
              (file.description && file.description === e.target.value && isSaved) ||
              ((!e.target.value || e.target.value === '') && !isSaved)
            ) {
              return
            }

            if (onAutoSave) {
              if (e.target.value) {
                setIsEmpty(false)
                setDescriptionIsEmpty(false)
                if (e.target.value.length >= 3) {
                  setHasErrorCharacterCounter(false)
                  setErrorCharacterCounter(false)
                }
              }
              onAutoSave(file, e.target.value, isSaved)
            }
          }}
        />
      </Form.Group>
      {isSaved && <CheckMarck className="check-marck-file" />}
    </FileDescriptionWrapper>
  )
}

export default FileDescription
