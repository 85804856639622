import React from 'react'
import { ActionArea, ContentArea, List } from './List.styles'

const ListItem = ({ actions, content, backgroundColor }) => {
  return (
    <List backgroundColor={backgroundColor}>
      <ContentArea>{content}</ContentArea>
      <ActionArea className="ActionArea">{actions}</ActionArea>
    </List>
  )
}

export default ListItem
