import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getActionIntangibleAssetVersions } from '../../../_redux/intangible_asset/intangibleAssetActions'
import { useSubheader } from '../../../../../../_metronic/layout'
import { Chrono } from 'react-chrono'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle } from '../../../../../routes/Helpers'

export function HistoryIntangibleAssetPage() {
  const { id1 } = useParams()

  const history = useHistory()
  const { intangibleAssetVersions } = useSelector((state) => state.intangibleAssets)
  const dispatch = useDispatch()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getActionIntangibleAssetVersions(id1))
    // eslint-disable-next-line
  }, [])

  const arrTitle = []
  if (typeof intangibleAssetVersions.versions !== 'undefined') {
    intangibleAssetVersions.versions.map((item) => {
      return arrTitle.push({ title: item.title })
    })
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            <Row>
              <Col className="text-left">
                {(typeof intangibleAssetVersions.intangible_asset !== 'undefined') !== null && (
                  <div>
                    <p>Ativo Intangível</p>
                    <p className="h4">
                      {typeof intangibleAssetVersions.intangible_asset !== 'undefined'
                        ? intangibleAssetVersions.intangible_asset[0].aicode
                        : ''}{' '}
                      -
                      {typeof intangibleAssetVersions.intangible_asset !== 'undefined'
                        ? intangibleAssetVersions.intangible_asset[0].title
                        : ''}
                    </p>{' '}
                    <hr />
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col className="text-center">
                <div style={{ width: 'auto', height: '500px' }}>
                  {typeof intangibleAssetVersions.versions !== 'undefined' && (
                    <Chrono items={arrTitle} mode="VERTICAL_ALTERNATING" useReadMore={false} cardHeight={150}>
                      {typeof intangibleAssetVersions.versions !== 'undefined' &&
                        intangibleAssetVersions.versions.map((dataValue, i) => (
                          <div key={i} className="text-left w-100">
                            <h5>{dataValue.cardTitle}</h5>
                            <p className="text-left w-100">{dataValue.cardDetailedText}</p>
                          </div>
                        ))}
                    </Chrono>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button className="font-weight-bold" variant="secondary" type="button" onClick={() => history.goBack()}>
                  <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                </Button>{' '}
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}
