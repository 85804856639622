import React from 'react'
import Select from 'react-select';
import Typography from "@material-ui/core/Typography";

export const ManagerServiceForm = (props) => {
    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <Typography {...props.innerProps}>Sem opções disponíveis.</Typography>;
    }

    const components = {
        NoOptionsMessage
    };

    return (
            <Select
                ref={props.selectInputRef}
                value={props.setSelectedOption}
                components={components}
                onChange={props.onChange}
                options={props.options}
                placeholder="Digite aqui o nome da pessoa que deseja selecionar"
            />
    );
}



