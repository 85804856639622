import React from 'react'
import RelatedAccordion from '../RelatedAccordion/RelatedAccordion'

const RelatedSolicitation = ({ itens = [], showIdent, backgroundColor }) => {
  return (
    <div className="w-100">
      {itens.map((item, index) => (
        <RelatedAccordion
          key={index}
          title={item.title}
          subTitle={item.subTitle}
          body={item.body}
          id={`related_solicitation_${index}`}
          ariaControls={`content_${index}`}
          showIdent={showIdent}
          backgroundColor={backgroundColor}
        />
      ))}
    </div>
  )
}

export default RelatedSolicitation
