import React from 'react'
import { HeaderWrapper, HeaderArrowIconWrapper, BoxAnswered, ArrowIcon, ChildrenContent } from './Accordion.styles'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'

const CustomToggle = ({ children, eventKey, callback, isAnswared = false, showBoxAnswered = false }) => {
  const currentEventKey = React.useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey))

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <HeaderWrapper onClick={decoratedOnClick}>
      <ChildrenContent>{children}</ChildrenContent>

      <HeaderArrowIconWrapper>
        {showBoxAnswered && (
          <BoxAnswered isAnswared={isAnswared}>{isAnswared ? 'Atendido' : 'Não atendido'}</BoxAnswered>
        )}
        <ArrowIcon>
          {isCurrentEventKey ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>}
        </ArrowIcon>
      </HeaderArrowIconWrapper>
    </HeaderWrapper>
  )
}
export default CustomToggle
