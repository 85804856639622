import * as requestFromServer from './adminUnitEndpoints'
import { adminUnitSlice } from './adminUnitSlice'

const { actions } = adminUnitSlice

/**
 * Action getIntangibleAssetsAdminUnit
 *
 * Retorna dados de Ativos Intangíveis da interface Administração de Ativos e Solicitações (Aba 1)
 */
export const getIntangibleAssetsAdminUnit = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getIntangibleAssetsAdminUnit(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getIntangibleAsset(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}


/**
 * Action getAdminUnitsByUser
 *
 * Retorna dados de Ativos Intangíveis da interface Administração de Ativos e Solicitações (Aba 1)
 */
export const getAdminUnitsByUser = () => (dispatch) => {

  return requestFromServer
    .getAdminUnitsByUser()
    .then((response) => {
      dispatch(actions.getAdminUnitsByUser(response.data))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
    })
}
/**
 * Action getSolicitationsAdminUnit
 *
 * Retorna dados de Ativos Intangíveis da interface Administração de Ativos e Solicitações (Aba 2)
 */
export const getSolicitationsAdminUnit = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolicitationsUnderAdminUnitResponsibility(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getSolicitations(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Action getQtdSolicitationsByStageNoLegacyAdminUnit
 *
 * Retorna dados de quantitativos de Solicitações da interface Administração de Ativos e Solicitações (Aba 3 - tab a)
 * por estágio
 */
export const getQtdSolicitationsByStageNoLegacyAdminUnit = (per_page, page, search, sort_field, ordering) => (
  dispatch
) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getQtdSolicitationsFromStagesAdminUnitNoLegacy(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getQtdSolNoLegacyByStage(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Action getQtdSolicitationsByStageLegacyAdminUnit
 *
 * Retorna dados de quantitativos de Solicitações da interface Administração de Ativos e Solicitações (Aba 3 - tab a)
 * por estágio
 */
export const getQtdSolicitationsByStageLegacyAdminUnit = (per_page, page, search, sort_field, ordering) => (
  dispatch
) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getQtdSolicitationsFromStagesAdminUnitLegacy(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getQtdSolLegacyByStage(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Limpa a propriedade lastError
 * @returns
 */
export const clearLastError = () => (dispatch) => {
  return dispatch(actions.clearLastError())
}

/**
 * Limpa a propriedade saveError
 * @returns
 */
export const clearSaveError = () => (dispatch) => {
  return dispatch(actions.clearSaveError())
}

/**
 * Limpa o status
 * @returns
 */
export const cleanSaveStatus = () => (dispatch) => {
  return dispatch(actions.setStatusMessage({ status: null, message: null }))
}
