import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

/**
 * Retorna da api objeto com as notificações para o usuário:
 * @returns
 */
export const getApiAllNotifications = async () => await axios.get(`${API_BASE_URL}/api/system/notifications`)

export const apiNotificationLikeRead = async (id) =>
  await axios.get(`${API_BASE_URL}/api/system/notifications/system_notification/${id}/as_read`)

export const apiNotificationsUserLikeRead = async () =>
  await axios.get(`${API_BASE_URL}/api/system/notifications/system_notification/user_as_read`)
