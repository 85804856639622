import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { ReadMore, Table, Button } from '../../../../components'
import { getAttendancesByStatus } from '../../_redux/attendances/attendanceActions'

const orderByOptions = [
  { value: 'code', text: 'Código da Solicitação' },
  { value: 'description', text: 'Descrição da Solicitação' },
  { value: 'service_code', text: 'Código do Serviço' },
  { value: 'service_title', text: 'Título do Serviço' },
  { value: 'created_at', text: 'Data de Criação da Solicitação' },
  { value: 'requester_name', text: 'Nome do Solicitante' },
  { value: 'requester_organization_unit', text: 'Sigla da Unidade' },
  { value: 'requester_organization', text: 'Nome da Unidade' },
]

export function AttendancesClosed() {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('id')
  const [ordering, setOrdering] = React.useState('asc')
  const { myAttendance, listLoading } = useSelector((state) => state.attendance)

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle} ${myAttendance.userName + '.'}`)

  useEffect(() => {
    dispatch(getAttendancesByStatus('encerrada', perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fa fa-search"></i>,
      title: 'Consultar Solicitação',
      onClick: (row) => history.push(`/solicitacao/gerencia-da-solicitacao/${row.solicitation_id}`),
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'description',
      text: 'Dados da Solicitação',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.description} maxCharacters={70} />
          <strong style={{ fontSize: '0.85rem' }}>
            {row.service_code}-{row.service_title}
          </strong>
        </>
      ),
      headerStyle: { width: '100ch', textAlign: 'left' },
    },
    {
      dataField: 'created_at',
      text: 'Criação/Solicitante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.created_at}
          <br />
          <span style={{ fontSize: '0.85rem' }}>
            {row.requester_name} ({row.requester_organization_unit})
          </span>
        </>
      ),
      headerStyle: { width: 300, textAlign: 'center' },
    },
  ]

  return (
    <Card border="secondary">
      <Card.Body>
        <Table
          data={myAttendance.data}
          noDataMessage={`Não existem solicitações encerradas atendidas por ${myAttendance.userName}.`}
          columns={columns}
          page={myAttendance.current_page}
          perPage={perPage}
          totalSize={myAttendance.total}
          orderByOptions={orderByOptions}
          orderByOnChange={(field) => setSortField(field)}
          orderByDirectionChange={(direction) => setOrdering(direction)}
          onSizePerPageChange={(perPage) => {
            setPerPage(perPage)
            setPage(1)
          }}
          onPageChange={(page) => setPage(page)}
          onTableChange={() => null}
          searchPlaceholder="Pesquise aqui"
          perPagePosition="top"
          actions={actionsTable}
          onSearch={(term) => setSearch(term)}
          searchValue={search}
          loading={listLoading}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md={8}>
            <Button
              variant="secondary"
              className="mr-5"
              onClick={() => history.push('/solicitacao/atendimentos/cancelados')}
            >
              Solicitações Canceladas
            </Button>
            <Button variant="primary" className="mr-5" onClick={() => history.push('/solicitacao/disponiveis')}>
              Solicitações Disponíveis em {myAttendance.userUnit}
            </Button>
          </Col>
          <Col md={4} className="text-right">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <i className="fa fa-chevron-left"></i>&nbsp;Voltar
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}
