import { ListIntangibleAssetsPage } from '../modules/Solicitation/pages/intangible_asset/crud/ListIntangibleAssetsPage'
import { CreateIntangibleAssetPage } from '../modules/Solicitation/pages/intangible_asset/crud/CreateIntangibleAssetPage'
import { EditIntangibleAssetPage } from '../modules/Solicitation/pages/intangible_asset/crud/EditIntangibleAssetPage'
import { HistoryIntangibleAssetPage } from '../modules/Solicitation/pages/intangible_asset/history/HistoryIntangibleAssetPage'

export default [
  /**
   * Ativos Intangíveis
   * pathname principal: /ativo-intangivel
   */
  {
    pathName: '/ativo-intangivel',
    exact: true,
    title: 'Ativos Intangíveis (dashboard)',
    subTitle: '',
    icon: '',
    component: ListIntangibleAssetsPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/ativo-intangivel/list',
    exact: true,
    title: 'Ativos Intangíveis',
    subTitle: 'Intangíveis cadastrados pelo solicitante.',
    icon: '',
    component: ListIntangibleAssetsPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/ativo-intangivel/novo',
    exact: true,
    title: 'Cadastro de Ativo Intangível',
    subTitle: 'Formulário de cadastro de ativo intangível.',
    icon: '',
    component: CreateIntangibleAssetPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/ativo-intangivel/:id1/edit',
    exact: true,
    title: 'Atualização de Ativo Intangível',
    subTitle: 'Formulário de atualização de ativo intangível.',
    icon: '',
    component: EditIntangibleAssetPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/ativo-intangivel/historico/:id1',
    exact: true,
    title: 'Histórico de Atualizações',
    subTitle: 'Ocorrências de atualizações realizadas no ativo intangível.',
    icon: '',
    component: HistoryIntangibleAssetPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
]
