import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  getRequirements,
  archiveRequirementRegister,
  deleteRequirementRegister,
  clearViewRequirementToEditRegister,
} from '../../_redux/services/requirement/requirementActions'

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { Card, Container, Row, Col, Button, InputGroup } from 'react-bootstrap'
import TooltipCustom from '../../../../components/TooltipCustom'
import { useSubheader } from '../../../../../_metronic/layout'
import { AlertMessage } from '../../components/services/AlertMessage'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import AlertGT from '../../../../components/AlertGT'
import { toast } from 'react-toastify'

export function RequirementPage() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id1 } = useParams()
  const { user } = useSelector((state) => state.auth)
  const { dataRequirements, errorOnDelete, successOnDelete } = useSelector((state) => state.requirements)

  const serviceCode =
    typeof dataRequirements.service !== 'undefined'
      ? `${dataRequirements.service.code} - ${dataRequirements.service.title}`
      : null

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(serviceCode)

  const [statusChange, setStatusChange] = useState(false)
  const [searchEmpty, setSearchEmpty] = useState(false)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    confirm: null,
    toastSuccess: '',
  })

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getRequirements(id1))
    dispatch(clearViewRequirementToEditRegister())

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getRequirements(id1))
    // eslint-disable-next-line
  }, [statusChange, id1])

  useEffect(() => {
    if (errorOnDelete !== null) {
      toast.error(`${errorOnDelete}`, {})
    }
    if (successOnDelete !== null) {
      toast.success(`Sucesso!  Requisito excluído.`, {})
      dispatch(getRequirements(id1))
    }
    // eslint-disable-next-line
  }, [errorOnDelete, successOnDelete, id1])

  const qtdRequirements =
    typeof dataRequirements.requirements !== 'undefined' ? dataRequirements.requirements.length : 0

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  /**
   * função consultarRequisito
   *
   * Função para disparar action de exclusão de serviço
   * @param {*} idRequisito
   */
  const consultarRequisito = (idRequisito) => {
    history.push(`/servico/${id1}/requisitos/${idRequisito}/consulta`)
  }
  /**
   * função arquivarRequisito
   *
   * Função para disparar action de exclusão de serviço
   * @param {*} idRequisito
   * @param {*} code
   * @param {*} title
   */
  const arquivarRequisito = (idRequisito, code, title, service_code) => {
    const handleConfirm = () => {
      dispatch(archiveRequirementRegister(idRequisito, user.id))
      dispatch(getRequirements(id1))
      setStatusChange(!statusChange)
      setConfigAlert({ ...configAlert, show: false })
    }

    setConfigAlert({
      show: true,
      title: 'Arquivar Requisito',
      text: `Deseja arquivar o requisito <strong>${code} - ${title}</strong> do serviço <strong>${service_code}</strong>?`,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      confirm: () => handleConfirm(),
      toastSuccess: `Sucesso!  Requisito arquivado.`,
    })
  }

  /**
   * função excluirRequisito
   *
   * Função para disparar action de exclusão de serviço
   * @param {*} idRequisito
   * @param {*} code
   * @param {*} title
   */
  const excluirRequisito = (idRequisito, code, title) => {
    const handleConfirm = () => {
      dispatch(deleteRequirementRegister(id1, idRequisito))
      dispatch(getRequirements(id1))
      setStatusChange(!statusChange)
      setConfigAlert({ ...configAlert, show: false })
    }

    setConfigAlert({
      show: true,
      title: 'Excluir Requisito',
      text: `Deseja excluir o requisito <strong>${code} - ${title}</strong> do serviço <strong>${serviceCode}</strong> ?`,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      confirm: () => handleConfirm(),
      //toastSuccess: `Sucesso!  Requisito excluído.`,
    })
  }

  const { SearchBar } = Search

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  implementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsFormatter = (cell, row) => (
    <>
      <TooltipCustom title="Arquivar Requisito" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => arquivarRequisito(row.id, row.code, row.currentVersion.description, serviceCode)}
        >
          <i className="fa fa-folder"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Atualizar Requisito" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => history.push(`/servico/${id1}/requisitos/${row.id}/edit`)}
        >
          <i className="fas fa-edit fa-sm"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Consultar Requisito" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => consultarRequisito(row.id)}
        >
          <i className="fas fa-search fa-sm"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Excluir Requisito" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => excluirRequisito(row.id, row.code, row.currentVersion.description)}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      </TooltipCustom>
    </>
  )

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'currentVersion.description',
      text: 'Descrição',
      align: 'left',
      classes: 'align-middle',
      sort: true,
      style: { maxWidth: 200 },
      headerStyle: () => {
        return { width: '60%', textAlign: 'left', overflowWrap: 'anywhere' }
      },
    },
    {
      dataField: 'currentVersion.type.type',
      classes: 'align-middle',
      text: 'Tipo',
      sort: true,

      headerStyle: () => {
        return { width: '15%' }
      },
    },
    {
      dataField: 'acoes',
      classes: 'align-middle',
      text: 'Ações',
      isDummyField: false,
      headerStyle: () => {
        return { width: '15%', minWidth: 190 }
      },
      formatter: actionsFormatter,
    },
  ]

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
          style={{ height: 30, paddingTop: 5 }}
        >
          {option.text}
        </button>
      ))}
    </div>
  )

  /**
   * Alterando o texto de exibição de registros.
   *
   * @param {*} from
   * @param {*} to
   * @param {*} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;&nbsp;Exibindo registros de <strong>{from}</strong> a <strong>{to}</strong> de um total de{' '}
      <strong>{size}</strong> registros.
    </span>
  )
  /**
   * Parâmetros para paginação
   */
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: qtdRequirements,
      },
    ],
    sizePerPageRenderer,
  }

  const qtdInSearch = (data, itemSearchText) => {
    const searcheredData = data.filter(function (item) {
      if (
        item.code.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.currentVersion.description.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.currentVersion.type.type.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1
      ) {
        return item
      } else {
        return false
      }
    })
    return searcheredData.length > 0 ? setSearchEmpty(false) : setSearchEmpty(true)
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <ToolkitProvider
                  keyField="id"
                  data={typeof dataRequirements.requirements !== 'undefined' ? dataRequirements.requirements : []}
                  columns={columns}
                  search={{
                    searchFormatted: true,
                  }}
                >
                  {(props) => (
                    <>
                      <Row>
                        <Col sm={9}></Col>
                        <Col sm={3}>
                          <div className="d-grid"></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={10} className="mb-4">
                          <InputGroup>
                            <InputGroup.Text
                              className="input-group-prepend"
                              style={{
                                height: 36,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            >
                              <i className="fas fa-search"></i>
                            </InputGroup.Text>
                            <SearchBar
                              {...props.searchProps}
                              placeholder="Digite o alvo de sua busca"
                              className="w-100 input-group-prepend"
                              style={{
                                height: 36,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                width: '100%',
                              }}
                            />
                            {typeof dataRequirements.service !== 'undefined'
                              ? qtdInSearch(dataRequirements.requirements, props.searchProps.searchText)
                              : null}
                          </InputGroup>
                        </Col>
                        <Col sm={2} className="text-right">
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() => history.push(`/servico/${id1}/requisitos/novo`)}
                            className="btn-block font-weight-bold"
                          >
                            Cadastrar Requisito
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {(qtdRequirements === 0 || searchEmpty) && (
                            <>
                              <AlertMessage
                                variant="alert alert-custom alert-light-primary alert-dismissible"
                                message={
                                  props.searchProps.searchText.length > 0
                                    ? `Não foram encontrados resultados para o termo ${props.searchProps.searchText}.`
                                    : 'Não há requisitos cadastrados para este serviço.'
                                }
                              />
                            </>
                          )}

                          {qtdRequirements !== 0 && !searchEmpty && (
                            <BootstrapTable
                              {...props.baseProps}
                              classes="bordered"
                              keyField="id"
                              data={dataRequirements.requirements}
                              columns={columns}
                              rowStyle={{ color: 'black' }}
                              headerClasses="text-center bg-light"
                              rowClasses="text-center"
                              bordered={true}
                              hover
                              noDataIndication="Não existem requisitos para o serviço"
                              pagination={paginationFactory(pageOptions)}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-0">
                        <Col className="text-right">
                          <Button
                            variant="secondary"
                            type="button"
                            onClick={() => history.push(`/servico/${id1}/requisitos/arquivados`)}
                            className="font-weight-bold mr-5"
                          >
                            Requisitos Arquivados
                          </Button>
                          {'  '}
                          <Button
                            variant="secondary"
                            type="button"
                            onClick={() => history.push('/servico')}
                            className="font-weight-bold"
                          >
                            <i className="fa fa-chevron-left"></i>
                            &nbsp;&nbsp;Voltar
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        onConfirm={configAlert.confirm}
        confirmButtonText={configAlert.confirmButtonText}
        showCancelButton={configAlert.cancelButton}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        toastSuccess={configAlert.toastSuccess}
      />
    </>
  )
}
