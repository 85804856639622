import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SweetAlert = withReactContent(Swal)

const AlertIntangibleAsset = ({ show, onConfirm, onDismissed, onDenied }) => {
  if (show) {
    SweetAlert.fire({
      title: 'Ativo Intangível',
      html: `<div style="text-align: justify;">
            É a invenção, patente, modelo de utilidade, desenho industrial, programa de computador ou qualquer outro desenvolvimento tecnológico que acarrete ou 
            possa acarretar o surgimento de um novo produto, processo ou aperfeiçoamento incremental. Inclui ativos protegidos por Direitos de Autor, como obras 
            literárias, audiovisuais, fotográficas, etc. Na hipótese de inexistência de ativo desenvolvido, deverá ser incluído o título do projeto ou objeto.
            </div>
      `,
      icon: 'info',
      width: 600,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) if (onConfirm) onConfirm()
      if (result.isDismissed) onDismissed()
      if (result.isDenied) onDenied()
      SweetAlert.close()
    })
  }
  return null
}

export default AlertIntangibleAsset
