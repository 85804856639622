import React from 'react'
import { Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../routes/Helpers'

import { AlertGT, Button } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { cleanSaveStatus, viewFaq } from '../_redux/faqActions'
import BasicSausegeCard from '../../../components/Card/BasicSausegeCard'
import ReactMarkdown from 'react-markdown'
//import { Remark } from 'react-remark';
import remarkGfm from 'remark-gfm'
import a11yEmoji from '@fec/remark-a11y-emoji'
import codeblocks from 'remark-code-blocks'
import oembed from '@agentofuser/remark-oembed'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import './css/react-markdown.css'

function FaqPage() {
  const { id1 } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const { faqSelected, saveStatus, statusMessage } = useSelector((state) => state.faq)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  React.useEffect(() => {
    dispatch(viewFaq(id1))
  }, [id1, dispatch])

  React.useEffect(() => {
    if (statusMessage) {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
    }
    dispatch(cleanSaveStatus())
    // eslint-disable-next-line
  }, [saveStatus, statusMessage])

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <BasicSausegeCard
            titleText={`${faqSelected.faq_code} - ${faqSelected.faq_title}`}
          //subText={faqSelected.faq_subtitle}
          />
          <ReactMarkdown
            remarkPlugins={[remarkGfm, a11yEmoji, oembed, [codeblocks, {}]]}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
            className="reactMarkdown"
            components={{
              blockquote: ({ node, ...props }) => (
                <blockquote
                  style={{ marginLeft: 8, borderLeft: '2px solid #CCC', paddingLeft: 8 }}
                  className="blockquote"
                  {...props}
                />
              ),
            }}
          >
            {faqSelected.faq_body_md}
          </ReactMarkdown>
        </Card.Body>

        <Card.Footer className="text-right">
          <Button
            variant="secondary"
            onClick={() => {
              history.goBack()
            }}
          >
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default FaqPage
