import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FileStatusBar, FileErrorMessage, FileRemove } from './FileBar.styles'
import RequiredDownload from '../FormByFile/RequiredDownload'

const FileBarWithRquiredDownload = ({
  file,
  index,
  handleClickRemoveFiles,
  requiredDownloadsAtt,
  setRequiredDownloadsAtt,
  errorMessage,
}) => {
  return (
    <FileStatusBar key={`file-bar-rewuired-download-${index}`}>
      <Row>
        <Col md={6}>
          <span className={`file-name ${file.invalid ? 'file-error' : ''}`}>
            <strong>Arquivo:</strong> {file.name}
          </span>
        </Col>
        {/* <FileSize>({fileSize(file.size)})</FileSize> */}
        <Col md={4} className="d-flex align-items-center" style={{ lineHeight: 1.5 }}>
          {file.invalid ? (
            <FileErrorMessage>({file.errorMessage})</FileErrorMessage>
          ) : (
            <RequiredDownload
              requiredDownloadsAtt={requiredDownloadsAtt}
              setRequiredDownloadsAtt={setRequiredDownloadsAtt}
              file={file}
              fileIndex={index}
            />
          )}
        </Col>
        <Col md="2">
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FileRemove variant="outline-dark" onClick={() => handleClickRemoveFiles(file)}>
                Excluir
              </FileRemove>
            </Col>
          </Row>
        </Col>
      </Row>
    </FileStatusBar>
  )
}

export default FileBarWithRquiredDownload
