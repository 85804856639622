import * as yup from 'yup';

const validationSchema = () => (
    yup.object().shape({
        title: yup.string()
            .required('O campo "Título do Ativo Intangível" é de preenchimento obrigatório.')
            .min(3, 'O campo "Título do Ativo Intangível" deve ter no mínimo 03 caracteres.')
            .max(100, 'O campo "Título do Ativo Intangível" deve ter no máximo 100 caracteres.'),
        description: yup.string()
        .required('O campo "Descrição do Ativo Intangível" é de preenchimento obrigatório.')
        .min(3, 'O campo "Descrição do Ativo Intangível" deve ter no mínimo 03 caracteres.')
        .max(1000, 'O campo "Descrição do Ativo Intangível" deve ter no máximo 1000 caracteres.')
    })
    
)

export default validationSchema