import styled from 'styled-components'

function setHeaderColor(theme) {
  switch (theme) {
    case 'incompleta':
      return '#d9d9d9'
    case 'cancelada':
      return 'black'
    case 'cancelamento-solicitacao':
      return 'black'
    case 'danger':
      return 'var(--red)'
    case 'acolhida':
      return 'var(--info)'
    case 'aguardando-atendimento':
      return '#fffc03'
    case 'em-atendimento':
      return '#a3c1fb'
    case 'atendimento-concluido':
      return '#1753c3'
    case 'atendimento-com-pendencia':
      return '#ca0008'
    case 'encerrada':
      return '#67a94d'
    case 'aguardando-acolhimento':
      return '#FA8600'
    case 'aguardando-novo-atendente':
      return '#9503f6'
    case 'encaminhamento-novo-atendente':
      return '#fffc03'
    case 'abandono-atendente':
      return '#9503f6'
    case 'transparent':
      return 'transparent'
    case 'off':
      return '#d9d9d9'
    case 'task-canceled':
      return '#f3f3f3'
    case 'task-realize':
      return '#1753c3'
    default:
      return '#ccc'
  }
}

function setBodyColor(theme) {
  switch (theme) {
    case 'task-canceled':
      return '#f3f3f3'
    default:
      return 'initial'
  }
}

function setTextColor(theme) {
  switch (theme) {
    case 'incompleta':
      return '#d9d9d9'
    case 'cancelada':
      return 'rgba(255, 255, 255, 0.8 ) !important'
    case 'cancelamento-solicitacao':
      return 'rgba(255, 255, 255, 0.8 ) !important'
    case 'danger':
      return 'rgba(255, 255, 255, 0.8 ) !important'
    case 'acolhida':
      return 'var(--white)'
    case 'aguardando-atendimento':
      return 'black'
    case 'em-atendimento':
      return 'var(--white)'
    case 'atendimento-concluido':
      return 'var(--white)'
    case 'atendimento-com-pendencia':
      return 'var(--white)'
    case 'encerrada':
      return 'var(--white) !important'
    case 'aguardando-acolhimento':
      return 'rgba(255, 255, 255, 0.8 ) !important'
    case 'aguardando-novo-atendente':
      return 'rgba(255, 255, 255, 0.8 ) !important'
    case 'encaminhamento-novo-atendente':
      return 'black !important'
    case 'abandono-atendente':
      return 'rgba(255, 255, 255, 0.8 ) !important'
    case 'task-realize':
      return '#ffffff'
    default:
      return '#333'
  }
}

export const ContainerCard = styled.div`
  margin: 20px 0px 30px 0px;
  border: 1px solid #cccccc;
  width: 100%;
`
export const HeaderCard = styled.div`
  background-color: ${(props) => setHeaderColor(props.theme)};
  color: ${(props) => setTextColor(props.theme)};
  padding: 16px 16px 8px;
  border-bottom: ${(props) =>
    props.theme === 'transparent' || props.theme === 'task-canceled' ? 'none' : '1px solid #cccccc'};
`

export const BodyCard = styled.div`
  background-color: ${(props) => setBodyColor(props.theme)};
  padding: 16px 16px 16px;
`

export const FooterCard = styled.div`
  padding: 16px 16px 16px;
`
