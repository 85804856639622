import React, { useCallback, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useSubheader } from '../../../../_metronic/layout'
import { Notice } from '../../../../_metronic/_partials/controls'
import { Spinner, Jumbotron } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const PageQuickLogin = () => {
  const suhbeader = useSubheader()
  suhbeader.setTitle('My Custom title')

  const { hash } = useParams()

  const quickLoginAccess = useCallback(async (valHash) => {
    const authAxios = axios.create()
    await authAxios
      .get(`${API_BASE_URL}/api/auth/qlacess/${valHash}`)
      .then((response) => {
        window.location = `/auth/login-api/${response.data.idksession}/${response.data.idk}`
      })
      .catch((error) => {
        // window.location = '/auth/login'
        window.location = '/auth/login/invalid-hash'
      })
  }, [])

  /**
   * Executar a função quickLoginAccess
   */
  useEffect(
    (token) => {
      if (token !== '') {
        quickLoginAccess(hash)
      }
    },
    [quickLoginAccess, hash]
  )

  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xl-12">
            <Notice icon="flaticon-warning font-danger mt-5">
              <span>Analizando credenciais. Por favor aguarde...</span>
            </Notice>
            <Jumbotron>
              <h1>
                <FormattedMessage id="SYSTEM.LOADING" />
              </h1>
              <div className="text-center">
                <Spinner animation="border" variant="primary" size="lg" />
              </div>
            </Jumbotron>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageQuickLogin
