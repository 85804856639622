import SolicitationsPage from '../modules/Solicitation/pages/SolicitationsPage'
import SolicitationsFromIntangibleAssetPage from '../modules/Solicitation/pages/SolicitationsFromIntangibleAssetPage'
import SolicitationsOff from '../modules/Solicitation/pages/SolicitationOff'
import SolicitationManagementPage from '../modules/Solicitation/pages/attendances/SolicitationManagementPage'
import SolicitationsRelatedPage from '../modules/Solicitation/pages/SolicitationsRelatedPage'
import SolicitationStepOnePage from '../modules/Solicitation/pages/SolicitationStepOnePage'
import SolicitationStepTwoPage from '../modules/Solicitation/pages/SolicitationStepTwoPage'
import SolicitationStepThreePage from '../modules/Solicitation/pages/SolicitationStepThreePage'
import SolicitationHistoryPage from '../modules/Solicitation/pages/SolicitationHistoryPage'
import SolicitationTasks from '../modules/Solicitation/pages/SolicitationTasks'
import { AttendancesOpened } from '../modules/Solicitation/pages/attendances/AttendancesOpened'
import { SolicitationAwaitingAttendantUnit } from '../modules/Solicitation/pages/SolicitationAwaitingAttendantUnit'
import { AttendancesCanceled } from '../modules/Solicitation/pages/attendances/AttendancesCanceled'
import { AttendancesClosed } from '../modules/Solicitation/pages/attendances/AttendancesClosed'
import { AttendanceOngoingPage } from '../modules/Solicitation/pages/attendances/AttendanceOngoingPage'
import { SolicitationDocuments } from '../modules/Solicitation/pages/attendances/SolicitationDocuments'
import { SolicitationsMyServicesPage } from '../modules/Solicitation/pages/attendances/SolicitationsMyServicesPage'
import { SolicitationForwardingPage } from '../modules/Solicitation/pages/attendances/SolicitationForwardingPage'
import SolicitationForumPage from '../modules/Solicitation/pages/attendances/SolicitationForumPage'
import TasksRegisterPage from '../modules/Tasks/pages/TasksRegisterPage'
import SolicitationRequirementsPage from '../modules/Solicitation/pages/attendances/SolicitationRequirementsPage'

export default [
  /**
   * Solicitacoes (Dashboard)
   * pathname principal: /solicitacao
   */
  {
    pathName: '/solicitacoes',
    exact: true,
    title: 'Minhas Solicitações',
    subTitle: 'Solicitações criadas pelo solicitante.',
    icon: '',
    component: SolicitationsPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacoes/por_ativo/:id1',
    exact: true,
    title: 'Solicitações do Ativo Intangível',
    subTitle: 'Solicitações criadas a partir do ativo intangível ',
    icon: '',
    component: SolicitationsFromIntangibleAssetPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacoes/canceladas',
    exact: true,
    title: 'Solicitações Canceladas',
    subTitle: 'Solicitações canceladas pelo solicitante.',
    icon: '',
    component: SolicitationsOff,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao',
    exact: true,
    title: 'Minhas Solicitações',
    subTitle: 'Solicitações criadas pelo solicitante.',
    icon: '',
    component: SolicitationsPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/:id1/gerenciamento',
    exact: true,
    title: 'Gerência de Solicitação',
    subTitle: 'Consulta dos dados da solicitação.',
    icon: '',
    component: SolicitationManagementPage,
    permission: ['admin', 'admin-servico', 'atendente', 'solicitante'],
  },
  {
    pathName: '/solicitacao/:id1/relacionadas',
    exact: true,
    title: 'Solicitações Relacionadas',
    subTitle: 'Solicitações descendentes e ascendentes à ',
    icon: '',
    component: SolicitationsRelatedPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/novo/etapa-um',
    exact: true,
    title: 'Cadastro de Solicitação',
    subTitle: '',
    icon: '',
    component: SolicitationStepOnePage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/novo/etapa-dois',
    exact: true,
    title: 'Cadastro de Solicitação',
    subTitle: 'Formulário de cadastro de solicitação.',
    icon: '',
    component: SolicitationStepTwoPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/novo/etapa-dois/admin-unidade',
    exact: true,
    title: 'Cadastro de Solicitação',
    subTitle: 'Formulário de cadastro de solicitação como Administrador de Unidade.',
    icon: '',
    component: SolicitationStepTwoPage,
    permission: ['admin-unidade'],
  },
  {
    pathName: '/solicitacao/novo/etapa-tres',
    exact: true,
    title: 'Cadastro de Solicitação',
    subTitle: 'Requisitos iniciais para criação da solicitação.',
    icon: '',
    component: SolicitationStepThreePage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/novo/etapa-tres/admin-unidade',
    exact: true,
    title: 'Cadastro de Solicitação',
    subTitle: 'Requisitos iniciais para criação da solicitação como Administrador de Unidade.',
    icon: '',
    component: SolicitationStepThreePage,
    permission: ['admin-unidade'],
  },
  {
    pathName: '/solicitacao/:id1/historico',
    exact: true,
    title: 'Histórico da Solicitação',
    subTitle: 'Registro do progresso no atendimento da solicitação.',
    icon: '',
    component: SolicitationHistoryPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/:id1/tarefas',
    exact: true,
    title: 'Tarefas da Solicitação',
    subTitle: 'Criação e Interação com tarefas associadas à solicitação.',
    icon: '',
    component: SolicitationTasks,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/solicitacao/atendimentos',
    exact: true,
    title: 'Meus Atendimentos',
    subTitle: 'Solicitações em andamento atendidas por ',
    icon: '',
    component: AttendancesOpened,
    permission: ['atendente'],
  },
  {
    pathName: '/solicitacao/disponiveis',
    exact: true,
    title: 'Solicitações Disponíveis em ',
    subTitle: 'Solicitações que aguardam acolhimento pelos atendentes da unidade.',
    icon: '',
    component: SolicitationAwaitingAttendantUnit,
    permission: ['atendente'],
  },
  {
    pathName: '/solicitacao/atendimentos/cancelados',
    exact: true,
    title: 'Solicitações Canceladas',
    subTitle: 'Solicitações canceladas que foram atendidas por ',
    icon: '',
    component: AttendancesCanceled,
    permission: ['atendente'],
  },
  {
    pathName: '/solicitacao/atendimentos/encerrados',
    exact: true,
    title: 'Solicitações Encerradas',
    subTitle: 'Solicitações com atendimento aprovado que foram realizadas por ',
    icon: '',
    component: AttendancesClosed,
    permission: ['atendente'],
  },
  {
    pathName: '/solicitacao/atendimentos/atendente/:id1/em_curso',
    exact: true,
    title: 'Atendimentos em Andamento',
    subTitle: '',
    icon: '',
    component: AttendanceOngoingPage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/solicitacao/:id1/documentos',
    exact: true,
    title: 'Documentos da Solicitação',
    subTitle:
      'Documentos anexados à solicitação em seu cadastro, no uso do forúm e em eventuais conclusões de atendimentos.',
    icon: '',
    component: SolicitationDocuments,
    permission: ['admin', 'atendente', 'solicitante', 'admin-servico'],
  },
  {
    pathName: '/solicitacao/atendimentos/admin_servico/solicitacoes/meus_servicos',
    exact: true,
    title: 'Solicitações dos Meus Serviços',
    subTitle: 'Solicitações dos serviços que você administra.',
    icon: '',
    component: SolicitationsMyServicesPage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/solicitacao/:id1/encaminhamento/service/:id2',
    exact: true,
    title: 'Encaminhamento de Solicitação',
    subTitle: 'Interface própria para encaminhamento de solicitação para atendimento.',
    icon: '',
    component: SolicitationForwardingPage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/solicitacao/:id1/forum-da-solicitacao',
    exact: true,
    title: 'Fórum da Solicitação',
    subTitle: 'Área para troca de mensagens entre solicitante, atendentes e administradores do serviço.',
    icon: '',
    component: SolicitationForumPage,
    permission: ['admin', 'atendente', 'solicitante', 'admin-servico', 'admin-unidade'],
  },
  {
    pathName: '/solicitacao/:id1/tarefa/novo',
    exact: true,
    title: 'Cadastro de Tarefa',
    subTitle: 'Formulário para cadastro de tarefas.',
    icon: '',
    component: TasksRegisterPage,
    permission: ['admin', 'atendente', 'admin-servico'],
  },
  {
    pathName: '/solicitacao/:id1/tarefa/:id2/editar',
    exact: true,
    title: 'Atualização da Tarefa',
    subTitle: 'Formulário para edição da tarefa.',
    icon: '',
    component: TasksRegisterPage,
    permission: ['admin', 'atendente', 'admin-servico'],
  },
  /**
   * Atendimentos
   * pathname principal: /solicitacao
   */
  {
    pathName: '/solicitacao/gerencia-da-solicitacao/:id1',
    exact: true,
    title: 'Gerência da Solicitação',
    subTitle: 'Consulta dos dados da solicitação.',
    icon: '',
    component: SolicitationManagementPage,
    permission: ['admin', 'admin-servico', 'admin-unidade', 'atendente', 'solicitante'],
  },
  {
    pathName: '/solicitacao/gerencia-da-solicitacao/requisitos-da-solicitacao/:id1',
    exact: true,
    title: 'Requisitos da Solicitação',
    subTitle: 'Requisitos do serviço atendidos pelo solicitante durante a criação da solicitação.',
    icon: '',
    component: SolicitationRequirementsPage,
    permission: ['admin', 'admin-servico', 'atendente', 'solicitante', 'admin-unidade'],
  },
]
