import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SweetAlert = withReactContent(Swal)

const AlertTask = ({ show, onConfirm, onDismissed, onDenied }) => {
  if (show) {
    SweetAlert.fire({
      title: 'O que é uma Tarefa?',
      html: `<div style="text-align: justify;">
              <p>
                Tarefas são pedidos feitos de um usuário diretamente para outro usuário do sistema. <br />
                São como um consultoria, solicitada por um e prestada por outro. 
              </p>
              <p>
                As tarefas podem ser utilizadas pelo atendente de uma solicitação quando este precisa de auxílio 
                para concluir seu atendimento. 
              </p>
              <p>
                Em uma tarefa são requeridas as seguintes informações: Um texto contendo o pedido de seu criador, quem será
                o usuário executante, o tipo de tarefa e, caso seja pertinente, há a possibilidade da anexação de arquivos à tarefa. 
              </p>
              <p>
                Sobre os estágios de uma tarefa, temos os seguintes: 
              </p>
              <ul>
                <li>Em andamento: É o momento que se inicia na criação da tarefa e vai até a sua realização</li>
                <li>Realizada: Momento após a plena realização da tarefa pelo executante.</li>
                <li>Cancelada: Quando a tarefa é cancelada por seu criador.</li>
              </ul>
            </div>
      `,
      icon: 'info',
      width: 760,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) if (onConfirm) onConfirm()
      if (result.isDismissed) onDismissed()
      if (result.isDenied) onDenied()
      SweetAlert.close()
    })
  }
  return null
}

export default AlertTask
