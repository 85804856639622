import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FileStatusBar, FileErrorMessage, FileRemove } from './FileBar.styles'
import FileDescription from '../FormByFile/FileDescription'
import TooltipCustom from '../../TooltipCustom'

const FileBarWithDescription = ({
  file,
  index,
  handleClickRemoveFiles,
  onAutoSave,
  errorMessage,
  onDeleteFile,
  descriptionIsEmpty,
  setDescriptionIsEmpty,
  errorCharacterCounter,
  setErrorCharacterCounter,
}) => {
  return (
    <FileStatusBar key={`file-bar-description-${index}`}>
      <Row>
        <Col md={4}>
          <TooltipCustom title={file.isSaved ? file.original_name : file.name} placement="top" arrow>
            <span className={`file-name ${file.invalid ? 'file-error' : ''}`}>
              {/* <strong>Arquivo:</strong> {file.isSaved ? file.original_name : file.name} */}
              <strong>Arquivo:</strong>{' '}
              {file.isSaved
                ? file.original_name.length >= 50
                  ? file.original_name.slice(0, 10) +
                    ' ... ' +
                    file.original_name.slice(file.original_name.length - 10, file.original_name.length)
                  : file.original_name
                : file.name.length >= 50
                ? file.name.slice(0, 10) + ' ... ' + file.name.slice(file.name.length - 10, file.name.length)
                : file.name}
            </span>
          </TooltipCustom>
        </Col>
        {/* <FileSize>({fileSize(file.size)})</FileSize> */}
        <Col md={6} className="d-flex align-items-baseline" style={{ lineHeight: 0, float: 'right' }}>
          {file.invalid ? (
            <FileErrorMessage>({file.errorMessage})</FileErrorMessage>
          ) : (
            <FileDescription
              onAutoSave={onAutoSave}
              file={file}
              isSaved={file.isSaved}
              descriptionIsEmpit={descriptionIsEmpty}
              setDescriptionIsEmpty={setDescriptionIsEmpty}
              errorCharacterCounter={errorCharacterCounter}
              setErrorCharacterCounter={setErrorCharacterCounter}
            />
          )}
        </Col>
        <Col md={2}>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FileRemove
                variant="outline-dark"
                onClick={() => {
                  handleClickRemoveFiles(file)
                  if (onDeleteFile && file.isSaved) {
                    onDeleteFile(file)
                  }
                }}
              >
                Excluir
              </FileRemove>
            </Col>
          </Row>
        </Col>
      </Row>
    </FileStatusBar>
  )
}

export default FileBarWithDescription
