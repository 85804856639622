import React from 'react'
import { Card, Container, Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { Col, Row } from 'react-bootstrap'

import { useSubheader } from '../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../routes/Helpers'

import { AlertGT, Table } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanErrors,
  cleanStatus,
  // createTypeTask,
  getListTypeTasks,
  selectTypeTask,
  deleteTypeTask,
} from './_redux/tasks_types/tasksTypesActions'
import { toast } from 'react-toastify'
import SearchBar from '../Solicitation/components/intangible_asset/SearchBar'

function TasksTypes() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { listTypeTasks, loading, saveStatus, statusMessage } = useSelector((state) => state.tasksTypes)

  //const [type, setType] = React.useState('')

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const [filteredData, setFilteredData] = React.useState([])

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  React.useEffect(() => {
    dispatch(getListTypeTasks())
    dispatch(cleanErrors())
    dispatch(cleanStatus())
  }, [dispatch])

  React.useEffect(() => {
    setFilteredData(listTypeTasks)
  }, [listTypeTasks])

  React.useEffect(() => {
    if (statusMessage) {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
    }
    dispatch(cleanStatus())
    // eslint-disable-next-line
  }, [saveStatus, statusMessage])

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%', verticalAlign: 'middle' }
      },
    },
    {
      dataField: 'type',
      text: 'Tipo',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      /* formatter: (cell, row) => (
        <>
          <ReadMore text={row.description} maxCharacters={70} />
          <strong style={{ fontSize: '0.85rem' }}>
            {row.service_code}-{row.service_title}
          </strong>
        </>
      ), */
      headerStyle: () => {
        return { width: '80%', verticalAlign: 'middle' }
      },
    },
    {
      dataField: 'number_typed_tasks',
      text: 'Qtd. Tarefas Tipadas',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%', verticalAlign: 'middle' }
      },
    },
  ]

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-edit fa-5x"></i>,
      title: 'Atualizar Tipo',
      onClick: (row) => {
        dispatch(selectTypeTask(row))
        history.push(`/tarefas/tipos/edit`)
      },
      disabled: (row) => !row.canDelete,
      visible: (row) => row.enable,
    },
    {
      text: <i className="fas fa-trash fa-5x"></i>,
      title: 'Excluir Tipo',
      onClick: (row) => {
        const content = `
          <p>Deseja realmente excluir o tipo <strong>${row.type}</strong>?</p>
        `
        setConfigAlert({
          show: true,
          title: null,
          text: content,
          icon: 'question',
          cancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          confirm: () => handleDeleteTypeTask(row.id),
          toastSuccess: null,
        })
      },
      disabled: (row) => !row.canDelete,
    },
  ]

  const handleDeleteTypeTask = (id) => {
    dispatch(deleteTypeTask(id))
      .then(() => {
        dispatch(getListTypeTasks())
      })
      .catch((error) => console.log(error))
  }

  const filterDataFunction = (input) => {
    // eslint-disable-next-line
    let result = listTypeTasks.filter((item) => {
      if (
        item.code.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
        item.type.toLowerCase().indexOf(input.toLowerCase()) !== -1
      ) {
        return item
      }
    })

    return setFilteredData(result)
  }

  const handleSearchText = (inpuText) => {
    filterDataFunction(String(inpuText))
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Container>
            <Row className="mt-0">
              <Col sm={9}>
                <SearchBar name="search" placeholder="Digite o alvo de sua busca" handleSearchText={handleSearchText} />
              </Col>
              <Col sm={3}>
                <Button
                  className="font-weight-bold"
                  width="20em"
                  onClick={() => history.push('/tarefas/tipos/new')}
                  block
                >
                  Cadastrar Tipo de Tarefa
                </Button>
              </Col>
            </Row>
            <Table
              data={filteredData}
              noDataMessage="Não existem tipos de tarefas cadastrados."
              columns={columns}
              totalSize={filteredData.length}
              onTableChange={() => null}
              searchPlaceholder="Pesquise aqui"
              perPagePosition="bottom"
              actions={actionsTable}
              //onSearch={(term) => setSearch(term)}
              //searchValue={search}
              loading={loading}
              remote={{ filter: false, pagination: false, sort: false }}
            />
            <Row className="mt-0">
              <Col className="text-right">
                <Button
                  variant="secondary"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}

export default TasksTypes
