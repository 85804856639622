import React from 'react'
import { BodyCard, ContainerCard, FooterCard, HeaderCard } from './CardGT.styles'

const CardGT = ({ header, body, footer, theme }) => {
  return (
    <ContainerCard>
      {header && <HeaderCard theme={theme}>{header}</HeaderCard>}
      {body && <BodyCard theme={theme}>{body}</BodyCard>}
      {footer && <FooterCard theme={theme}>{footer}</FooterCard>}
    </ContainerCard>
  )
}

export default CardGT
