import { createSlice } from '@reduxjs/toolkit'

const initialServiceState = {
  listLoading: true,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  notifications: null,
  notificationRead: null,
  systemActualPage: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const systemSlice = createSlice({
  name: 'system',
  initialState: initialServiceState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.statusMessage = action.payload ? action.payload.message : null
    },

    getNotifications: (state, action) => {
      state.notifications = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    clearLastError: (state) => {
      state.lastError = null
    },

    clearSaveError: (state) => {
      state.saveError = null
    },

    setStatusMessage: (state, action) => {
      state.saveStatus = action.payload ? action.payload.status : null
      state.statusMessage = action.payload ? action.payload.message : null
    },

    setNotificationLikRead: (state, action) => {
      state.notificationRead = action.payload
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    setSystemActualPage: (state, action) => {
      state.systemActualPage = action.payload
    },
  },
})
