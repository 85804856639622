import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../routes/Helpers'
import { useSubheader } from '../../../../../_metronic/layout'
import { Alert, Button, Card, Col, NavLink, Row } from 'react-bootstrap'
import { AlertDotStage, AlertGT, BadgeGT, ReadMore, Table } from '../../../../components'
import { AlertMessage } from '../../../Solicitation/components/services/AlertMessage'
import {
  getIntangibleAssetsAdminUnit,
  getQtdSolicitationsByStageNoLegacyAdminUnit,
  getQtdSolicitationsByStageLegacyAdminUnit,
  getSolicitationsAdminUnit,
} from '../../_redux/adminUnit/adminUnitActions'
import IntangibleAssetDataModal from '../../../Tasks/components/IntangibleAssetDataModal'
import TooltipCustom from '../../../../components/TooltipCustom'
import {
  deleteSolicitation,
  selectIntangibleAssetAdminUnit,
  selectOriginIntangibleAssetFromSolicitation,
  selectSolicitationAdminUnit,
  selectSolicitationInProgress,
  stepOne,
} from '../../../Solicitation/_redux/solicitation/solicitationActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export function AdminAssetsSolicitationsPage() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { id1 } = useParams()
  const history = useHistory()
  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle} `)

  const [configAlert, setConfigAlert] = useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
    closeToast: false,
  })

  const [showAlertDotStage, setShowAlertDotStage] = React.useState(false)

  const [key, setKey] = useState('intangibleTab')

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  const MySwal = withReactContent(Swal)

  /**
   * Tabela Ativos (Tab 1)
   */
  const [pageTab1, setPageTab1] = React.useState(1)
  const [perPageTab1, setPerPageTab1] = React.useState(5)
  const [searchTab1, setSearchTab1] = React.useState('')
  const [sortFieldTab1, setSortFieldTab1] = React.useState('')
  const [orderingTab1, setOrderingTab1] = React.useState('')

  const showAlertIntangibleAssetDataModal = (v_code, v_title, v_description, v_moment, v_initials) => {
    const content = () => (
      <IntangibleAssetDataModal
        intangibleAssetCode={v_code}
        intangibleAssetTitle={v_title}
        intangibleAssetDescription={v_description}
        intangibleAssetUserMomentCreate={v_moment}
        intangibleAssetUnitInitInitials={v_initials}
      />
    )
    setConfigAlert({
      show: true,
      title: null,
      text: content(),
      width: 800,
      confirmButtonText: 'Fechar',
      confirm: () => { },
      toastSuccess: null,
    })
  }

  /**
   * Tabela Ativos (Tab 2)
   */
  const [pageTab2, setPageTab2] = React.useState(1)
  const [perPageTab2, setPerPageTab2] = React.useState(5)
  const [searchTab2, setSearchTab2] = React.useState('')
  const [sortFieldTab2, setSortFieldTab2] = React.useState('')
  const [orderingTab2, setOrderingTab2] = React.useState('')

  /**
   * Tabela Quantitativo de Solicitações Não legadas (Tab 3A)
   */
  const [pageTab3a, setPageTab3a] = React.useState(1)
  // eslint-disable-next-line
  const [perPageTab3a, setPerPageTab3a] = React.useState(5)
  // eslint-disable-next-line
  const [searchTab3a, setSearchTab3a] = React.useState('')
  // eslint-disable-next-line
  const [sortFieldTab3a, setSortFieldTab3a] = React.useState('')
  // eslint-disable-next-line
  const [orderingTab3a, setOrderingTab3a] = React.useState('')

  /**
   * Tabela Quantitativo de Solicitações legadas (Tab 3b)
   */
  // eslint-disable-next-line
  const [pageTab3b, setPageTab3b] = React.useState(1)
  // eslint-disable-next-line
  const [perPageTab3b, setPerPageTab3b] = React.useState(5)
  // eslint-disable-next-line
  const [searchTab3b, setSearchTab3b] = React.useState('')
  // eslint-disable-next-line
  const [sortFieldTab3b, setSortFieldTab3b] = React.useState('')
  // eslint-disable-next-line
  const [orderingTab3b, setOrderingTab3b] = React.useState('')

  useEffect(() => {
    //Atualizar estado, pegando as solicitações que por ventura estejam incompletas
    dispatch(stepOne())
    if (typeof id1 !== 'undefined') {
      switch (id1) {
        case '1':
          setKey('intangibleTab')
          break
        case '2':
          setKey('solicitationTab2')
          break
        case '3':
          setKey('statisticDataTab3')
          break
        default:
          setKey('intangibleTab')
      }
    }

    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getIntangibleAssetsAdminUnit(perPageTab1, pageTab1, searchTab1, sortFieldTab1, orderingTab1))
    // eslint-disable-next-line
  }, [perPageTab1, pageTab1, searchTab1, sortFieldTab1, orderingTab1])


  useEffect(() => {
    dispatch(getSolicitationsAdminUnit(perPageTab2, pageTab2, searchTab2, sortFieldTab2, orderingTab2))
    // eslint-disable-next-line
  }, [perPageTab2, pageTab2, searchTab2, sortFieldTab2, orderingTab2])

  useEffect(() => {
    dispatch(
      getQtdSolicitationsByStageNoLegacyAdminUnit(perPageTab3a, pageTab3a, searchTab3a, sortFieldTab3a, orderingTab3a)
    )
    // eslint-disable-next-line
  }, [perPageTab3a, pageTab3a, searchTab3a, sortFieldTab3a, orderingTab3a])

  useEffect(() => {
    dispatch(
      getQtdSolicitationsByStageLegacyAdminUnit(perPageTab3b, pageTab3b, searchTab3b, sortFieldTab3b, orderingTab3b)
    )
    // eslint-disable-next-line
  }, [perPageTab3b, pageTab3b, searchTab3b, sortFieldTab3b, orderingTab3b])

  const {
    loading,
    intangibleAssetList,
    solicitationsList,
    qtdSolByStageNoLegacyList,
    qtdSolByStageLegacyList,
  } = useSelector((state) => state.adminUnit)
  const { incompleteSolicitations } = useSelector((state) => state.solicitations)

  function confirmRemoveSolicition(solicitation) {
    dispatch(deleteSolicitation(solicitation))
  }

  /**
   * createSolicitationFromIntangibleAsset
   *
   * Função para criar uma Solicitação a partir de um Ativo
   * @param {*} ativo
   */
  function createSolicitationFromIntangibleAsset(ativo) {
    if (incompleteSolicitations && incompleteSolicitations.id) {
      MySwal.fire({
        title: '',
        heightAuto: true,
        width: 600,
        html: `<p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
          e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
          Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
          <p>Deseja prosseguir?</p>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          confirmRemoveSolicition(incompleteSolicitations)
          dispatch(selectIntangibleAssetAdminUnit(ativo))
          history.push('/solicitacao/novo/etapa-dois/admin-unidade')
        }
        if (result.isDismissed) {
          // setProceedButton(false)
        }
      })
    } else {
      dispatch(selectIntangibleAssetAdminUnit(ativo))
      history.push('/solicitacao/novo/etapa-dois/admin-unidade')
    }
  }

  /**
   * createSolicitationFromSolciitation
   *
   * Função para criar uma Solicitação a partir de outra Solicitação
   * @param {*} ativo
   */
  function createSolicitationFromSolciitation(sol) {
    if (incompleteSolicitations && incompleteSolicitations.id) {
      MySwal.fire({
        title: '',
        heightAuto: true,
        width: 600,
        html: `<p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
          e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
          Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
          <p>Deseja prosseguir?</p>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          confirmRemoveSolicition(incompleteSolicitations)
          selectCurrentSolicitation(sol)
          history.push('/solicitacao/novo/etapa-dois/admin-unidade')
        }
        if (result.isDismissed) {
          // setProceedButton(false)
        }
      })
    } else {
      selectCurrentSolicitation(sol)
      history.push('/solicitacao/novo/etapa-dois/admin-unidade')
    }
  }
  /**
   * setIncompleteSolicitation
   *
   * Função que seleciona a Solicitação que está incompleta para continuar o cadastro da mesma
   * @param {*} e
   * @param {*} solicition
   */
  function setIncompleteSolicitation(e, solicition) {
    e.preventDefault()
    dispatch(selectSolicitationInProgress(solicition))
    history.push('/solicitacao/novo/etapa-tres/admin-unidade')
  }

  /**
   * selectCurrentSolicitation
   *
   * Função que seleciona uma solicitação como origem de um outra que será criada.
   * @param {*} sol
   */
  const selectCurrentSolicitation = (sol) => {
    dispatch(
      selectSolicitationAdminUnit({
        id: sol.solicitation_id,
        code: sol.solicitation_code,
        description: sol.solicitation_description,
        moment: sol.solicitation_create_date,
        solicitation_organization: {
          id: sol.solicitation_organization_id,
          name: sol.solicitation_organization_name,
          initials: sol.solicitation_organization_initials,
        },
        stage: {
          id: sol.solicitation_stage_id,
          stage: sol.solicitation_stage_stage,
          slug: sol.solicitation_stage_slug,
        },
        user: {
          id: sol.solicitation_creator_user_id,
          name: sol.solicitation_creator_user_name,
          email: sol.solicitation_creator_user_email,
          organization: {
            id: sol.solicitation_creator_user_unit_id,
            name: sol.solicitation_creator_user_unit_name,
            initials: sol.solicitation_creator_user_unit_initials,
          },
        },
        intangibleAssetOriginParent: sol.intangible_asset_origin_parent,
      })
    )
    dispatch(selectOriginIntangibleAssetFromSolicitation(sol.solicitation_id))
  }

  /**
   * Dados de Tabela Tab1
   */

  //Botões da aba 1
  const actionsButtonsTableTab1 = [
    {
      text: <i className="fas fa-folder-plus"></i>,
      title: 'Criar Solicitação',
      onClick: (row) =>
        createSolicitationFromIntangibleAsset({
          code: row.intangible_asset_code,
          id: row.intangible_asset_id,
          user: {
            id: row.intangible_asset_user_organization_id,
            name: row.intangible_asset_user_name,
            user: row.intangible_asset_user_email,
            organization: {
              id: row.intangible_asset_organization_id,
              name: row.intangible_asset_organization_name,
              initials: row.intangible_asset_organization_initials,
            },
          },
          organization: {
            id: row.intangible_asset_organization_id,
            name: row.intangible_asset_organization_name,
            initials: row.intangible_asset_organization_initials,
          },
          title: row.intangible_asset_title,
          description: row.intangible_asset_description,
          moment: row.intangible_asset_moment,
        }),
    },
    {
      text: <i className="fas fa-search"></i>,
      title: 'Consultar Ativo',
      onClick: (row) =>
        showAlertIntangibleAssetDataModal(
          row.intangible_asset_code,
          row.intangible_asset_title,
          row.intangible_asset_description,
          `Criado em ${row.intangible_asset_moment} por ${row.intangible_asset_user_name} (${row.intangible_asset_user_email}).`,
          row.intangible_asset_organization_initials
        ),
    },
    {
      text: <i className="fas fa-history fa-sm"></i>,
      title: 'Histórico do Ativo',
      onClick: (row) => history.push(`/ativo-intangivel/historico/${row.intangible_asset_id}`),
    },
  ]

  const orderByOptionsTab1 = [
    { value: 'intangible_asset_code', text: 'Código do Ativo' },
    { value: 'intangible_asset_title', text: 'Título do Ativo' },
    { value: 'intangible_asset_moment', text: 'Data de Criação do Ativo' },
    { value: 'intangible_asset_organization_name', text: 'Nome da Unidade do Ativo' },
    { value: 'intangible_asset_organization_initials', text: 'Sigla da Unidade do Ativo' },
    { value: 'intangible_asset_user_name', text: 'Nome do Criador do Ativo' },
    { value: 'intangible_asset_user_email', text: 'E-mail do Criador do Ativo' },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columnsTab1 = [
    {
      dataField: 'intangible_asset_code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'intangible_asset_title',
      text: 'Título do Ativo Itangível',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.intangible_asset_title} maxCharacters={70} />
          <br />
          {row.legacy && (
            <Alert
              variant={'light'}
              style={{ height: '25px', marginBottom: '1px', minWidth: '380px', paddingTop: '4px' }}
            >
              <p className="text-center" style={{ color: 'black' }}>
                <strong>Legado de outra Unidade.</strong>
              </p>
            </Alert>
          )}
        </>
      ),
      headerStyle: { width: '45%', textAlign: 'center' },
    },
    {
      dataField: 'intangible_asset_moment',
      text: 'Data de Criação/Criador',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.intangible_asset_moment} <br />
          <small>
            {row.intangible_asset_user_name} ({row.intangible_asset_user_email})
          </small>
        </>
      ),
      headerStyle: { width: '40%', textAlign: 'center' },
    },
    {
      dataField: 'intangible_asset_organization_initials',
      text: 'Unidade do Ativo',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
  ]

  /**
   * Dados de Tabela Tab2
   */

  // Botões da aba 2
  const actionsButtonsTableTab2 = [
    {
      text: <i className="fas fa-folder-plus"></i>,
      title: 'Criar Solicitação',
      onClick: (row) => createSolicitationFromSolciitation(row),
      disabled: (row) => (row.solicitation_stage_slug === 'incompleta' ? true : false),
    },
    {
      text: <i className="fas fa-search"></i>,
      title: 'Consultar Solicitação',
      onClick: (row) => history.push(`/solicitacao/gerencia-da-solicitacao/${row.solicitation_id}`),
      disabled: (row) => (row.solicitation_stage_slug === 'incompleta' ? true : false),
    },
  ]

  const orderByOptionsTab2 = [
    { value: 'solicitation_code', text: 'Código da Solicitação' },
    { value: 'solicitation_description', text: 'Descrição da Solicitação' },
    { value: 'solicitation_create_date', text: 'Data de Criação da Solicitação' },
    { value: 'solicitation_creator_user_name', text: 'Nome do Solicitante' },
    { value: 'solicitation_creator_user_unit', text: 'Unidade do Solicitante' },
    { value: 'solicitation_creator_user_unit_initials', text: 'Sigla da Unidade da Solicitação' },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columnsTab2 = [
    {
      dataField: 'solicitation_code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'solicitation_description',
      text: 'Dados da Solicitação',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.solicitation_description} maxCharacters={70} />
          <br />
          <small>
            <strong>
              {row.service_code} -{' '}
              {row.service_title.length <= 70 ? row.service_title : row.service_title.slice(0, 70) + '...'}
            </strong>
          </small>
          {row.isLegacy === '1' && (
            <Alert
              variant={'light'}
              style={{ height: '25px', marginBottom: '1px', minWidth: '380px', paddingTop: '4px' }}
            >
              <p className="text-center" style={{ color: 'black' }}>
                <strong>Legado de outra Unidade.</strong>
              </p>
            </Alert>
          )}
        </>
      ),
      headerStyle: { width: '45%', textAlign: 'center' },
    },
    {
      dataField: 'solicitation_create_date',
      text: 'Criação/Solicitante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.solicitation_create_date} <br />
          <small>
            {row.solicitation_creator_user_name} ({row.solicitation_creator_user_unit_initials})
          </small>
        </>
      ),
      headerStyle: { width: '40%', textAlign: 'center' },
    },
    {
      dataField: 'solicitation_organization_initials',
      text: 'Unidade da Solicitação',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'solicitation_stage_slug',
      text: 'Estágio',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.solicitation_stage} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={row.solicitation_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '10%', textAlign: 'center' },
    },
  ]

  /**
   * Dados de Tabela Tab3a
   */

  /**
   * Definição das colunas da tabela dataField é o campo da tabela do banco
   */
  const columnsTab3a = [
    {
      dataField: 'initials',
      text: 'Unidade',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '10%' }
      },
    },
    {
      dataField: 'qtd_ativos',
      text: 'Qtd. de Ativos',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'qtd_solicitacoes',
      text: 'Qtd. de Solicitações',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'aguardando_acolhimento_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Aguardando Acolhimento'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'aguardando-acolhimento'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'aguardando_atendimento_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Aguardando Atendimento'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'aguardando-atendimento'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'em_atendimento_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Em Atendimento'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'em-atendimento'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'atendimento_concluido_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Atendimento Concluido'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'atendimento-concluido'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'encerrada_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Encerrada'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'encerrada'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'atendimento_com_pendencia_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Atendimento Com Pendencia'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'atendimento-com-pendencia'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'cancelada_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Cancelada'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'cancelada'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'aguardando_novo_atendente_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Aguardando Novo Atendente'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'aguardando-novo-atendente'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
  ]
  /**
   * Dados de Tabela Tab3a
   */

  /**
   * Definição das colunas da tabela dataField é o campo da tabela do banco
   */
  const columnsTab3b = [
    {
      dataField: 'initials',
      text: 'Unidade',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '10%' }
      },
    },
    {
      dataField: 'qtd_ativos',
      text: 'Qtd. de Ativos',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'qtd_solicitacoes',
      text: 'Qtd. de Solicitações',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'aguardando_acolhimento_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Aguardando Acolhimento'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'aguardando-acolhimento'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'aguardando_atendimento_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Aguardando Atendimento'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'aguardando-atendimento'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'em_atendimento_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Em Atendimento'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'em-atendimento'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'atendimento_concluido_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Atendimento Concluido'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'atendimento-concluido'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'encerrada_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Encerrada'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'encerrada'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'atendimento_com_pendencia_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Atendimento Com Pendencia'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'atendimento-com-pendencia'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'cancelada_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Cancelada'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'cancelada'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
    {
      dataField: 'aguardando_novo_atendente_value',
      text: '#',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerFormatter: () => (
        <TooltipCustom title={'Aguardando Novo Atendente'} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={'aguardando-novo-atendente'} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '8,75%', textAlign: 'center' },
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={() => setConfigAlert({ ...configAlert, show: false })}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      <AlertDotStage
        show={showAlertDotStage}
        onConfirm={() => setShowAlertDotStage(false)}
        onDismissed={() => setShowAlertDotStage(false)}
      />
      <Card border="secondary">
        <Card.Body>
          <Row>
            <Col>
              <Alert key={'warning'} variant={'warning'}>
                <span style={{ color: 'black' }}>
                  Atualmente você administra os Ativos Intangíveis e Solicitações das seguintes unidades:{' '}
                  <strong>{intangibleAssetList.unitsByComma}.</strong>
                </span>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              {incompleteSolicitations && incompleteSolicitations.id && (
                <>
                  <Alert key={'uyt'} variant={'light'}>
                    <span style={{ color: 'black', cursor: 'pointer' }}>
                      <strong>ATENÇÃO!</strong> Há uma solicitação sua com o cadastro incompleto.{' '}
                      <span
                        style={{ color: 'blue' }}
                        onClick={(e) => setIncompleteSolicitation(e, incompleteSolicitations)}
                        className="link-primary"
                      >
                        Clique aqui
                      </span>{' '}
                      para finalizá-la.
                    </span>
                  </Alert>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="ml-5">
              <Tabs id="admin_intangible_solicitation_tab" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="intangibleTab" title="Ativos">
                  <Row>
                    <Col className="mt-3">
                      {/*  Ativos - Início */}
                      <div className="mt-3">
                        <AlertMessage
                          variant="alert alert-custom alert-light-primary alert-dismissible"
                          message={`A lista abaixo contém os ativos intangíveis que pertencem às suas unidades e outros ativos criados em outras unidades por
                          pesquisadores que estão, ou estiveram alocados nas unidades que você administra (legado de outras unidades).`}
                        />
                      </div>
                      <div className="mt-5">
                        <Table
                          keyField="dataField"
                          className="mt-5"
                          data={intangibleAssetList.data}
                          noDataMessage={
                            typeof searchTab1 !== 'undefined'
                              ? `Não foram encontrados resultados para o termo ${searchTab1}.`
                              : `Não existem Ativos Intangíveis.`
                          }
                          columns={columnsTab1}
                          page={intangibleAssetList.current_page}
                          perPage={perPageTab1}
                          totalSize={intangibleAssetList.total}
                          orderByOptions={orderByOptionsTab1}
                          orderByOnChange={(field) => setSortFieldTab1(field)}
                          orderByDirectionChange={(direction) => setOrderingTab1(direction)}
                          onSizePerPageChange={(perPageTab1) => {
                            setPerPageTab1(perPageTab1)
                            setPageTab1(1)
                          }}
                          onPageChange={(page) => setPageTab1(page)}
                          onTableChange={() => null}
                          searchPlaceholder="Pesquise aqui"
                          perPagePosition="top"
                          actions={actionsButtonsTableTab1}
                          onSearch={(term) => setSearchTab1(term)}
                          searchValue={searchTab1}
                          loading={loading}
                        />
                      </div>
                      {/*  Ativos - Fim */}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="solicitationTab2" title="Solicitações">
                  <Row>
                    <Col className="mt-3">
                      {/*  Solicitações - Início */}
                      <div className="mt-3">
                        <AlertMessage
                          variant="alert alert-custom alert-light-primary alert-dismissible"
                          message={`A lista abaixo contém as solicitações que pertencem às suas unidades e outras criadas em outras unidades por pesquisadores
                          que estão, ou estiveram alocados nas unidades que você administra (legado de outras unidades).`}
                        />
                      </div>
                      <div className="mt-5">
                        <Table
                          className="mt-5"
                          data={solicitationsList.data}
                          noDataMessage={
                            typeof searchTab2 !== 'undefined'
                              ? `Não foram encontrados resultados para o termo ${searchTab2}.`
                              : `Não existem Solicitações.`
                          }
                          columns={columnsTab2}
                          page={solicitationsList.current_page}
                          perPage={perPageTab2}
                          totalSize={solicitationsList.total}
                          orderByOptions={orderByOptionsTab2}
                          orderByOnChange={(field) => setSortFieldTab2(field)}
                          orderByDirectionChange={(direction) => setOrderingTab2(direction)}
                          onSizePerPageChange={(perPageTab2) => {
                            setPerPageTab2(perPageTab2)
                            setPageTab2(1)
                          }}
                          onPageChange={(page) => setPageTab2(page)}
                          onTableChange={() => null}
                          searchPlaceholder="Pesquise aqui"
                          perPagePosition="top"
                          actions={actionsButtonsTableTab2}
                          onSearch={(term) => setSearchTab2(term)}
                          searchValue={searchTab2}
                          loading={loading}
                        />
                      </div>
                      {/*  Solicitações - Fim */}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="statisticDataTab3" title="Dados Estatísticos">
                  <Row>
                    <Col sm={6}>
                      <span className="h3">Produção das minhas unidades</span>
                    </Col>
                    <Col sm={6} className="text-right">
                      <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotStage(true)}>
                        Legenda dos Estágios da Solicitação
                      </NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      {/*  Quantitativo de solicitações não legadas - Início */}

                      <div className="mt-5">
                        <Table
                          className="mt-5"
                          data={qtdSolByStageNoLegacyList.data}
                          columns={columnsTab3a}
                          page={qtdSolByStageNoLegacyList.current_page}
                          totalSize={qtdSolByStageNoLegacyList.total}
                          onPageChange={(page) => setPageTab3a(page)}
                          onTableChange={() => null}
                          actions={null}
                          loading={loading}
                        />
                      </div>
                      {/*  Quantitativo de solicitações não legadas - Fim */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      {/*  Quantitativo de solicitações  legadas - Início */}
                      <div className="mt-3">
                        <span className="h3">Legado de outras unidades</span>
                      </div>
                      <div className="mt-5">
                        <Table
                          className="mt-5"
                          data={qtdSolByStageLegacyList.data}
                          columns={columnsTab3b}
                          page={qtdSolByStageLegacyList.current_page}
                          totalSize={qtdSolByStageLegacyList.total}
                          onPageChange={(page) => setPageTab3b(page)}
                          onTableChange={() => null}
                          actions={null}
                          loading={loading}
                        />
                      </div>
                      {/*  Quantitativo de solicitações  legadas - Fim */}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="text-right">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
