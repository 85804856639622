import { ServiceMyAdmin } from '../modules/Solicitation/pages/services/ServiceMyAdmin'
import { ServiceMyAttendant } from '../modules/Solicitation/pages/services/ServiceMyAdminAttendant'
import { ServiceListPage } from '../modules/Solicitation/pages/services/ServiceListPage'
import { ServiceCreatePage } from '../modules/Solicitation/pages/services/ServiceCreatePage'
import ServiceManagementPage from '../modules/Solicitation/pages/services/ServiceManagementPage'
import { ServiceViewPage } from '../modules/Solicitation/pages/services/ServiceViewPage'
import { RequirementViewPage } from '../modules/Solicitation/pages/service_requirements/RequirementViewPage'
import { RequirementPage } from '../modules/Solicitation/pages/service_requirements/RequirementPage'
import { RequirementDiscontinuedPage } from '../modules/Solicitation/pages/service_requirements/RequirementDiscontinuedPage'
import ServiceRequirementsCreatePage from '../modules/Solicitation/pages/service_requirements/ServiceRequirementsCreatePage'
import { RequirementViewObsoletePage } from '../modules/Solicitation/pages/service_requirements/RequirementViewObsoletePage'
import { AttendantsQualifiedForService } from '../modules/Solicitation/pages/services/AttendantsQualifiedForService'

export default [
  {
    pathName: '/servico/meus/administrados',
    exact: true,
    title: 'Meus Serviços',
    subTitle: 'Serviços administrados por ',
    icon: '',
    component: ServiceMyAdmin,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/servico/meus/atendidos',
    exact: true,
    title: 'Meus Serviços',
    subTitle: 'Serviços atendidos por',
    icon: '',
    component: ServiceMyAttendant,
    permission: ['atendente'],
  },

  /**
   * Serviço (Serviços)
   * pathname principal: /servico
   */
  {
    pathName: '/servico',
    exact: true,
    title: 'Serviços',
    subTitle: 'Serviços disponíveis no SISGESTEC.',
    icon: '',
    component: ServiceListPage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/servico/novo',
    exact: true,
    title: 'Cadastro de Serviço',
    subTitle: 'Formulário para cadastro de serviço.',
    icon: '',
    component: ServiceCreatePage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/servico/:id1/edit',
    exact: true,
    title: 'Edição de Serviço',
    subTitle: 'Formulário para cadastro e atualização do Serviço.',
    icon: '',
    component: ServiceCreatePage,
    permission: ['admin'],
  },
  {
    pathName: '/servico/:id1/gerenciamento',
    exact: true,
    title: 'Gerenciamento de Serviço',
    subTitle: 'Gerência de Serviço  SV001',
    icon: '',
    component: ServiceManagementPage,
    permission: ['admin'],
  },
  {
    pathName: '/servico/:id1/consulta',
    exact: true,
    title: 'Consulta de Serviço',
    subTitle: 'Consulta dos dados do serviço.',
    icon: '',
    component: ServiceViewPage,
    permission: ['admin', 'admin-servico', 'atendente', 'solicitante'],
  },
  {
    pathName: '/servico/:id1/consulta/requisito/:id2/:id3',
    exact: true,
    title: 'Consulta de Requisito',
    subTitle: 'Consultado dos dados do requisito do serviço.',
    icon: '',
    component: RequirementViewPage,
    permission: ['admin', 'admin-servico', 'atendente'],
  },
  {
    pathName: '/servico/:id1/requisitos',
    exact: true,
    title: 'Requisitos do Serviço',
    subTitle: '',
    icon: '',
    component: RequirementPage,
    permission: ['admin'],
  },
  {
    pathName: '/servico/:id1/requisitos/arquivados',
    exact: true,
    title: 'Requisitos Arquivados',
    subTitle: '',
    icon: '',
    component: RequirementDiscontinuedPage,
    permission: ['admin'],
  },
  {
    pathName: '/servico/:id1/requisitos/arquivados/:id2/consulta',
    exact: true,
    title: 'Consulta de Requisito',
    subTitle: 'Consulta de requisito arquivado.',
    icon: '',
    component: RequirementViewPage,
    permission: ['admin'],
  },

  {
    pathName: '/servico/:id1/requisitos/novo',
    exact: true,
    title: 'Cadastro de Requisito',
    subTitle: 'Formulário para cadastro de requisito do serviço.',
    icon: '',
    component: ServiceRequirementsCreatePage,
    permission: ['admin'],
  },
  {
    pathName: '/servico/:id1/requisitos/:id2/edit',
    exact: true,
    title: 'Atualização de Requisito',
    subTitle: '',
    icon: '',
    component: ServiceRequirementsCreatePage,
    permission: ['admin'],
  },
  {
    pathName: '/servico/:id1/requisitos/:id2/consulta',
    exact: true,
    title: 'Consulta de Requisito',
    subTitle: 'Consultado dos dados do requisito do serviço.',
    icon: '',
    component: RequirementViewPage,
    permission: ['admin', 'atendente'],
  },

  {
    pathName: '/servico/:id1/requisitos/versao/:id2/consulta',
    exact: true,
    title: 'Consulta de Requisito',
    subTitle: 'Consultado dos dados do requisito obsoleto do serviço.',
    icon: '',
    component: RequirementViewObsoletePage,
    permission: ['admin', 'admin-servico'],
  },
  {
    pathName: '/servico/:id1/atendentes',
    exact: true,
    title: 'Atendentes Habilitados',
    subTitle: 'Atendentes habilitados para o serviço ',
    icon: '',
    component: AttendantsQualifiedForService,
    permission: ['admin', 'admin-servico'],
  },
]
