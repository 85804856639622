import FaqsPage from '../modules/Faq/pages/Faqs'
import FaqPage from '../modules/Faq/pages/Faq'

export default [
  /**
   * FAQ
   * pathname principal: /faq
   */
  {
    pathName: '/faq',
    exact: true,
    title: 'FAQ',
    subTitle: 'Repositório de perguntas frequentes.',
    icon: '',
    component: FaqsPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },

  {
    pathName: '/faq/:id1',
    exact: true,
    title: 'Dados da FAQ',
    subTitle: 'Leia a resposta para a pergunta abaixo. Isso pode te ajudar na utilização do sistema.',
    icon: '',
    component: FaqPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
]
