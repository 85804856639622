import TooltipCustom from '../../../../components/TooltipCustom'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { BadgeGT } from '../../../../components'
const IntangibleAssetDataModal = ({
  intangibleAssetCode,
  intangibleAssetTitle,
  intangibleAssetDescription,
  intangibleAssetUserMomentCreate = null,
  intangibleAssetUnitInitInitials = null,
}) => {
  return (
    <div style={{ textAlign: 'left', overflow: 'hidden' }}>
      <Row>
        <Col>
          <strong className="mt-5">Ativo Intangível</strong>
          <h3>
            <strong>{intangibleAssetCode}</strong>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong className="mt-5">Título</strong>
          <p>{intangibleAssetTitle}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Descrição do Ativo</strong>
          <p>{intangibleAssetDescription}</p>
        </Col>
      </Row>

      {intangibleAssetUnitInitInitials !== null && (
        <Row>
          <TooltipCustom title="Unidade de Origem do Ativo" placement="top" arrow>
            <span className="btn btn-sm font-weight-bold text-center">
              <BadgeGT
                variant={'secondary'}
                text={intangibleAssetUnitInitInitials}
                style={{ marginLeft: '3px', width: 'auto', fontSize: '12px' }}
              />
            </span>
          </TooltipCustom>
        </Row>
      )}

      {intangibleAssetUserMomentCreate !== null && (
        <Row>
          <Col>
            <small>
              <span className="font-weight-bold">{intangibleAssetUserMomentCreate}</span>
            </small>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default IntangibleAssetDataModal
