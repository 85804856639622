import React from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const ServiceForm = (props) => {
  const history = useHistory()
  return (
    <>
      <Form noValidate onSubmit={props.handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationFormik01">
            <Form.Label>Título do Serviço</Form.Label>
            <Form.Control
              type="text"
              placeholder="Título do Serviço"
              onChange={props.onChangeTitle}
              onBlur={props.onBlueTitle}
              {...props.getFieldPropsTitle}
              className={props.touchedTitle && props.errorsTitle ? 'is-invalid' : null}
            />
            {props.touchedTitle && props.errorsTitle ? (
              <Form.Control.Feedback type="invalid">{props.errorsTitle}</Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationFormik02">
            <Form.Label>Descrição do Serviço</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              placeholder="Descrição do Serviço"
              name="description"
              onChange={props.onChangeDescription}
              maxLength="1000"
              {...props.getFieldPropsDescription}
              className={props.touchedDescription && props.errorsDescription ? 'is-invalid' : null}
            />
            {props.touchedDescription && props.errorsDescription ? (
              <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                {props.errorsDescription}
              </Form.Control.Feedback>
            ) : null}

            <Form.Text className="text-right">
              {props.valuesDescription.length !== 0
                ? `${props.valuesDescription.length}/1000 caracteres`
                : 'Máximo de 1000 caracteres.'}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row className="mt-0">
          <Col className="text-left">
            <Button type="submit" className="font-weight-bold">
              Salvar
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Col>
          <Col className="text-right">
            <Button
              variant="secondary"
              type="button"
              onClick={() => history.push('/servico')}
              className="font-weight-bold"
            >
              <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
            </Button>{' '}
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ServiceForm
