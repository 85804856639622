import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import TooltipCustom from '../../../../components/TooltipCustom'
import { CardGT, Button as ButtonGT } from '../../../../components'
import handleDownload from '../../../../Utils/downloadFile'

const Header = ({
  numMessage,
  userType,
  authorNameMessage,
  authorEmailMessage,
  moment,
  messageWasDeleted,
  deletedMessage,
  messageWasRealize,
  realizeMessage,
  stageSlug,
}) => (
  <>
    <Row>
      {messageWasDeleted && !messageWasRealize && (
        <Col md="12" className="d-flex mb-3">
          <>
            <h5 className="mr-1 mb-0">Interação {numMessage}</h5> - {deletedMessage}
          </>
        </Col>
      )}
      {messageWasRealize && (
        <Col md="12" className="d-flex mb-3">
          {realizeMessage}
        </Col>
      )}
      {!messageWasDeleted && !messageWasRealize && (
        <>
          <Col md="6" className="d-flex mb-3">
            {stageSlug !== 'cancelada' && <h5 className="mr-1 mb-0">Interação {numMessage}</h5>}
            {stageSlug === 'cancelada' && <h5 className="mr-1 mb-0">Tarefa Cancelada</h5>}
          </Col>
          <Col md="6" className="d-flex justify-content-end opacity-50">
            <div>{moment}</div>
          </Col>
        </>
      )}
    </Row>
    {!messageWasDeleted && !messageWasRealize && (
      <Row>
        <Col>
          <span className="opacity-50">
            {userType} <strong>{authorNameMessage} </strong> ({authorEmailMessage})
          </span>
        </Col>
      </Row>
    )}
  </>
)

const Body = ({ message }) => (
  <p style={{ fontWeight: 500 }} className="mb-5">
    {message}
  </p>
)

const Footer = ({ message, onDelete }) => (
  <Row>
    <Col md={8}>
      {message.interaction_attachments.map((item, index) => {
        return (
          <TooltipCustom key={index} title={item.original_name} placement="top" arrow>
            <ButtonGT
              size="sm"
              variant="file"
              type="button"
              className="mr-2 mt-2"
              onClick={() => handleDownload(item.attachment_id, item.original_name)}
            >
              {item.original_name_tooltip}
            </ButtonGT>
          </TooltipCustom>
        )
      })}
    </Col>
    <Col md={4} className="d-flex justify-content-end align-items-end">
      {message.canDelete && (
        <Button
          size="sm"
          className="ml-3 align-items-center"
          variant="secondary"
          type="button"
          onClick={() => onDelete(message)}
          style={{ flexShrink: 0, maxHeight: 30 }}
        >
          Excluir interação
        </Button>
      )}
    </Col>
  </Row>
)

export default function CardInterectionsMessages({ messages = [], onDelete }) {
  return messages.map((message, index) => (
    <CardGT
      key={index}
      header={
        <Header
          numMessage={message.interaction_code}
          userType={message.interaction_owner_rule}
          authorNameMessage={message.interaction_owner_name}
          authorEmailMessage={message.interaction_owner_email}
          moment={message.interaction_create_moment}
          messageWasDeleted={message.interaction_has_been_deleted}
          deletedMessage={message.deleted_interaction_message}
          messageWasRealize={message.interaction_task_new_stage_slug === 'realizada'}
          realizeMessage={message.interaction_description}
          stageSlug={message.interaction_task_new_stage_slug || ''}
        />
      }
      body={
        !message.interaction_has_been_deleted &&
        message.interaction_task_new_stage_slug !== 'realizada' && <Body message={message.interaction_description} />
      }
      footer={
        !message.interaction_has_been_deleted &&
        message.interaction_attachments && <Footer message={message} onDelete={onDelete} />
      }
      theme={
        message.interaction_has_been_deleted
          ? 'off'
          : message.interaction_task_new_stage_slug === 'cancelada'
            ? 'task-canceled'
            : message.interaction_task_new_stage_slug === 'realizada'
              ? 'task-realize'
              : 'transparent'
      }
    />
  ))
}
