import React from 'react'

function InputSearch({ id, value, placeholder, onChange }) {
  return (
    <div className="input-group input-group-solid mb-5">
      <span className="input-group-text" id={id}>
        <i className="fas fa-search"></i>
      </span>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby={id}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default InputSearch
