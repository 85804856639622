import SolicitationsDashboard from '../modules/Solicitation/pages/SolicitationsDashboard'

export default [
  /**
   * Admin (Dashboard)
   * pathname principal: /admin
   */

  {
    pathName: '/dashboard',
    exact: true,
    title: 'Tela Inicial',
    subTitle: 'Sistema de Gestão Tecnológica',
    icon: '',
    component: SolicitationsDashboard,
    //component: DashboardPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
]
