import React from 'react'
import {
  BasicSausegeCardWrapper,
  Container,
  SubText,
  TitleText,
  Link,
  SubInfoText,
  LineSeparator,
} from './BasicSausegeCard.styles'

const BasicSausegeCard = ({
  titleText,
  subText,
  textLink,
  handleClick,
  subTextInfoOne = null,
  subTextInfoTwo = null,
}) => {
  return (
    <BasicSausegeCardWrapper>
      <Container>
        <div>
          <SubText>{subText}</SubText>
          <TitleText>{titleText}</TitleText>
          {(subTextInfoOne !== null || subTextInfoTwo !== null) && (
            <>
              <LineSeparator />
              <SubInfoText>Autor: {subTextInfoOne}</SubInfoText>
              <SubInfoText>
                Unidade de Origem: <strong>{subTextInfoTwo}</strong>{' '}
              </SubInfoText>
            </>
          )}
        </div>
        {textLink && <Link onClick={() => handleClick()}>{textLink}</Link>}
      </Container>
    </BasicSausegeCardWrapper>
  )
}

export default BasicSausegeCard
