import React from 'react'
import axios from 'axios'
import { AlertMessage } from '../AlertMessage'

const handleDownload = (idDownload, original_name) => {
  const API_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  axios({
    url: `${API_BASE_URL}/api/attachment/${idDownload}/download`,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', original_name)
    document.body.appendChild(link)
    link.click()
  })
}

export const RequirementViewDataAttachmentsDownload = (props) => {
  const arrAttachments = props.attachments
  if (arrAttachments.length !== 0) {
    const listAttachments = arrAttachments.map((attachment, i) => (
      <p
        key={i}
        style={{ cursor: 'pointer' }}
        className=""
        onClick={() => handleDownload(attachment.attachment.id, attachment.attachment.original_name)}
      >
        - {attachment.attachment.original_name} {attachment.required_download ? '(download obrigatório)' : null}
      </p>
    ))

    return listAttachments
  } else {
    return (
      <AlertMessage
        variant="alert alert-custom alert-light-primary alert-dismissible"
        message="Não há arquivos anexados."
      />
    )
  }
}
