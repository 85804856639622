import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Form, Col, Row, Card, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
//import { newIntangibleAsset } from "../../../_redux/intangible_asset/IntangibleAssetEndpoints";
import { createIntangibleAsset } from '../../../_redux/intangible_asset/intangibleAssetActions'
import validationSchema from './validationSchema'
import { useSubheader } from '../../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle } from '../../../../../routes/Helpers'
import { toast } from 'react-toastify'
import {
  deleteSolicitation,
  selectIntangibleAsset,
  selectSolicitation,
  selectSolicitationInProgress,
  stepOne,
} from '../../../_redux/solicitation/solicitationActions'
import { AlertGT } from '../../../../../components'

export function CreateIntangibleAssetPage() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { lastError } = useSelector((state) => state.intangibleAssets)
  const { incompleteSolicitations } = useSelector((state) => state.solicitations)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 650,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const subheader = useSubheader()
  const pathname = window.location.pathname
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(stepOne())
    // eslint-disable-next-line
  }, [])

  function handleFormSubmit(values) {
    setConfigAlert({ show: false, ...configAlert })
    dispatch(createIntangibleAsset(user.id, values)).then((resp) => {
      if (resp.status === 'success') {
        dispatch(selectSolicitation({}))
        dispatch(selectSolicitationInProgress({}))
        dispatch(selectIntangibleAsset({ code: resp.intangibleAsset.aicode, ...resp.intangibleAsset }))
        if (incompleteSolicitations && incompleteSolicitations.id) confirmRemoveSolicition()
        toast.success(`Sucesso!  ${resp.message}`, {})
        history.push('/solicitacao/novo/etapa-dois')
      } else {
        toast.error(resp.message, {})
      }
    })
  }

  function confirmRemoveSolicition() {
    dispatch(deleteSolicitation(incompleteSolicitations))
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <Card border="secondary">
        <Card.Body>
          {lastError !== null && (
            <Alert variant="danger" className="pt-5">
              {Object.values(lastError[0]).map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </Alert>
          )}
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(createIntangibleAsset(user.id, values)).then((resp) => {
                if (resp.status === 'success') {
                  toast.success(`Sucesso!  ${resp.message}`, {})
                  history.push('/ativo-intangivel/list')
                } else {
                  toast.error(resp.message, {})
                }
              })
            }}
            initialValues={{
              title: '',
              description: '',
            }}
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    <Form.Label>Título do Ativo Intangível</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Título do Ativo Intangível"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps('title')}
                      className={formik.touched.title && formik.errors.title ? 'is-invalid' : null}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationFormik02">
                    <Form.Label>Descrição do Ativo Intangível</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      placeholder="Descrição do Ativo Intangível"
                      name="description"
                      onChange={formik.handleChange}
                      maxLength="1000"
                      {...formik.getFieldProps('description')}
                      className={formik.touched.description && formik.errors.description ? 'is-invalid' : null}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    ) : null}

                    <Form.Text className="text-right">
                      {formik.values.description.length !== 0
                        ? `${formik.values.description.length}/1000 caracteres`
                        : 'Máximo de 1000 caracteres.'}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row className="mt-0">
                  <Col className="text-left">
                    <Button type="submit" className="font-weight-bold">
                      Salvar
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {user && user.roles.includes('solicitante') && (
                      <>
                        <Button
                          type="button"
                          className="font-weight-bold"
                          onClick={(e) => {
                            formik.validateForm().then((resp) => {
                              if (Object.keys(resp).length === 0) {
                                if (incompleteSolicitations && incompleteSolicitations.id) {
                                  const content = `
        <p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
        e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
        Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
        <p>Deseja prosseguir?</p>
      `
                                  setConfigAlert({
                                    show: true,
                                    title: null,
                                    text: content,
                                    width: 650,
                                    icon: 'question',
                                    cancelButton: true,
                                    confirmButtonText: 'Prosseguir',
                                    cancelButtonText: 'Cancelar',
                                    confirm: () => handleFormSubmit(formik.values),
                                    toastSuccess: null,
                                  })
                                } else {
                                  handleFormSubmit(formik.values)
                                }
                              }
                            })
                          }}
                        >
                          Salvar e Criar Solicitação
                        </Button>{' '}
                      </>
                    )}
                  </Col>
                  <Col className="text-right">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => history.push('/ativo-intangivel/list')}
                      className="font-weight-bold"
                    >
                      <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                    </Button>{' '}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  )
}
