import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SweetAlert = withReactContent(Swal)

const AlertPassword = ({ show, onConfirm }) => {
  if (show) {
    SweetAlert.fire({
      title: 'Requisitos de segurança para o cadastro da senha de acesso',
      html: `<div style="text-align: justify;">
              <p>
                A senha de acesso deve ter:
              </p>
              <ul>
                <li>Entre 8 e 16 caracteres.</li>
                <li>Pelo menos uma letra MAIÚSCULA.</li>
                <li>Pelo menos uma letra MINÚSCULA.</li>
                <li>Pelo menos um NÚMERO.</li>
              </ul>
              <p>
                Obs.: Não utilize números sequenciais ou caracteres repetidos em sequência, nem datas comemorativas ou de nascimento em suas senhas.
              </p>
            </div>
      `,
      icon: 'info',
      width: 520,
      heightAuto: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) if (onConfirm) onConfirm()
      if (result.isDismissed) if (onConfirm) onConfirm()
      if (result.isDenied) if (onConfirm) onConfirm()
      SweetAlert.close()
    })
  }
  return null
}

export default AlertPassword
