import React, { useState } from 'react'
import {
  Wrapper,
  Label,
  Content,
  ContentYellow,
  FileList,
  FileMandatory,
  FileOptional,
  FileWrapper,
  CheckWrapper,
  LinkVerify,
} from './RequirementsContentForm.styles'
import axios from 'axios'
import CheckMarck from '../../../../../components/CheckMarck'
import {
  setMandatoryDownloadRead,
  setReadInfoMessage,
  stepThree,
} from '../../../_redux/solicitation/solicitationActions'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Tooltip } from '@material-ui/core'

const RequirementsContentForm = ({
  requirement,
  solicitationId,
  solicitationCode = null,
  userEmail = null,
  hashForm = null,
  token = null,
  formToken = null,
  route = null,
  intangibleAsset = null,
  serviceData = null,
}) => {
  const dispatch = useDispatch()
  const { encryptedListFormHash } = useSelector((state) => state.requirements)

  const API_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  const mandatoryDownloads = requirement.attachments.mandatoryDownloads
  const optionalDownloads = requirement.attachments.optionalDownloads

  let linkForm = ''
  let linkFormView = ''
  let encryptKeyForm = ''
  if (encryptedListFormHash.length > 0) {
    let result = encryptedListFormHash.find((item) => item.requirement_code === String(requirement.code))

    if (typeof result !== 'undefined') {
      linkForm = result.linkForm
      linkFormView = result.linkFormView
      encryptKeyForm = result.hash
    }
  } else {
    linkForm = ''
    linkFormView = ''
    let encryptKeyForm = ''
  }

  // const linkForm = process.env.REACT_APP_API_FORMS_URL + '/form/' + encryptKeyForm + '/block'
  // const linkFormView = process.env.REACT_APP_API_FORMS_URL + '/form/' + encryptKeyForm + '/view'

  const APP_API_FORMS_URL = process.env.REACT_APP_API_FORMS_URL

  const getFormFilledResponse = async (encryptKeyForm) =>
    await axios.get(`${APP_API_FORMS_URL}/api/form/occurrence-close/${encryptKeyForm}`)

  const setMarkElectronicFormAsCompleted = async (solId, requirementVersionId, form_hash) => {
    const authAxios = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return await authAxios.get(
      `${API_BASE_URL}/api/solicitation/form_filled/solicitation/${solId}/requirement_version/${requirementVersionId}/form_hash/${form_hash}`
    )
  }

  const [isHovered, setIsHovered] = useState(false)
  const estiloBase = {
    backgroundColor: '#D3D3D3',
    color: '#FFFFFF',
    cursor: 'pointer',
    padding: '7px 15px 7px 15px',
    marginRight: '5px',
  }
  const estiloHover = {
    backgroundColor: '#A9A9A9',
    color: '#FFFFFF',
  }

  const formFillesVerify = () => {
    getFormFilledResponse(encryptKeyForm).then((response) => {
      if (response.data.closeMoment !== false) {
        setMarkElectronicFormAsCompleted(solicitationId, requirement.requirement_version_id, hashForm).then(
          (response) => {
            dispatch(stepThree(solicitationId))
            toast.success(`Sucesso! O preenchimento do formulário do requisito ${requirement.code} foi confirmado.`, {})
          }
        )
      } else {
        toast.warning(`O Formulário do requisito ${requirement.code} ainda não foi plenamente preenchido.`, {})
      }
    })
  }

  const handleLinkFormStatus = () => {
    if (
      requirement.requirement_was_answered === false &&
      requirement.type.slug === 'formulario-eletronico' &&
      (route === '/solicitacao/novo/etapa-tres' || route === '/solicitacao/novo/etapa-tres/admin-unidade')
    ) {
      formFillesVerify()
    } else {
      console.log('Requisito já atendido!')
    }
  }

  const handleDownload = (downloadId, originalName) => {
    const API_BASE_URL =
      `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_API_URL_HOMOL}`
        : `${process.env.REACT_APP_API_URL_PROD}`

    axios({
      url: `${API_BASE_URL}/api/attachment/${downloadId}/download`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', originalName)
      document.body.appendChild(link)
      link.click()
    })
  }

  return (
    <Wrapper>
      <Label>
        {requirement.type.slug === 'informativo' && 'Leia com atenção o informativo abaixo:'}
        {requirement.type.slug === 'formulario-eletronico' && requirement.requirement_was_answered === false
          ? 'Acesse o link abaixo e responda o formulário:'
          : null}
      </Label>

      {requirement.type.slug === 'informativo' && (
        <>
          <Content>{requirement.info_message}</Content>
          {requirement.mandatory_reading && (
            <>
              <CheckWrapper>
                <Form.Check
                  id="checkbox-informative"
                  label="Li, entendi e aceito as informações dispostas pelo informativo acima"
                  name="checkbox-informative"
                  type="checkbox"
                  defaultChecked={requirement.read_confirmation_was_answered}
                  disabled={requirement.read_confirmation_was_answered}
                  onClick={(e) => {
                    if (!requirement.read_confirmation_was_answered) {
                      dispatch(
                        setReadInfoMessage({
                          requirementVersionId: requirement.requirement_version_id,
                          solicitationId: solicitationId,
                        })
                      )
                    }
                  }}
                />
                {requirement.read_confirmation_was_answered && <CheckMarck className="check-marck" />}
              </CheckWrapper>
              <hr />
            </>
          )}
        </>
      )}
      {requirement.type.slug === 'formulario-eletronico' && (
        <>
          <Content>
            {requirement.requirement_was_answered === false && (
              <>
                <a href={linkForm} target="_blank" rel="noopener noreferrer">
                  {requirement.code} - {requirement.description}
                </a>
              </>
            )}
            {requirement.requirement_was_answered && (
              <>
                <Row>
                  <Col sm={10}>
                    <Tooltip title="Visualizar Formulário Preenchido" placement="top">
                      <a href={linkFormView} target="_blank" rel="noopener noreferrer">
                        {requirement.code} - {requirement.description}
                      </a>
                    </Tooltip>
                  </Col>
                  <Col sm={2} className="text-right">
                    <Tooltip title="Visualizar Formulário Preenchido" placement="top">
                      <Button
                        variant="secondary"
                        size="sm"
                        href={linkFormView}
                        target="_blank"
                        style={isHovered ? { ...estiloBase, ...estiloHover } : estiloBase}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <i className="fas fa-search text-dark"></i>
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </>
            )}
          </Content>
          {requirement.requirement_was_answered === false && (
            <>
              <Row className="mt-2"></Row>
              <ContentYellow>
                <Row className="mt-1">
                  <Col>
                    <div onClick={() => handleLinkFormStatus()}>
                      Já terminou de preencher este formulário?{' '}
                      <LinkVerify className="text-blue">
                        <strong>Se sim, clique aqui para confirmar o preenchimento.</strong>
                      </LinkVerify>
                    </div>
                  </Col>
                </Row>
              </ContentYellow>
            </>
          )}
          <hr />
        </>
      )}

      {mandatoryDownloads.length > 0 && (
        <>
          <Label>Arquivos para leitura obrigatória:</Label>
          <FileList>
            {mandatoryDownloads.map((fileMandatory, index) => (
              <FileWrapper key={`file-optional-${index}`}>
                <FileMandatory
                  onClick={() => {
                    handleDownload(fileMandatory.id, fileMandatory.original_name)
                    if (!fileMandatory.mandatory_download_was_answered) {
                      dispatch(
                        setMandatoryDownloadRead({
                          requirementVersionId: requirement.requirement_version_id,
                          attachmentId: fileMandatory.id,
                          solicitationId: solicitationId,
                        })
                      )
                    }
                  }}
                >
                  {fileMandatory.original_name}
                </FileMandatory>
                {fileMandatory.mandatory_download_was_answered && <CheckMarck className="check-marck" />}
              </FileWrapper>
            ))}
          </FileList>
        </>
      )}

      {optionalDownloads.length > 0 && (
        <>
          <Label>Arquivos para leitura falcultativa:</Label>
          <FileList>
            {optionalDownloads.map((fileOptional, index) => (
              <FileOptional
                key={`file-optional-${index}`}
                onClick={() => {
                  handleDownload(fileOptional.id, fileOptional.original_name)
                }}
              >
                {fileOptional.original_name}
              </FileOptional>
            ))}
          </FileList>
        </>
      )}
    </Wrapper>
  )
}

export default RequirementsContentForm
