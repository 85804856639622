import styled from 'styled-components'

export const ActionButtonsWrapper = styled.div`
  padding-top: 20px;
  /*border-bottom: 2px solid #ebebeb;*/
  width: 100%;
  /*margin-bottom: 20px;*/
  display: flex;
  flex-wrap: wrap;
  align-itens: strech;
  align-content: strech;
`
export const ButtonWrapper = styled.div`
  margin: 15px;
`

export const AlertWrapper = styled.div`
  width: 100%;
`
