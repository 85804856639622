import * as React from 'react'
import { styled } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

export default styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: 12,
    fontFamily: 'Open Sans'
  },
}))
