import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Button, Row, Col } from 'react-bootstrap'

import { useSubheader } from '../../../../../_metronic/layout'

import { useDispatch, useSelector } from 'react-redux'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { userViewDataAction } from '../../_redux/admin/adminActions'

function UserView() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id1: id } = useParams()

  React.useEffect(() => {
    dispatch(userViewDataAction(id))
    // eslint-disable-next-line
  }, [id])

  const { userViewData } = useSelector((state) => state.admin)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  return (
    <>
      <Card border="secondary">
        <div className="py-9 p-10">
          <div className="mt-4">
            <span className="font-weight-bolder mr-2" style={{ fontSize: '19px' }}>
              Dados pessoais
            </span>
          </div>
          <hr />
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Nome Completo:</span>
            </Col>
            <Col sm={9}>
              <span>{userViewData.name}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">E-mail:</span>
            </Col>
            <Col sm={9}>
              <span>{userViewData.email}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">CPF:</span>
            </Col>
            <Col sm={9}>
              <span>{userViewData.cpf}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Unidade de Alocação:</span>
            </Col>
            <Col sm={9}>
              <span>
                {userViewData.unit} ({userViewData.unitInitials})
              </span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Telefone:</span>
            </Col>
            <Col sm={9}>
              <span>{userViewData.phone}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Data de Nascimento:</span>
            </Col>
            <Col sm={9}>
              <span>{userViewData.dateOfBirth}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Primeiro acesso:</span>
            </Col>
            <Col sm={9}>
              <span>{userViewData.dateOfCreateOnSystem}</span>
            </Col>
          </Row>

          <div style={{ fontSize: '19px' }} className="mt-10">
            <div className="mt-4">
              <span className="font-weight-bolder mr-2">Perfis</span>
            </div>
          </div>
          <hr />

          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col>
              <span>{userViewData.profiles}</span>
            </Col>
          </Row>
        </div>
        <Card.Footer>
          <Row className="mt-0">
            <Col className="text-right">
              <Button
                variant="secondary"
                onClick={() => {
                  history.goBack()
                }}
              >
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}

export default UserView
