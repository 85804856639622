import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Alert, Col, Row, Button } from 'react-bootstrap'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { toast } from 'react-toastify'
import {
  getSolicitationManagement,
  receiveSolicitation,
  startAttendance,
  cancelSolicitation,
  abandonAttendance,
  completeSolicitation,
  approveAttendanceSolicitation,
  rejectAttendanceSolicitation,
  cleanSaveStatus,
  getAttendancesOpened,
} from '../../_redux/attendances/attendanceActions'
import HeaderSolicitationManagement from '../../components/attendances/HeaderSolicitationManagement'
import SolicitationManagementActionButton from '../../components/attendances/SolicitationManagementActionButtons'
import { AlertGT, CardGT } from '../../../../components'
import LoadingSpinner from '../../../../components/LoadingSpinner'

import CancelSolicitationForm from '../../components/attendances/ComponentsModalForms/CancelSolicitationForm'
import AbandonAttendanceForm from '../../components/attendances/ComponentsModalForms/AbandonAttendanceForm'
import CompleteAttendanceForm from '../../components/attendances/ComponentsModalForms/CompleteAttendanceForm'
import ReproveAttendanceForm from '../../components/attendances/ComponentsModalForms/ReproveAttendanceForm'
import { clearSaveError } from '../../_redux/solicitation/solicitationActions'
import TooltipCustom from '../../../../components/TooltipCustom'
import handleDownload from '../../../../Utils/downloadFile'

const SolicitationManagementPage = () => {
  const { id1: solicitationId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { saveError, saveStatus, listLoading, statusMessage, solicitationSelected } = useSelector(
    (state) => state.attendance
  )

  useEffect(() => {
    dispatch(getSolicitationManagement(solicitationId))
    localStorage.setItem('cancelDescription', '')
    localStorage.setItem('rejectMessage', '')
    localStorage.setItem('abandonDescription', '')
    localStorage.setItem('concludingOpinion', '')
    localStorage.setItem('completeAttachments', JSON.stringify([]))
    // eslint-disable-next-line
  }, [solicitationId])

  const subheader = useSubheader()
  const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  const [attendanceComplete, setAttendanceComplete] = useState(null)

  const [configAlert, setConfigAlert] = useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
    closeToast: false,
  })

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(clearSaveError())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(statusMessage, {})
      dispatch(cleanSaveStatus())

      if (attendanceComplete !== null) {
        dispatch(getAttendancesOpened())
        dispatch(getSolicitationManagement(solicitationId))
        localStorage.setItem('cancelDescription', '')
        localStorage.setItem('rejectMessage', '')
        localStorage.setItem('abandonDescription', '')
        localStorage.setItem('concludingOpinion', '')
        localStorage.setItem('completeAttachments', JSON.stringify([]))
        setAttendanceComplete(null)
      }
    }
    // eslint-disable-next-line
  }, [saveStatus])

  const hasSolicitationSelected = !!solicitationSelected

  const handleReceiveSolicitation = () => {
    if (solicitationSelected.stage.slug === 'aguardando-acolhimento') {
      const content = `<p>Deseja realmente acolher a solicitação <strong>${solicitationSelected.code}</strong>?</p>`
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        width: 600,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirm: () => dispatch(receiveSolicitation(solicitationId)),
        toastSuccess: null,
      })
    }
  }

  const handleStartAttendance = () => {
    if (
      solicitationSelected.stage.slug === 'aguardando-atendimento' ||
      solicitationSelected.stage.slug === 'atendimento-com-pendencia'
    ) {
      const content = `<p>Deseja ${
        solicitationSelected.stage.slug === 'atendimento-com-pendencia' ? 'retomar' : 'realmente iniciar'
      }  o atendimento da solicitação <strong>${solicitationSelected.code}</strong>?</p>`
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        width: 600,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirm: () => dispatch(startAttendance(solicitationId)),
        toastSuccess: null,
      })
    }
  }

  const handleCancelSolicitation = () => {
    if (
      solicitationSelected.stage.slug !== 'aguardando-concluido' &&
      solicitationSelected.stage.slug !== 'cancelada' &&
      solicitationSelected.stage.slug !== 'encerrada'
    ) {
      const handleDispatch = (cancelDescription) => {
        dispatch(cancelSolicitation(solicitationId, cancelDescription))
      }

      const content = () => (
        <CancelSolicitationForm
          configAlert={configAlert}
          setConfigAlert={setConfigAlert}
          solicitationSelected={solicitationSelected}
          handleDispatch={handleDispatch}
        />
      )
      setConfigAlert({
        show: true,
        title: 'Cancelamento da Solicitação',
        text: content(),
        width: 900,
        icon: null,
        cancelButton: true,
        confirmButtonText: null,
        cancelButtonText: null,
        confirm: () => {},
        toastSuccess: null,
        showConfirmButton: false,
      })
    }
  }

  const handleAbandonAttendance = () => {
    if (
      solicitationSelected.stage.slug !== 'aguardando-concluido' &&
      solicitationSelected.stage.slug !== 'cancelada' &&
      solicitationSelected.stage.slug !== 'encerrada'
    ) {
      const handleDispatch = (abandonDescription) => {
        dispatch(abandonAttendance(solicitationId, abandonDescription))
          .then((resp) => {
            history.push(`/solicitacao/atendimentos`)
          })
          .catch((error) => toast.error(error.response.data.message))
      }

      const content = () => (
        <AbandonAttendanceForm
          configAlert={configAlert}
          setConfigAlert={setConfigAlert}
          solicitationSelected={solicitationSelected}
          handleDispatch={handleDispatch}
          history={history}
        />
      )

      setConfigAlert({
        show: true,
        title: 'Abandono do Atendimento',
        text: content(),
        width: 900,
        icon: null,
        cancelButton: true,
        confirmButtonText: null,
        cancelButtonText: null,
        confirm: () => {},
        toastSuccess: null,
        showConfirmButton: false,
      })
    }
  }

  const handleCompleteAttendance = () => {
    if (solicitationSelected.stage.slug === 'em-atendimento') {
      const handleDispatch = (objectCompleteSolicitation) => {
        dispatch(completeSolicitation(solicitationId, objectCompleteSolicitation))
        setAttendanceComplete('completed')
        history.push(`/solicitacao/atendimentos`)
      }

      const content = () => (
        <CompleteAttendanceForm
          configAlert={configAlert}
          setConfigAlert={setConfigAlert}
          solicitationSelected={solicitationSelected}
          handleDispatch={handleDispatch}
        />
      )

      setConfigAlert({
        show: true,
        title: 'Conclusão do Atendimento',
        text: content(),
        width: 900,
        icon: null,
        cancelButton: true,
        confirmButtonText: null,
        cancelButtonText: null,
        confirm: () => {},
        toastSuccess: null,
        showConfirmButton: false,
      })
    }
  }

  const handleApproveAttendance = () => {
    if (solicitationSelected.stage.slug === 'atendimento-concluido') {
      const content = `<p>Deseja realmente aprovar o atendimento realizado para a solicitação <strong>${solicitationSelected.code}</strong>?</p>`
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        width: 600,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirm: () => dispatch(approveAttendanceSolicitation(solicitationId)),
        toastSuccess: null,
      })
    }
  }

  const handleReproveAttendance = () => {
    if (solicitationSelected.stage.slug === 'atendimento-concluido') {
      const handleDispatch = (rejectMessage) => {
        dispatch(rejectAttendanceSolicitation(solicitationId, rejectMessage))
      }

      const content = () => (
        <ReproveAttendanceForm
          configAlert={configAlert}
          setConfigAlert={setConfigAlert}
          solicitationSelected={solicitationSelected}
          handleDispatch={handleDispatch}
        />
      )

      setConfigAlert({
        show: true,
        title: 'Lançamento de Pendência no Atendimento',
        text: content(),
        width: 800,
        icon: null,
        cancelButton: true,
        confirmButtonText: null,
        cancelButtonText: null,
        confirm: () => {},
        toastSuccess: null,
        showConfirmButton: false,
      })
    }
  }
  const actionsMethods = {
    receiveSolicitation: handleReceiveSolicitation,
    startAttendance: handleStartAttendance,
    cancelSolicitation: handleCancelSolicitation,
    abandonAttendance: handleAbandonAttendance,
    completeAttendance: handleCompleteAttendance,
    approveAttendance: handleApproveAttendance,
    reproveAttendance: handleReproveAttendance,
  }
  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      {hasSolicitationSelected && (
        <Card border="secondary">
          <Card.Body>
            <>
              {saveError.length > 0 && (
                <Alert variant="danger">
                  {Object.values(saveError[0]).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                  ))}
                </Alert>
              )}
              <Row>
                <Col className="text-right"></Col>
              </Row>
              <Row className="m-0" style={{ width: '100%' }}>
                <HeaderSolicitationManagement
                  code={solicitationSelected.code}
                  stage={solicitationSelected.stage}
                  actual_attendant={solicitationSelected.actual_attendant}
                  organizationsInitials={solicitationSelected.solicitation_organization.initials}
                  itemsLittleSausage={[
                    solicitationSelected.parentType === 'Solicitation'
                      ? {
                          type: 'solicitation',
                          code: solicitationSelected.parent.code,
                          title:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description.length > 70
                                ? `${solicitationSelected.parent.description.substring(0, 70)} ...`
                                : solicitationSelected.parent.description
                              : solicitationSelected.parent.title.length > 70
                              ? `${solicitationSelected.parent.title.substring(0, 70)}...`
                              : solicitationSelected.parent.title,
                          titleModal:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.title,
                          description:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.description,
                          serviceCode: solicitationSelected.service.code,
                          serviceTitle: solicitationSelected.service.title,
                          solicitationSlug:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.slug
                              : '',
                          solicitationStage:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.stage
                              : '',
                          solicitationOrganizationInitials:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.organization.initials
                              : '',
                          momentByUser:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.momentByUser
                              : '',
                          solicitationDescription: solicitationSelected.description,
                        }
                      : {
                          type: 'intangible-asset',
                          code: solicitationSelected.intangibleAssetOriginParent.code,
                          title: solicitationSelected.intangibleAssetOriginParent.title,
                          titleModal: solicitationSelected.service.title,
                          description: solicitationSelected.intangibleAssetOriginParent.description,
                          momentByUser: solicitationSelected.intangibleAssetOriginParent.moment_by_user,
                          organizationInitials: solicitationSelected.intangibleAssetOriginParent.organization_initials,
                        },

                    {
                      type: 'service',

                      code: solicitationSelected.service.code,
                      title:
                        solicitationSelected.service.title.length > 70
                          ? `${solicitationSelected.service.title.substring(0, 70)}...`
                          : solicitationSelected.service.title,
                      serviceCode: solicitationSelected.service.code,
                      serviceTitle: solicitationSelected.service.title,
                      titleModal: solicitationSelected.service.title,
                      serviceDescription: solicitationSelected.service.description,
                    },
                  ]}
                />
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="card card-custom">
                    <div className="card-header py-0 px-0 border-0">
                      <div className="card-body py-4 px-4 border-0">
                        <p className="text-black-50 font-weight-normal">Descrição da Solicitação</p>
                        <p className="text-black text-justify">{solicitationSelected.description}</p>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-between py-4 px-4 bg-secondary">
                      <strong>
                        {solicitationSelected.momentByUser}
                        {solicitationSelected.complementCreatedByUnitAdmin ? (
                          <span className="ml-1">{solicitationSelected.complementCreatedByUnitAdmin}</span>
                        ) : (
                          '.'
                        )}
                      </strong>
                    </div>
                  </div>
                </Col>
              </Row>
              {solicitationSelected.hasAttendanceStatus && (
                <Row>
                  <Col sm={12}>
                    <CardGT
                      header={
                        <div className="d-flex justify-content-between">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: solicitationSelected.attendanceStatus.titleAttendanceStatus,
                            }}
                          />
                          <span>{solicitationSelected.attendanceStatus.momentAttendanceStatus}</span>
                        </div>
                      }
                      body={
                        <>
                          <p>
                            Autor: <strong>{solicitationSelected.attendanceStatus.authorRole}</strong>{' '}
                            {solicitationSelected.attendanceStatus.authorNameEmail}
                          </p>
                          <p>{solicitationSelected.attendanceStatus.message}</p>
                        </>
                      }
                      footer={
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-start flex-wrap">
                            {solicitationSelected.attendanceStatus.attachments.map((item, index) => {
                              return (
                                <TooltipCustom key={index} title={item.original_name_tooltip} placement="top" arrow>
                                  <Button
                                    size="sm"
                                    variant="secondary"
                                    type="button"
                                    className="mr-3 mb-3"
                                    onClick={() => handleDownload(item.id, item.original_name)}
                                  >
                                    {item.original_name}
                                  </Button>
                                </TooltipCustom>
                              )
                            })}
                          </div>
                          <div className="d-flex justify-content-end">
                            {/**
                             *  <Button
                             *    variant="secondary"
                             *    type="button"
                             *    href={`/solicitacao/${solicitationSelected.id}/forum-da-solicitacao`}
                             *  >
                             *    Acessar Fórum
                             *  </Button>
                             */}
                          </div>
                        </div>
                      }
                      theme={solicitationSelected.attendanceStatus.solicitationMomentStageSlug}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                {listLoading ? (
                  <LoadingSpinner />
                ) : (
                  <SolicitationManagementActionButton
                    solicitationStage={solicitationSelected.stage.slug}
                    solicitationUserCanCancel={solicitationSelected.userCanCancelSolicitation}
                    solicitationId={solicitationSelected.id}
                    userIsAdminThisService={solicitationSelected.service.userIsAdminThisService}
                    userIsAttendentThisService={solicitationSelected.service.userIsAttendentThisService}
                    userIsRequesterThisSolicitation={solicitationSelected.userIsRequesterThisSolicitation}
                    actionsMethods={actionsMethods}
                    userIsAttendentThisSolicitation={solicitationSelected.userIsAttendentThisSolicitation}
                    serviceId={solicitationSelected.service.id}
                    requesterCanCancelSolicitation={solicitationSelected.requesterCanCancelSolicitation}
                    unreadForumPosts={solicitationSelected.unreadForumPosts}
                  />
                )}
              </Row>
            </>
          </Card.Body>
          <Card.Footer>
            <Row className="mt-0">
              <Col className="text-right">
                <Button variant="secondary" type="button" onClick={() => history.goBack()} className="font-weight-bold">
                  <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </>
  )
}

export default SolicitationManagementPage
