import React, { useEffect } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../routes/Helpers'
import TooltipCustom from '../../../components/TooltipCustom'

import { AlertGT, Button, Table, ReadMore, BadgeGT, AlertDotTaskStage } from '../../../components'
import { cleanSaveStatus, getSolicitationManagement } from '../_redux/attendances/attendanceActions'
import HeaderSolicitationManagement from '../components/attendances/HeaderSolicitationManagement'
import { getTasks } from '../_redux/solicitation/solicitationActions'
import { NavLink } from 'react-router-dom'
import AlertTask from '../../../components/AlertTask'
import { cancelTask, setTaskRegisterData, setTaskUpdateData } from '../../Tasks/_redux/tasks/tasksActions'
import CancelTaskForm from '../../Tasks/components/CancelTaskForm'
import { toast } from 'react-toastify'

const orderByOptions = [
  { value: 'task_code', text: 'Código da Tarefa' },
  { value: 'task_description', text: 'Descrição da Tarefa' },
  { value: 'task_create_date', text: 'Criação' },
  { value: 'task_stage_slug', text: 'Estágio' },
]

function SolicitationTasks() {
  const { id1: solicitationId } = useParams()
  const { solicitationSelected } = useSelector((state) => state.attendance)
  const { tasks, listLoading, saveError } = useSelector((state) => state.solicitations)
  const { statusMessage, saveStatus } = useSelector((state) => state.tasks)
  const { user } = useSelector((state) => state.auth)

  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('')
  const [ordering, setOrdering] = React.useState('desc')
  const [showAlertDotStage, setShowAlertDotStage] = React.useState(false)
  const [showAlertTask, setShowAlertTask] = React.useState(false)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    width: 600,
    icon: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })
  const arrBreadCrumb = [
    {
      pathname: '/dashboard',
      title: 'Home',
      subTitle: '',
      icon: '',
    },
  ]

  const history = useHistory()
  const dispatch = useDispatch()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    dispatch(getSolicitationManagement(solicitationId))
    dispatch(setTaskRegisterData(null))
    dispatch(setTaskUpdateData(null))
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getTasks(solicitationId, perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [dispatch, pathname, solicitationId, perPage, page, search, sortField, ordering])
  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(statusMessage, {})
      dispatch(cleanSaveStatus())
    }
    // eslint-disable-next-line
  }, [saveStatus])

  const hasSolicitationSelected = !!solicitationSelected

  const handleCancelTaskModal = (task_id, task_data) => {
    const handleDispatch = (description) => {
      dispatch(
        cancelTask({
          taskId: task_id,
          description: description,
        })
      )
      dispatch(getTasks(solicitationId, perPage, page, search, sortField, ordering))
    }
    const content = () => (
      <CancelTaskForm
        configAlert={configAlert}
        setConfigAlert={setConfigAlert}
        task={task_data}
        handleDispatch={handleDispatch}
      />
    )
    setConfigAlert({
      show: true,
      title: 'Cancelamento da Tarefa',
      text: content(),
      width: 900,
      icon: null,
      cancelButton: null,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {},
      toastSuccess: '',
      showConfirmButton: false,
    })
  }

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'task_code',
      text: 'Código',
      sort: false,
      align: 'center',
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <strong style={{ fontSize: '0.85rem' }}>{row.task_code}</strong>
        </>
      ),
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'task_description',
      text: 'Descrição da Tarefa',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => <ReadMore text={row.task_description} maxCharacters={70} />,
      headerStyle: { width: '50%', textAlign: 'center' },
    },
    {
      dataField: 'task_create_date',
      text: 'Criação',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <p className="mb-0">{row.task_create_date}</p>
          <strong style={{ fontSize: '0.85rem' }}>{row.task_create_date_for_humans}</strong>
        </>
      ),
      headerStyle: { width: '20%', textAlign: 'center' },
    },
    {
      dataField: 'task_creator_id',
      text: 'Criador',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <p className="mb-0">{row.task_creator_name}</p>
          <strong style={{ fontSize: '0.85rem' }}>{row.task_creator_email}</strong>
        </>
      ),
      headerStyle: { width: '30%', textAlign: 'center' },
    },
    {
      dataField: 'task_stage_slug',
      text: 'Estágio',
      align: 'right',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.task_stage} placement="top" arrow>
          <div className="text-center">
            <BadgeGT variant={row.task_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: '5%', textAlign: 'right' },
    },
  ]

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-edit fa-5x"></i>,
      title: 'Atualizar Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}/editar`),
      visible: (row) => (row.task_editable ? true : false),
    },
    {
      text: <i className="fas fa-ban fa-5x"></i>,
      title: 'Cancelar Tarefa',
      onClick: (row) => {
        handleCancelTaskModal(row.task_id, row)
      },
      visible: (row) => row.task_stage_slug === 'em-andamento' && row.task_creator_id === user.id,
    },
    {
      text: <i className="fas fa-search  fa-5x"></i>,
      title: 'Consultar Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}`),
      visible: (row) => row.task_stage_slug === 'realizada' || row.task_stage_slug === 'cancelada',
    },
    {
      text: <i className="fas fa-hand-point-up fa-5x"></i>,
      title: 'Interagir com a Tarefa',
      onClick: (row) => history.push(`/tarefa/${row.task_id}`),
      visible: (row) => row.task_stage_slug === 'em-andamento',
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      <AlertDotTaskStage
        show={showAlertDotStage}
        onConfirm={() => setShowAlertDotStage(false)}
        onDismissed={() => setShowAlertDotStage(false)}
      />
      <AlertTask
        show={showAlertTask}
        onConfirm={() => setShowAlertTask(false)}
        onDismissed={() => setShowAlertTask(false)}
      />
      {hasSolicitationSelected && (
        <Card border="secondary" className="pt-5 pr-5">
          <Card.Body>
            <Container>
              {saveError.length > 0 && (
                <Alert variant="danger">
                  {Object.values(saveError[0]).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                  ))}
                </Alert>
              )}
              <Row>
                <Col className="text-right"></Col>
              </Row>
              <Row className="m-0" style={{ width: '100%' }}>
                <HeaderSolicitationManagement
                  code={solicitationSelected.code}
                  stage={solicitationSelected.stage}
                  actual_attendant={solicitationSelected.actual_attendant}
                  organizationsInitials={solicitationSelected.solicitation_organization.initials}
                  itemsLittleSausage={[
                    solicitationSelected.parentType === 'Solicitation'
                      ? {
                          type: 'solicitation',
                          code: solicitationSelected.parent.code,
                          title:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description.length > 70
                                ? `${solicitationSelected.parent.description.substring(0, 70)} ...`
                                : solicitationSelected.parent.description
                              : solicitationSelected.parent.title.length > 70
                              ? `${solicitationSelected.parent.title.substring(0, 70)}...`
                              : solicitationSelected.parent.title,
                          titleModal:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.title,
                          description:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.description,
                          serviceCode: solicitationSelected.service.code,
                          serviceTitle: solicitationSelected.service.title,
                          solicitationSlug:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.slug
                              : '',
                          solicitationStage:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.stage
                              : '',
                          solicitationOrganizationInitials:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.organization.initials
                              : '',
                          momentByUser:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.momentByUser
                              : '',
                          solicitationDescription: solicitationSelected.description,
                        }
                      : {
                          type: 'intangible-asset',
                          code: solicitationSelected.intangibleAssetOriginParent.code,
                          title: solicitationSelected.intangibleAssetOriginParent.title,
                          titleModal: solicitationSelected.service.title,
                          description: solicitationSelected.intangibleAssetOriginParent.description,
                          momentByUser: solicitationSelected.intangibleAssetOriginParent.moment_by_user,
                          organizationInitials: solicitationSelected.intangibleAssetOriginParent.organization_initials,
                        },

                    {
                      type: 'service',

                      code: solicitationSelected.service.code,
                      title:
                        solicitationSelected.service.title.length > 70
                          ? `${solicitationSelected.service.title.substring(0, 70)}...`
                          : solicitationSelected.service.title,
                      serviceCode: solicitationSelected.service.code,
                      serviceTitle: solicitationSelected.service.title,
                      titleModal: solicitationSelected.service.title,
                      serviceDescription: solicitationSelected.service.description,
                    },
                  ]}
                />
              </Row>
              <Row>
                <Col sm={12}>
                  <p>Descrição da Solicitação:</p>
                  <p>{solicitationSelected.description}</p>
                  <strong>
                    <p>{solicitationSelected.momentByUser}</p>
                  </strong>
                  <hr />
                </Col>
              </Row>
              <Row className="mb-5">
                <Col className="d-flex justify-content-end">
                  <NavLink to="#" className="pl-5" onClick={() => setShowAlertTask(true)}>
                    O que é uma tarefa?
                  </NavLink>
                  <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotStage(true)}>
                    Legenda dos Estágios da Tarefa
                  </NavLink>
                </Col>
              </Row>
              <Table
                data={tasks.data}
                noDataMessage="Não existem tarefas nesta solicitação."
                columns={columns}
                page={tasks.current_page}
                perPage={perPage}
                totalSize={tasks.total}
                orderByOptions={orderByOptions}
                orderByOnChange={(field) => setSortField(field)}
                orderByDirectionChange={(direction) => setOrdering(direction)}
                onSizePerPageChange={(perPage) => {
                  setPerPage(perPage)
                  setPage(1)
                }}
                onPageChange={(page) => setPage(page)}
                onTableChange={() => null}
                searchPlaceholder="Pesquise aqui"
                perPagePosition="top"
                actions={actionsTable}
                onSearch={(term) => setSearch(term)}
                searchValue={search}
                loading={listLoading}
              />
              <Row className="mt-5 mb-0">
                <Col md={12}>
                  {(user.roles.includes('solicitante') ||
                    user.roles.includes('admin') ||
                    user.roles.includes('atendente') ||
                    user.roles.includes('admin-servico')) &&
                    (solicitationSelected.stage.slug === 'aguardando-atendimento' ||
                      solicitationSelected.stage.slug === 'em-atendimento' ||
                      solicitationSelected.stage.slug === 'atendimento-com-pendencia') && (
                      <Button
                        variant="primary"
                        className="mr-2"
                        onClick={() => {
                          history.push(`/solicitacao/${solicitationId}/tarefa/novo`)
                        }}
                      >
                        Criar Tarefa
                      </Button>
                    )}
                </Col>
              </Row>
            </Container>
          </Card.Body>

          <Card.Footer>
            <Container>
              <Row>
                <Col md={12}>
                  <p>
                    Para acessar as tarefas que você criou ou as demais tarefas que você recebeu, escolha um dos botões
                    abaixo.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Button
                    variant="secondary"
                    className="mr-2"
                    onClick={() => {
                      history.push(`/tarefas/criadas/${user.id}`)
                    }}
                  >
                    Tarefas Criadas
                  </Button>
                  <Button
                    variant="secondary"
                    className="mr-2"
                    onClick={() => {
                      history.push(`/tarefas/recebidas/${user.id}`)
                    }}
                  >
                    Tarefas Recebidas
                  </Button>
                </Col>
                <Col md={4} className="text-right">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      history.push(`/solicitacao/gerencia-da-solicitacao/${solicitationId}`)
                    }}
                  >
                    <i className="fa fa-chevron-left"></i>&nbsp; Voltar
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Footer>
        </Card>
      )}
    </>
  )
}

export default SolicitationTasks
