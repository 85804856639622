import Admin from './Admin'
import Audit from './Audit'
import Dashboards from './Dashboards'
import Faq from './Faq'
import IntangibleAssets from './IntangibleAssets'
import Reports from './Reports'
import Services from './Services'
import Solicitations from './Solicitations'
import System from './System'
import Tasks from './Tasks'

const routes = [].concat(
  Admin,
  Audit,
  Dashboards,
  Faq,
  IntangibleAssets,
  Reports,
  Services,
  Solicitations,
  System,
  Tasks
)

/**
 * getAllroutes
 *
 * Esta função retorna o array de objetos de rotas.
 * @returns array Object
 */

export const getAllRoutes = () => routes

/**
 * getAllRoutesWithoutComponents
 *
 * Esta função retorna o array objetos de rotas (routes), sem a propriedade component
 * @returns array Object
 */
export const getAllRoutesWithoutComponents = () => {
  return routes.map((route) => {
    return {
      pathName: route.pathName,
      exact: route.exact,
      title: route.title,
      subTitle: route.subTitle,
      icon: route.icon,
      permission: route.permission,
    }
  })
}

/**
 * getAllBreadcrumbs
 *
 * Esta função retorna um objeto contendo array de objeto que é usado no componente <BreadCrumbs />
 * do template Metronic.
 *
 * Para utilizá-lo, faça o import do modulo: import { useSubheader } from ".../_metronic/layout";
 * e instancie na page da seguinte forma: subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
 * Recomenda-se instancia-lo dentro do hooks useEffect() para que seja configurado na montagem
 * da page.
 *
 * Ela depende de um parâmetro real_pathname que é o pathname da pagina
 * e pode ser obtido com o comando: window.location.pathname
 *
 * @param {*} real_pathname //pathname da page atual: window.location.pathname
 * @returns
 */
export const getAllBreadcrumbs = (real_pathname) => {
  /**
   * Obtem o pathname real da janela
   */
  //const real_pathname = window.location.pathname

  /**
   * Instancia o array arr e arrBreadCrumbs
   */
  const arr = []
  const arrBreadCrumbs = []

  if (real_pathname !== '/dashboard') {
    const routHomePage = routes.find((route) => route.pathName === '/dashboard')

    arrBreadCrumbs.push({
      pathname: routHomePage.pathName,
      title: 'Home',
      subTitle: routHomePage.subTitle,
      icon: '',
    })
  }

  const numParams = [1, 2, 3]
  /**
   * Percorre o array formado pelo split de real_pathname separados por /
   */
  for (let index = 0; index < real_pathname.split('/').length; index++) {
    /**
     * Quando o item do array for um número,  substitui ele por :id (parâmetro da url para busca no array de objetos getAllRoutesWithoutComponents())
     */

    arr.push(
      !isNaN(real_pathname.split('/')[index]) && index !== 0 ? `:id${numParams[0]}` : real_pathname.split('/')[index]
    )

    if (!isNaN(real_pathname.split('/')[index]) && index !== 0) {
      numParams.shift()
    }
    /**
     * Em uma constante resultado,  coloca o resultado da busca do item de array no array de objetos getAllRoutesWithoutComponents()
     */

    const resultado = getAllRoutesWithoutComponents().find((item) => item.pathName === `${arr.join('/')}`)

    /**
     * Se encontrar o item no array...
     */
    if (typeof resultado !== 'undefined') {
      /**
       * cria um array vazio com o nome de arrBredCrumbsPathname
       * e uma variael item para adicionar o item do array
       */
      let arrBredCrumbsPathname = []
      let item = null
      const itemParams = [1, 2, 3]

      for (let i = 0; i < arr.length; i++) {
        /**
         * Percorre o array criado para realizar a busca no objeto getAllRoutesWithoutComponents(),
         * substituindo os parâmetros (:id) pelo numero correspondente em real_pathname
         */
        if ((item = arr[i] === `:id${itemParams[0]}`)) {
          item = real_pathname.split('/')[i]
          itemParams.shift()
        } else {
          item = arr[i]
        }

        //Adiciona o item no array arrBredCrumbsPathname
        arrBredCrumbsPathname.push(item)
      }

      /**
       * Cria um array que será utizado para popular o componente <BreadCrumbs items={subheader.breadcrumbs} />
       * Ele é instanciado no primeiro carregamento do component através do hook useEffect do react e o custom hook
       * useSubheader do template
       *
       * imports:
       * import { useSubheader } from "../../../../../../_metronic/layout";
       * import { getAllBreadcrumbs } from "../../../../../breadcrumbItems";
       *
       * No component:
       *   useEffect(() => {
       *     subheader.setBreadcrumbs(getAllBreadcrumbs())
       *   // eslint-disable-next-line
       *   }, []);
       */
      arrBreadCrumbs.push({
        pathname: arrBredCrumbsPathname.join('/'),
        title: resultado.title,
        subTitle: resultado.subTitle,
        icon: '',
      })
    }
  }
  return arrBreadCrumbs
}

/**
 * getCurrentPageTitleSubTitle
 *
 * Esta função retorna um objeto com as propriedade title e subTitle da pagina atual
 * Ela necessita do parametro real_pathname, que é o pathname da pagina atual, obtido
 * com o comando window.location.pathname
 * @param {*} real_pathname
 * @returns
 */
export const getCurrentPageTitleSubTitle = (real_pathname) => {
  const lenBreadCrumbs = getAllBreadcrumbs(real_pathname).length
  const objTitleAndSubTitle = {
    title: getAllBreadcrumbs(real_pathname)[lenBreadCrumbs - 1].title,
    subTitle: getAllBreadcrumbs(real_pathname)[lenBreadCrumbs - 1].subTitle,
  }

  return objTitleAndSubTitle
}

export const hasPermission = (real_pathname, arrRoles) => {
  //substituir parâmetro de rota numérico para :id,  de forma a não gerar undefined no find...
  const arr = []
  const itemParams = [1, 2, 3]
  for (let index = 0; index < real_pathname.split('/').length; index++) {
    if (!isNaN(real_pathname.split('/')[index]) && index !== 0) {
      arr.push(`:id${itemParams[0]}`)
      itemParams.shift()
    } else {
      arr.push(real_pathname.split('/')[index])
    }
    //arr.push(!isNaN(real_pathname.split('/')[index]) && index !== 0 ? ':id' : real_pathname.split('/')[index])
  }

  const result = getAllRoutesWithoutComponents().find((item) => item.pathName === `${arr.join('/')}`)
  let hasPermissionArr = []
  result.permission.map((search) => {
    if (typeof arrRoles !== 'undefined') {
      if (Object.values(arrRoles).indexOf(search) !== -1) {
        hasPermissionArr.push(search)
      }
    }
    return true
  })
  return hasPermissionArr.length > 0 ? true : false
}
