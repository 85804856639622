import { createSlice } from '@reduxjs/toolkit'

const initialServiceState = {
  listLoading: true,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  service: {},
  support: {},
  requirementToEdit: {},
  discontinuedRequirementsList: [],
  viewRequirement: {},
  dataRequirements: {},
  errorOnDelete: null,
  successOnDelete: null,
  systemFormsToken: null,
  systemFormsHash: null,
  formEncryptedHash: null,
  systemFormsList: [],
  formsLinksViews: {},
  encryptedListFormHash: [],
  encryptedListFormHashDemo: [],
  reloadListOfElectronicForms: true,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const requirementSlice = createSlice({
  name: 'requirements',
  initialState: initialServiceState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    setSystemFormsToken: (state, action) => {
      state.systemFormsToken = action.payload
    },
    setSystemFormsHash: (state, action) => {
      state.systemFormsHash = action.payload
    },
    setFormsLinksViews: (state, action) => {
      state.formsLinksViews = action.payload
    },
    setSystemFormsList: (state, action) => {
      state.systemFormsList = action.payload
    },
    setFormEncryptedHash: (state, action) => {
      state.formEncryptedHash = action.payload
    },
    setReloadListEletronicForms: (state, action) => {
      state.reloadListOfElectronicForms = action.payload
    },
    setEncryptedListFormHash: (state, action) => {
      state.encryptedListFormHash = [...state.encryptedListFormHash, action.payload]
    },
    setEncryptedListFormHashDemo: (state, action) => {
      state.encryptedListFormHashDemo = [...state.encryptedListFormHashDemo, action.payload]
    },
    clearEncryptedListFormHash: (state, action) => {
      state.encryptedListFormHash = []
    },
    clearSystemFormsList: (state, action) => {
      state.systemFormsList = []
    },
    setErrorOnDelete: (state, action) => {
      state.errorOnDelete = action.payload
    },

    setSuccessOnDelete: (state, action) => {
      state.successOnDelete = action.payload
    },

    clearSuccessOnDelete: (state) => {
      state.successOnDelete = null
    },

    clearErrorOnDelete: (state) => {
      state.errorOnDelete = null
    },

    setStatusMessage: (state, action) => {
      //state.saveStatus = null
      state.statusMessage = action.payload
    },

    getAllRequirementsObj: (state, action) => {
      state.dataRequirements = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getRequirementObj: (state, action) => {
      state.viewRequirement = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getRequirementSupport: (state, action) => {
      state.support = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getRequirementDataToEdit: (state, action) => {
      state.requirementToEdit = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    clearRequirementDataToEdit: (state, action) => {
      state.requirementToEdit = {}
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    clearRequirementObj: (state, action) => {
      state.viewRequirement = {}
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getRequirementVersionObj: (state, action) => {
      state.viewRequirement = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getAllRequirementsDiscontinued: (state, action) => {
      state.discontinuedRequirementsList = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    archiveRequirement: (state, action) => {
      state.saveStatus = action.payload
      state.listLoading = false
      state.error = null
    },

    unarchiveRequirement: (state, action) => {
      state.saveStatus = action.payload
      state.listLoading = false
      state.error = null
    },

    deleteRequirement: (state, action) => {
      state.saveStatus = action.payload
      state.listLoading = false
      state.error = null
    },

    deleteAttachmentRequirementVersion: (state, action) => {
      state.saveStatus = action.payload
      state.listLoading = false
      state.error = null
    },

    setViewRequirement: (state, action) => {
      state.viewRequirement = action.payload
    },

    setTest: (state, action) => {
      state.service.code = action.payload
    },

    saveRequirementStatusSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.statusMessage = action.payload
    },
  },
})
