import { createSlice } from '@reduxjs/toolkit'

const initialAttendanceState = {
  listLoading: true,
  myServices: [],
  myAttendance: [],
  getDocuments: [],
  solicitations: [],
  solicitationDocuments: [],
  attendancesOngoing: [],
  solicitaionsMyServices: [],
  attendanceEnableServices: [],
  attendanceOngoing: [],
  solicitationSelected: null,
  attendantsList: [],
  solicitationForumMessages: null,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  serviceList: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const attendanceSlice = createSlice({
  name: 'attendances',
  initialState: initialAttendanceState,
  reducers: {
    catchError: (state, action) => {
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    setSolicitationSelected: (state, action) => {
      state.solicitationSelected = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.lastError = null
    },

    setLastError: (state, action) => {
      state.lastError = action.payload
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    getByStatus: (state, action) => {
      state.lastError = null
      state.myAttendance = action.payload
    },

    getDocuments: (state, action) => {
      state.lastError = null
      state.solicitationDocuments = action.payload
    },
    getAttendances: (state, action) => {
      state.lastError = null
      state.attendancesOngoing = action.payload
    },

    getSolicitationsMyServicesObj: (state, action) => {
      state.lastError = null
      state.solicitaionsMyServices = action.payload
    },

    getSolicitationForumMessages: (state, action) => {
      state.lastError = null
      state.solicitationForumMessages = action.payload
    },

    getAttendants: (state, action) => {
      state.lastError = null
      state.attendantsList = action.payload
    },

    getSolicitationsAwaitingAttendantUnit: (state, action) => {
      state.lastError = null
      state.solicitations = action.payload
    },

    successMessage: (state, action) => {
      state.statusMessage = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
    },

    successForwardingAttendent: (state, action) => {
      state.statusMessage = action.payload.message
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
    },

    cleanSaveStatus: (state, action) => {
      state.saveStatus = null
    },

    clearSaveError: (state, action) => {
      state.saveError = []
    },
  },
})
