import React from 'react'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Button } from '../../../../../components'
import { ActionButtonsWrapper, ButtonWrapper, AlertWrapper } from './SolicitationManagementActionButtons.styles'

const SolicitationManagementActionButton = ({
  solicitationStage,
  solicitationUserCanCancel,
  solicitationId,
  userIsAdminThisService,
  userIsAttendentThisService,
  userIsRequesterThisSolicitation,
  actionsMethods,
  userIsAttendentThisSolicitation,
  serviceId,
  requesterCanCancelSolicitation,
  unreadForumPosts,
  isSolicitationAttendant,
}) => {
  const { user } = useSelector((state) => state.auth)

  const history = useHistory()
  return (
    <ActionButtonsWrapper>
      {!userIsRequesterThisSolicitation &&
        !userIsAttendentThisService &&
        !userIsAdminThisService &&
        user.roles &&
        user.roles.indexOf('atendente') > -1 && (
          <AlertWrapper>
            <Alert variant="alert alert-custom alert-light-primary alert-dismissible">
              Você pode somente consultar os dados desta solicitação. O acolhimento e o atendimento só são permitidos
              para atendentes habilitados para o serviço solicitado.
            </Alert>
          </AlertWrapper>
        )}
      {userIsRequesterThisSolicitation && (
        <>
          <ButtonWrapper>
            <Button
              className="mr-5 mt-5"
              variant="vertical"
              icon={<i className="far fa-thumbs-up"></i>}
              disabled={solicitationStage !== 'atendimento-concluido'}
              onClick={() => actionsMethods.approveAttendance()}
            >
              Aprovar Atendimento
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              className="mr-5 mt-5"
              variant="vertical"
              icon={<i className="fas fa-exclamation-triangle"></i>}
              disabled={solicitationStage !== 'atendimento-concluido'}
              onClick={() => actionsMethods.reproveAttendance()}
            >
              Lançar Pendência
            </Button>
          </ButtonWrapper>
        </>
      )}
      {userIsAttendentThisService && !userIsRequesterThisSolicitation && (
        <>
          <ButtonWrapper>
            <Button
              className="mr-5 mt-5"
              variant="vertical"
              icon={<i className="fas fa-cart-arrow-down"></i>}
              disabled={solicitationStage !== 'aguardando-acolhimento'}
              onClick={() => actionsMethods.receiveSolicitation()}
            >
              Acolher Solicitação
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              className="mr-5 mt-5"
              variant="vertical"
              icon={<i className="fas fa-play"></i>}
              disabled={
                userIsAttendentThisSolicitation === false ||
                solicitationStage === 'atendimento-concluido' ||
                solicitationStage === 'em-atendimento'
              }
              onClick={() => actionsMethods.startAttendance()}
            >
              {solicitationStage === 'atendimento-com-pendencia' ? 'Retomar' : 'Iniciar'} Atendimento
            </Button>
          </ButtonWrapper>
        </>
      )}
      {(userIsAdminThisService ||
        userIsAttendentThisService ||
        userIsRequesterThisSolicitation ||
        user.roles.includes('admin-unidade')) && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="fas fa-mail-bulk"></i>}
            onClick={() =>
              history.push(`/solicitacao/gerencia-da-solicitacao/requisitos-da-solicitacao/${solicitationId}`)
            }
          >
            Requisitos da Solicitação
          </Button>
        </ButtonWrapper>
      )}
      {userIsAttendentThisService && !userIsRequesterThisSolicitation && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="fas fa-flag-checkered"></i>}
            disabled={solicitationStage !== 'em-atendimento'}
            onClick={() => actionsMethods.completeAttendance()}
          >
            Concluir Atendimento
          </Button>
        </ButtonWrapper>
      )}
      {userIsAttendentThisService && userIsAttendentThisSolicitation && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="fas fa-receipt"></i>}
            disabled={
              !(
                solicitationStage === 'aguardando-atendimento' ||
                solicitationStage !== 'em-atendimento' ||
                solicitationStage !== 'atendimento-concluido' ||
                solicitationStage !== 'atendimento-com-pendencia'
              )
            }
            onClick={() => actionsMethods.abandonAttendance()}
          >
            Abandonar Atendimento
          </Button>
        </ButtonWrapper>
      )}
      {(userIsAdminThisService || userIsAttendentThisService || userIsRequesterThisSolicitation) && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="far fa-comment"></i>}
            disabled={solicitationStage === 'aguardando-acolhimento'}
            onClick={() => history.push(`/solicitacao/${solicitationId}/forum-da-solicitacao`)}
            badge={unreadForumPosts || null}
          >
            Fórum da Solicitação
          </Button>
        </ButtonWrapper>
      )}
      {(userIsAdminThisService || userIsAttendentThisService || userIsRequesterThisSolicitation) && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="fas fa-archive"></i>}
            onClick={() => history.push(`/solicitacao/${solicitationId}/documentos`)}
          >
            Documentos da Solicitação
          </Button>
        </ButtonWrapper>
      )}
      {(userIsAdminThisService || userIsRequesterThisSolicitation) && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="far fa-times-circle"></i>}
            onClick={() => actionsMethods.cancelSolicitation()}
            disabled={
              solicitationStage === 'cancelada' ||
              solicitationStage === 'atendimento-concluido' ||
              solicitationStage === 'encerrada' ||
              (solicitationStage === 'atendimento-com-pendencia' &&
                (userIsAdminThisService || userIsRequesterThisSolicitation))
            }
          >
            Cancelar Solicitação
          </Button>
        </ButtonWrapper>
      )}
      {/* {(userIsAdminThisService || userIsAttendentThisService || userIsRequesterThisSolicitation) && ( */}
      <ButtonWrapper>
        <Button
          className="mr-5 mt-5"
          variant="vertical"
          icon={<i className="fas fa-history"></i>}
          onClick={() => history.push(`/solicitacao/${solicitationId}/historico`)}
        >
          Histórico da Solicitação
        </Button>
      </ButtonWrapper>
      {/* )} */}
      {/* {(userIsAdminThisService || userIsAttendentThisService || userIsRequesterThisSolicitation) && ( */}
      <ButtonWrapper>
        <Button
          className="mr-5 mt-5"
          variant="vertical"
          icon={<i className="fas fa-project-diagram"></i>}
          onClick={() => history.push(`/solicitacao/${solicitationId}/relacionadas`)}
        >
          Solicitações Relacionadas
        </Button>
      </ButtonWrapper>
      {/* )} */}
      {userIsAdminThisService && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="fas fa-arrow-right"></i>}
            disabled={solicitationStage === 'cancelada' || solicitationStage === 'encerrada'}
            onClick={() => history.push(`/solicitacao/${solicitationId}/encaminhamento/service/${serviceId}`)}
          >
            Encaminhar Solicitação
          </Button>
        </ButtonWrapper>
      )}
      {(userIsAttendentThisService || userIsAdminThisService) && (
        <ButtonWrapper>
          <Button
            className="mr-5 mt-5"
            variant="vertical"
            icon={<i className="fas fa-tasks"></i>}
            disabled={
              solicitationStage !== 'em-atendimento' &&
              solicitationStage !== 'aguardando-atendimento' &&
              solicitationStage !== 'atendimento-com-pendencia' &&
              userIsRequesterThisSolicitation
            }
            onClick={() => history.push(`/solicitacao/${solicitationId}/tarefas`)}
          >
            Tarefas da Solicitação
          </Button>
        </ButtonWrapper>
      )}
    </ActionButtonsWrapper>
  )
}

export default SolicitationManagementActionButton
