import React, { useState } from 'react'
import axios from 'axios'
import { Col, Row, Button, Form } from 'react-bootstrap'
import { AlertMessage } from '../AlertMessage'

const handleDownload = (idDownload, original_name) => {
  const API_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  axios({
    url: `${API_BASE_URL}/api/attachment/${idDownload}/download`,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', original_name)
    document.body.appendChild(link)
    link.click()
  })
}

export const RequirementAttachmentsOnEditForm = (props) => {
  let [attachments, setAttachments] = useState(props.attachments)

  const setRequiredDownloadHandler = (attachmentIndex, value) => {
    const newArray = attachments.map((item, index) => {
      if (index === attachmentIndex) {
        return { ...item, required_download: !!parseInt(value) }
      }

      return item
    })

    setAttachments(newArray)
  }

  if (attachments.length !== 0) {
    const listAttachments = attachments.map((attachment, i) => {
      return (
        <div key={i} id={`item-${i}`}>
          <Row className="mb-1">
            <Col sm={6}>
              <p style={{ cursor: 'pointer' }} className="">
                <strong>Arquivo:</strong> {attachment.attachment.original_name}
              </p>
            </Col>
            <Col sm={4}>
              <strong>Download Obrigatório?</strong>&nbsp;&nbsp;
              <Form.Check
                inline
                id={`required_download_${attachment.id}`}
                label={'Sim'}
                name={`requiredDownload${attachment.id}`}
                type="radio"
                value={1}
                onChange={(e) => setRequiredDownloadHandler(i, e.target.value)}
                onClick={(e) => {
                  props.handleCheckRequirementAttachment(attachment.id, e.target.value)
                }}
                checked={attachment.required_download}
              />
              <Form.Check
                inline
                id={`required_download_${attachment.id}`}
                label={'Não'}
                name={`requiredDownload${attachment.id}`}
                type="radio"
                value={0}
                onChange={(e) => setRequiredDownloadHandler(i, e.target.value)}
                onClick={(e) => {
                  props.handleCheckRequirementAttachment(attachment.id, e.target.value)
                }}
                checked={!attachment.required_download}
              />
            </Col>
            <Col sm={2} className="text-right">
              <Button
                className="mb-0 mt-0"
                variant="outline-secondary"
                size="sm"
                onClick={() => handleDownload(attachment.attachment.id, attachment.attachment.original_name)}
              >
                Baixar
              </Button>
              {'  '}
              <Button
                className="mb-0 mt-0"
                variant="outline-secondary"
                size="sm"
                onClick={(e) => props.handleRemoveItem(attachment.id, `item-${i}`, attachment.attachment.original_name)}
              >
                Excluir
              </Button>
            </Col>
          </Row>
          <hr />
        </div>
      )
    })

    return listAttachments
  } else {
    return (
      <AlertMessage
        variant="alert alert-custom alert-light-primary alert-dismissible"
        message="Não há arquivos anexados."
      />
    )
  }
}
