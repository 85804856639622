import ReportIntangibleAssetsPage from '../modules/Reports/pages/ReportIntangibleAssetsPage'
import DossierIntangibleAssetPage from '../modules/Reports/pages/DossierIntangibleAssetPage'

export default [
  /**
   * relatórios
   * pathname principal: /relatorios
   */
  {
    pathName: '/relatorios/intangible_assets_tabular',
    exact: true,
    title: 'Relatório de Solicitações',
    subTitle: 'Formulário de criação do relatório.',
    icon: '',

    component: ReportIntangibleAssetsPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/relatorios/dossie_ativo_intangivel',
    exact: true,
    title: 'Dossiê de Ativo Intangível',
    subTitle: 'Relatório do Ativo Intangível no formato PDF.',
    icon: '',
    component: DossierIntangibleAssetPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
]
