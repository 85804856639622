import TooltipCustom from '../TooltipCustom'
import React from 'react'
import { Button as ButtonBootstrap } from 'react-bootstrap'
import { BadgeGT } from '..'
import { VerticalButton, HorizontalButton, FileButton } from './Dashboard.styles'
import './tooltip.css'

const Button = ({
  variant,
  icon,
  disabled,
  className,
  onClick,
  badge,
  badgeVariant = 'danger',
  children,
  title = null,
  placement = 'bottom',
}) => {
  /*
  const OverlayTooltip = (props) => {
    return (
      <TooltipCustom className="gestec-tooltip" {...props}>
        {title}
      </TooltipCustom>
    )
  }
  */

  if (variant === 'vertical')
    return (
      <div style={{ position: 'relative' }}>
        {badge && (
          <BadgeGT
            variant={badgeVariant}
            text={badge}
            circle
            style={{
              fontSize: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              position: 'absolute',
              left: 90,
              top: -8,
              zIndex: 99,
            }}
          />
        )}
        <VerticalButton disabled={disabled} className={className} onClick={onClick}>
          <span className="iconButton-icon">{icon}</span>
          {children}
        </VerticalButton>
      </div>
    )
  else if (variant === 'horizontal')
    return (
      <div style={{ position: 'relative' }}>
        {badge && (
          <BadgeGT
            variant={badgeVariant}
            text={badge}
            circle
            style={{
              fontSize: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              position: 'absolute',
              left: 180,
              top: -15,
              zIndex: 99,
            }}
          />
        )}
        <HorizontalButton disabled={disabled} className={className} onClick={onClick}>
          <span className="iconButton-icon">{icon}</span>
          {children}
        </HorizontalButton>
      </div>
    )
  else if (variant === 'file')
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        {badge && (
          <BadgeGT
            variant={badgeVariant}
            text={badge}
            circle
            style={{
              fontSize: '1.3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 25,
              height: 25,
              position: 'absolute',
              top: -10,
              right: 5,
              zIndex: 99,
            }}
          />
        )}
        <FileButton disabled={disabled} variant="secondary" className={className} onClick={onClick}>
          {children.length > 20
            ? `${children.substring(0, 10)}...${children.substring(children.length - 9)}`
            : children}
        </FileButton>
      </div>
    )
  else
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        {badge && (
          <BadgeGT
            variant={badgeVariant}
            text={badge}
            circle
            style={{
              fontSize: '1.3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 25,
              height: 25,
              position: 'absolute',
              top: -10,
              right: 5,
              zIndex: 99,
            }}
          />
        )}
        {!title && (
          <ButtonBootstrap disabled={disabled} variant={variant} className={className} onClick={onClick}>
            <strong>{children}</strong>
          </ButtonBootstrap>
        )}
        {title && (
          <TooltipCustom title={title} placement="top" arrow>
            <div>
              <ButtonBootstrap
                disabled={disabled}
                variant={variant}
                className={className}
                onClick={onClick}
                style={disabled ? { pointerEvents: 'none' } : {}}
              >
                {children}
              </ButtonBootstrap>
            </div>
          </TooltipCustom>
        )}
      </div>
    )
}

export default Button
