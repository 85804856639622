import React from 'react'
import { Badge } from 'react-bootstrap'
import './badgeStyles.css'

const BadgeGT = ({ text, variant = 'primary', pill, circle, className, style }) => {
  return (
    <Badge
      variant={variant}
      style={
        style || {
          fontSize: '1rem',
          display: circle ? 'inline-block' : 'flex',
          alignItems: 'center',
          width: circle ? 20 : 'initial',
          height: circle ? 20 : 'initial',
          border: '1px solid white',
        }
      }
      className={className}
      pill={pill || circle}
    >
      {text}
    </Badge>
  )
}

export default BadgeGT
