import * as requestFromServer from './auditEndpoints'
import { auditSlice } from './auditSlice'

const { actions } = auditSlice

/**
 * Retorna da api um objeto com todos
 * usuarios (não deletado logicamente) do sistema
 * @returns
 */
export const getAudit = (id) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .getAudit(id)
    .then((response) => {
      dispatch(actions.setAuditSelected(response.data))
      dispatch(actions.setLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response && error.response.data
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setLoading(false))
    })
}

/**
 * Limpa a propriedade lastError
 * @returns
 */
export const clearLastError = () => (dispatch) => {
  return dispatch(actions.clearLastError())
}

/**
 * Limpa a propriedade saveErro
 * @returns
 */
export const clearSaveError = () => (dispatch) => {
  return dispatch(actions.clearSaveError())
}

/**
 * Limpa o status
 * @returns
 */
export const cleanSaveStatus = () => (dispatch) => {
  return dispatch(actions.setStatusMessage({ status: null, message: null }))
}

/**
 * Pegar lista de auditorias
 */
export const getAuditList = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .getAuditList(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.setAuditList(response.data))
      dispatch(actions.setLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setLoading(false))
    })
}
