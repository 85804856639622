import React, { useEffect, useState } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import { useSubheader } from '../../../../_metronic/layout'
import { AlertGT, AlertIntangibleAsset, BadgeGT, BlockInfo, Button, List, Typography } from '../../../components'
import { useHistory, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  stepOne,
  selectIntangibleAsset,
  selectSolicitation,
  deleteSolicitation,
  clearStateSolicitation,
  selectSolicitationInProgress,
} from '../_redux/solicitation/solicitationActions'
import TooltipCustom from '../../../components/TooltipCustom'
import { getAllBreadcrumbs } from '../../../../app/routes/Helpers'

function SolicitationStepOnePage() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { mySolicitations, incompleteSolicitations, myIntangibleAssets } = useSelector((state) => state.solicitations)
  const [lastSolicitions, setLastSolicitions] = useState([])
  const [intangibleAssets, setIntangibleAssets] = useState([])
  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const [showAlertIntangibleAsset, setShowAlertIntangibleAsset] = React.useState(false)

  const subheader = useSubheader()
  const pathname = window.location.pathname
  subheader.setTitle('Cadastro de Solicitações')
  subheader.setSubtitle(
    `Clique em um ativo intangível ou em uma solicitação já existente para criar uma nova solicitação de serviço. Se você preferir, pode cadastrar outro ativo intangível para iniciar sua nova solicitação.`
  )

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(stepOne())
    dispatch(clearStateSolicitation())
  }, [dispatch])

  useEffect(() => {
    orderData()
    // eslint-disable-next-line
  }, [mySolicitations, incompleteSolicitations, myIntangibleAssets])

  function setIntangibleAsset(e, asset) {
    e.preventDefault()
    if (incompleteSolicitations && incompleteSolicitations.id) {
      const content = `
        <p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
        e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
        Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
        <p>Deseja prosseguir?</p>
      `
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        width: 600,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
        confirm: () => confirmSolicitionFromIntagibleAsset(asset),
        toastSuccess: null,
      })
    } else {
      confirmSolicitionFromIntagibleAsset(asset)
    }
  }

  function setIncompleteSolicitation(e, solicition) {
    e.preventDefault()
    dispatch(selectSolicitationInProgress(solicition))
    history.push('/solicitacao/novo/etapa-tres')
  }

  function setSolicitation(e, solicition) {
    e.preventDefault()
    if (incompleteSolicitations && incompleteSolicitations.id) {
      const content = `
        <p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
        e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
        Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
        <p>Deseja prosseguir?</p>
      `
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        width: 600,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
        confirm: () => confirmSolicition(solicition),
        toastSuccess: null,
      })
    } else {
      confirmSolicition(solicition)
    }
  }

  function confirmSolicition(solicition) {
    dispatch(selectSolicitation(solicition))
    dispatch(selectIntangibleAsset(solicition.intangibleAssetOriginParent))

    if (incompleteSolicitations && incompleteSolicitations.id) confirmRemoveSolicition(incompleteSolicitations)
    history.push('/solicitacao/novo/etapa-dois')
  }

  function confirmSolicitionFromIntagibleAsset(asset) {
    dispatch(selectIntangibleAsset(asset))
    if (incompleteSolicitations && incompleteSolicitations.id) confirmRemoveSolicition(incompleteSolicitations)
    history.push('/solicitacao/novo/etapa-dois')
  }

  function removeSolicitation(e, solicitation) {
    e.preventDefault()
    setConfigAlert({
      show: true,
      title: null,
      text: `<p>Deseja descartar sua solicitação incompleta?</p> <p>(todos os dados informados serão perdidos)</p>`,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        confirmRemoveSolicition(solicitation)
        setConfigAlert({ ...configAlert, show: false })
      },
      toastSuccess: 'Sucesso! Solicitação descartada.',
    })
  }

  function confirmRemoveSolicition(solicitation) {
    dispatch(deleteSolicitation(solicitation))
  }

  const TextReadMore = ({ text }) => (
    <div className="d-flex align-items-baseline">
      <span className="d-inline-block text-truncate" style={{ width: '40ch' }}>
        {text}
      </span>
    </div>
  )

  function orderData() {
    const slctns = []

    if (incompleteSolicitations && incompleteSolicitations.id) {
      const { code, description, momentCreateByUser, service, stage } = incompleteSolicitations

      slctns.push({
        code,
        description: <TextReadMore text={description} />,
        info: (
          <TooltipCustom title="Mais informações" placement="top" arrow>
            <i
              style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
              className="fas fa-info-circle ml-5 position-relative"
              onClick={() =>
                setConfigAlert({
                  show: true,
                  title: (
                    <div className="text-left">
                      <h3 className="swal2-title text-left p-0" style={{ fontSize: '1.25rem', fontWeight: 400 }}>
                        Solicitação
                      </h3>
                      <h2 className="swal2-title text-left p-0" style={{ fontSize: '1.5rem' }}>
                        {code}
                      </h2>
                    </div>
                  ),
                  text: (
                    <div className="text-left">
                      <p>{description}</p>
                      <p style={{ fontSize: '1rem' }}>
                        <strong>{momentCreateByUser}</strong>
                      </p>
                      <div style={{ display: 'inline-flex' }}>
                        <BlockInfo style={{ width: 'max-content' }} className="mr-5">
                          <i className="fas fa-concierge-bell"></i> <strong>{service.code}</strong> - {service.title}
                        </BlockInfo>
                        <BadgeGT
                          variant={stage.slug}
                          text={stage.stage}
                          style={{ display: 'flex', alignItems: 'center' }}
                        />
                      </div>
                    </div>
                  ),
                  icon: null,
                  width: 780,
                  cancelButton: false,
                  confirmButtonText: 'Fechar',
                  confirm: () => setConfigAlert({ ...configAlert, show: false }),
                  toastSuccess: null,
                })
              }
            ></i>
          </TooltipCustom>
        ),
        text: <strong>Solicitação incompleta</strong>,
        action: (
          <div className="d-flex align-items-center justify-content-end w-100 px-3">
            <Button
              className="mr-0 w-full"
              variant="warning"
              onClick={(e) => setIncompleteSolicitation(e, incompleteSolicitations)}
            >
              Continuar
            </Button>
            &nbsp;&nbsp;
            <Button className="w-100" variant="warning" onClick={(e) => removeSolicitation(e, incompleteSolicitations)}>
              Descartar
            </Button>
          </div>
        ),
        backgroundColor: 'rgba(180, 114, 20, 0.2)',
      })
    }

    mySolicitations.forEach((solicition) => {
      const { code, description, moment, momentCreateByUser, service, stage, solicitation_organization } = solicition
      slctns.push({
        code,
        description: <TextReadMore text={description} />,
        info: (
          <TooltipCustom title="Mais informações" placement="top" arrow>
            <i
              style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
              className="fas fa-info-circle ml-5 position-relative"
              onClick={() =>
                setConfigAlert({
                  show: true,
                  title: (
                    <div className="text-left">
                      <h3 className="swal2-title text-left p-0" style={{ fontSize: '1.25rem', fontWeight: 400 }}>
                        Solicitação
                      </h3>
                      <h2 className="swal2-title text-left p-0" style={{ fontSize: '1.5rem' }}>
                        {code}
                      </h2>
                    </div>
                  ),
                  text: (
                    <div className="text-left">
                      <p>{description}</p>
                      <p style={{ fontSize: '1rem' }}>
                        <strong>{momentCreateByUser}</strong>
                      </p>
                      <div style={{ display: 'inline-flex' }}>
                        <BlockInfo style={{ width: 'max-content' }} className="mr-5">
                          <i className="fas fa-concierge-bell"></i> <strong>{service.code}</strong> - {service.title}
                        </BlockInfo>
                        <BlockInfo style={{ width: 'max-content' }} className="mr-5">
                          <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
                            <strong>{solicitation_organization.initials}</strong>
                          </TooltipCustom>
                        </BlockInfo>
                        <BadgeGT
                          variant={stage.slug}
                          text={stage.stage}
                          style={{ display: 'flex', alignItems: 'center' }}
                        />
                      </div>
                    </div>
                  ),
                  icon: null,
                  width: 780,
                  cancelButton: false,
                  confirmButtonText: 'Fechar',
                  confirm: () => setConfigAlert({ ...configAlert, show: false }),
                  toastSuccess: null,
                })
              }
            ></i>
          </TooltipCustom>
        ),
        moment,
        action: (
          <div className="d-flex align-items-center justify-content-center w-100 px-3">
            <Button className="w-100" variant="primary" onClick={(e) => setSolicitation(e, solicition)}>
              Criar Solicitação
            </Button>
          </div>
        ),
      })
    })

    setLastSolicitions(incompleteSolicitations && incompleteSolicitations.id ? slctns.slice(0, 5) : slctns)

    const intangiAssets = []

    myIntangibleAssets.forEach((asset) => {
      const { code, title, description, moment } = asset
      intangiAssets.push({
        code,
        description: <TextReadMore text={title} />,
        info: (
          <TooltipCustom title="Mais informações" placement="top" arrow>
            <i
              style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
              className="fas fa-info-circle ml-5 position-relative"
              onClick={() =>
                setConfigAlert({
                  show: true,
                  title: (
                    <div className="text-left">
                      <h3 className="swal2-title text-left p-0" style={{ fontSize: '1.25rem', fontWeight: 400 }}>
                        Ativo Intangível
                      </h3>
                      <h2 className="swal2-title text-left p-0" style={{ fontSize: '1.5rem' }}>
                        {title}
                      </h2>
                    </div>
                  ),
                  text: <div className="text-left">{description}</div>,
                  width: 600,
                  icon: null,
                  cancelButton: false,
                  confirmButtonText: 'Fechar',
                  confirm: () => setConfigAlert({ ...configAlert, show: false }),
                  toastSuccess: null,
                })
              }
            ></i>
          </TooltipCustom>
        ),
        moment,
        action: (
          <div className="d-flex align-items-center justify-content-end w-100 px-3">
            <Button className="w-100" variant="primary" onClick={(e) => setIntangibleAsset(e, asset)}>
              Criar Solicitação
            </Button>
          </div>
        ),
      })
    })

    setIntangibleAssets(intangiAssets)
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <AlertIntangibleAsset
        show={showAlertIntangibleAsset}
        onConfirm={() => setShowAlertIntangibleAsset(false)}
        onDismissed={() => setShowAlertIntangibleAsset(false)}
      />
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Container>
            <Row>
              <Col className="text-right">
                <Typography variant="stage">Etapa 1 de 3</Typography>
              </Col>
            </Row>

            <Row className="mb-5">
              <Col>
                <h3 className="mb-5">Meus Ativos Intangíveis</h3>
              </Col>
              <Col sm={3} className="text-right">
                <div className="d-grid">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => history.push('/ativo-intangivel/novo')}
                    className="btn-block font-weight-bold"
                  >
                    Cadastrar Ativo Intangível
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col>
                <List
                  itens={intangibleAssets}
                  noItensMessage="Ainda não existem ativos Intangíveis criados por você."
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col className="d-flex justify-content-end">
                <NavLink to="#" className="pl-5" onClick={() => setShowAlertIntangibleAsset(true)}>
                  O que é um ativo intangível?
                </NavLink>
                <NavLink to="/ativo-intangivel/list" className="pl-5">
                  Ver todos os ativos Intangíveis
                </NavLink>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <h3 className="mb-5">Minhas Solicitações Recentes</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <List itens={lastSolicitions} noItensMessage="Ainda não existem solicitações criadas por você." />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col className="d-flex justify-content-end">
                <NavLink to="/solicitacoes" className="pl-5">
                  Ver todas as solicitações
                </NavLink>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer className="text-right">
          <Button variant="secondary" onClick={() => history.push('/dashboard')}>
            Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default SolicitationStepOnePage
