import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { useSelector } from 'react-redux'

export function HeaderMenu({ layoutProps }) {
  const { user } = useSelector((state) => state.auth)

  const location = useLocation()
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? 'menu-item-active' : ''
  }

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Home</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*Classic submenu*/}

        {/*begin::1a MENU DE ADMINISTRAÇÃO*/}

        {typeof user.roles !== 'undefined' && Object.values(user.roles).includes('admin') && (
          <>
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/admin')}`}
            >
              <NavLink className="menu-link menu-toggle" to="/admin">
                <span className="menu-text">Administração</span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive('/administracao')}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/administracao">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
                      </span>
                      <span className="menu-text">Usuários</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::Sub item Acesso de Usuários */}
                        <li className={`menu-item ${getMenuItemActive('/administracao/usuarios/liberar-acesso')}`}>
                          <NavLink className="menu-link" to="/administracao/usuarios/liberar-acesso">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Acesso de Usuários</span>
                          </NavLink>
                        </li>
                        {/*end::Sub item Acesso de Usuários */}

                        {/*begin::Sub item Gerência de Usuários*/}
                        <li className={`menu-item ${getMenuItemActive('/administracao/usuarios/gerencia')}`}>
                          <NavLink className="menu-link" to="/administracao/usuarios/gerencia">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Gerência de Usuários</span>
                          </NavLink>
                        </li>
                        {/*end::Sub item Gerência de Usuários*/}
                      </ul>
                    </div>
                  </li>
                  {/*end::Item Solicitações */}
                  <li className={`menu-item ${getMenuItemActive('/servico')}`}>
                    <NavLink className="menu-link" to="/servico">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Food/Dinner.svg')} />
                      </span>
                      <span className="menu-text">Serviços</span>
                    </NavLink>
                  </li>
                  {/*end::Item Solicitações */}
                  {/*begin::Tarefas*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive('/tarefas')}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/tarefas">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
                      </span>
                      <span className="menu-text">Tarefas</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/tarefas/tipos')}`}>
                          <NavLink className="menu-link" to="/tarefas/tipos">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Tipos de Tarefas</span>
                          </NavLink>
                        </li>
                        {/*end::3 Level*/}

                        {/*begin::3 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/tarefas/visiveis/admin')}`}>
                          <NavLink className="menu-link" to="/tarefas/visiveis/admin">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Gerência da Tarefas</span>
                          </NavLink>
                        </li>
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                  {/*end::Tarefas*/}

                  {/*begin::Auditoria*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive('/auditoria')}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/auditoria">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Shield-check.svg')} />
                      </span>
                      <span className="menu-text">Auditoria</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/auditoria')}`}>
                          <NavLink className="menu-link" to="/auditoria">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Apoio à auditoria</span>
                          </NavLink>
                        </li>
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                  {/*end::Auditoria*/}
                </ul>
              </div>
            </li>
            {/** Relatórios */}
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/reports')}`}
            >
              <NavLink className="menu-link menu-toggle" to="/reports">
                <span className="menu-text">Relatórios</span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                  {/*begin:: Nivel 1*/}
                  <li className={`menu-item ${getMenuItemActive('/relatorios/dossie_ativo_intangivel')}`}>
                    <NavLink className="menu-link" to="/relatorios/dossie_ativo_intangivel">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
                      </span>
                      <span className="menu-text">Ativos Intangíveis</span>
                    </NavLink>
                  </li>
                  <li className={`menu-item ${getMenuItemActive('/relatorios/intangible_assets_tabular')}`}>
                    <NavLink className="menu-link" to="/relatorios/intangible_assets_tabular">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Files/folder_open.svg')} />
                      </span>
                      <span className="menu-text">Solicitações</span>
                    </NavLink>
                  </li>
                  {/*end:: Nivel 1 */}
                </ul>
              </div>
            </li>
          </>
        )}

        {/*end::1a MENU DE ADMINISTRAÇÃO*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  )
}
