import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { ReadMore, Table, Button } from '../../../../components'
import { getMyServicesAdmin } from '../../_redux/services/serviceActions'

const orderByOptions = [
  { value: 'code', text: 'Código do Serviço' },
  { value: 'title', text: 'Título do Serviço' },
  { value: 'description', text: 'Descrição do Serviço' },
  { value: 'solicitations_in_service', text: 'Solicitações em Atendimento' },
  { value: 'qualified_attendants', text: 'Atendentes Habilitados' },
]

const arrBreadCrumb = [
  {
    pathname: '/solicitacao/atendimentos/admin_servico/solicitacoes/meus_servicos',
    title: 'Solicitações dos Meus Serviços',
    subTitle: 'Solicitações dos serviços que você administra.',
    icon: '',
  },
]

export function ServiceMyAdmin() {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('id')
  const [ordering, setOrdering] = React.useState('asc')
  const { myServicesAdmin, listLoading } = useSelector((state) => state.services)

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle} ${myServicesAdmin.userName + '.' || '...'}`)

  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getMyServicesAdmin(perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fa fa-search"></i>,
      title: 'Consultar Serviço',
      onClick: (row) => history.push(`/servico/${row.id}/consulta`),
    },
    {
      text: <i className="fa fa-users-cog"></i>,
      title: 'Atendentes Habilitados',
      onClick: (row) => history.push(`/servico/${row.id}/atendentes`),
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'title',
      text: 'Título',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <strong>{row.title}</strong>
          <br />
          <ReadMore text={row.description} maxCharacters={70} />
        </>
      ),
      headerStyle: { width: '75ch', textAlign: 'left' },
    },
    {
      dataField: 'solicitations_in_service',
      text: 'Solicitações em Atendimento',
      align: 'center',
      sort: false,
      classes: 'align-middle',
    },
    {
      dataField: 'qualified_attendants',
      text: 'Atendentes Habilitados',
      align: 'center',
      sort: false,
      classes: 'align-middle',
    },
  ]

  return (
    <Card border="secondary">
      <Card.Body>
        <Table
          data={myServicesAdmin.data}
          noDataMessage={
            typeof search !== 'undefined'
              ? `Não foram encontrados resultados para o termo ${search}.`
              : `Não existem serviços administrados por você.`
          }
          columns={columns}
          page={myServicesAdmin.current_page}
          perPage={perPage}
          totalSize={myServicesAdmin.total}
          orderByOptions={orderByOptions}
          orderByOnChange={(field) => setSortField(field)}
          orderByDirectionChange={(direction) => setOrdering(direction)}
          onSizePerPageChange={(perPage) => {
            setPerPage(perPage)
            setPage(1)
          }}
          onPageChange={(page) => setPage(page)}
          onTableChange={() => null}
          searchPlaceholder="Pesquise aqui"
          perPagePosition="top"
          actions={actionsTable}
          onSearch={(term) => setSearch(term)}
          searchValue={search}
          loading={listLoading}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col className="text-right">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <i className="fa fa-chevron-left"></i>&nbsp;Voltar
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}
