import React, { useEffect } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle, getAllBreadcrumbs } from '../../../routes/Helpers'
import { getRelatedSolicitations } from '../_redux/solicitation/solicitationActions'
import RelatedSolicitation from '../components/solicitation/RelatedSolicitation'
import { BadgeGT, BlockInfo } from '../../../components'
import TooltipCustom from '../../../components/TooltipCustom'


function SolicitationsRelatedPage() {
  const dispatch = useDispatch()
  const { id1 } = useParams()
  const pathname = window.location.pathname
  const { relatedSolicitations } = useSelector((state) => state.solicitations)

  const [itensRelated, setItensRelated] = React.useState([])
  const [actualSolicitation, setActualSolicitation] = React.useState([])
  const [solicitationsChildren, setSolicitationsChildren] = React.useState([])

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getRelatedSolicitations(id1))
    // eslint-disable-next-line
  }, [dispatch, id1, pathname])

  useEffect(() => {
    orderData()
    // eslint-disable-next-line
  }, [relatedSolicitations])

  const history = useHistory()

  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(
    `${getCurrentPageTitleSubTitle(pathname).subTitle} ${relatedSolicitations.actualSolicitation ? relatedSolicitations.actualSolicitation.code + '.' : ''
    }`
  )

  function orderData() {
    var intangibleItem = {}
    var actualSolicitationItem = {}
    var items = []
    var childrens = []

    if (relatedSolicitations.relatedSolicitations && relatedSolicitations.intangible_asset) {
      intangibleItem = {
        title: (
          <>
            <strong>{relatedSolicitations.intangible_asset.code}</strong>
          </>
        ),
        subTitle: relatedSolicitations.intangible_asset.title,
        body: (
          <>
            <Row className="w-100 justify-content-end">
              <Col className="col-10">{relatedSolicitations.intangible_asset.description}</Col>
              <Col className="col-10">
                <strong>{relatedSolicitations.intangible_asset.momentByUser}</strong>
              </Col>
              <Col className="col-10">
                <TooltipCustom title="Unidade de Origem do Ativo" placement="top" arrow>
                  <span className="btn btn-secondary btn-sm mr-3 font-weight-bold text-center">
                    <BadgeGT variant={'secondary'} text={relatedSolicitations.intangible_asset.organization_initials} />
                  </span>
                </TooltipCustom>
              </Col>
            </Row>
          </>
        ),
      }
      items = relatedSolicitations.relatedSolicitations.map((item, index) => {
        return {
          title: (
            <>
              <span style={{ fontSize: '1.3rem', marginRight: '1rem' }}>{index + 1}ª</span> <strong>{item.code}</strong>
            </>
          ),
          subTitle: item.description,
          body: (
            <Row className="w-100 justify-content-end">
              <Col className="col-10 mb-3">
                <strong>{item.momentByUser}</strong>
              </Col>
              <Col className="col-10 d-flex">
                <BlockInfo className="mr-5" style={{ maxWidth: 'max-content' }}>
                  <div className="d-inline">
                    <i className="fas fa-concierge-bell"></i>
                    <strong>{item.service_code}</strong> - {item.service_title}
                  </div>
                </BlockInfo>
                <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
                  <span className="btn btn-secondary btn-sm mr-3 font-weight-bold">
                    <BadgeGT variant={'secondary'} text={item.solicitation_organization_initials} />
                  </span>
                </TooltipCustom>
                &nbsp;&nbsp;
                <BadgeGT variant={item.stage_slug} text={item.stage} />
              </Col>
            </Row>
          ),
        }
      })
      setItensRelated([intangibleItem, ...items])
    }

    if (relatedSolicitations.actualSolicitation) {
      actualSolicitationItem = {
        title: (
          <>
            <strong>{relatedSolicitations.actualSolicitation.code}</strong>
          </>
        ),
        subTitle: relatedSolicitations.actualSolicitation.description,
        body: (
          <Row className="w-100 justify-content-end">
            <Col className="col-10 mb-3">
              <strong>{relatedSolicitations.actualSolicitation.momentByUser}</strong>
            </Col>
            <Col className="col-10 d-flex">
              <BlockInfo className="mr-5" style={{ maxWidth: 'max-content' }}>
                <div className="d-inline">
                  <i className="fas fa-concierge-bell"></i>
                  <strong>{relatedSolicitations.actualSolicitation.service_code}</strong> -{' '}
                  {relatedSolicitations.actualSolicitation.service_title}
                </div>
              </BlockInfo>
              <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
                <span className="btn btn-secondary btn-sm mr-3 font-weight-bold">
                  <BadgeGT
                    variant={'secondary'}
                    text={relatedSolicitations.actualSolicitation.solicitation_organization_initials}
                  />
                </span>
              </TooltipCustom>
              &nbsp;&nbsp;
              <BadgeGT
                variant={relatedSolicitations.actualSolicitation.stage_slug}
                text={relatedSolicitations.actualSolicitation.stage}
              />
            </Col>
          </Row>
        ),
      }
      setActualSolicitation([actualSolicitationItem])
    }

    if (relatedSolicitations.solicitationsChildren && relatedSolicitations.solicitationsChildren.length > 0) {
      childrens = relatedSolicitations.solicitationsChildren.map((item, index) => {
        return {
          title: (
            <>
              <strong>{item.code}</strong>
            </>
          ),
          subTitle: item.description,
          body: (
            <Row className="w-100 justify-content-end">
              <Col className="col-10 mb-3">
                <strong>{item.momentByUser}</strong>
              </Col>
              <Col className="col-10 d-flex">
                <BlockInfo className="mr-5" style={{ maxWidth: 'max-content' }}>
                  <div className="d-inline">
                    <i className="fas fa-concierge-bell"></i>
                    <strong>{item.service_code}</strong> - {item.service_title}
                  </div>
                </BlockInfo>
                <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
                  <span className="btn btn-secondary btn-sm mr-3 font-weight-bold">
                    <BadgeGT variant={'secondary'} text={item.solicitation_organization_initials} />
                  </span>
                </TooltipCustom>
                &nbsp;&nbsp;
                <BadgeGT variant={item.stage_slug} text={item.stage} />
              </Col>
            </Row>
          ),
        }
      })
      setSolicitationsChildren(childrens)
    }
  }

  return (
    <>
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <RelatedSolicitation itens={itensRelated} />
          <RelatedSolicitation itens={actualSolicitation} showIdent backgroundColor="rgba(255, 185, 73, 0.15)" />
          {solicitationsChildren.length > 0 && (
            <div className="d-flex justify-content-end">
              <div style={{ width: 'calc( 100% - 3rem )' }}>
                <RelatedSolicitation itens={solicitationsChildren} showIdent />
              </div>
            </div>
          )}
          <br />
        </Card.Body>
        <Card.Footer className="text-right">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default SolicitationsRelatedPage
