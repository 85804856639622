import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

export const getDashboard = async () => await axios.get(`${API_BASE_URL}/api/admin/dashboard`)

/**
 * Atualizar Unidade do usuário novo pelo Login Único da Fiocruz.
 * @param {*} id
 * @returns
 */
export const setUnitFromUser = async (id, idUnit) =>
  await axios.get(`${API_BASE_URL}/api/user/${id}/organization/${idUnit}/set_unit`)
