import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

export const getFaq = async (search, page, per_page, ordering, sort_field) =>
  await axios.get(`${API_BASE_URL}/api/faqs`, { params: { search, page, per_page, ordering, sort_field } })

export const showFaq = async (faq_id) => await axios.get(`${API_BASE_URL}/api/faqs/${faq_id}`)
