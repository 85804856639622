import React, { Suspense } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen } from '../_metronic/layout'
import { getAllRoutes } from './routes/Helpers'

// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";

//lazy é um component do Reac
// const AdminPage = lazy(() =>
//   import("./modules/Admin/pages/adminPage")
// );

// const SolicitacoesPage = lazy(() =>
//   import("./modules/Solicitation/pages/solicitacoesPage")
// );
// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const ListRoutes = getAllRoutes().map((route, i) => {
    if (route.exact) {
      return <Route key={i} exact path={route.pathName} component={route.component} />
    } else {
      return <Route key={i} path={route.pathName} component={route.component} />
    }
  })

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        {ListRoutes}

        <Redirect to="error/error-v1" />

        {/**
          <ContentRoute path="/builder" component={BuilderPage} />  //<ContentRoute é component do metronic
          <ContentRoute path="/my-page" component={MyPage} />
          <Route path="/dashboard" component={DashboardPage} />
          <Route path="/user-profile" component={UserProfilepage} />
          <ContentRoute path="/apresentacao" component={PresentationPage} />
          <ContentRoute path="/admin" component={AdminPage} />
          <ContentRoute path="/solicitacoes" component={SolicitacoesPage} />

          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
        */}
      </Switch>
    </Suspense>
  )
}
