import React, { useState } from 'react'
import { useCombobox } from 'downshift'
import Option from './Option'
import { SelectWrapper, OptionsList, SelectInput, ArrowButtonWrapper, ArrowButton } from './SelectService.styles'

const SelectService = ({ options, handlerChange, serviceIdSelected }) => {
  const [inputItems, setInputItems] = useState(options)

  // State for all primitive types
  // const [value, setValue] = useState(defaultValue)

  const filterByName = (data, inputValue) => {
    return data.filter((item) => {
      return item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    })
  }

  // props for the combobox
  const comboboxProps = {
    className: 'search has-icons-left has-buttons-right',
  }

  // props for the menu
  const menuProps = {
    className: 'menu',
  }
  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    selectItem,
    openMenu,
    reset,
    getToggleButtonProps,
  } = useCombobox({
    items: inputItems,
    selectedItem: options.find((item) => item.id === serviceIdSelected),
    onInputValueChange: ({ inputValue }) => {
      setInputItems(filterByName(options, inputValue))
    },
    onSelectedItemChange: (value) => {
      handlerChange(value.selectedItem.id)
    },
    itemToString: (item) => (item ? item.label : ''),
  })

  return (
    <SelectWrapper {...getComboboxProps(comboboxProps)} id="select-service">
      <SelectInput
        {...getInputProps({
          onFocus: () => {
            if (!isOpen) {
              openMenu()
            }
          },
          onClick: (t) => {
            t.target.value = ''
            reset()
            openMenu()
          },
        })}
        type="text"
        className="input-select-service"
        placeholder=""
      />
      <ArrowButtonWrapper>
        <ArrowButton type="button" {...getToggleButtonProps()} aria-label="toggle menu" className="btn btn-clear">
          {isOpen ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>}
        </ArrowButton>
      </ArrowButtonWrapper>

      <OptionsList {...getMenuProps(menuProps)}>
        {isOpen &&
          inputItems.map((item, index) => (
            <Option
              key={`option-select-${index}`}
              index={index}
              item={item}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              openMenu={openMenu}
              handlerSelectItem={selectItem}
            />
          ))}
      </OptionsList>
    </SelectWrapper>
  )
}

export default SelectService
