import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Form, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useSubheader } from '../../../../_metronic/layout'
import Select from 'react-select'
import { getCurrentPageTitleSubTitle, getAllBreadcrumbs } from '../../../routes/Helpers'
import {
  allServices,
  allFiocruzUnits,
  tabularReportExport,
  tabularReportExportCount,
  setLoadingReportBuild,
  setReportBuildFalse,
  setLoadReportBuildRead,
} from '../_redux/reports/reportActions'
import { AlertMessage } from '../../Solicitation/components/services/AlertMessage'
import { toast } from 'react-toastify'

function ReportIntangibleAssetsPage() {
  const dispatch = useDispatch()
  const pathname = window.location.pathname

  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const [teste, setTeste] = useState({ units: [], services: [], startDate: null, endDate: null })

  const history = useHistory()
  const { serviceList, unitList, countRegisterTabularReport, reportRead, buildingReport } = useSelector(
    (state) => state.reports
  )

  const arrServices = serviceList
  const arrUnits = unitList

  useEffect(() => {
    if (countRegisterTabularReport !== null && countRegisterTabularReport > 0) {
      toast.success(`Sucesso! Relatório gerado.`, {})
      dispatch(tabularReportExport(teste.units, teste.services, teste.startDate, teste.endDate))
    }
    // eslint-disable-next-line
  }, [countRegisterTabularReport])

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(allServices())
    dispatch(allFiocruzUnits())
    // eslint-disable-next-line
  }, [pathname])

  useEffect(() => {
    if (reportRead) {
      dispatch(setReportBuildFalse())
      dispatch(setLoadReportBuildRead())
    }
    // eslint-disable-next-line
  }, [reportRead])

  const handleServices = (item) => {
    let servicesValues = item.map(function (unit) {
      return unit.value
    })
    setTeste({ ...teste, services: servicesValues })
  }
  const handleUnits = (item) => {
    let unitsValues = item.map(function (unit) {
      return unit.value
    })
    setTeste({ ...teste, units: unitsValues })
  }
  const handleStartDate = (item) => {
    setTeste({ ...teste, startDate: item })
  }
  const handleEndDate = (item) => {
    setTeste({ ...teste, endDate: item })
  }

  const handleSubmit = () => {
    dispatch(setLoadingReportBuild())
    dispatch(tabularReportExportCount(teste.units, teste.services, teste.startDate, teste.endDate))
    //dispatch(tabularReportExport(teste.units, teste.services, teste.startDate, teste.endDate))
  }

  return (
    <>
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Container>
            {buildingReport && (
              <>
                <Row>
                  <Col className="text-center">
                    <p className="overlay-layer bg-transparent mb-5">
                      <span className="spinner spinner-lg spinner-success" />
                    </p>
                    <p className="h4 mt-10">Criando relatório, por favor aguarde ...</p>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                {countRegisterTabularReport === 0 && (
                  <>
                    <AlertMessage
                      variant="alert alert-custom alert-danger alert-dismissible"
                      message={'Os parâmetros informados não retornaram registros para compor um relatório.'}
                    />
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-3 mb-5">
              <Col>
                <Form.Label>Selecione uma ou mais Unidades.</Form.Label>
                <Select
                  options={arrUnits}
                  noOptionsMessage={() => 'Sem opções disponíveis para o termo digitado'}
                  placeholder="Digite o código ou o nome das unidades."
                  onChange={(item) => handleUnits(item)}
                  isMulti
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-5">
              <Col>
                <Form.Label>Data Inicial</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Data inicial"
                  onChange={(dateStart) => handleStartDate(dateStart.target.value)}
                />
              </Col>
              <Col>
                <Form.Label>Data Final</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Data final"
                  onChange={(dateEnd) => handleEndDate(dateEnd.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-5">
              <Col>
                <Form.Label>Selecione um ou mais Serviços.</Form.Label>
                <Select
                  options={arrServices}
                  noOptionsMessage={() => 'Sem opções disponíveis para o termo digitado'}
                  placeholder="Digite o código ou o nome dos serviços."
                  onChange={(item) => handleServices(item)}
                  isMulti
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Container>
            <Row>
              <Col className="text-left">
                <Button variant="primary" onClick={() => handleSubmit()}>
                  Gerar Relatório
                </Button>
              </Col>
              <Col className="text-right">
                <Button variant="secondary" onClick={() => history.goBack()}>
                  <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </>
  )
}

export default ReportIntangibleAssetsPage
