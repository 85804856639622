import styled from 'styled-components'

export const List = styled.li`
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  border: 2px solid #ccc;
  display: flex;
  justify-content: space-between;
  list-style: none;
  transition: all 0.8s;
  line-height: 2;
  margin-bottom: 8px;

  &:hover {
    background-color: ${(props) => props.backgroundColor || 'rgba(20, 68, 134, 0.05)'};
  }

  .ActionArea {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
  }

  &:hover .ActionArea {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
`
export const ContentArea = styled.div`
  padding: 11.5px 10px 10px;
  width: 75%;
`

export const ActionArea = styled.div`
  display: flex;
  justify-content: end;
  width: 13%;
`
