import React from 'react'
import { Formik } from 'formik'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { Col, Row, Form } from 'react-bootstrap'

import { useSubheader } from '../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../routes/Helpers'

import { Button } from '../../components'
import { validationSchemaTaskType } from './validationSchema'
import { useDispatch, useSelector } from 'react-redux'
import { cleanErrors, cleanStatus, createTypeTask } from './_redux/tasks_types/tasksTypesActions'
import { toast } from 'react-toastify'

function TasksTypesCreate() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { saveError, saveStatus, statusMessage } = useSelector((state) => state.tasksTypes)

  const [type, setType] = React.useState('')

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle('Formulário para cadastro de tipo de tarefa.')

  React.useEffect(() => {
    dispatch(cleanErrors())
    dispatch(cleanStatus())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (statusMessage) {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
    }
    dispatch(cleanStatus())
    // eslint-disable-next-line
  }, [saveStatus, statusMessage])

  const handleCreateTaskType = () => {
    dispatch(createTypeTask(type))
      .then(() => {
        setType('')
        history.push('/tarefas/tipos')
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Formik
            validationSchema={validationSchemaTaskType}
            onSubmit={(values) => {
              console.log('-_')
            }}
            initialValues={{
              type: '',
            }}
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={12}>
                    <Form.Group md="12">
                      <Form.Label>Nome do Tipo de Tarefa</Form.Label>
                      <Form.Control
                        as="input"
                        name="type"
                        maxLength="100"
                        {...formik.getFieldProps('type')}
                        className={
                          (formik.touched.type && (formik.errors.type || type.length <= 3)) || saveError.length > 0
                            ? 'is-invalid'
                            : null
                        }
                        defaultValue=""
                        value={type}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setType(e.target.value)
                          dispatch(cleanErrors())
                        }}
                      />
                      {formik.touched.type && (formik.errors.type || (type.length <= 3 && type !== '')) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.type}
                        </Form.Control.Feedback>
                      )}
                      {saveError.length > 0 && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {saveError[0].type[0]}
                        </Form.Control.Feedback>
                      )}
                      <Form.Text className="text-right">Máximo de 100 caracteres.</Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Col className="text-right">
                    <Button type="submit" className="font-weight-bold" onClick={() => handleCreateTaskType()}>
                      Confirmar Criação
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>

        <Card.Footer className="text-right">
          <Button
            variant="secondary"
            onClick={() => {
              history.goBack()
            }}
          >
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default TasksTypesCreate
