import React, { useState, useEffect, useCallback } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { register } from '../_redux/authCrud'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactInputMask from 'react-input-mask'
import { validateCPF } from 'validations-br'
import { Col, Form, Row } from 'react-bootstrap'

const initialValues = {
  name: '',
  email: '',
  username: '',
  phone: '',
  date_of_birth: '',
  cpf: '',
  organization_id: '', //Gerar Unida padrão eleatório: Math.floor(Math.random() * 29 + 1).toString(),
  password: '',
  password_confirmation: '',
  acceptTerms: false,
  organizationList: {},
}

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

function Registration(props) {
  const { intl } = props
  const [saveStatus, setSaveStatus] = useState(null)
  const [organizacao, setOrganizacao] = useState([])
  const [registration, setRegistration] = useState({
    saveError: null,
    saveStatus: null,
    saveClassName: 'mb-10 alert alert-custom alert-light-info alert-dismissible',
  })
  const [isVerified, setIsVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userEmail, setUserEmail] = useState(null)

  const history = useHistory()

  const MySwal = withReactContent(Swal)

  const API_BASE_API_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  function handleOnChange(value) {
    setIsVerified(true)
  }

  const passwordInstructions = () => {
    MySwal.fire({
      title: 'Requisitos de segurança para o cadastro da senha de acesso',
      html: `<div style="text-align: justify;">
                <p>
                  A senha de acesso deve ter:
                </p>
                <ul>
                  <li>Entre 8 e 16 caracteres.</li>
                  <li>Pelo menos uma letra MAIÚSCULA.</li>
                  <li>Pelo menos uma letra MINÚSCULA.</li>
                  <li>Pelo menos um NÚMERO.</li>
                </ul>
                <p>
                  Obs.: Não utilize números sequenciais ou caracteres repetidos em sequência, nem datas comemorativas ou de nascimento em suas senhas.
                </p>
              </div>
        `,
      width: 600,
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Fechar',
    })
  }

  const wasRegistered = () => {
    if (saveStatus === 'success') {
      MySwal.fire({
        title: 'Atenção!',
        width: 600,
        html: `<p>Seus dados foram salvos com sucesso.</p> <p>Uma mensagem foi enviada para o e-mail <b>${userEmail} </b> contendo o link de ativação do seu cadastro.</p><p>Você possui 30 minutos para clicar no link de ativação e confirmar seu cadastro no sistema.</p>`,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/auth/login`)
        }
        if (result.isDismissed) {
          history.push(`/auth/login`)
        }
      })
    }
  }

  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Mínimo de 3 caracteres para este campo')
      .max(100, 'Máximo de 100 caracteres para este campo')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_EMAIL_FIELD',
        })
      )
      .min(3, 'Mínimo de 3 caracteres para este campo')
      .max(50, 'Máximo de 50 caracteres para este campo')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    phone: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .max(20, 'Máximo de 20 caracteres para este campo')
      .matches(
        /^[0-9 +-\\(\\)]+$/,
        'O campo "Telefone do usuário" aceita somente os caracteres 0 1 2 3 4 5 6 7 8 9 () +- .'
      ),

    date_of_birth: Yup.date()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .max(new Date(), 'Não é possível especificar uma data de nascimento futura.')
      .typeError('Este valor precisa ser uma data no formato (DD/MM/AAAA)'),

    cpf: Yup.string()
      .test('is-cpf', 'O campo "CPF do Usuário" deve ser um CPF válido.', (value) => validateCPF(value))
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    organization_id: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    password: Yup.string()
      .min(8, 'A senha deve conter no mínimo 8 caracteres.')
      .max(16, 'A senha deve conter no máximo 16 caracteres.')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .matches(/(?=.*[a-z])/, 'A senha requer pelo menos uma letra minúscula em sua composição.')
      .matches(/(?=.*[A-Z])/, 'A senha requer pelo menos uma letra maiúscula em sua composição.')
      .matches(/(?=.*[0-9])/, 'A senha requer pelo menos um número em sua composição.')
      .matches(
        /^(?!.*(01234|12345|23456|34567|45678|56789|67890)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(
        // eslint-disable-next-line
        /^(?!.*(\*\*\*\*\*|\&\&\&\&\&|\%\%\%\%\%|\$\$\$\$\$|\#\#\#\#\#|\@\@\@\@\@|\!\!\!\!\!|\?\?\?\?\?)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(
        /^(?!.*(98765|87654|76543|65432|54321|43210)).*$/,
        'A senha não pode conter mais do que 4 números sequenciais.'
      )
      .matches(/^(?!.*(\w)\1{4}).*$/, 'A senha não pode conter mais do que 4 caracteres repetidos em sequência.'),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Repita a senha informada.'),
      }),
    acceptTerms: Yup.bool().required('Você deve aceitar os Termos e Condições'),
  })

  const getOrganizacao = useCallback(async () => {
    await axios
      .get(`${API_BASE_URL}/api/organization/list/fiocruz_unit`)
      .then((response) => {
        setOrganizacao({ organizationList: response.data })
      })
      .catch((error) => {
        setRegistration({
          saveError: error,
          saveStatus: 'error',
          saveClassName: 'mb-10 alert alert-custom alert-danger alert-dismissible',
        })
      })
  }, [])

  useEffect(() => {
    getOrganizacao()
  }, [getOrganizacao])

  useEffect(() => {
    wasRegistered()
    // eslint-disable-next-line
  }, [saveStatus])

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      enableLoading()
      register(
        values.name,
        values.email,
        values.phone,
        values.date_of_birth,
        values.cpf,
        values.organization_id,
        values.password
      )
        .then(({ data: { accessToken } }) => {
          setUserEmail(values.email)
          props.register(accessToken)
          setSaveStatus('success')
          formik.resetForm({
            values: {
              ...values,
              name: '',
              username: '',
              phone: '',
              date_of_birth: '',
              cpf: '',
              password: '',
              password_confirmation: '',
              email: '',
              organization_id: '',
            },
          })
          setStatus(
            intl.formatMessage({
              id: 'AUTH.REGISTER.SUCCESS',
            })
          )
          setRegistration({
            ...registration,
            saveError: null,
            saveStatus: 'success',
            saveClassName: 'mb-10 alert alert-custom alert-success alert-dismissible',
          })
          disableLoading()
          setSubmitting(false)
        })
        .catch((error) => {
          //setMsgErrors({...erros, organizacaoErrors: error.response.data})
          setSaveStatus('error')
          setRegistration({
            ...registration,
            saveError: error.response.data,
            saveStatus: 'error',
            saveClassName: 'mb-10 alert alert-custom alert-danger alert-dismissible',
          })
          setSubmitting(false)
          setStatus(
            intl.formatMessage({
              id: 'AUTH.REGISTER.ERROR',
            })
          )
          disableLoading()
        })
    },
  })
  return (
    <>
      <div className="mt-20">
        <div className="login-form login-signin" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.REGISTER.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">Insira seus dados para criar sua conta</p>
          </div>
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div className={registration.saveClassName}>
                <div className="alert-text font-weight-bold">
                  {formik.status}
                  {registration.saveError !== null && Array.isArray(registration.saveError) && (
                    <div>
                      <hr />
                      <ul>
                        {registration.saveError.map((err, i) => (
                          <li key={i}>{Object.entries(err)[i][1]}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* end: Alert */}
            {/* begin: name */}
            <div className="form-group fv-plugins-icon-container">
              <label>Nome completo:</label>
              <input
                placeholder="Digite seu nome completo"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('name')}`}
                name="name"
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>
            {/* end: name */}
            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <label>E-mail:</label>
              <input
                placeholder="Digite seu e-mail"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
                name="email"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}
            {/* begin: Telefone */}
            <div className="form-group fv-plugins-icon-container">
              <label>Telefone:</label>
              <input
                placeholder="Digite seu telefone"
                type="text"
                maxLength="20"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('phone')}`}
                name="phone"
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.phone}</div>
                </div>
              ) : null}
            </div>
            {/* end: Telefone */}
            {/* begin: Data de Nascimento */}
            <div className="form-group fv-plugins-icon-container">
              <label>Data de Nascimento:</label>
              <input
                placeholder="Data de Nascimento"
                type="date"
                //onKeyUp={(a) => setFieldBirth(a.target.value)}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('date_of_birth')}`}
                name="date_of_birth"
                {...formik.getFieldProps('date_of_birth')}
              />
              {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.date_of_birth}</div>
                </div>
              ) : null}
            </div>
            {/* end: Data de Nascimento */}
            {/* begin: CPF */}
            <div className="form-group fv-plugins-icon-container">
              <label>CPF:</label>
              <ReactInputMask
                name="cpf"
                mask="999.999.999-99"
                maskChar=""
                placeholder="Digite seu CPF"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('cpf')}`}
                {...formik.getFieldProps('cpf')}
              />

              {formik.touched.cpf && formik.errors.cpf ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.cpf}</div>
                </div>
              ) : null}
            </div>
            {/* end: CPF */}
            {/* begin: Unidade */}
            <div className="form-group fv-plugins-icon-container">
              <label>Unidade:</label>
              {typeof organizacao.organizationList !== 'undefined' && (
                <select
                  defaultValue={organizacao.defaultValue}
                  name="organization_idd"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('organization_id')}`}
                  {...formik.getFieldProps('organization_id')}
                >
                  <option value="" disabled>
                    Selecione sua Unidade
                  </option>
                  {organizacao.organizationList.map((org, key) => (
                    <option key={org.id} value={org.id}>
                      {org.organization}
                    </option>
                  ))}
                </select>
              )}

              {formik.touched.organization_id && formik.errors.organization_id ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.organization_id}</div>
                </div>
              ) : null}
            </div>
            {/* end: Unidade */}
            {/* begin: Password Instructions */}
            <div className="form-group fv-plugins-icon-container">
              <span className="btn btn-block text-justify text-info" onClick={() => passwordInstructions()}>
                Clique aqui e veja os requisitos de segurança para criação da senha
              </span>
            </div>
            {/* end: Password Instructions */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <label>Senha:</label>
              <input
                placeholder="Digite sua senha"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
                name="password"
                autoComplete="on"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}
            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container mt-0 pt-0">
              <label>Confirmação de senha:</label>
              <input
                placeholder="Digite a confirmação de sua senha"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'password_confirmation'
                )}`}
                name="password_confirmation"
                autoComplete="on"
                {...formik.getFieldProps('password_confirmation')}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.password_confirmation}</div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}
            {/* begin: Terms and Conditions */}
            <div className="form-group">
              <Row className="form-group">
                <Col>
                  <Form.Check
                    className="float-left"
                    label=""
                    name="acceptTerms"
                    type={'switch'}
                    id={`acceptTerms`}
                    {...formik.getFieldProps('acceptTerms')}
                  />
                  {/* <Link to="/terms" target="_blank" className="mr-1 font-weight-bold text-left" rel="noopener">
                    Eu concordo com os Termos e Condições
                  </Link> */}
                  <a
                    href={`${API_BASE_API_URL}/api/attachment/download_term`}
                    download={'Termos e Condições'}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Eu concordo com os Termos e Condições
                  </a>
                </Col>
              </Row>
            </div>
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap flex-center">
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleOnChange} />

              {!formik.isSubmitting && registration.saveStatus !== 'success' && (
                <button
                  type="submit"
                  disabled={formik.isSubmitting || !isVerified || !formik.isValid || !formik.values.acceptTerms}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <span>Gravar</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              )}

              <Link to="/auth/login">
                <button type="button" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                  Cancelar
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(Registration))
