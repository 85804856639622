import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const APP_API_FORMS_URL = process.env.REACT_APP_API_FORMS_URL

export const getMySolicitations = async () => await axios.get(`${API_BASE_URL}/api/solicitation/view/mysolicitations`)
export const getMySolicitationsFromIntangibleAsset = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/view/mysolicitations/intangible_asset/${id}`)

export const getMyCanceledSolicitations = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/view/my_canceled_solicitations`)

export const showSolicitation = async (id) => await axios.get(`${API_BASE_URL}/api/solicitation/${id}`)

export const solicitationManagement = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/management/solicitation/${id}`)

export const solicitationIntangibleAssetOrigin = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/${id}/intagible_asset_origin`)

export const getStepOne = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/step_one/solicitation/registration`)

export const setStepTwo = async (obj) => {
  let params = {
    description: obj.description,
    service_id: obj.serviceIdSelected,
    organization_id: obj.organizationIdSelected,
  }

  if (obj.parant === 'intangible_asset') {
    params = {
      ...params,
      intangible_asset_id: obj.intangibleAssetSelected,
    }
  } else {
    params = {
      ...params,
      solicitation_parent_id: obj.solicitationSelectedId,
    }
  }

  return await axios.post(`${API_BASE_URL}/api/solicitation/step_two/store`, params)
}

export const editStepTwo = async (obj) => {
  let params = {
    description: obj.description,
    service_id: obj.serviceIdSelected,
    organization_id: obj.organizationIdSelected,
  }

  if (obj.parant === 'intangible_asset') {
    params = {
      ...params,
      intangible_asset_id: obj.intangibleAssetSelected,
    }
  } else {
    params = {
      ...params,
      solicitation_parent_id: obj.solicitationSelectedId,
    }
  }

  return await axios.put(`${API_BASE_URL}/api/solicitation/${obj.solicitationInProgressId}/step_two/edit`, params)
}

export const getServices = async () => await axios.get(`${API_BASE_URL}/api/services/for-ref`)

export const hasServicesEnabled = async () => await axios.get(`${API_BASE_URL}/api/services/has_enabled`)

export const getSolicitationHistory = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/history/solicitation/${id}`)

export const getRelatedSolicitations = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/related_solicitations/solicitation/${id}`)

export const destroy = async (id) => await axios.delete(`${API_BASE_URL}/api/solicitation/${id}`)

export const checkingRequirementsMetBySolicitation = async (id) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/check/requirements/solicitation/${id}`)

export const saveMandatoryDownload = async (obj) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${obj.solicitationId}/step_three/mandatory_download/store`, {
    requirement_version_id: obj.requirementVersionId,
    attachment_id: obj.attachmentId,
  })

export const saveReadInfoMessage = async (obj) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${obj.solicitationId}/step_three/read_info_message/store`, {
    requirement_version_id: obj.requirementVersionId,
  })

export const saveUploadFiles = async (obj) => {
  const data = new FormData()
  data.append('requirement_version_id', obj.requirementVersionId)
  data.append('description', obj.fileDescription)
  data.append('uuid', obj.attachment.uuid)
  data.append('attachments', obj.attachment)

  return await axios.post(
    `${API_BASE_URL}/api/solicitation/${obj.solicitationId}/step_three/upload_files/store`,
    data,
    {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    }
  )
}

export const deleteSolicitationFile = async (solicitationUploadFileId) =>
  await axios.delete(`${API_BASE_URL}/api/solicitation/solicitationuploadfiles/${solicitationUploadFileId}`)

export const updateSolicitationFileDescription = async (obj) =>
  await axios.put(`${API_BASE_URL}/api/solicitation/solicitationuploadfiles/${obj.solicitationUploadFileId}/update`, {
    description: obj.description,
  })

export const saveStepThree = async (solicitationId) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/step_three/store`)

export const saveRequirementRead = async (obj) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${obj.solicitationId}/step_three/requirement_read/store`, {
    requirement_version_id: obj.requirementVersionId,
  })

export const getTasksSolicitation = async (solicitationId, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/task/solicitation/${solicitationId}`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getFormFilledResponse = async (encryptKeyForm) =>
  await axios.get(`${APP_API_FORMS_URL}/api/form/occurrence-close/${encryptKeyForm}`)
