const formObject = (
  userEmail,
  solicitationId,
  solicitationCode,
  requirementVersionCode,
  urlLocation,
  formHash,
  intangibleAsset = null,
  serviceData = null
) => {
  let obj = JSON.stringify({
    personData: {
      userEmail: {
        data: userEmail,
        inFormHeader: {
          label: 'Usuário:',
          order: 1,
        },
        inFormModal: null,
      },
      solicitationId: {
        data: solicitationId,
        inFormHeader: null,
        inFormModal: null,
      },
      solicitationCode: {
        data: solicitationCode,
        inFormHeader: null,
        inFormModal: {
          label: 'Código da Solicitação:',
          order: 2,
        },
      },
      requirementVersionId: {
        data: requirementVersionCode,
        inFormHeader: null,
        inFormModal: {
          label: 'Requisito:',
          order: 4,
        },
      },
      intangibleAssetData: {
        data: intangibleAsset,
        inFormHeader: null,
        inFormModal: {
          label: 'Ativo Intangível:',
          order: 1,
        },
      },
      itemServiceData: {
        data: serviceData,
        inFormHeader: null,
        inFormModal: {
          label: 'Serviço:',
          order: 3,
        },
      },
    },
    formHash: formHash,
    //formHash: '7e249bba-446b-416b-4a88-f4c9542c7b26',
    beforeLocation: urlLocation,
  })

  return obj
}

export default formObject
