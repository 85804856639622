import React, { useState, memo, useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { validationSchemaForumMessage } from '../../../../pages/attendances/validationSchema'
import Dropzone from '../../../../../../components/Dropzone'

const ForumMessageForm = ({
  configAlert,
  setConfigAlert,
  solicitationSelected,
  handleDispatch,
  typeMessage = 'create',
}) => {
  const [attachments, setAttachments] = useState([])
  const [description, setDescription] = useState(localStorage.getItem('forumMessage'))

  useEffect(() => {
    setDescription(localStorage.getItem('forumMessage'))
  }, [])

  return (
    <div style={{ textAlign: 'left', width: '97%' }}>
      <p>Decreva abaixo o conteúdo de sua mensagem.</p>
      <Formik
        validationSchema={validationSchemaForumMessage}
        onSubmit={(values) => {
          if (values.forumMessage.length >= 3) {
            setConfigAlert({ ...configAlert, closeToast: true })
            handleDispatch({ message: values.forumMessage, attachments })
            localStorage.setItem('forumMessage', '')
          }
        }}
        initialValues={{
          forumMessage: description,
        }}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit} style={{ width: '98%' }}>
            <Row>
              <Col sm={12}>
                <Form.Group md="12">
                  <Form.Label>Mensagem</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    name="forumMessage"
                    maxLength="1000"
                    {...formik.getFieldProps('forumMessage')}
                    className={
                      formik.touched.forumMessage && (formik.errors.forumMessage || description.length <= 3)
                        ? 'is-invalid'
                        : null
                    }
                    defaultValue={description}
                    value={description}
                    onChange={(e) => {
                      formik.handleChange(e)
                      setDescription(e.target.value)
                      localStorage.setItem('forumMessage', e.target.value)
                    }}
                  />
                  {formik.touched.forumMessage && (formik.errors.forumMessage || description.length <= 3) && (
                    <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                      {formik.errors.forumMessage}
                    </Form.Control.Feedback>
                  )}
                  <Form.Text className="text-right">
                    {formik.values.forumMessage.length !== 0
                      ? `${formik.values.forumMessage.length}/1000 caracteres`
                      : 'Máximo de 1000 caracteres.'}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Dropzone
                  attachments={attachments}
                  setAttachments={setAttachments}
                  maxAmountFiles={10}
                  formByFile="UPLOAD_IN_LIGHTBOX"
                />
                <br />
                <br />
              </Col>
            </Row>
            <Row className="mt-0">
              <Col className="text-right">
                <Button
                  type="submit"
                  className="font-weight-bold"
                  onClick={() => {
                    console.clear()
                  }}
                >
                  {typeMessage === 'create' ? 'Criar Mensagem' : 'Responder Mensagem'}
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    localStorage.setItem('forumMessage', '')
                    localStorage.setItem('completeAttachments', JSON.stringify([]))
                    setConfigAlert({ ...configAlert, closeToast: true })
                  }}
                  className="font-weight-bold"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default memo(ForumMessageForm)
