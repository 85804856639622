import * as requestFromServer from './serviceEndpoints'
import { serviceSlice } from './serviceSlice'

const { actions } = serviceSlice

/**
 * Objeto com todos os serviços.
 */
export const allServices = () => (dispatch) => {
  // dispatch(actions.setStatusMessage("mensagem definida"));
  return requestFromServer
    .getAllServices()
    .then((response) => {
      dispatch(actions.getServices(response.data))
      dispatch(allSimplifiedUserList())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Objeto com um Serviço específico
 * @param {*} id
 * @returns
 */
export const getService = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Service .....'))
  return requestFromServer
    .getService(id)
    .then((response) => {
      dispatch(actions.getService(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Remoção de serviço (delete)
 * @param {*} id
 * @returns
 */
export const removeService = (id) => (dispatch) => {
  return requestFromServer
    .deleteService(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('serviceDeleted'))
      dispatch(actions.deleteService(response.data))
      dispatch(actions.setSuccessOnDelete(response.data.message))
      dispatch(allServices())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setErrorOnDelete(error.response.data.message))
      dispatch(actions.clearErrorOnDelete())
    })
}
/**
 * Habilitar serviço
 * @param {*} id
 * @returns
 */
export const enableService = (id) => (dispatch) => {
  return requestFromServer
    .enableService(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('serviceEnabled'))
      dispatch(actions.enabledService(response.data))
      dispatch(allServices())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Desabilitar serviço
 * @param {*} id
 * @returns
 */
export const disableService = (id) => (dispatch) => {
  return requestFromServer
    .disableService(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('serviceDisable'))
      dispatch(actions.disableService(response.data))
      dispatch(allServices())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Criar serviço
 * @param {*} newServiceObj
 * @returns
 */
export const createService = (newServiceObj) => (dispatch) => {
  return requestFromServer
    .newService(newServiceObj)
    .then((response) => {
      dispatch(actions.setStatusMessage(response.data.service))
      dispatch(actions.saveServiceStatusSuccess(response.data.message))
      dispatch(allServices())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Adicionar Administrador ao serviço
 * @param {*} idService
 * @param {*} idUser
 * @returns
 */
export const addAdminToService = (idService, idUser) => (dispatch) => {
  return requestFromServer
    .setServiceAdminitrator(idService, idUser)
    .then((response) => {
      dispatch(actions.setAdministratorToService(response.data))
      dispatch(actions.saveServiceStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Adicionar Atendente ao serviço
 * @param {*} idService
 * @param {*} idUser
 * @returns
 */
export const addAttendantToService = (idService, idUser) => (dispatch) => {
  return requestFromServer
    .setServiceAttendant(idService, idUser)
    .then((response) => {
      dispatch(actions.removeAttendantFromService(response.data.message))
      dispatch(actions.saveServiceStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Remover Administrador de Serviço
 * @param {*} idService
 * @param {*} idUser
 * @returns
 */
export const removeAdminFromService = (idService, idUser) => (dispatch) => {
  return requestFromServer
    .removeAdministratorFromService(idService, idUser)
    .then((response) => {
      dispatch(actions.removeAdministratorFromService(response.data))
      dispatch(actions.saveServiceStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Remover Atendente do Serviço
 * @param {*} idService
 * @param {*} idUser
 * @returns
 */
export const removeAttendantFromService = (idService, idUser) => (dispatch) => {
  return requestFromServer
    .removeAttendantFromService(idService, idUser)
    .then((response) => {
      dispatch(actions.removeAttendantFromService(response.data))
      dispatch(actions.saveServiceStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.errorOnDeleteAttendant(error.response.data.message))
    })
}
/**
 * Atualizar serviço
 * @param {*} id
 * @param {*} editServiceObj
 * @returns
 */
export const updateService = (id, editServiceObj) => (dispatch) => {
  dispatch(actions.setStatusMessage('Editando Serviço', '[', editServiceObj, ']'))
  return requestFromServer
    .editService(id, editServiceObj)
    .then((response) => {
      dispatch(actions.saveServiceStatusSuccess(response.data.message))
      dispatch(actions.updatedService(response.data))
      dispatch(allServices())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Listagem simplificada de usuário
 * @returns
 */
export const allSimplifiedUserList = () => (dispatch) => {
  dispatch(actions.setStatusMessage('Lista de usuário simplificada carregada'))
  return requestFromServer
    .getUsersSimplified()
    .then((response) => {
      dispatch(actions.getUserListSimplified(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Objeto com um Serviço específico do administrador
 * @param {*} id
 * @returns
 */
export const getMyServicesAdmin = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getMyServicesAdmin(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getMyServicesAdmin(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.getMyServicesAdmin(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Objeto com um Serviço específico do atendente
 * @param {*} per_page
 * @param {*} page
 * @param {*} search
 * @param {*} sort_field
 * @param {*} ordering
 * @returns
 */
export const getMyServicesAttendant = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getMyServicesAttendant(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getMyServicesAttendant(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.getMyServicesAttendant(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Objeto com um Serviço específico do atendente
 * @param {*} id
 * @returns
 */
export const getAttendantsQualifiedForService = (id, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getAttendantsQualifiedForService(id, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getAttendants(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.getMyServicesAttendant(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}
/**
 * limpa error on state
 * @returns
 */
export const clearErrorsOnDelete = () => (dispatch) => {
  return dispatch(actions.clearErrorOnDelete())
}
