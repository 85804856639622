import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Button, Container, Row, Col, InputGroup } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  getAllBlockedUsersAction,
  blockUserAction,
  enableUserAction,
  clearLastError,
} from '../../_redux/userQualification/userQualificationActions'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../routes/Helpers'
import { useSubheader } from '../../../../../_metronic/layout'

import { toast } from 'react-toastify'
import TooltipCustom from '../../../../components/TooltipCustom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AlertMessage } from '../../../Solicitation/components/services/AlertMessage'
import CancelAccessForm from '../../components/CancelAccessForm'

export function UserQualification() {
  const MySwal = withReactContent(Swal)

  const dispatch = useDispatch()
  const history = useHistory()
  const { usersBlockedList, lastError } = useSelector((state) => state.userQualification)
  const { authToken } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.auth)

  const [denyUser, setDenyUser] = useState({})
  const [enableUser, setEnableUser] = useState({})
  const [searchEmpty, setSearchEmpty] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState(null)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)
  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    dispatch(getAllBlockedUsersAction())
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (showToast) {
      if (lastError !== null) {
        toast.error(() => (
          <div
            dangerouslySetInnerHTML={{
              __html: `Atenção! ${lastError.message}.`,
            }}
          />
        ))
        setShowToast(false)
        setMessage(null)
        dispatch(getAllBlockedUsersAction()) //Force
      } else {
        toast.success(() => (
          <div
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        ))
        setShowToast(false)
        setMessage(null)
        dispatch(getAllBlockedUsersAction()) //Force
      }
    }
    // eslint-disable-next-line
  }, [showToast])

  useEffect(() => {
    //blockSetUser(denyUser.id, denyUser)
    if (typeof denyUser.id !== 'undefined') {
      //dispatch(blockUserAction(denyUser.id))
      dispatch(getAllBlockedUsersAction())
      setDenyUser({})
    }
    if (typeof enableUser.id !== 'undefined') {
      // dispatch(enableUserAction(enableUser.id))
      dispatch(getAllBlockedUsersAction())
      setEnableUser({})
    }

    // eslint-disable-next-line
  }, [denyUser, enableUser])

  const { SearchBar } = Search

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela
   * provida por <BootstrapTable ?>.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsFormatter = (cell, row) => (
    <>
      <TooltipCustom title="Liberar Acesso" placement="top" arrow>
        <Button
          variant="success"
          size="sm"
          id={row.id}
          onClick={() =>
            MySwal.fire({
              title: 'Liberar acesso',
              html: (
                <>
                  <CancelAccessForm userName={row.name} rowContent={row} token={authToken} />
                </>
              ),
              icon: '',
              heightAuto: true,
              width: 600,
              showConfirmButton: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                //executar função api para liberar acesso
                setEnableUser({ id: row.id, hash_mail: row.hash_mail, name: row.name })
                dispatch(enableUserAction(row.id))
                dispatch(getAllBlockedUsersAction()).then(() => {
                  setMessage(`Sucesso! <strong> ${row.name}</strong> agora é um usuário habilitado do sistema.`)
                  setShowToast(true)
                  dispatch(clearLastError())
                })
              }
            })
          }
        >
          <i className="fas fa-user-check"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Negar Acesso" placement="top" arrow>
        <Button
          variant="danger"
          size="sm"
          id={row.id}
          onClick={async function() {
            MySwal.fire({
              title: 'Recusar acesso',
              html: `Deseja recusar o pedido de acesso para usuário <strong>${row.name}</strong>`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            }).then((result) => {
              dispatch(blockUserAction(row.id))
              dispatch(getAllBlockedUsersAction()).then(() => {
                setMessage(`Sucesso! Pedido de liberação de acesso recusado para <strong> ${row.name}</strong>.`)
                setShowToast(true)
                dispatch(clearLastError())
              })
              setDenyUser({ id: row.id })
            })
          }}
        >
          <i className="fas fa-user-slash fa-sm"></i>
        </Button>
      </TooltipCustom>
    </>
  )

  const actionsFormatterFieldName = (cell, row) => (
    <>
      <span>
        <strong>{row.name}</strong>
      </span>
      <br />
      <span>
        <small>{row.email}</small>
      </span>
    </>
  )
  const actionsFormatterFieldCPF = (cell, row) => (
    <>
      <span>
        <strong>{row.cpf}</strong>
      </span>
    </>
  )

  /**
   * Colunas da tabela de User Qualification
   */
  const columns = [
    {
      dataField: 'id',
      classes: 'align-middle',
      text: '#',
      sort: true,
      headerStyle: { width: '4%', textAlign: 'center' },
    },
    {
      dataField: 'moment',
      classes: 'align-middle',
      text: 'Momento do Cadastro',
      sort: true,
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'name',
      classes: 'align-middle',
      text: 'Usuário',
      sort: true,
      formatter: actionsFormatterFieldName,
      headerStyle: { width: '20%', textAlign: 'center' },
    },

    {
      dataField: 'cpf',
      classes: 'align-middle',
      text: 'CPF',
      sort: true,
      formatter: actionsFormatterFieldCPF,
      headerStyle: { width: '10%', textAlign: 'center' },
    },
    {
      dataField: 'date_of_birth_formated',
      classes: 'align-middle',
      text: 'Data de Nascimento',
      sort: true,
      headerStyle: { width: '7%', textAlign: 'center' },
    },
    {
      dataField: 'organization.name',
      classes: 'align-middle',
      text: 'Unidade',
      sort: true,
      headerStyle: { width: '26%', textAlign: 'center' },
    },
    {
      dataField: 'phone',
      classes: 'align-middle',
      text: 'Telefone',
      sort: true,
      headerStyle: { width: '13%', textAlign: 'center' },
    },
    {
      dataField: 'actions',
      classes: 'align-middle',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: actionsFormatter,
      headerStyle: { width: '10%', textAlign: 'center' },
    },
  ]
  /**
   * Alterando o texto de exibição de registros.
   *
   * @param {*} from
   * @param {*} to
   * @param {*} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;&nbsp;Exibindo registros de <strong>{from}</strong> a <strong>{to}</strong> de um total de{' '}
      <strong>{size}</strong> registros.
    </span>
  )

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
          style={{ height: 30, paddingTop: 5 }}
        >
          {option.text}
        </button>
      ))}
    </div>
  )
  /**
   * Parâmetros para paginação
   */
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: usersBlockedList.length,
      },
    ],
    sizePerPageRenderer,
  }

  const qtdInSearch = (data, itemSearchText) => {
    const searcheredData = data.filter(function(item) {
      if (
        item.email.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.cpf.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.phone.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.organization.name.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.moment.indexOf(itemSearchText) !== -1
      ) {
        return item
      }
      return false
    })
    return searcheredData.length > 0 ? setSearchEmpty(false) : setSearchEmpty(true)
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            <ToolkitProvider keyField="id" data={usersBlockedList} columns={columns} search>
              {(props) => (
                <>
                  <Row>
                    <Col sm={9}></Col>
                    <Col sm={3}>
                      <div className="d-grid"></div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-4">
                      <InputGroup>
                        <InputGroup.Text style={{ height: 36 }}>
                          <i className="fas fa-search"></i>
                        </InputGroup.Text>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Digite o alvo de sua busca"
                          className="w-100"
                          style={{ height: 36 }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      {qtdInSearch(usersBlockedList, props.searchProps.searchText)}

                      {((usersBlockedList.length === 0 && !searchEmpty) || searchEmpty) && (
                        <>
                          <AlertMessage
                            variant="alert alert-custom alert-light-primary alert-dismissible"
                            message={
                              props.searchProps.searchText.length > 0
                                ? `Não foram encontrados resultados para o termo ${props.searchProps.searchText}`
                                : 'Não há nenhuma ocorrência de novos usuarios cadastrados para liberação de acesso.'
                            }
                          />
                        </>
                      )}
                      {!searchEmpty && (
                        <BootstrapTable
                          {...props.baseProps}
                          classes="bordered"
                          keyField="id"
                          data={usersBlockedList}
                          columns={columns}
                          rowStyle={{ color: 'black' }}
                          headerClasses="text-center bg-light"
                          rowClasses="text-center"
                          bordered={true}
                          noDataIndication="Não há nenhuma ocorrência de novos usuarios cadastrados para liberação de acesso"
                          pagination={paginationFactory(pageOptions)}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}
