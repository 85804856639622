import styled from 'styled-components'

export const Code = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 1rem;
  margin-left: 1rem;
`

export const HeaderWrapper = styled.div`
  //border-bottom: 1px solid #ebebeb;
  width: 100%;
  //padding-bottom: 20px;
  margin-bottom: 20px;
`

export const TitleHeader = styled.h3`
  margin: 0px;
`

export const ItemLittleSausage = styled.div`
  padding: 10px 20px;
  display: flex;
  background-color: #f3f3f3;
  width: 100%;
  border-radius: 10px;
`

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 5px;
`

export const ContentRowItemLittleSausage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  ${ItemLittleSausage}:nth-child(odd) {
    margin-right: 10px;
  }
`



