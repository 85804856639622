import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSubheader } from '../../../../../_metronic/layout'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import TooltipCustom from '../../../../components/TooltipCustom'
import { Card, Container, Row, Col, Button, InputGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { allServices, removeService, enableService, disableService } from '../../_redux/services/serviceActions'
import { AlertMessage } from '../../components/services/AlertMessage'
import AlertGT from '../../../../components/AlertGT'

export function ServiceListPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)
  const { serviceList, saveStatus, listLoading, errorOnDelete } = useSelector((state) => state.services)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const [showAlert, setShowAlert] = React.useState(false)
  const [alertTitle, setAlertTitle] = React.useState('')
  const [alertText, setAlertText] = React.useState('')
  const [alertConfirm, setAlertConfirm] = React.useState(null)
  const [alertShowCancelButton, setAlertShowCancelButton] = React.useState(false)
  const [alertConfirmButtonText, setAlertConfirmButtonText] = React.useState(null)
  const [alertIcon, setAlertIcon] = React.useState(null)
  const [toastSuccess, setToastSuccess] = React.useState(null)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  /**
   * Prepara estado local para loading de Exclusão de serviço
   */
  const [loadinProcessSendmail, setLoadinProcessSendmail] = useState(false)
  const [hideServiceList, setHideServiceList] = useState(false)
  const [searchEmpty, setSearchEmpty] = useState(false)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(allServices())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (errorOnDelete !== null) {
      setLoadinProcessSendmail(false)
      setHideServiceList(false)
      toast.error(`Erro! ${errorOnDelete}`, {})
    }
    // eslint-disable-next-line
  }, [errorOnDelete])

  /**
   * Remover loading de envio de email na exclusão do serviço
   */
  if (saveStatus === 'success' && loadinProcessSendmail === true) {
    setLoadinProcessSendmail(false)
    setHideServiceList(false)
    toast.success(`Sucesso!  Serviço excluído.`, {})
  }

  /**
   * Função que retorna os dados de um serviço a partir do id de serviço
   * @param {*} idService
   */
  const getServiceById = (serviceId) => {
    return serviceList.find((service) => {
      if (service.id === serviceId) {
        return service
      }
      return false
    })
  }

  /**
   * função serviceDelete
   *
   * Função para disparar action de exclusão de serviço
   * @param {*} idService
   * @param {*} code
   * @param {*} title
   */
  const serviceDelete = (idService, code, title) => {
    setAlertTitle('Excluir Serviço')
    setAlertText(`Deseja excluir serviço <strong>${code} - ${title}</strong>?`)
    setAlertIcon('question')
    setAlertShowCancelButton(true)
    setAlertConfirmButtonText('Sim')
    setToastSuccess(null)

    const ConfirmDelete = () => {
      setLoadinProcessSendmail(true)
      setHideServiceList(true)
      dispatch(removeService(idService))
      setShowAlert(false)
    }

    setAlertConfirm(() => ConfirmDelete)
    setShowAlert(true)
  }
  /**
   * função serviceToggleEnabled
   *
   * Esta função dispara a action para habilitar o serviço, caso esteja
   * desabilitado ou vice-versa.
   * @param {*} idService
   * @param {*} enable
   * @param {*} code
   * @param {*} title
   */
  const serviceToggleEnabled = (idService, enable, code, title) => {
    const service = getServiceById(idService)
    const hasAdministrators = service.administrators.length > 0
    const hasAttendants = service.attendants.length > 0

    if (!enable && (!hasAdministrators || !hasAttendants)) {
      return toast.error(
        'Para habilitar um serviço é necessário que ele possua pelo menos um administrador de serviço e um atendente.'
      )
    }

    const toggleActionTitle = enable ? 'Desabilitar o serviço' : 'Habilitar o serviço'
    const toggleActionSubTitle = enable ? 'Deseja desabilitar o serviço ' : 'Deseja habilitar o serviço '

    setAlertTitle(toggleActionTitle)
    setAlertText(`${toggleActionSubTitle} <strong>${code} - ${title}</strong>?`)
    setAlertIcon('question')
    setAlertShowCancelButton(true)
    setAlertConfirmButtonText('Sim')
    setToastSuccess(`Sucesso!  Serviço ${enable ? 'desabilitado' : 'habilitado'}.`)

    const ConfirmToggle = () => {
      if (enable) dispatch(disableService(idService))
      else dispatch(enableService(idService))
      setShowAlert(false)
    }

    setShowAlert(true)
    setAlertConfirm(() => ConfirmToggle)
  }
  /**
   * função serviceToggleRequeriments
   */
  const serviceToggleRequeriments = (idService) => {
    history.push(`/servico/${idService}/requisitos`)
  }
  /**
   * função handleView
   *
   * Esta função abre a page de Consulta do Serviço,
   * se o operador for do tipo admin ou admin do servico
   * @param {*} idService
   */
  const handleView = (idService) => {
    history.push(`/servico/${idService}/consulta`)
  }

  const { SearchBar } = Search
  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsFormatter = (cell, row) => (
    <>
      <TooltipCustom title="Gerenciar Serviço" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => history.push(`/servico/${row.id}/gerenciamento`)}
        >
          <i className="fas fa-edit fa-sm"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Consultar dados do Serviço" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => handleView(row.id)}
        >
          <i className="fas fa-search fa-sm"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Excluir Serviço" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => serviceDelete(row.id, row.code, row.title)}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title={row.enable ? 'Desabilitar Serviço' : 'Habilitar Serviço'} placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => serviceToggleEnabled(row.id, row.enable, row.code, row.title)}
        >
          {row.enable ? <i className="fas fa-lock fa-sm"></i> : <i className="fas fa-lock-open fa-sm"></i>}
        </Button>
      </TooltipCustom>{' '}
      <TooltipCustom title="Requisitos do Serviço" placement="top" arrow>
        <Button
          className="mt-1 mb-1"
          variant="outline-secondary"
          size="sm"
          id={row.id}
          onClick={() => serviceToggleRequeriments(row.id)}
        >
          <i className="fa fa-list"></i>
        </Button>
      </TooltipCustom>{' '}
    </>
  )
  /**
   * Função administratorsFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Administradores)
   * provida por <BootstrapTable />.  Neste caso,  exibe relação de administradores
   * separadas por vírgula
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const administratorsFormatter = (cell, row) => {
    let administratorList = row.administrators.map((admin) => {
      return admin.name
    })
    return administratorList.length > 0 ? administratorList.join(', ') : 'Sem administradores'
  }
  const enableFormatter = (cell, row) => {
    return row.enable ? 'SIM' : 'NÃO'
  }
  const requirementsFormatter = (cell, row) => {
    return row.countRequirements > 0 ? row.countRequirements : 'Sem requisitos'
  }

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'title',
      text: 'Título',
      align: 'left',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '37%', textAlign: 'left', overflowWrap: 'anywhere' }
      },
    },
    {
      dataField: 'countRequirements',
      text: 'Requisitos',
      sort: true,
      classes: 'align-middle',
      isDummyField: false,
      headerStyle: () => {
        return { width: '5%' }
      },
      formatter: requirementsFormatter,
    },
    {
      dataField: 'enable',
      text: 'Habilitado?',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '3%' }
      },
      formatter: enableFormatter,
    },
    {
      dataField: 'administradores',
      text: 'Administradores',
      align: 'left',
      isDummyField: false,
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '30%', textAlign: 'left' }
      },
      formatter: administratorsFormatter,
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      isDummyField: true,
      csvExport: false,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '20%', minWidth: 200 }
      },
      formatter: actionsFormatter,
    },
  ]
  /**
   * Alterando o texto de exibição de registros.
   *
   * @param {*} from
   * @param {*} to
   * @param {*} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;&nbsp;Exibindo registros de <strong>{from}</strong> a <strong>{to}</strong> de um total de{' '}
      <strong>{size}</strong> registros.
    </span>
  )
  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
          style={{ height: 30, paddingTop: 5 }}
        >
          {option.text}
        </button>
      ))}
    </div>
  )

  /**
   * Parâmetros para paginação
   */
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: serviceList.length,
      },
    ],
    sizePerPageRenderer,
  }

  const qtdInSearch = (data, itemSearchText) => {
    // eslint-disable-next-line
    const searcheredData = data.filter(function (item) {
      const administratorsArr = item.administrators.map((admin) => {
        return admin.name
      })
      const administrators = administratorsArr.length > 0 ? administratorsArr.join(', ') : 'Sem administradores'

      if (
        item.code.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.title.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1 ||
        item.moment.indexOf(itemSearchText) !== -1 ||
        administrators.toLowerCase().indexOf(itemSearchText.toLowerCase()) !== -1
      ) {
        return item
      }
    })
    searcheredData.length > 0 ? setSearchEmpty(false) : setSearchEmpty(true)
  }
  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            <Row>
              <Col className="text-center">
                {loadinProcessSendmail && (
                  <>
                    {
                      <>
                        <Row className="mt-10">
                          <Col className="text-center">
                            <div className="overlay-layer bg-transparent mb-5">
                              <div className="d-flex justify-content-center">
                                <div className="spinner spinner-lg spinner-success" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-10">
                          <Col className="text-center">
                            <p className="h4">Por favor aguarde!</p>
                            <p className="h4">Processando exclusão de serviço e envio de e-mails ...</p>
                          </Col>
                        </Row>
                      </>
                    }
                  </>
                )}
              </Col>
            </Row>
            {serviceList.length === 0 && listLoading && (
              <>
                <Row>
                  <Col className="text-center">
                    <p className="overlay-layer bg-transparent mb-5">
                      <span className="spinner spinner-lg spinner-success" />
                    </p>
                    <p className="h4 mt-10">Carregando serviços ...</p>
                  </Col>
                </Row>
              </>
            )}
            <>
              {!hideServiceList && (
                <>
                  <ToolkitProvider
                    keyField="id"
                    data={serviceList}
                    columns={columns}
                    search={{
                      searchFormatted: true,
                    }}
                  >
                    {(props) => (
                      <>
                        <Row>
                          <Col sm={9}></Col>
                          <Col sm={3}>
                            <div className="d-grid"></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={10} className="mb-4">
                            <InputGroup>
                              <InputGroup.Text
                                className="input-group-prepend"
                                style={{
                                  height: 36,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              >
                                <i className="fas fa-search"></i>
                              </InputGroup.Text>
                              <SearchBar
                                {...props.searchProps}
                                placeholder="Digite o alvo de sua busca"
                                className="w-100 input-group-prepend"
                                style={{
                                  height: 36,
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  width: '100%',
                                }}
                              />

                              {qtdInSearch(serviceList, props.searchProps.searchText)}
                            </InputGroup>
                          </Col>
                          <Col sm={2} className="text-right">
                            <Button
                              variant="primary"
                              type="button"
                              onClick={() => history.push('/servico/novo')}
                              className="btn-block font-weight-bold"
                            >
                              Cadastrar Serviço
                            </Button>
                          </Col>
                        </Row>
                        {((serviceList.length === 0 && !searchEmpty) || searchEmpty) && (
                          <Row>
                            <Col>
                              <AlertMessage
                                variant="alert alert-custom alert-light-primary alert-dismissible"
                                message={
                                  props.searchProps.searchText.length > 0
                                    ? `Não foram encontrados resultados para o termo ${props.searchProps.searchText}`
                                    : 'Não exitem serviços cadastrados no sistema.'
                                }
                              />
                            </Col>
                          </Row>
                        )}
                        {!searchEmpty && (
                          <Row>
                            <Col>
                              <BootstrapTable
                                {...props.baseProps}
                                classes="bordered"
                                keyField="id"
                                data={serviceList}
                                columns={columns}
                                rowStyle={{ color: 'black' }}
                                headerClasses="text-center bg-light"
                                rowClasses="text-center"
                                bordered={true}
                                hover
                                noDataIndication="Não existem serviços cadastrados por você no sistema."
                                pagination={paginationFactory(pageOptions)}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className="mt-0">
                          <Col className="text-right">
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={() => history.push('/')}
                              className="font-weight-bold"
                            >
                              <i className="fa fa-chevron-left"></i>
                              &nbsp;&nbsp;Voltar
                            </Button>{' '}
                          </Col>
                        </Row>
                      </>
                    )}
                  </ToolkitProvider>
                </>
              )}
            </>
          </Container>
        </Card.Body>
      </Card>
      <AlertGT
        show={showAlert}
        title={alertTitle}
        text={alertText}
        icon={alertIcon}
        showCancelButton={alertShowCancelButton}
        confirmButtonText={alertConfirmButtonText}
        onConfirm={alertConfirm}
        onDismissed={() => setShowAlert(false)}
        toastSuccess={toastSuccess}
      />
    </>
  )
}
