import styled from 'styled-components'

export const Code = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 1rem;
`

export const Moment = styled.span`
  font-size: 1rem;
  text-align: right;

  &::before {
    content: '\f017';
    font-weight: 500;
    font-family: 'Font Awesome 5 Free';
    margin-right: 0.5rem;
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.5);
  }
`

export const BlockInfo = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  font-size: 1.2rem;
  flex-grow: 2;
  border-radius: 0.5rem;

  i {
    color: var(--dark);
    margin-right: 0.5rem;
  }
`
