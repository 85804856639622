import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import TooltipCustom from '../../../../../components/TooltipCustom'
import { AnswerCard } from './CardForumMessages.styles'
import { BadgeGT, CardGT, Button as ButtonGT } from '../../../../../components'
import handleDownload from '../../../../../Utils/downloadFile'

const Header = ({
  numMessage,
  userType,
  authorNameMessage,
  authorEmailMessage,
  moment,
  momentStage,
  momentStageSlug,
  messageWasDeleted,
  deletedMessage,
}) => (
  <>
    <Row>
      {messageWasDeleted && (
        <Col md="12" className="d-flex mb-3">
          {/*  <h4 className="mr-3 mb-0">Mensagem {numMessage}</h4> -{' '} */}
          <div dangerouslySetInnerHTML={{ __html: deletedMessage }} />
        </Col>
      )}
      {!messageWasDeleted && (
        <>
          <Col md="6" className="d-flex mb-3">
            <h4 className="mr-3 mb-0">Mensagem {numMessage}</h4>
            {momentStageSlug && <BadgeGT text={momentStage} variant={momentStageSlug} />}
          </Col>
          <Col md="6" className="d-flex justify-content-end opacity-50">
            <div>{moment}</div>
          </Col>
        </>
      )}
    </Row>
    {!messageWasDeleted && (
      <Row>
        <Col>
          <span className="opacity-50">
            Autor: <strong>{userType}</strong> {authorNameMessage} ({authorEmailMessage})
          </span>
        </Col>
      </Row>
    )}
  </>
)

const Body = ({ message, answers = [], onDelete, numMessage }) => (
  <>
    <p style={{ fontWeight: 500 }} className="mb-5">
      {message}
    </p>
    <div className="mt-5">
      {answers.length > 0 && <h5 style={{ fontWeight: 500 }}>Respostas à mensagem</h5>}
      {answers.map((answer, index) => (
        <AnswerCard key={index}>
          <div>
            <div className="float-right opacity-75">{answer.moment}</div>
            <h6 className="mb-5 opacity-75" style={{ fontWeight: 400, fontSize: '1rem' }}>
              <strong>{answer.userType}</strong> {answer.authorNameMessage} ({answer.authorEmailMessage})
            </h6>
            <p className="mb-5">{answer.message}</p>
            {(answer.attachments.length > 0 || answer.canDelete) && (
              <Row>
                <Col md={8}>
                  {answer.attachments.map((attachment, index) => (
                    <TooltipCustom key={index} title={attachment.original_name_tooltip} placement="top" arrow>
                      <ButtonGT
                        size="sm"
                        variant="file"
                        type="button"
                        className="mr-2 mt-2"
                        onClick={() => handleDownload(attachment.id, attachment.original_name)}
                      >
                        {attachment.original_name}
                      </ButtonGT>
                    </TooltipCustom>
                  ))}
                </Col>
                <Col md={4} className="d-flex justify-content-end align-items-end">
                  {answer.canDelete && (
                    <Button
                      size="sm"
                      className="ml-3 d-flex align-items-center"
                      variant="secondary"
                      type="button"
                      onClick={() => onDelete(answer, numMessage)}
                      style={{ flexShrink: 0, maxHeight: 30 }}
                    >
                      Excluir Resposta
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </AnswerCard>
      ))}
    </div>
  </>
)

const Footer = ({ message, onAnswering, onDelete }) => (
  <Row>
    <Col md={8}>
      {message.attachments.map((item, index) => {
        return (
          <TooltipCustom key={index} title={item.original_name_tooltip} placement="top" arrow>
            <ButtonGT
              size="sm"
              variant="file"
              type="button"
              className="mr-2 mt-2"
              onClick={() => handleDownload(item.id, item.original_name)}
            >
              {item.original_name}
            </ButtonGT>
          </TooltipCustom>
        )
      })}
    </Col>
    <Col md={4} className="d-flex justify-content-end align-items-end">
      {message.canBeAnswered && (
        <Button
          size="sm"
          variant="secondary"
          type="button"
          className="align-items-center"
          onClick={() => onAnswering(message)}
          style={{ flexShrink: 0, maxHeight: 30 }}
        >
          Responder Mensagem
        </Button>
      )}
      {message.canDelete && (
        <Button
          size="sm"
          className="ml-3 align-items-center"
          variant="secondary"
          type="button"
          onClick={() => onDelete(message)}
          style={{ flexShrink: 0, maxHeight: 30 }}
        >
          Excluir Mensagem
        </Button>
      )}
    </Col>
  </Row>
)

export default function CardForumMessages({ messages = [], onAnswering, onDelete, onDeleteAnswer }) {
  return messages.map((message, index) => (
    <CardGT
      key={index}
      header={
        <Header
          numMessage={message.numMessage}
          userType={message.userType}
          authorNameMessage={message.authorNameMessage}
          authorEmailMessage={message.authorEmailMessage}
          moment={message.moment}
          momentStageSlug={message.momentStageSlug}
          momentStage={message.forumMessageStatus}
          messageWasDeleted={message.messageWasDeleted}
          deletedMessage={message.deletedMessage}
        />
      }
      body={
        !message.messageWasDeleted && (
          <Body
            message={message.message}
            answers={message.answers}
            onDelete={onDeleteAnswer}
            numMessage={message.numMessage}
          />
        )
      }
      footer={!message.messageWasDeleted && <Footer message={message} onAnswering={onAnswering} onDelete={onDelete} />}
      theme={message.messageWasDeleted ? 'off' : 'transparent'}
    />
  ))
}
