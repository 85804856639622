import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const FileRemove = styled(Button)`
  /* position: absolute; */
  /* top: 20px;
  right: 10px; */
  line-height: 15px;
  cursor: pointer;
  margin-right: -10px;
  margin-left: -1px;
  margin-top: -10px;
`
export const FileStatusBar = styled.div`
  width: 100%;
  vertical-align: top;
  position: relative;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px solid #bdbdbd;

  .file-name {
    display: inline-block;
    vertical-align: top;
    color: #3f4254;
  }

  .file-error {
    display: inline-block;
    vertical-align: top;
    color: #9aa9bb;
  }
`
export const FileErrorMessage = styled.span`
  color: red;
`

export const FileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ hasErrorMessage }) => (hasErrorMessage ? '275px' : '730px')};
`
