import * as requestFromServer from './solicitationEndpoints'
import { solicitationSlice } from './solicitationSlice'

const { actions } = solicitationSlice

/**
 * Objeto com todos os serviços.
 */
export const stepOne = () => (dispatch) => {
  return requestFromServer
    .getStepOne()
    .then((response) => {
      dispatch(actions.getStepOne(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const getMySolicitations = () => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getMySolicitations()
    .then((response) => {
      dispatch(actions.getMySolicitations(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const getMySolicitationsFromIntangibleAsset = (aiId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getMySolicitationsFromIntangibleAsset(aiId)
    .then((response) => {
      dispatch(actions.getMySolicitationsFromIntangibleAsset(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const getMyCanceledSolicitations = () => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getMyCanceledSolicitations()
    .then((response) => {
      dispatch(actions.getMyCanceledSolicitations(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const getSolicitationHistory = (id) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolicitationHistory(id)
    .then((response) => {
      dispatch(actions.getSolicitationHistory(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const getRelatedSolicitations = (id) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getRelatedSolicitations(id)
    .then((response) => {
      dispatch(actions.getRelatedSolicitations(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const showSolicitation = (id) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .showSolicitation(id)
    .then((response) => {
      dispatch(actions.setSolicitationSelected(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const solicitationManagement = (id) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .solicitationManagement(id)
    .then((response) => {
      dispatch(actions.setSolicitationSelected(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const filterSolicitations = (term) => (dispatch) => {
  return dispatch(actions.filterSolicitations(term))
}

export const selectIntangibleAsset = (intangibleAsset) => (dispatch) => {
  // dispatch(actions.clearStateSolicitation())
  dispatch(actions.setIntangibleAssetSelected(intangibleAsset))
}

export const selectIntangibleAssetAdminUnit = (intangibleAsset) => (dispatch) => {
  dispatch(actions.clearStateSolicitation())
  dispatch(actions.setIntangibleAssetSelected(intangibleAsset))
}

export const selectOriginIntangibleAssetFromSolicitation = (solId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .solicitationIntangibleAssetOrigin(solId)
    .then((response) => {
      dispatch(actions.setIntangibleAssetSelected(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

export const selectSolicitationAdminUnit = (solicitation) => (dispatch) => {
  dispatch(actions.clearStateSolicitation())
  dispatch(actions.setSolicitationSelected(solicitation))
}
export const selectSolicitation = (solicitation) => (dispatch) => {
  dispatch(actions.setSolicitationSelected(solicitation))
}

export const selectSolicitationInProgress = (solicitation) => (dispatch) => {
  dispatch(actions.setSolicitationInProgress(solicitation))
}

export const clearSaveStatus = () => (dispatch) => {
  dispatch(actions.clearSaveStatus())
}

export const clearSaveError = () => (dispatch) => {
  dispatch(actions.clearSaveError())
}
export const emptyHistory = () => (dispatch) => {
  dispatch(actions.setSolicitationHistoryEmpty())
}

// Action usada para limpar solicitação e ativo selecionado no estado principal
export const clearStateSolicitation = () => (dispatch) => {
  dispatch(actions.clearStateSolicitation())
}

/**
 * Criar solicitacao etapa dois
 * @param {*} newObj
 * @returns
 */
export const createSolicitationStepTwo = (newObj) => (dispatch) => {
  return requestFromServer
    .setStepTwo(newObj)
    .then((response) => {
      dispatch(actions.setSolicitationInProgress(response.data.solicitation))
      dispatch(actions.saveSolicitationStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Editar solicitacao etapa dois
 * @param {*} newObj
 * @returns
 */
export const editSolicitationStepTwo = (obj) => (dispatch) => {
  return requestFromServer
    .editStepTwo(obj)
    .then((response) => {
      dispatch(actions.setSolicitationInProgress(response.data.solicitation))
      dispatch(actions.saveSolicitationStatusSuccess(response.data.message))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Objeto com todos os serviços.
 */
export const getServicesForRef = () => (dispatch) => {
  return requestFromServer
    .getServices()
    .then((response) => {
      dispatch(actions.setServicesListForRef(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const getHasServicesEnabled = () => (dispatch) => {
  return requestFromServer
    .hasServicesEnabled()
    .then((response) => {
      dispatch(actions.hasServicesEnabled(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const deleteSolicitation = (solicitation) => (dispatch) => {
  return requestFromServer
    .destroy(solicitation.id)
    .then((response) => {
      requestFromServer
        .getStepOne()
        .then((response) => {
          dispatch(actions.setStatusMessage(response.data.message))
          dispatch(actions.getStepOne(response.data))
        })
        .catch((error) => {
          dispatch(actions.catchError(error.response.data))
        })
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const stepThree = (solicitationId) => (dispatch) => {
  return requestFromServer
    .checkingRequirementsMetBySolicitation(solicitationId)
    .then((response) => {
      dispatch(actions.getStepThree(response.data))
    })
    .catch((error) => {
      if (error.response) dispatch(actions.catchError(error.response.data))
      else {
        const err = []
        err[0] = { error: [`Estamos com alguma instabilidade no momento. Tenta novamente em instantes.`] }
        dispatch(actions.catchError(err))
      }
    })
}

// Salvar o arquivo obrigatorio de download como lido solicitação etapa 3
export const setMandatoryDownloadRead = (obj) => (dispatch) => {
  return requestFromServer
    .saveMandatoryDownload(obj)
    .then((response) => {
      dispatch(actions.setMandatoryDownloadRead(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

// Salvar o campo do requisito informativo como lido na solicitação etapa 3
export const setReadInfoMessage = (obj) => (dispatch) => {
  return requestFromServer
    .saveReadInfoMessage(obj)
    .then((response) => {
      dispatch(actions.setReadInfoMessage(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

// Salvar um arquivo de solicotação no autoSave
export const setUploadFiles = (obj) => (dispatch) => {
  return requestFromServer
    .saveUploadFiles(obj)
    .then((response) => {
      dispatch(actions.setUploadFiles(response.data))
    })
    .catch((error) => {
      if (error.response) dispatch(actions.catchError(error.response.data))
      else {
        const err = []
        err[0] = { error: [`Não foi possível fazer o upload do arquivo (${error.name} - ${error.message}).`] }
        dispatch(actions.catchError(err))
      }
    })
}

// Excluir um arquivo de solicotação quando ele já foi salvo no autoSave
export const deleteUploadedFileSolicitation = (solicitationUploadFileId, requirementVersionId) => (dispatch) => {
  return requestFromServer
    .deleteSolicitationFile(solicitationUploadFileId)
    .then((response) => {
      const data = {
        solicitationUploadFileId,
        requirementVersionId,
      }
      dispatch(actions.removeUploadedFile(data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

// Editar descrição de um arquivo de solicotação no autoSave
export const updateFileDescription = (obj) => (dispatch) => {
  return requestFromServer
    .updateSolicitationFileDescription(obj)
    .then((response) => {
      dispatch(actions.updateSolicitationFileDescription(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const finishStepThree = (solicitationId) => (dispatch) => {
  return requestFromServer
    .saveStepThree(solicitationId)
    .then((response) => {
      if (response.data.status === 'success') {
        dispatch(actions.finishIncompleteSolicitations(response.data))
        dispatch(actions.finishStepThreeSuccess(response.data.message))
      } else if (response.data.status === 'error') {
        dispatch(actions.catchError([response.data]))
      }
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

export const solicitationWasNotCompleted = (error) => (dispatch) => {
  dispatch(actions.catchError(error))
}

// Editar descrição de um arquivo de solicotação no autoSave
export const setRequirementRead = (obj) => (dispatch) => {
  return requestFromServer
    .saveRequirementRead(obj)
    .then((response) => {
      dispatch(actions.setRequirementRead({ ...response.data, requirement_version_id: obj.requirementVersionId }))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

//Obtem as tarefas da solicitação
export const getTasks = (solicitationId, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getTasksSolicitation(solicitationId, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.setListLoading(false))
      dispatch(actions.setTasks(response.data))
      return response.data
    })
    .catch((error) => {
      dispatch(actions.setListLoading(false))
      dispatch(actions.catchError(error.response.data))
      throw error
    })
}

/**
 * Objeto com resposta de um fomulario eletrônico (se foi preenchido).
 */
export const getFormFilled = (encryptKeyForm) => (dispatch) => {
  return requestFromServer
    .getFormFilledResponse(encryptKeyForm)
    .then((response) => {
      if (response.data.closeMoment !== false) {
        console.log('Formulário preenchido')
      }
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
