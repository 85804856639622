import React, { useState, memo, useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { validationSchemaCompleteAttendance } from '../../../../pages/attendances/validationSchema'
import { CheckWrapper } from '../ComponentModalForm.styles'
import Dropzone from '../../../../../../components/Dropzone'

const CompleteAttendanceForm = ({ configAlert, setConfigAlert, solicitationSelected, handleDispatch }) => {
  const [checkboxConfirm, setCheckboxConfirm] = useState()
  const [attachments, setAttachments] = useState([])
  const [description, setDescription] = useState(localStorage.getItem('concludingOpinion'))

  useEffect(() => {
    setDescription(localStorage.getItem('concludingOpinion'))
  }, [])

  return (
    <div style={{ textAlign: 'left', width: '97%' }}>
      <p>Descreva abaixo o seu parecer de conclusão do atendimento.</p>
      <p>
        <strong>Observação:</strong> Caso ainda não o tenha feito no fórum da solicitação, deposite os arquivos de
        conclusão do atendimento no campo de envio de arquivos localizado abaixo.
      </p>
      <Formik
        validationSchema={validationSchemaCompleteAttendance}
        onSubmit={(values) => {
          if (values.concludingOpinion.length >= 3) {
            setConfigAlert({ ...configAlert, closeToast: true })
            handleDispatch({ message: values.concludingOpinion, attachments })
            localStorage.setItem('concludingOpinion', '')
          }
        }}
        initialValues={{
          concludingOpinion: description,
        }}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit} style={{ width: '98%' }}>
            <Row>
              <Col sm={12}>
                <Form.Group md="12">
                  <Form.Label>Parecer de Conclusão</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    name="concludingOpinion"
                    maxLength="1000"
                    {...formik.getFieldProps('concludingOpinion')}
                    className={
                      formik.touched.concludingOpinion && (formik.errors.concludingOpinion || description.length <= 3)
                        ? 'is-invalid'
                        : null
                    }
                    defaultValue={description}
                    value={description}
                    onChange={(e) => {
                      formik.handleChange(e)
                      setDescription(e.target.value)
                      localStorage.setItem('concludingOpinion', e.target.value)
                    }}
                  />
                  {formik.touched.concludingOpinion && (formik.errors.concludingOpinion || description.length <= 3) && (
                    <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                      {formik.errors.concludingOpinion}
                    </Form.Control.Feedback>
                  )}
                  <Form.Text className="text-right">
                    {formik.values.concludingOpinion.length !== 0
                      ? `${formik.values.concludingOpinion.length}/1000 caracteres`
                      : 'Máximo de 1000 caracteres.'}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Dropzone
                  attachments={attachments}
                  setAttachments={setAttachments}
                  maxAmountFiles={20}
                  formByFile="UPLOAD_IN_LIGHTBOX"
                />
                <CheckWrapper>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      id="checkbox-confirmation"
                      label="Estou ciente de que esta ação é irreversivel."
                      name="checkbox-confirmation"
                      type="checkbox"
                      className="checkbox-confirm"
                      checked={checkboxConfirm}
                      onClick={(e) => {
                        if (checkboxConfirm) {
                          setCheckboxConfirm(false)
                        } else {
                          setCheckboxConfirm(true)
                        }
                      }}
                    />
                  </Form.Group>
                </CheckWrapper>
                <br />
                <br />
              </Col>
            </Row>
            <Row className="mt-0">
              <Col className="text-right">
                <Button
                  type="submit"
                  className="font-weight-bold"
                  disabled={!checkboxConfirm}
                  onClick={() => {
                    console.clear()
                  }}
                >
                  Concluir Atendimento
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    localStorage.setItem('concludingOpinion', '')
                    localStorage.setItem('completeAttachments', JSON.stringify([]))
                    setConfigAlert({ ...configAlert, closeToast: true })
                  }}
                  className="font-weight-bold"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default memo(CompleteAttendanceForm)
