import { createSlice } from '@reduxjs/toolkit'

const initialSolicitationState = {
  listLoading: true,
  myIntangibleAssets: [],
  intangibleAssetSelected: {},
  incompleteSolicitations: {},
  solicitationSelected: {},
  solicitationHistoryObj: {},
  relatedSolicitations: {},
  solicitationInProgress: {},
  mySolicitations: [],
  mySolicitationsFromIntangibleAsset: [],
  myCanceledSolicitations: [],
  totalSolicitations: 0,
  hasServicesEnabled: null,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  serviceList: [],
  tasks: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const solicitationSlice = createSlice({
  name: 'solicitations',
  initialState: initialSolicitationState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    getStepOne: (state, action) => {
      state.myIntangibleAssets = action.payload.myIntangibleAssets
      state.incompleteSolicitations = action.payload.incomplete
      state.mySolicitations = action.payload.mySolicitations
      state.totalSolicitations = action.payload.totalSolicitations
      state.totalIntangibleAssets = action.payload.totalIntangibleAssets
      state.hasServicesEnabled = action.payload.hasServicesEnabled
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getStepThree: (state, action) => {
      state.solicitationInProgress = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    getMySolicitations: (state, action) => {
      state.mySolicitations = action.payload
    },

    getMySolicitationsFromIntangibleAsset: (state, action) => {
      state.mySolicitationsFromIntangibleAsset = action.payload
    },

    getMyCanceledSolicitations: (state, action) => {
      state.myCanceledSolicitations = action.payload
    },

    hasServicesEnabled: (state, action) => {
      state.hasServicesEnabled = action.payload
    },

    getSolicitationHistory: (state, action) => {
      state.solicitationHistoryObj = action.payload
      state.listLoading = false
      state.error = null
    },
    getRelatedSolicitations: (state, action) => {
      state.relatedSolicitations = action.payload
      state.listLoading = false
      state.error = null
    },

    filterSolicitations: (state, action) => {
      //state.incompleteSolicitations = action.payload.incomplete
      state.mySolicitations = state.mySolicitations.filter((solicitation) => {
        return solicitation.code === action.payload
      })
    },

    setIntangibleAssetSelected: (state, action) => {
      state.intangibleAssetSelected = action.payload
    },

    setSolicitationSelected: (state, action) => {
      state.solicitationSelected = action.payload
    },

    setSolicitationInProgress: (state, action) => {
      state.solicitationInProgress = action.payload
    },

    saveSolicitationStatusSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.statusMessage = action.payload
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    setStatusMessage: (state, action) => {
      state.statusMessage = action.payload
    },
    setSolicitationHistoryEmpty: (state) => {
      state.solicitationHistoryObj = {}
    },

    clearSaveStatus: (state, action) => {
      state.saveStatus = ''
    },

    clearSaveError: (state, action) => {
      state.saveError = []
    },

    setServicesListForRef: (state, action) => {
      state.serviceList = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
      state.error = null
      state.saveError = []
    },

    clearStateSolicitation: (state, action) => {
      state.intangibleAssetSelected = {}
      state.solicitationSelected = {}
      state.solicitationInProgress = {}
    },

    setMandatoryDownloadRead: (state, action) => {
      const solicitation = action.payload.solicitationMandatoryDownload
      const requirementWasAnswered = action.payload.requirement_was_answered
      const allRequirementWasAnswered = action.payload.all_requirements_was_answered

      if (state.solicitationInProgress.id === solicitation.solicitation_id) {
        state.solicitationInProgress.requirements.forEach((requirement, indexRequiriment) => {
          if (requirement.requirement_version_id === solicitation.requirement_version_id) {
            requirement.attachments.mandatoryDownloads.forEach((attachment, indexAttachment) => {
              if (attachment.id === solicitation.attachment_id) {
                state.solicitationInProgress.requirements[indexRequiriment].attachments.mandatoryDownloads[
                  indexAttachment
                ].mandatory_download_was_answered = true
              }
            })

            state.solicitationInProgress.requirements[
              indexRequiriment
            ].requirement_was_answered = requirementWasAnswered
          }
        })

        state.solicitationInProgress.all_requirements_was_answered = allRequirementWasAnswered
        state.error = null
      }
    },

    setReadInfoMessage: (state, action) => {
      const solicitation = action.payload.solicitationReadInfoMessageConfirmation
      const requirementWasAnswered = action.payload.requirement_was_answered
      const allRequirementWasAnswered = action.payload.all_requirements_was_answered

      if (state.solicitationInProgress.id === solicitation.solicitation_id) {
        state.solicitationInProgress.requirements.forEach((requirement, indexRequiriment) => {
          if (requirement.requirement_version_id === solicitation.requirement_version_id) {
            state.solicitationInProgress.requirements[indexRequiriment].read_confirmation_was_answered =
              solicitation.read_confirmation
            state.solicitationInProgress.requirements[
              indexRequiriment
            ].requirement_was_answered = requirementWasAnswered
          }
        })

        state.solicitationInProgress.all_requirements_was_answered = allRequirementWasAnswered
        state.error = null
      }
    },

    setUploadFiles: (state, action) => {
      const solicitation = action.payload.solicitation
      const uploadedFile = action.payload.attachment
      uploadedFile.solicitation_upload_files_id = action.payload.solicitation_upload_files_id
      const requirementWasAnswered = action.payload.requirement_was_answered
      const allRequirementWasAnswered = action.payload.all_requirements_was_answered

      if (state.solicitationInProgress.id === solicitation.solicitation_id) {
        state.solicitationInProgress.requirements.forEach((requirement, indexRequiriment) => {
          if (requirement.requirement_version_id === parseInt(solicitation.requirement_version_id)) {
            const oldUploadedFilesList =
              state.solicitationInProgress.requirements[indexRequiriment].uploadedRequirementFiles
            oldUploadedFilesList.push(uploadedFile)
            state.solicitationInProgress.requirements[indexRequiriment].uploadedRequirementFiles = oldUploadedFilesList
            state.solicitationInProgress.requirements[
              indexRequiriment
            ].requirement_was_answered = requirementWasAnswered
          }
        })

        state.solicitationInProgress.all_requirements_was_answered = allRequirementWasAnswered
        state.error = null
      }
    },

    removeUploadedFile: (state, action) => {
      state.solicitationInProgress.requirements.forEach((requirement, indexRequiriment) => {
        if (requirement.requirement_version_id === parseInt(action.payload.requirementVersionId)) {
          const oldUploadedFilesList =
            state.solicitationInProgress.requirements[indexRequiriment].uploadedRequirementFiles
          const fileIndex = oldUploadedFilesList.findIndex(
            (el) => el.solicitation_upload_files_id === action.payload.solicitationUploadFileId
          )
          oldUploadedFilesList.splice(fileIndex, 1)
          state.solicitationInProgress.requirements[indexRequiriment].uploadedRequirementFiles = oldUploadedFilesList
          state.error = null
        }
      })
    },

    updateSolicitationFileDescription: (state, action) => {
      const requirementVersionId = action.payload.requirement_version_id
      const solicitationUploadFilesId = action.payload.solicitation_upload_files_id
      const fileDescription = action.payload.attachment.description

      state.solicitationInProgress.requirements.forEach((requirement, indexRequiriment) => {
        if (requirement.requirement_version_id === parseInt(requirementVersionId)) {
          requirement.uploadedRequirementFiles.forEach((file, fileIndex) => {
            if (file.solicitation_upload_files_id === solicitationUploadFilesId) {
              state.solicitationInProgress.requirements[indexRequiriment].uploadedRequirementFiles[
                fileIndex
              ].description = fileDescription
            }
          })
        }
      })
      state.error = null
    },

    finishIncompleteSolicitations: (state, action) => {
      if (state.incompleteSolicitations && state.incompleteSolicitations.id === action.payload.solicitation.id) {
        state.incompleteSolicitations = {}
      }
    },

    finishStepThreeSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'finish'
      state.statusMessage = action.payload
    },

    setRequirementRead: (state, action) => {
      const requirementRead = action.payload.requirement_read
      const requirementWasAnswered = action.payload.requirement_was_answered
      const allRequirementsWasAnswered = action.payload.all_requirements_was_answered
      const requirementVersionId = action.payload.requirement_version_id

      state.solicitationInProgress.requirements.forEach((requirement, indexRequiriment) => {
        if (requirement.requirement_version_id === parseInt(requirementVersionId)) {
          state.solicitationInProgress.requirements[indexRequiriment].requirement_read = requirementRead
          state.solicitationInProgress.requirements[indexRequiriment].requirement_was_answered = requirementWasAnswered
          state.error = null
        }
      })

      state.solicitationInProgress.all_requirements_was_answered = allRequirementsWasAnswered
    },

    setTasks: (state, action) => {
      state.tasks = action.payload
    },
  },
})
