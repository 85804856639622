import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { login } from '../_redux/authCrud'
import ReCAPTCHA from 'react-google-recaptcha'
import { Alert, Modal } from 'react-bootstrap'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { clearFirstLogin } from '../../Solicitation/_redux/dashboard/dashboardActions'

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const initialValues = {
  email: '',
  password: '',
}

function Login(props) {
  const { intl } = props
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [sysmsg, setSysMsg] = useState({
    message: null,
    status: null,
  })

  const [show, setShow] = useState(false)

  const { userstatus } = useParams()

  const dispatch = useDispatch()

  React.useEffect(
    () => {
      dispatch(clearFirstLogin())
      if (userstatus === 'invalid-hash') {
        setShow(true)
      }
    },
    // eslint-disable-next-line
    []
  )

  function handleOnChange(value) {
    setIsVerified(true)
  }
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail com formato errado')
      .min(4, 'O comprimento mínimo do campo senha é 3 caracteres')
      .max(50, 'O comprimento máximo do campo é 50 caracteres')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(3, 'O comprimento mínimo do campo senha é de 3 caracteres')
      .max(50, 'O comprimento máximo do campo senha é de 50 caracteres')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { accessToken } }) => {
            disableLoading()
            props.login(accessToken)
          })
          .catch((error) => {
            disableLoading()
            setSubmitting(false)
            setSysMsg({
              message: error.response.data.message,
              status: error.response.data.status,
            })
            setStatus(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.INVALID_LOGIN',
              })
            )
          })
      }, 1000)
    },
  })

  if (userstatus === 'FmN2syR0V5Iiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3') {
    setTimeout(() => history.push('/auth/login'), 10000)
  }

  const handleClose = () => {
    history.push(`/auth/login`)
    setShow(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Link Inválido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Este link não é mais válido.</p>
          <p>Isso ocorre por alguns dos motivos abaixo:</p> <br />
          <ul>
            <li>O link já foi utilizado.</li>
            <li>Passaram-se 30 minutos depois de sua criação.</li>
            <li>O token utilizado não é mais válido.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      <div id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="text-center mb-10 mt-10">
          <h4 className="font-size-h1">Escolha uma das formas de acesso ao sistema</h4>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}

        {userstatus === 'FmN2syR0V5Iiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3' && (
          <>
            <Alert variant="danger">
              <Alert.Heading>Atenção!</Alert.Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: 'Não foi possível se conectar ao servidor. Por favor, tente novamente mais tarde.',
                }}
              />
            </Alert>
          </>
        )}
        {userstatus === 'xu37aYYJqHxlYPNjCT9Z9ae7WxKBBUBGVZ1WqKvIv2xQeyeueLGnli' && (
          <>
            <Alert variant="danger">
              <Alert.Heading>Atenção!</Alert.Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "Acesso bloqueado no sistema. Envie uma mensagem para <a href='mailto:taskData.task_user_executant_email'>administrador@fiocruz.com.br.</a>",
                }}
              />
            </Alert>
          </>
        )}

        {userstatus === 'JZWatbnEi7p983' && (
          <>
            <Alert variant="danger">
              <Alert.Heading>Atenção!</Alert.Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: 'Seu cadastro não foi concluído. Verifique seu e-mail para mais informações.',
                }}
              />
            </Alert>
          </>
        )}
        {userstatus === 'smFhPrBxED7Ncy' && (
          <>
            <Alert variant="danger">
              <Alert.Heading>Atenção!</Alert.Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    'Houve um problema com seu acesso via Login Único. <br>Por favor, entre em contato com a Administração do sistema através do e-mail: <a href="mailto:administradores.sisgestec@fiocruz.br"> administradores.sisgestec@fiocruz.br</a>.',
                }}
              />
            </Alert>
          </>
        )}

        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          {formik.status && (
            <>
              {sysmsg.message === 'waiting_release' ? (
                <Alert variant={'warning'}>
                  <Alert.Heading>
                    <div
                      style={{ color: 'black' }}
                      dangerouslySetInnerHTML={{
                        __html: sysmsg.message === 'waiting_release' ? 'AGUARDANDO LIBERAÇÃO!' : 'Atenção!',
                      }}
                    />
                  </Alert.Heading>

                  <div
                    style={{ color: 'black' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        'Sua solicitação de acesso ainda está sendo avaliada pelos administradores do sistema. <br />Você será avisado por e-mail assim que seu acesso for liberado.',
                    }}
                  />
                </Alert>
              ) : (
                <Alert variant={sysmsg.message === 'no_email_validation' ? 'warning' : 'danger'}>
                  <Alert.Heading>
                    <div
                      style={{ color: sysmsg.message === 'no_email_validation' ? 'black' : 'white' }}
                      dangerouslySetInnerHTML={{
                        __html: sysmsg.message === 'no_email_validation' ? 'CONFIRME SEU CADASTRO!' : 'Atenção!',
                      }}
                    />
                  </Alert.Heading>

                  <div
                    style={{ color: sysmsg.message === 'no_email_validation' ? 'black' : 'white' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        sysmsg.message === 'no_email_validation'
                          ? 'Você já realizou seu cadastro. Para confirmá-lo, clique no link de ativação que lhe enviamos por e-mail. <br /><strong>Observação:</strong> Caso tenham se passado mais de 30 minutos do envio, por favor, cadastre-se novamente.'
                          : sysmsg.message,
                    }}
                  />
                </Alert>
              )}
            </>
          )}
          <Row className="mt-15">
            <Col style={{ borderRight: '1px solid #000', padding: '0 40px' }}>
              <div className="text-center">
                <h4>
                  Você é um colaborador Fiocruz? <br />
                  <br />
                  Acesse o sistema com o seu
                  <br />
                  <strong>Login Único Fiocruz</strong>
                </h4>
              </div>
              <div
                className="mt-28 form-group d-flex flex-wrap justify-content-between align-items-justify mb-5"
                style={{ width: '285px', margin: '0 auto' }}
              >
                <a
                  href={`${API_BASE_URL}/saml2/test/login`}
                  className={`btn btn-primary btn-block font-weight-bold px-10 py-4`}
                >
                  <span>Entrar com Login Único</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </a>
              </div>
              <br />
              <hr />
              <br />
              <div className="text-center mt-5">
                <div className="mb-15 mb-lg-0 justify-content-center mt-5">
                  <h4>
                    <strong>
                      Não é colaborador Fiocruz e ainda
                      <br />
                      não se cadastrou neste sistema?
                    </strong>
                    <br />
                    <br />
                    <span className="font-size-h5">Clique no botão abaixo e cadastre-se.</span>
                  </h4>
                  <Link
                    to="/auth/registration"
                    className={`btn btn-primary btn-block font-weight-bold px-9 py-4 mt-9`}
                    id="kt_login_signup"
                  >
                    Cadastre-se
                  </Link>
                </div>
              </div>

              {/* --------------------------------------------------------------------- */}
            </Col>
            {/* ------------------------------------------------------------------------- */}
            <Col style={{ padding: '0 40px' }}>
              <div className="text-center">
                <h5>
                  <strong>
                    Não tem Login Único da Fiocruz, mas
                    <br />
                    já é cadastrado neste sistema?
                  </strong>
                </h5>
                <h4 className="mt-8">
                  Preencha os campos abaixo com
                  <br />
                  seus dados de autenticação.
                </h4>
              </div>
              <div style={{ width: '300px', margin: '0 auto' }}>
                <div className="form-group fv-plugins-icon-container mt-10">
                  <Form.Label>E-mail</Form.Label>
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
                    name="email"
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Senha</Form.Label>
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
                    name="password"
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleOnChange} />
                  <Button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={!isVerified}
                    className={`btn btn-primary font-weight-bold px-9 py-4 mt-5`}
                    block
                  >
                    <span>Entrar</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: '0px' }}>
            <Col>
              <div className="text-center mb-0 p-0 mt-10">
                <h4 className="font-size-h3">Não conseguiu acessar o sistema?</h4>
              </div>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <div className="text-center mb-lg-20">
                <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  <FormattedMessage id="AUTH.GENERAL.CLICK_HERE" />
                </Link>
              </div>
            </Col>
          </Row>
        </form>
        {/*end::Form*/}
      </div>
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(Login))
