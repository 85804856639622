import React, { useState, useMemo, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Nav, Tab, Dropdown, Row, Col } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
// import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { getAllNotifications, readNotification } from '../../../../../app/modules/Admin/_redux/system/systemActions'
import { DropdownTopbarItemNotificationToggler } from '../../../../_partials/dropdowns/DropdownTopbarItemNotificationToggler'

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
}

const UserNotificationsDropdown = () => {
  const dispatch = useDispatch()

  const [key, setKey] = useState('news')
  const { notifications } = useSelector((state) => state.system)
  const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg')
  const uiService = useHtmlClassService()

  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.notifications.layout') === 'offcanvas',
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    //console.clear();
    const timerNotifications = window.setInterval(() => {
      // let hoje = new Date()
      // console.log(`carregou api... ${systemActualPage}`, hoje.toLocaleTimeString()+ " " + teste )
      dispatch(getAllNotifications())
    }, 12000)

    return () => {
      window.clearInterval(timerNotifications)
    }
    // eslint-disable-next-line
  }, [])

  const handleNotificationLikeRead = (id) => {
    dispatch(readNotification(id))
    dispatch(getAllNotifications())
  }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <i className="fas fa-bell"></i>
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle as={DropdownTopbarItemNotificationToggler} id="kt_quick_notifications_toggle">
            <div
              className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto mr-5"
              id="kt_quick_notifications_toggle"
            >
              <div className="btn btn-icon mr-1 pulse pulse-primary">
                <span className="svg-icon svg-icon-xl">
                  <i className="fas fa-bell"></i>
                </span>
                <span className="pulse-ring" />
              </div>
              <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md">
                {notifications !== null ? notifications.counters.unread : 0}
              </span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notificações do Usuário</span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  ></Nav>
                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="news" id="topbar_notifications_news">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: '300px', position: 'relative' }}
                      >
                        {notifications == null && (
                          <>
                            <div className="text-center text-muted">Sem Notificações no momento</div>
                          </>
                        )}
                        {notifications !== null &&
                          notifications.newNotifications.map((item, indexNew) => (
                            <Link
                              to={typeof item.link !== 'undefined' ? item.link : '/'}
                              key={indexNew}
                              className="navi-item"
                              onClick={() => handleNotificationLikeRead(item.id)}
                            >
                              <div className="navi-link container">
                                {item.within24hours && (
                                  <>
                                    <Row>
                                      <Col lg={2} className="navi-icon mr-2">
                                        {item.within24hours && (
                                          <span className="btn btn-text btn-info btn-sm font-weight-bold btn-font-md">
                                            Nova
                                          </span>
                                        )}
                                      </Col>
                                      <Col lg={9} className="navi-text">
                                        <div className="font-weight-bold">{item.message}</div>
                                        <div className="text-muted">{item.real_moment}</div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                {!item.within24hours && (
                                  <>
                                    <Row>
                                      <Col lg={11} className="navi-text">
                                        <div className="font-weight-bold">{item.message}</div>
                                        <div className="text-muted">{item.real_moment}</div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </div>
                            </Link>
                          ))}
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}

export default memo(UserNotificationsDropdown)
