import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

export const showSolicitation = async (id) => await axios.get(`${API_BASE_URL}/api/solicitation/${id}`)

export const getSolicitationManagement = async (solicitationId) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/${solicitationId}/attendant/management`)

export const receiveSolicitation = async (solicitationId) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/receive`)

export const startAttendance = async (solicitationId) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/start_attendance`)

export const cancelSolicitation = async (solicitationId, cancelMessage) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/cancel_solicitation`, {
    message: cancelMessage,
  })
export const abandonAttendance = async (solicitationId, cancelMessage) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/abandon_attendance`, {
    message: cancelMessage,
  })
export const approveAttendanceSolicitation = async (solicitationId) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/approve_attendance_solicitation`)

export const rejectAttendanceSolicitation = async (solicitationId, rejectMessage) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/reject_attendance_solicitation`, {
    message: rejectMessage,
  })

export const completeSolicitation = async (solicitationId, obj) => {
  const data = new FormData()
  data.append('message', obj.message)

  for (let i = 0; i < obj.attachments.length; i++) {
    data.append('attachments[]', obj.attachments[i])
  }

  return await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/attending/complete_solicitation`, data, {
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  })
}
export const getAttendantsByService = async (serviceId) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${serviceId}/attending/start_attendance`)

export const getAttendancesByStatus = async (slug_status, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/attending/my/${slug_status}`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getAttendancesOpened = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/attending/my`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getSolcitationDocuments = async (solicitationId, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/${solicitationId}/documents`, {
    params: { per_page, page, search, sort_field, ordering },
  })
//Atendimentos em Andamento por um Atendente
export const getAttendancesOngoing = async (atendanceId, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/attendances/attendant/user/${atendanceId}`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getSolicitationsMyServices = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/admin_service/my_services_solicitation/list`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getSolicitationForumMessages = async (solicitationId) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/${solicitationId}/forum/messages`)

export const getAttendantsQualifiedForService = async (id, per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/service/${id}/qualified/attendants`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getAttendantsQualifiedForServiceToSolicitation = async (
  id,
  id_solicitation,
  per_page,
  page,
  search,
  sort_field,
  ordering
) =>
  await axios.get(`${API_BASE_URL}/api/service/${id}/qualified/attendants/to/solicitation/${id_solicitation}`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const forwardingToNewAttendent = async (idSolicitation, obj) =>
  await axios.post(`${API_BASE_URL}/api/solicitation/${idSolicitation}/attending/forward_attendance_solicitation`, {
    attendant_id: obj.attendant_id,
    message: obj.message,
  })

export const getSolicitationsAwaitingAttendantUnit = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/solicitation/awaiting_attendant/unit`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const createForumMessage = async (solicitationId, obj) => {
  const data = new FormData()
  data.append('message', obj.message)

  for (let i = 0; i < obj.attachments.length; i++) {
    data.append('attachments[]', obj.attachments[i])
  }

  return await axios.post(`${API_BASE_URL}/api/solicitation/${solicitationId}/forum/message/new`, data, {
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  })
}

export const createForumMessageReply = async (solicitationId, solicitationForumMessageId, obj) => {
  const data = new FormData()
  data.append('message', obj.message)

  for (let i = 0; i < obj.attachments.length; i++) {
    data.append('attachments[]', obj.attachments[i])
  }

  return await axios.post(
    `${API_BASE_URL}/api/solicitation/${solicitationId}/forum/message/${solicitationForumMessageId}/answer/new`,
    data,
    {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    }
  )
}

export const deleteForumMessage = async (solicitationId, solicitationForumMessageId) =>
  await axios.delete(
    `${API_BASE_URL}/api/solicitation/${solicitationId}/forum/message/${solicitationForumMessageId}/delete`
  )

export const deleteForumMessageReply = async (solicitationId, solicitationForumMessageId) =>
  await axios.delete(
    `${API_BASE_URL}/api/solicitation/${solicitationId}/forum/message/${solicitationForumMessageId}/replies/delete`
  )
