const CLIENT_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_CLIENT_URL_HOMOL}`
    : `${process.env.REACT_APP_CLIENT_URL_PROD}`

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState()

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }

      return config
    },
    (err) => {
      Promise.reject(err)
    }
  )

  /**
   * Se o status do response de uma requisição for 401 - Unauthorized
   * força o logout do sistema...
   */
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function(error) {
      let actualUrlBase = window.location.pathname

      if (error.response.status === 0 && actualUrlBase === '/auth/forgot-password') {
        window.location = `${CLIENT_BASE_URL}/auth/forgot-password/GgvbG9naW4iLCJpYXQiOjE2ODM2MzU2MDcsImV4c`
      }
      // if (error.response.status === 0 && actualUrlBase === '/auth/login') {
      //   window.location = `${CLIENT_BASE_URL}/auth/login/FmN2syR0V5Iiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3`
      // }
      // if (error.response.status === 0) {
      //   console.log('Sem conexão com a api')
      // }
      // if (error.code === 'ERR_NETWORK') {
      //   console.log('há um erro de de rede')
      // }
      if (error.response.status === 401 && actualUrlBase !== '/auth/login') {
        window.location = `${CLIENT_BASE_URL}/401-unauthorized`
      }

      return Promise.reject(error)
    }
  )
}
