import axios from "axios";


const API_BASE_URL =  (`${process.env.NODE_ENV}` === 'development') 
? `${process.env.REACT_APP_API_URL_HOMOL}` 
: `${process.env.REACT_APP_API_URL_PROD}`


export const newIntangibleAsset = async (id, obj) => (
    await axios.post(`${API_BASE_URL}/api/intangible_asset`, {
        user_id: id,
        title: obj.title,
        description: obj.description
    })
)

export const editIntangibleAsset = async (id, obj) => (
    await axios.put(`${API_BASE_URL}/api/intangible_asset/${id}`, {
        title: obj.title,
        description: obj.description
    })
)

export const getIntangibleAsset = async (id) => (
    await axios.get(`${API_BASE_URL}/api/intangible_asset/${id}`)
)

export const deleteIntangibleAsset = async (id) => (
    await axios.delete(`${API_BASE_URL}/api/intangible_asset/${id}`)
)

export const getIntangibleAssetsByUser = async (id) => (
    await axios.get(`${API_BASE_URL}/api/intangible_asset/user/${id}`)
)

export const getAllIntangibleAssetVersions = async (id) => (
    await axios.get(`${API_BASE_URL}/api/intangible_asset/history/${id}`)
)
