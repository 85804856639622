import styled from 'styled-components'

export const BasicSausegeCardWrapper = styled.div`
  margin: 20px 0px 30px 0px;
`
export const Container = styled.div`
  padding: 10px 20px;
  display: flex;
  background-color: #f3f3f3;
  width: 100%;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
`

export const SubText = styled.span`
  display: block;
  color: #3d4b4c;
  margin-bottom: 5px;
`

export const TitleText = styled.h5`
  color: #0e100f;
  /* color:#333; */
`

export const SubInfoText = styled.span`
  display: block;
  color: #3d4b4c;
  font-size: 11px;
  margin-bottom: 5px;
`
export const LineSeparator = styled.hr`
  display: block;
  color: #3d4b4c;
  margin-bottom: 5px;
`

export const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
`
