import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const UserWelcome = ({ messageType = 'message', welcomeUser, token, objUser }) => {
  const [unitId, setUnitId] = React.useState(null)

  const setUnitFromUser = async (idUser, idUnit) => {
    const unitAxios = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    await unitAxios
      .get(`${API_BASE_URL}/api/user/${idUser}/organization/${idUnit}/set_unit`)
      .then((response) => {
        console.log('res.ok')
      })
      .catch((error) => {
        console.log('Ocorreu um erro')
      })
  }

  return (
    <>
      {messageType === 'message' && (
        <>
          <h3 class="my-5">Seja bem-vindo(a), {welcomeUser}.</h3>
          <p className="text-justify">Agora você possui acesso ao sistema Gestec.</p>
          <p className="text-justify">
            Caso tenha dúvidas quanto à execução de qualquer funcionalidade do sistema, acesse nossa{' '}
            <a href="/faq">FAQ</a> ou contate os adminsitradores através do e-mail: <br />
            <a href="mailto:administradores-gestec@fiocruz.br">administradores-gestec@fiocruz.br</a>.
          </p>
          <p>
            <button
              type="button"
              className="btn btn-primary"
              aria-label=""
              onClick={() => {
                Swal.clickConfirm()
                // Swal.closeModal()
              }}
            >
              Fechar
            </button>
          </p>
        </>
      )}
      {messageType === 'form' && (
        <>
          <h3 className="my-5">Seja bem-vindo(a), {welcomeUser}.</h3>
          <p className="text-justify">
            Por favor, selecione a unidade em que trabalha na Fiocruz para começar a acessar o Sistema Gestec.
          </p>
          <div className="form-group">
            <label for="exampleFormControlSelect1">Unidade da Fiocruz</label>
            {typeof objUser.fiocruz_units !== 'undefined' && (
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={(item) => setUnitId(item.target.value)}
              >
                <option value="" disabled selected hidden>
                  Selecione a unidade em que trabalha
                </option>
                {objUser.fiocruz_units.map((org, key) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <p className="text-justify"></p>
          <p className="text-justify">
            Caso tenha dúvidas quanto à execução de qualquer funcionalidade do sistema, acesse nossa{' '}
            <a href="/faq">FAQ</a> ou contate os adminsitradores através do e-mail: <br />
            <a href="mailto:administradores-gestec@fiocruz.br">administradores-gestec@fiocruz.br</a>.
          </p>
          <p>
            <button
              type="button"
              className="btn btn-primary"
              aria-label=""
              onChange={(e) => console.log(e.target.value)}
              disabled={unitId === null || unitId === '' ? true : false}
              onClick={() => {
                setUnitFromUser(objUser.id, unitId)
                Swal.clickConfirm()
              }}
            >
              Confirmar
            </button>
          </p>
        </>
      )}
    </>
  )
}

export default UserWelcome
