import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Card, Container } from 'react-bootstrap'
import TooltipCustom from '../../../../components/TooltipCustom'

const AssetItem = (props) => {
  const toggleReadMore = (e) => {
    e.preventDefault()
    if (e.target.textContent === 'Leia mais') {
      e.target.textContent = 'Leia menos'

      e.target.parentElement.parentElement.parentElement.childNodes[0].childNodes[0].textContent =
        e.target.dataset.content
    } else {
      e.target.textContent = 'Leia mais'
      e.target.parentElement.parentElement.parentElement.childNodes[0].childNodes[0].textContent =
        e.target.dataset.content.substring(0, 200) + ' ...  '
    }
  }

  return (
    <>
      <Card border="dark" className="mb-5" key={props.ativo.id} style={{ minHeight: 150 }}>
        <Container fluid>
          <Row>
            <Col xs={1}>
              <Row className="align-items-center h-100 flex-wrap">
                <Col className="mx-auto">
                  <p
                    className="text-center"
                    style={{
                      fontSize: 50,
                      color: '#5c6570',
                      fontWeight: 'bold',
                      height: 60,
                      width: 80,
                    }}
                  >
                    {props.ativo.amountServiceSolicitations.toString().padStart(2, '0')}
                  </p>
                  <p
                    className="text-center"
                    style={{
                      fontSize: 12,
                      color: '#5c6570',
                      fontWeight: 'bold',
                      width: 80,
                    }}
                  >
                    Solicitações
                    <br />
                    Criadas
                  </p>
                </Col>
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <Col>
                  <p
                    className="mt-4"
                    style={{
                      fontSize: 14,
                      minHeight: 28,
                    }}
                  >
                    <strong className="h4">{props.ativo.aicode}</strong> - {props.ativo.title}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ minHeight: 48 }} className="text-justify description">
                    {props.ativo.description.length >= 200
                      ? props.ativo.description.substring(0, 200) + ' ...'
                      : props.ativo.description}

                    {props.ativo.description.length >= 200 && (
                      <span>
                        &nbsp;&nbsp;
                        <a href="/" variant="secondary" data-content={props.ativo.description} onClick={toggleReadMore}>
                          Leia mais
                        </a>
                      </span>
                    )}
                  </p>
                </Col>
              </Row>

              <Row className="mb-5">
                <Col>
                  <TooltipCustom title="Unidade de Origem do Ativo" placement="top" arrow>
                    <span className=" btn-secondary btn-sm mr-3 font-weight-bold cursor:default">
                      {props.ativo.organization.initials}
                    </span>
                  </TooltipCustom>
                  <TooltipCustom title="Consultar Solicitações" placement="top" arrow>
                    <Link
                      className="btn btn-secondary btn-sm mr-3"
                      role="button"
                      to={`/solicitacoes/por_ativo/${props.ativo.id}`}
                    >
                      <i className="fas fa-search"></i>
                    </Link>
                  </TooltipCustom>

                  <TooltipCustom title="Atualizar Ativo Intangível" placement="top" arrow>
                    <Link
                      className="btn btn-secondary btn-sm mr-3"
                      role="button"
                      to={`/ativo-intangivel/${props.ativo.id}/edit`}
                    >
                      <i className="fas fa-edit"></i>
                    </Link>
                  </TooltipCustom>

                  <TooltipCustom title="Excluir Ativo Intangível" placement="top" arrow>
                    <Button
                      variant="secondary"
                      size="sm"
                      className="mr-3"
                      onClick={() =>
                        props.handleDestroyAssestIntangible(props.ativo.id, props.ativo.aicode, props.ativo.title)
                      }
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </TooltipCustom>

                  <TooltipCustom title="Histórico de Atualizações" placement="top" arrow>
                    <Link
                      className="btn btn-secondary btn-sm"
                      role="button"
                      to={`/ativo-intangivel/historico/${props.ativo.id}`}
                    >
                      <i className="fas fa-history"></i>
                    </Link>
                  </TooltipCustom>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Row>
                <Col className="text-right mt-2">
                  <p className="mt-2">
                    <i className="far fa-clock"></i>
                    {` Criado em ${props.ativo.moment}`}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="text-right mt-5 pt-5">
                  <Button
                    variant="secondary"
                    className="font-weight-bold pt-3 mt-5"
                    onClick={(e) => props.handleSolicitarServico(e, props.ativo)}
                  >
                    Criar Solicitação
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default AssetItem
