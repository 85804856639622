const formObjectDemo = (userEmail, solicitationId, urlLocation, formHash, disposable = true) => {
  let obj = JSON.stringify({
    personData: {
      userEmailTeste: {
        data: userEmail,
        inFormHeader: {
          label: 'Usuário',
          order: 1,
        },
      },
      solicitationId: {
        data: solicitationId,
        inFormHeader: null,
        inFormModal: null,
      },
      serviceCode: {
        data: 'TESTE',
        inFormHeader: {
          label: 'Serviço Solicitado',
          order: 2,
        },
      },
      dataTime: {
        data: new Date().getTime(),
        inFormHeader: {
          label: 'Nº do Teste',
          order: 3,
        },
      },
      demonstration: disposable ? { data: true } : { data: false },
    },
    formHash: formHash,
    //formHash: '7e249bba-446b-416b-4a88-f4c9542c7b26',
    beforeLocation: urlLocation,
  })

  return obj
}

export default formObjectDemo
