import React from "react";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";
import { Button } from "react-bootstrap";

export function ErrorPage7() {
  const history = useHistory();

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
            Oops!
          </h1>
          <p className="font-weight-boldest display-4">
            Desculpe.
          </p>
          <p className="font-size-h3">
            Mas você não tem permissão para acessar esta área do sistema.
            <br />
            Se precisar de fato acessá-la, contacte um adminnistrador
            <br />
            e solicite acesso.
          </p>
          <div className="containter">
            <div className="row">
                <Button onClick={() => history.push('/dashboard')}>
                <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Retornar ao Sistema
                </Button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
