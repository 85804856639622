import styled from 'styled-components'

export const VerticalButton = styled.button`
  padding: 8px 16px;
  border: 0;
  border-radius: 10px;
  height: 120px;
  min-width: 120px;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: all 0.5s;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
  }

  * {
    color: #232323;
  }

  .iconButton-icon {
    * {
      font-size: 3rem;
    }
  }
`

export const HorizontalButton = styled.button`
  padding: 8px 16px;
  border: 0;
  border-radius: 16px;
  height: 120px;
  min-width: 200px;
  max-width: 200px;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  opacity: 0.7;
  transition: all 0.5s;
  text-align: left;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.3;
  }

  * {
    color: #333;
  }

  .iconButton-icon {
    margin-right: 1rem;
    * {
      font-size: 3rem;
    }
  }
`

export const FileButton = styled.button`
  min-width: 23ch;
  max-width: 23ch;
  opacity: 0.7;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:hover {
    text-decoration: underline;
  }
`
