import TooltipCustom from '../../TooltipCustom'
import React from 'react'
import { Card } from 'react-bootstrap'
import { BadgeGT } from '../..'
import { CardLabel } from './Dashboard.styles'
import { CardContent } from './Dashboard.styles'
import { CardIcon } from './Dashboard.styles'

const CardDashboard = ({ icon, content, label, variant, badge, toolTipText }) => {
  const [bgColor, setBgColor] = React.useState('#C9DAF8')

  React.useEffect(() => {
    switch (variant) {
      case 'primary':
        setBgColor('#C9DAF8')
        break
      case 'secondary':
        setBgColor('#D9EAD3')
        break
      case 'warning':
        setBgColor('#FFF2CD')
        break
      case 'danger':
        setBgColor('#F4CCCC')
        break
      default:
        setBgColor('#C9DAF8')
    }
  }, [variant])
  return (
    <TooltipCustom title={toolTipText == null ? '' : toolTipText} placement="top">
      <Card
        className="border border-dark"
        style={{
          boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: '1rem',
          backgroundColor: bgColor,
          minHeight: 146,
        }}
      >
        <Card.Body>
          {badge && (
            <>
              <BadgeGT
                variant="danger"
                className="position-absolute"
                style={{ fontSize: '1rem', top: -8, right: -5 }}
                text={badge}
              />
            </>
          )}
          {icon && <CardIcon>{icon}</CardIcon>}
          <CardContent>
            {content}
            <CardLabel className="text-center">{label}</CardLabel>
          </CardContent>
        </Card.Body>
      </Card>
    </TooltipCustom>
  )
}

export default CardDashboard
