import React from 'react'
import { Formik } from 'formik'
import * as auth from '../../../Auth/_redux/authRedux'
import { Col, Row, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { InputCPF } from '../../../../components/Input'
import { AlertPassword } from '../../../../components'
import { getFinishUser, updateFinishUser } from '../../_redux/admin/adminActions'
import { validationSchemaFinishUser } from './validationSchema'
import { toast } from 'react-toastify'

function UserFinish(props) {
  const { hash } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const { formData, saveStatus, statusMessage, listLoading, saveError, lastError } = useSelector((state) => state.admin)

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (saveStatus === 'success' && statusMessage !== null) {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
      history.push(`/auth/login`)
    }
    // eslint-disable-next-line
  }, [saveStatus])

  React.useEffect(() => {
    dispatch(getFinishUser(hash))

    // eslint-disable-next-line
  }, [hash])

  const handleSubmit = (values) => {
    dispatch(updateFinishUser(hash, values.password))
  }

  return (
    <>
      <AlertPassword show={open} onConfirm={() => setOpen(false)} />
      <div className="login-aside login-signin" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.USER.FINISH.TITLE" />
          </h3>
          <FormattedMessage id="AUTH.USER.FINISH.SUBTITLE" />
        </div>

        <Formik
          validationSchema={validationSchemaFinishUser}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          on
          initialValues={{
            password: '',
            c_password: '',
          }}
        >
          {(formik) => (
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              <div className="form-group fv-plugins-icon-container">
                <Row>
                  <Col sm={12}>
                    {lastError && lastError.message && (
                      <Col sm={12}>
                        <Alert variant="danger">
                          <p>
                            <strong>Ops! Não foi possivel finalizar este cadastro.</strong>{' '}
                          </p>
                          <hr />
                          <div dangerouslySetInnerHTML={{ __html: lastError.message }}></div>
                        </Alert>

                        <p className="mt-10">
                          <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">
                            Ir para tela de login.
                          </Link>
                        </p>
                      </Col>
                    )}
                  </Col>
                </Row>

                {!lastError && (
                  <>
                    <Row>
                      <Col sm={12}>
                        <Form.Group md="12">
                          <Form.Label>Nome do Usuário</Form.Label>
                          <Form.Control
                            as="input"
                            name="name"
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            value={formData.name}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group md="12">
                          <Form.Label>E-mail do Usuário</Form.Label>
                          <Form.Control
                            as="input"
                            name="email"
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            value={formData.email}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group md="12">
                          <Form.Label>CPF do Usuário</Form.Label>
                          <InputCPF
                            name="cpf"
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            value={formData.cpf}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group md="12">
                          <Form.Label>Unidade de alocação do usuário</Form.Label>
                          <Form.Control
                            as="select"
                            name="unit"
                            id="taskType"
                            className={formik.touched.organization && formik.errors.organization ? 'is-invalid' : null}
                            disabled
                          >
                            <option key={`unit-${formData.organization_id}`} value={formData.organization_id}>
                              {formData.organization}
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr className="my-5" />
                    <Row className="mt-5">
                      <Col sm={12} className="mb-5">
                        <h4 className="font-size-h4">
                          Para finalizar o seu cadastro, crie e repita a sua senha de acesso nos campos abaixo.
                        </h4>
                        <button className="btn btn-link font-weight-bold p-0" onClick={() => setOpen(true)}>
                          Clique aqui e veja os requisitos de segurança para a criação da senha.
                        </button>
                      </Col>
                      <Col sm={6}>
                        <Form.Group md="6">
                          <Form.Label>Senha de acesso ao sistema</Form.Label>
                          <Form.Control
                            as="input"
                            name="password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            placeholder="************"
                            {...formik.getFieldProps('password')}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.password && formik.errors && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="float-left"
                              style={{ width: '70%', display: 'block' }}
                            >
                              {formik.errors.password}
                            </Form.Control.Feedback>
                          )}
                          {saveError.length > 0 && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="float-left"
                              style={{ width: '70%', display: 'block' }}
                            >
                              {saveError[0].password[0]}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group md="6">
                          <Form.Label>Repita a senha de acesso ao sistema</Form.Label>
                          <Form.Control
                            as="input"
                            name="c_password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            placeholder="************"
                            {...formik.getFieldProps('c_password')}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.c_password && formik.errors.c_password && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="float-left"
                              style={{ width: '70%', display: 'block' }}
                            >
                              {formik.errors.c_password}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}

                {!lastError && (
                  <>
                    <div className="form-group d-flex flex-wrap flex-center">
                      {/*  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleOnChange} /> */}
                      <Button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
                        {listLoading && <Spinner animation="border" variant="light" />}
                        {!listLoading && 'Finalizar Cadastro'}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(UserFinish))
