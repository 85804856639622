import React from 'react'
import styled from 'styled-components'

export const CheckMarkWrapper = styled.div`
  display: block;
  width: 30px;

  i {
    color: #24751e;
  }
`

const CheckMarck = ({ className }) => {
  return (
    <CheckMarkWrapper className={className}>
      <i className="fas fa-check"></i>
    </CheckMarkWrapper>
  )
}

export default CheckMarck
