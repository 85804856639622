import * as yup from 'yup'

export const validationSchemaCancelSolicitation = () =>
  yup.object().shape({
    cancelDescription: yup
      .string()
      .required('O campo "Justificativa do Cancelamento" é de preenchimento obrigatório.')
      .min(3, 'O campo "Justificativa do Cancelamento" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Justificativa do Cancelamento" deve ter no máximo 1000 caracteres.'),
  })

export const validationSchemaAbandonAttendance = () =>
  yup.object().shape({
    abandonDescription: yup
      .string()
      .required('O campo "Justificativa do Abandono" é de preenchimento obrigatório.')
      .min(3, 'O campo "Justificativa do Abandono" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Justificativa do Abandono" deve ter no máximo 1000 caracteres.'),
  })

export const validationSchemaForwardDescription = () =>
  yup.object().shape({
    forwardDescription: yup
      .string()
      .required('O campo "Descrição da justificativa" é de preenchimento obrigatório.')
      .min(3, 'O campo "Descrição da justificativa" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Descrição da justificativa" deve ter no máximo 1000 caracteres.'),
  })

export const validationSchemaCompleteAttendance = () =>
  yup.object().shape({
    concludingOpinion: yup
      .string()
      .required('O campo "Parecer de Conclusão" é de preenchimento obrigatório.')
      .min(3, 'O campo "Parecer de Conclusão" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Parecer de Conclusão" deve ter no máximo 1000 caracteres.'),
  })

export const validationSchemaRejectAttendance = () =>
  yup.object().shape({
    rejectMessage: yup
      .string()
      .required('O campo "Justificativa para Reprovação" é de preenchimento obrigatório.')
      .min(3, 'O campo "Justificativa para Reprovação" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Justificativa para Reprovação" deve ter no máximo 1000 caracteres.'),
  })

export const validationSchemaForumMessage = () =>
  yup.object().shape({
    forumMessage: yup
      .string()
      .required('O campo "Mensagem" é de preenchimento obrigatório.')
      .min(3, 'O campo "Mensagem" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Mensagem" deve ter no máximo 1000 caracteres.'),
  })

export const validationTask = () =>
  yup.object().shape({
    description: yup
      .string()
      .required('O campo "Descrição da Tarefa" é de preenchimento obrigatório.')
      .min(3, 'O campo "Descrição da Tarefa" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Descrição da Tarefa" deve ter no máximo 1000 caracteres.'),
    executantTask: yup
      .string()
      .ensure()
      .required('O campo "Executante da Tarefa" é de preenchimento obrigatório.'),
    taskType: yup
      .string()
      .ensure()
      .required('O campo "Tipo da Tarefa" é de preenchimento obrigatório.'),
  })
