import axios from 'axios'

const handleDownload = (downloadId, originalName) => {
  const API_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  axios({
    url: `${API_BASE_URL}/api/attachment/${downloadId}/download`,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', originalName)
    document.body.appendChild(link)
    link.click()
  })
}

export default handleDownload
