import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { CardDashboard, AlertIntangibleAsset } from '../../../../../components'
import { getDashboard } from '../../../_redux/dashboard/dashboardActions'

const Cards = () => {
  const dispatch = useDispatch()
  const { totalSolicitations, incompleteSolicitations, totalIntangibleAssets } = useSelector(
    (state) => state.solicitations
  )
  const { user } = useSelector((state) => state.auth)
  const { widgets, count } = useSelector((state) => state.dashboard)

  const [showAlertIntangibleAsset, setShowAlertIntangibleAsset] = React.useState(false)
  const [colWidth, setColWidth] = React.useState(null)

  // eslint-disable-next-line
  var leftCard = {}
  // eslint-disable-next-line
  var rightCard = {}
  var showIntangibleAssetLink = false

  useEffect(() => {
    dispatch(getDashboard())
  }, [dispatch])

  useEffect(() => {
    setColWidth(count > 5 ? 3 : 12 / count)
  }, [count])

  switch (user.roles[0]) {
    case 'admin':
      leftCard = {
        text: 'Solicitações dos Meus Serviços',
        total: '?',
        badge: incompleteSolicitations ? 'Existem novas solicitações.' : null,
      }
      rightCard = { text: 'Meus Serviços', total: '?', badge: null }
      break
    case 'admin-servico':
      leftCard = {
        text: 'Solicitações dos Meus Serviços',
        total: '?',
        badge: incompleteSolicitations ? 'Existem novas solicitações.' : null,
      }
      rightCard = { text: 'Meus Serviços', total: '?', badge: null }
      break
    case 'atendente':
      leftCard = { text: 'Meus Atendimentos', total: '?', badge: null }
      rightCard = { text: 'Solicitações Disponíveis em BIO', total: '?', badge: null }
      break
    case 'solicitante':
      leftCard = {
        text: 'Minhas Solicitações',
        total: totalSolicitations,
        badge: incompleteSolicitations ? 'Existe solicitação incompleta.' : null,
      }
      rightCard = { text: 'Meus Ativos Intangíveis', total: totalIntangibleAssets, badge: null }
      showIntangibleAssetLink = true
      break
    default:
      break
  }

  return (
    <>
      <AlertIntangibleAsset
        show={showAlertIntangibleAsset}
        onConfirm={() => setShowAlertIntangibleAsset(false)}
        onDismissed={() => setShowAlertIntangibleAsset(false)}
      />
      {showIntangibleAssetLink && (
        <Row>
          <Col className="pl-5 text-right">
            <NavLink to="#" onClick={() => setShowAlertIntangibleAsset(true)}>
              O que é um ativo intangível?
            </NavLink>
          </Col>
        </Row>
      )}
      {colWidth && (
        <Row className="py-5">
          {widgets.map((widget, index) => (
            <Col className={`col-md-${colWidth} col-12`} key={index}>
              <NavLink to={widget.link}>
                <CardDashboard
                  variant={widget.bgColor || 'primary'}
                  icon={widget.icon ? <i className={widget.icon}></i> : null}
                  content={widget.value}
                  label={widget.label}
                  badge={widget.label_badge}
                  toolTipText={widget.tooltip_badge}
                />
              </NavLink>
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default Cards
