import * as yup from 'yup'

export const validationSchemaTaskType = () =>
  yup.object().shape({
    type: yup
      .string()
      .required('O campo "Tipo de Tarefa" é de preenchimento obrigatório.')
      .min(3, 'O campo "Tipo de Tarefa" deve ter no mínimo 03 caracteres.')
      .max(100, 'O campo "Tipo de Tarefa" deve ter no máximo 100 caracteres.'),
  })
