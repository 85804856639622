import * as requestFromServer from './tasksTypesEndpoints'

import { tasksTypesSlice } from './tasksTypesSlice'

const { actions } = tasksTypesSlice

/**
 * Criar Tipo de Tarefa
 * @param {*} newIntangibleAssetObj
 * @returns
 */
export const createTypeTask = (type) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .createTypeTask(type)
    .then((response) => {
      dispatch(actions.createTypeTask(response.data))
      dispatch(actions.setLoading(false))
      return response.data
    })
    .catch((error) => {
      dispatch(actions.setLoading(false))
      dispatch(actions.catchError(error.response.data))
      throw error
    })
}

/**
 * Atualizar Tipo de Tarefa
 * @param {*} newIntangibleAssetObj
 * @returns
 */
export const updateTypeTask = (id, type) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .updateTypeTask(id, type)
    .then((response) => {
      dispatch(actions.updateTypeTask(response.data))
      dispatch(actions.setLoading(false))
      return response.data
    })
    .catch((error) => {
      dispatch(actions.setLoading(false))
      dispatch(actions.catchError(error.response.data))
      throw error
    })
}

/**
 * Remover Tipo de Tarefa
 * @param {*} newIntangibleAssetObj
 * @returns
 */
export const deleteTypeTask = (id) => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .deleteTypeTask(id)
    .then((response) => {
      dispatch(actions.deleteTypeTask(response.data))
      dispatch(actions.setLoading(false))
      return response.data
    })
    .catch((error) => {
      dispatch(actions.setLoading(false))
      dispatch(actions.catchError(error.response.data))
      throw error
    })
}

/**
 * Lista os Tipos de Tarefa
 * @param {*} newIntangibleAssetObj
 * @returns
 */
export const getListTypeTasks = () => (dispatch) => {
  dispatch(actions.setLoading(true))
  return requestFromServer
    .getTypeTasks()
    .then((response) => {
      dispatch(actions.listTypeTasks(response.data.types))
      dispatch(actions.setLoading(false))
      return response.data
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response ? error.response.data : 'Não foi possível listar, tente novamente em instantes.'
        )
      )
      dispatch(actions.setLoading(false))
      throw error
    })
}

export const cleanStatus = () => (dispatch) => {
  dispatch(actions.cleanStatus())
}

export const cleanErrors = () => (dispatch) => {
  dispatch(actions.cleanErrors())
}

export const selectTypeTask = (typeTask) => (dispatch) => {
  dispatch(actions.setTypeTaskSelected(typeTask))
}

export const cleanTypeTaskSelected = (typeTask) => (dispatch) => {
  dispatch(actions.setTypeTaskSelected(null))
}
