import React from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button, Col, Row } from 'react-bootstrap'
import TooltipCustom from '../../../../components/TooltipCustom'
import { AlertMessage } from './AlertMessage'

export const ListRequirementsService = (props) => {
  const history = useHistory()
  const arrrequirements = props.requirements
  const service = props.service

  return (
    <>
      <Row>
        <Col>
          {service !== null && arrrequirements.length === 0 && (
            <AlertMessage
              variant="alert alert-custom alert-light-primary alert-dismissible"
              message="Não há requisitos cadastrados para este serviço"
            />
          )}
        </Col>
      </Row>
      {service !== null && arrrequirements.length > 0 && (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th>CÓDIGO</th>
                <th>DESCRIÇÃO</th>
                <th>TIPO</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {service.requirements.map((requirement, i) => (
                <tr key={i}>
                  <td className="align-middle" style={{ width: '5%' }}>
                    {requirement.code}
                  </td>
                  <td className="align-middle" style={{ width: '75%' }}>
                    {requirement.currentVersion.description}
                  </td>
                  <td className="align-middle" style={{ width: '15%' }}>
                    {requirement.currentVersion.type.type}
                  </td>
                  <td className="align-middle" style={{ width: '5%' }}>
                    <TooltipCustom title="Consultar dados do Requisito" placement="top" arrow>
                      <Button
                        className="mt-1 mb-1"
                        variant="outline-secondary"
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/servico/${service.id}/consulta/requisito/${requirement.currentVersion.requirement_id}/${service.id}`
                          )
                        }
                      >
                        <i className="fas fa-search fa-sm"></i>
                      </Button>
                    </TooltipCustom>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}
