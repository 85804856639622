import { createSlice } from '@reduxjs/toolkit'

const initialTaskState = {
  listLoading: true,
  myServices: [],
  myTask: [],
  getDocuments: [],
  tasksOngoing: [],
  solicitaionsMyServices: [],
  taskEnableServices: [],
  taskOngoing: [],
  solicitationSelected: null,
  tasksList: [],
  receivedTasksList: [],
  createdTasksList: [],
  solicitationForumMessages: null,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  serviceList: [],
  taskData: null,
  taskRegisterData: null,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const taskSlice = createSlice({
  name: 'tasks',
  initialState: initialTaskState,
  reducers: {
    catchError: (state, action) => {
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    setLastError: (state, action) => {
      state.lastError = action.payload
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    successMessage: (state, action) => {
      state.statusMessage = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
      state.saveError = []
    },

    cleanSaveStatus: (state, action) => {
      state.saveStatus = null
      state.statusMessage = null
      state.lastError = null
    },

    clearSaveError: (state, action) => {
      state.saveError = []
      state.lastError = null
      state.statusMessage = null
    },

    setTaskData: (state, action) => {
      state.taskData = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.lastError = null
    },

    setTaskRegisterData: (state, action) => {
      state.taskRegisterData = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.lastError = null
    },

    setTaskUpdateData: (state, action) => {
      state.taskUpdateData = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.lastError = null
    },

    getReceivedTask: (state, action) => {
      state.receivedTasksList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
    getCreatedTask: (state, action) => {
      state.createdTasksList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
  },
})
