import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

//${API_BASE_URL}   //Para usar parâmetros do .env
export const LOGIN_URL = `${API_BASE_URL}/api/auth/login`
// export const REGISTER_URL = `${API_BASE_URL}/api/auth/register`
export const REGISTER_URL = `${API_BASE_URL}/api/user_candidate/create`
export const REQUEST_PASSWORD_URL = `${API_BASE_URL}/api/forget-password`
export const RESET_PASSWORD_URL = `${API_BASE_URL}/api/auth/updatepassword`
export const LOGOUT_URL = `${API_BASE_URL}/api/auth/logout`

export const ME_URL = `${API_BASE_URL}/api/auth/profile`

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password })
}

export function register(name, email, phone, date_of_birth, cpf, organization_id, password) {
  return axios.post(REGISTER_URL, { name, email, phone, date_of_birth, cpf, organization_id, password })
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email })
}
export function resetPassword(password, hashmail) {
  return axios.post(RESET_PASSWORD_URL, { password, hashmail })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL)
}
export function userLogout() {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(LOGOUT_URL)
}
