import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, Card, Alert, Button } from 'react-bootstrap'
import {
  getActionIntangibleAssetsByUser,
  removeIntangibleAsset,
} from '../../../_redux/intangible_asset/intangibleAssetActions'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'
import AssetItem from '../../../components/intangible_asset/AssetItem'
import SearchBar from '../../../components/intangible_asset/SearchBar'
import { useSubheader } from '../../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle } from '../../../../../routes/Helpers'
import { AlertGT, AlertIntangibleAsset } from '../../../../../components'
import { deleteSolicitation, selectIntangibleAsset, stepOne } from '../../../_redux/solicitation/solicitationActions'

export function ListIntangibleAssetsPage() {
  const userId = useSelector(({ auth }) => auth.user.id)
  const { listIntangibleAssetsByUser, errorOnDelete, successOnDelete } = useSelector((state) => state.intangibleAssets)
  const { incompleteSolicitations } = useSelector((state) => state.solicitations)

  const history = useHistory()
  const [intangibleAssets, setIntangibleAssets] = useState([])
  const [deleteIA, setDeleteIA] = useState(null)
  const [searchTextInput, setSearchTextInput] = useState('')
  const [intangibleAssetsFiltereds, setIntangibleAssetsFiltereds] = useState(intangibleAssets)
  const [showAlertIntangibleAsset, setShowAlertIntangibleAsset] = useState(false)
  const [search, setSearch] = useState(null)

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const MySwal = withReactContent(Swal)

  const dispatch = useDispatch()
  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getActionIntangibleAssetsByUser(userId))
    dispatch(stepOne())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setIntangibleAssets(listIntangibleAssetsByUser)
    setIntangibleAssetsFiltereds(listIntangibleAssetsByUser)
  }, [listIntangibleAssetsByUser, deleteIA])

  useEffect(() => {
    if (errorOnDelete !== null) {
      toast.error(`${errorOnDelete}`, {})
    }
    if (successOnDelete !== null) {
      toast.success(`Sucesso!  Ativo Intangível excluído.`, {})
    }
    // eslint-disable-next-line
  }, [errorOnDelete, successOnDelete])

  useEffect(() => {
    if (deleteIA !== null) {
      dispatch(removeIntangibleAsset(deleteIA))
      //dispatch(getActionIntangibleAssetsByUser(userId))
    }
    dispatch(getActionIntangibleAssetsByUser(userId))
    setDeleteIA(null)
    // eslint-disable-next-line
  }, [deleteIA])

  const handleSolicitarServico = (e, ativo) => {
    e.preventDefault()
    if (incompleteSolicitations && incompleteSolicitations.id) {
      const content = `
        <p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
        e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
        Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
        <p>Deseja prosseguir?</p>
      `
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        width: 650,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
        confirm: () => confirmSolicition(ativo),
        toastSuccess: null,
      })
    } else {
      confirmSolicition(ativo)
    }
  }

  function confirmSolicition(ativo) {
    dispatch(selectIntangibleAsset({ code: ativo.aicode, ...ativo }))
    if (incompleteSolicitations && incompleteSolicitations.id) confirmRemoveSolicition()
    history.push('/solicitacao/novo/etapa-dois')
  }

  function confirmRemoveSolicition() {
    dispatch(deleteSolicitation(incompleteSolicitations))
  }

  function handleDestroyAssestIntangible(aiId, aicode, title) {
    MySwal.fire({
      title: 'Excluir Ativo Intangível',
      html: `Deseja excluir o Ativo Intangível  <strong>${aicode} - ${title}</strong>?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleteIA(aiId)
      }
    })
  }

  const handleSearchText = (inpuText) => {
    setSearchTextInput(inpuText)

    const entrada = inpuText
    setSearch(entrada)
    const filtrados =
      entrada !== null || entrada !== ''
        ? intangibleAssets.filter((ai) => {
            if (entrada.length > 0) {
              return (
                ai.aicode.toLowerCase().indexOf(`${entrada.toLowerCase()}`) !== -1 ||
                ai.title.toLowerCase().indexOf(`${entrada.toLowerCase()}`) !== -1 ||
                ai.description.toLowerCase().indexOf(`${entrada.toLowerCase()}`) !== -1 ||
                ai.organization.initials.toLowerCase().indexOf(`${entrada.toLowerCase()}`) !== -1 ||
                ai.services_count
                  .toString()
                  .padStart(2, '0')
                  .indexOf(`${entrada.toLowerCase()}`) !== -1 ||
                ai.moment.indexOf(`${entrada.toLowerCase()}`) !== -1
              )
            } else {
              return true
            }
          })
        : null
    setIntangibleAssetsFiltereds(filtrados)
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <AlertIntangibleAsset
        show={showAlertIntangibleAsset}
        onConfirm={() => setShowAlertIntangibleAsset(false)}
        onDismissed={() => setShowAlertIntangibleAsset(false)}
      />
      <Card border="secondary" className="pt-0">
        <Card.Body>
          <Row className="mt-0">
            <Col>
              <div
                className="text-right"
                style={{ cursor: 'pointer' }}
                onClick={() => setShowAlertIntangibleAsset(true)}
              >
                O que é um Ativo Intangível?
              </div>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col sm={9}>
              <SearchBar name="search" placeholder="Digite o alvo de sua busca" handleSearchText={handleSearchText} />
            </Col>
            <Col sm={3} className="text-right">
              <div className="d-grid">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => history.push('/ativo-intangivel/novo')}
                  className="font-weight-bold btn-block"
                >
                  Cadastrar Ativo Intangível
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {intangibleAssetsFiltereds.length === 0 && (
                <Alert variant="primary" className="mt-3">
                  {intangibleAssetsFiltereds.length === 0 && searchTextInput.length !== 0
                    ? `Não foram encontrados resultados para o termo ${search}.`
                    : 'Não existem Ativos Intangíveis cadastrados por você no sistema.'}
                </Alert>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              {intangibleAssetsFiltereds.map((ativo, i) => (
                <AssetItem
                  ativo={ativo}
                  handleSolicitarServico={handleSolicitarServico}
                  handleDestroyAssestIntangible={handleDestroyAssestIntangible}
                  key={i}
                />
              ))}
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="text-right">
              <Button
                variant="secondary"
                type="button"
                onClick={() => history.push('/dashboard')}
                className="font-weight-bold"
              >
                <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
              </Button>{' '}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
