import { ErrorWithoutPermissionPage } from '../modules/System/pages/ErrorWithoutPermissionPage'
import ErrorUnauthorized from '../modules/System/components/ErrorUnauthorized'
import { PresentationPage } from '../pages/sis/PresentationPage'
import WarninPage from '../modules/System/pages/WarningPage'

export default [
  {
    pathName: '/403-forbidden',
    exact: true,
    title: 'Acesso Proibido',
    subTitle: 'Erro 403',
    icon: '',
    component: ErrorWithoutPermissionPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/401-unauthorized',
    exact: true,
    title: 'Acesso Proibido',
    subTitle: 'Erro 403',
    icon: '',
    component: ErrorUnauthorized,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
  {
    pathName: '/reset/password/warning',
    exact: true,
    title: 'Alteração de senha',
    subTitle: 'Não foi possível atender solicitação de alteração de senha',
    icon: '',
    component: WarninPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },

  /**
   * Page Teste
   */
  {
    pathName: '/teste',
    exact: false,
    title: 'Page Testes',
    subTitle: 'Um subtítulo de Teste',
    icon: '',
    component: PresentationPage,
    permission: ['admin', 'admin-servico', 'solicitante'],
  },
]
