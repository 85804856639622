import { DashboardPage } from '../pages/DashboardPage'
import { UserQualification } from '../modules/Admin/pages/user/UserQualification'
import UserListPage from '../modules/Admin/pages/user/UserListPage'
import UserCreate from '../modules/Admin/pages/user/UserCreate'
import UserUpdate from '../modules/Admin/pages/user/UserUpdate'
import UserPermissionsEditPage from '../modules/Admin/pages/user/UserPermissionsEditPage'
import UserOrganizationHistory from '../modules/Admin/pages/user/UserOrganizationHistory'
import { AdminAssetsSolicitationsPage } from '../modules/Admin/pages/AdminUnit/AdminAssetsSolicitationsPage'
import UserView from '../modules/Admin/pages/user/UserView'
import UserOwnView from '../modules/Admin/pages/user/UserOwnView'
import ResetPassUserLogged from '../modules/Admin/pages/user/ResetPassUserLogged'

export default [
  /**
   * Admin
   * pathname principal: /administracao
   */
  {
    pathName: '/administracao',
    exact: true,
    title: 'Administração',
    subTitle: 'Dashboard de Administração.',
    icon: '',
    component: DashboardPage,
    permission: ['admin'],
  },
  {
    pathName: '/adm/rsp/:id1',
    exact: true,
    title: 'Usuário',
    subTitle: 'Alteração de Senha.',
    icon: '',
    component: ResetPassUserLogged,
    permission: ['admin'],
  },

  /**
   * Admin (usuários)
   * pathname principal: /admin/user
   */
  {
    pathName: '/administracao/usuarios/liberar-acesso',
    exact: true,
    title: 'Acesso de Usuários',
    subTitle: 'Lista dos candidatos a usuários do sistema. Aqui você pode liberar ou negar seu acesso.',
    icon: '',
    component: UserQualification,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/usuarios/gerencia',
    exact: true,
    title: 'Gerência de Usuários',
    subTitle: 'Controle das permissões e níveis de atuação do usuário.',
    icon: '',
    component: UserListPage,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/usuarios/novo',
    exact: true,
    title: 'Cadastro de Usuário',
    subTitle: 'Formulário de cadastro de novos usuários do sistema.',
    icon: '',
    component: UserCreate,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/usuarios/:id1/edit',
    exact: true,
    title: 'Atualização de Usuário',
    subTitle: 'Formulário de atualização dos dados do usuário.',
    icon: '',
    component: UserUpdate,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/usuarios/:id1/view',
    exact: true,
    title: 'Consulta de Usuário',
    subTitle: 'Visualização dos dados do usuário.',
    icon: '',
    component: UserView,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/usuario/view',
    exact: true,
    title: 'Meus Dados',
    subTitle: 'Consulta dos meus dados no sistema.',
    icon: '',
    component: UserOwnView,
    permission: ['admin', 'solicitante', 'admin-unidade', 'admin-servico', 'atendente'],
  },

  {
    pathName: '/administracao/usuarios/:id1/permissoes',
    exact: true,
    title: 'Acessos e Permissões do Usuário',
    subTitle: 'Controle das permissões e níveis de atuação do usuário.',
    icon: '',
    component: UserPermissionsEditPage,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/usuarios/:id1/historico/unidades',
    exact: true,
    title: 'Histórico de Unidades do Usuário',
    subTitle: 'Listagem das unidades em que o usuário já foi alocado.',
    icon: '',
    component: UserOrganizationHistory,
    permission: ['admin'],
  },
  {
    pathName: '/administracao/unidades/gerencia',
    exact: true,
    title: 'Administração de Ativos e Solicitações',
    subTitle: 'Ativos Intangíveis e Solictações sob sua adminisração.',
    icon: '',
    component: AdminAssetsSolicitationsPage,
    permission: ['admin-unidade'],
  },
  {
    pathName: '/administracao/unidades/gerencia/:id1',
    exact: true,
    title: 'Administração de Ativos e Solicitações',
    subTitle: 'Ativos Intangíveis e Solictações sob sua adminisração.',
    icon: '',
    component: AdminAssetsSolicitationsPage,
    permission: ['admin-unidade'],
  },
]
