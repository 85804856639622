import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

export const createTypeTask = async (type) =>
  await axios.post(`${API_BASE_URL}/api/types_task`, {
    type,
  })

export const updateTypeTask = async (id, type) =>
  await axios.put(`${API_BASE_URL}/api/types_task/${id}`, {
    type,
  })

export const getTypeTasks = async (type) => await axios.get(`${API_BASE_URL}/api/types_task`)

export const deleteTypeTask = async (id) => await axios.delete(`${API_BASE_URL}/api/types_task/${id}`)
