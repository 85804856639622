import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

/**
 * Retorna da api objeto com todos usuários e todos os seus dados:
 * organização, infomações, acessos, serviços, etc..
 * @returns
 */
export const getAllUsersFull = async () => await axios.get(`${API_BASE_URL}/api/users`)

/**
 * Retorna dados de perfil, acesso, administrador/atendente de serviço
 * @param {*} id
 * @returns
 */
export const getAccessRoleAndServicesByUser = async (id) =>
  await axios.get(`${API_BASE_URL}/api/user/${id}/get-roles-and-service-permissions`)

/**
 * Retorna objeto com os usuarios atualmente bloqueados no sistema.
 * @returns
 */
export async function getAllBlockedUsers() {
  return axios.get(`${API_BASE_URL}/api/user/qualification/blocked_users`)
}
/**
 * Retorna dados dos usuários
 * @returns
 */
export async function getAllUsers() {
  return axios.get(`${API_BASE_URL}/api/usuarios`)
}
/**
 * Retorna dados de um usuário do sistema
 * @param {*} id
 * @returns
 */
export async function getUser(id) {
  return axios.get(`${API_BASE_URL}/api/usuarios/${id}`)
}
/**
 * Grava o bloqueio do usuario no sistema, passando id do usuario e justificativa
 * @param {*} id
 * @param {*} obj
 * @returns
 */
export function blockUserWithJustification(id, obj) {
  return axios.put(`${API_BASE_URL}/api/user/qualification/user_qualification/${id}`, {
    user_id: obj.user_id,
    enable: obj.enable,
    justification: obj.justification,
  })
}

/**
 * Edição das permissões de acesso,  Pefis de acesso e Serviços.
 * @param {*} id
 * @param {*} obj
 * @returns
 */
export function editPermissions(id, obj) {
  return axios.post(`${API_BASE_URL}/api/user/${id}/setpermissions`, { obj })
}

/**
 * Endpoint para liberar acesso do usuário no sistema.
 * @param {*} id
 * @returns
 */
export const enableUser = async (id) => await axios.post(`${API_BASE_URL}/api/user/${id}/enable`)
/**
 * Endpoint para desabilitar o acesso do usuário no sistema.
 * @param {*} id
 * @returns
 */
export const disableUser = async (id) => await axios.post(`${API_BASE_URL}/api/user/${id}/disable`)

// export async function getAllUsers() {
//   return axios.get(`${API_BASE_URL}/api/user/qualification/blocked_users`);
// }

export const getUsersSimplified = async () => await axios.get(`${API_BASE_URL}/api/user-simplified`)

export const getTasksVisibleAdmin = async (per_page, page, search, sort_field, ordering) =>
  await axios.get(`${API_BASE_URL}/api/task/admin/visible`, {
    params: { per_page, page, search, sort_field, ordering },
  })

export const getCreateFormData = async () => await axios.get(`${API_BASE_URL}/api/admin/user/create/form_data`)

export const getUpdateFormData = async (id) => await axios.get(`${API_BASE_URL}/api/admin/user/${id}/edit/form_data`)

/**
 * Cadastrar Usuario (Por Administrador do Sistema).
 * @param name
 * @param email
 * @param cpf
 * @param date_of_birth
 * @param phone
 * @param organization
 * @returns
 */
export function createUser({ name, email, cpf, date_of_birth, phone, organization }) {
  return axios.post(`${API_BASE_URL}/api/admin/user/create`, { name, email, cpf, date_of_birth, phone, organization })
}
/**
 * Alterar senha do Usuario atualmente logado
 * @param actual_password
 * @param password
 * @returns
 */
export function userChangePassword({ actual_password, password }) {
  return axios.post(`${API_BASE_URL}/api/admin/user/change/password`, { actual_password, password })
}
/**
 * Alterar Telefone do Usuario atualmente logado
 * @param phone
 * @returns
 */
export function userChangePhone({ phone }) {
  return axios.post(`${API_BASE_URL}/api/admin/user/change/phone`, { phone })
}
/**
 * Pegar hash da senha atual do usuário
 * @param hashadPassword
 * @returns
 */
export function getHashedPassword() {
  return axios.post(`${API_BASE_URL}/api/admin/user/hashadpassword`)
}

/**
 * Atualizar Usuario (Por Administrador do Sistema).
 * @param {*} User
 * @returns
 */
export function updateUser({ id, name, email, cpf, date_of_birth, phone, organization }) {
  return axios.put(`${API_BASE_URL}/api/admin/user/${id}/update`, {
    name,
    email,
    cpf,
    date_of_birth,
    phone,
    organization,
  })
}

/**
 * Atualizar senhas do usuário na finalização.
 * @param {*} hash
 * @param {*} password
 * @returns
 */
export function updateFinishUser(hash, password) {
  return axios.post(`${API_BASE_URL}/api/admin/user/complete_user_registration/hash/${hash}`, { password })
}

/**
 * Finalização do usuário.
 * @param {*} hash
 * @returns
 */
export function finishUser(hash) {
  return axios.get(`${API_BASE_URL}/api/admin/user/complete_user_registration/hash/${hash}`)
}
/**
 * Verificação de hashmail
 * @param {*} hashmail
 * @returns
 */
export function verifyHashmailUrl(hashmail) {
  return axios.get(`${API_BASE_URL}/api/adm/user/hashvalidation/hashmail/${hashmail}`)
}
/**
 * Histórico de Unidades do usuário.
 * @param {*} id
 * @returns
 */
export function userOrganizationHistory(id) {
  return axios.get(`${API_BASE_URL}/api/user/${id}/organization/history`)
}
/**
 * Dados de Consulta de Usuário
 * @param {*} id
 * @returns
 */
export function userDataViewEndpoint(id) {
  return axios.get(`${API_BASE_URL}/api/user/${id}/view`)
}
