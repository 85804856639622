import { createSlice } from '@reduxjs/toolkit'

const initialTasksTypes = {
  loading: true,
  listTypeTasks: [],
  typeTaskSelected: null,
  lastError: null,
  saveStatus: null,
  statusMessage: null,
  saveError: [],
  errorOnUpdate: false,
  errorOnDelete: null,
  successOnDelete: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const tasksTypesSlice = createSlice({
  name: 'tasksTypes',
  initialState: initialTasksTypes,
  reducers: {
    catchError: (state, action) => {
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    createTypeTask: (state, action) => {
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
      state.error = null
      state.statusMessage = '<strong>Sucesso!</strong> Tipo de tarefa criado.'
    },

    updateTypeTask: (state, action) => {
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
      state.error = null
      state.statusMessage = '<strong>Sucesso!</strong> Tipo de tarefa atualizado.'
    },

    deleteTypeTask: (state, action) => {
      state.saveError = []
      state.saveStatus = 'success'
      state.lastError = null
      state.error = null
      state.statusMessage = '<strong>Sucesso!</strong> Tipo de tarefa excluído.'
    },

    listTypeTasks: (state, action) => {
      state.listTypeTasks = action.payload
      state.saveStatus = null
      state.saveError = []
      state.errorOnUpdate = false
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setTypeTaskSelected: (state, action) => {
      state.typeTaskSelected = action.payload
    },

    cleanStatus: (state, action) => {
      state.saveStatus = null
      state.statusMessage = null
    },

    cleanErrors: (state, action) => {
      state.saveStatus = null
      state.saveError = []
      state.errorOnUpdate = false
    },
  },
})
