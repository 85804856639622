import React from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Card, Button, Col, Row, Form, Spinner } from 'react-bootstrap'

import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle, hasPermission } from '../../../../routes/Helpers'

import { validationSchemaUser } from './validationSchema'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReactInputMask from 'react-input-mask'
import { cleanSaveStatus, createUser, getCreateFormData } from '../../_redux/admin/adminActions'

function UserCreate() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { formData, saveStatus, statusMessage, listLoading, saveError } = useSelector((state) => state.admin)

  const { user } = useSelector((state) => state.auth)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  React.useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
      dispatch(cleanSaveStatus())
      history.push('/administracao/usuarios/gerencia')
    }
    // eslint-disable-next-line
  }, [saveStatus, statusMessage])

  React.useEffect(() => {
    if (saveError && saveError.message)
      toast.error(() => <div dangerouslySetInnerHTML={{ __html: saveError.message }} />)
  }, [saveError])

  React.useEffect(() => {
    dispatch(getCreateFormData())
    // eslint-disable-next-line
  }, [])

  const handleSubmit = (values) => {
    dispatch(createUser({ ...values, cpf: values.cpf.replace(/\D/g, '') }))
  }

  return (
    <>
      <Card border="secondary">
        <Formik
          validationSchema={validationSchemaUser}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          initialValues={{
            name: '',
            email: '',
            cpf: '',
            phone: '',
            date_of_birth: '',
            organization: '',
          }}
        >
          {(formik) => (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Form.Group md="12">
                      <Form.Label>Nome do Usuário</Form.Label>
                      <Form.Control
                        as="input"
                        name="name"
                        maxLength="100"
                        {...formik.getFieldProps('name')}
                        className={
                          (formik.touched.name && formik.errors.name) || (saveError.length > 0 && saveError[0].name)
                            ? 'is-invalid'
                            : null
                        }
                        onChange={formik.handleChange}
                      />
                      {((formik.touched.name && formik.errors.name) || (saveError.length > 0 && saveError[0].name)) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.name || saveError[0].name[0]}
                        </Form.Control.Feedback>
                      )}
                      {/* <Form.Text className="text-right">Máximo de 100 caracteres.</Form.Text> */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group md="6">
                      <Form.Label>E-mail do Usuário</Form.Label>
                      <Form.Control
                        as="input"
                        name="email"
                        type="email"
                        maxLength="255"
                        {...formik.getFieldProps('email')}
                        className={
                          (formik.touched.email && formik.errors.email) || (saveError.length > 0 && saveError[0].email)
                            ? 'is-invalid'
                            : null
                        }
                        onChange={formik.handleChange}
                      />
                      {((formik.touched.email && formik.errors.email) ||
                        (saveError.length > 0 && saveError[0].email)) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.email || saveError[0].email[0]}
                        </Form.Control.Feedback>
                      )}
                      {/* <Form.Text className="text-right">Máximo de 100 caracteres.</Form.Text> */}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group md="6">
                      <Form.Label>CPF do Usuário</Form.Label>

                      <ReactInputMask
                        name="cpf"
                        mask="999.999.999-99"
                        {...formik.getFieldProps('cpf')}
                        className={
                          (formik.touched.cpf && formik.errors.cpf) || (saveError.length > 0 && saveError[0].cpf)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        onChange={formik.handleChange}
                      />
                      {((formik.touched.cpf && formik.errors.cpf) || (saveError.length > 0 && saveError[0].cpf)) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.cpf || saveError[0].cpf[0]}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group md="6">
                      <Form.Label>Data de Nascimento</Form.Label>
                      <Form.Control
                        as="input"
                        name="date_of_birth"
                        type="date"
                        maxLength="255"
                        {...formik.getFieldProps('date_of_birth')}
                        className={
                          (formik.touched.date_of_birth && formik.errors.date_of_birth) ||
                          (saveError.length > 0 && saveError[0].date_of_birth)
                            ? 'is-invalid'
                            : null
                        }
                        onChange={formik.handleChange}
                      />
                      {((formik.touched.date_of_birth && formik.errors.date_of_birth) ||
                        (saveError.length > 0 && saveError[0].date_of_birth)) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.date_of_birth || saveError[0].date_of_birth[0]}
                        </Form.Control.Feedback>
                      )}
                      {/* <Form.Text className="text-right">Máximo de 100 caracteres.</Form.Text> */}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group md="6">
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        as="input"
                        name="phone"
                        type="text"
                        maxLength="20"
                        {...formik.getFieldProps('phone')}
                        className={
                          (formik.touched.phone && formik.errors.phone) || (saveError.length > 0 && saveError[0].phone)
                            ? 'is-invalid'
                            : null
                        }
                        onChange={formik.handleChange}
                      />
                      {((formik.touched.phone && formik.errors.phone) ||
                        (saveError.length > 0 && saveError[0].phone)) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.phone || saveError[0].phone[0]}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <Form.Group md="12">
                      <Form.Label>Unidade de Alocação do Usuário</Form.Label>
                      <Form.Control
                        as="select"
                        name="organization"
                        {...formik.getFieldProps('organization')}
                        onChange={formik.handleChange}
                        className={
                          (formik.touched.organization && formik.errors.organization) ||
                          (saveError.length > 0 && saveError[0].organization)
                            ? 'is-invalid'
                            : null
                        }
                      >
                        <option value="">Selecione a unidade de alocação do usuário</option>
                        {formData.organizationList
                          ? formData.organizationList.map((unit) => (
                              <option key={`unit-${unit.id}`} value={unit.id}>
                                {unit.formated_name}
                              </option>
                            ))
                          : null}
                      </Form.Control>
                      {((formik.touched.organization && formik.errors.organization) || saveError.length > 0) && (
                        <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                          {formik.errors.organization || (saveError.length > 0 && saveError[0].organization)}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>

              <Card.Footer>
                <Row className="mt-0">
                  <Col className="text-left">
                    <Button type="submit" className="font-weight-bold" active={!listLoading} disabled={listLoading}>
                      {listLoading && <Spinner animation="border" variant="light" />}
                      {!listLoading && 'Confirmar Cadastro'}
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  )
}

export default UserCreate
