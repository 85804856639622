import React from 'react'

export const RequirementViewDataTypeForm = (props) => (
  <>
    <dl className="row">
      <dt className="col-sm-3 text-right">Dados do Formulário</dt>
      <dd className="col-sm-9 text-justify">
        <p className="font-weight-bold">{props.formName}</p>
        <p>{props.formDescription}</p>
        <p className="mt-5">
          <a href={props.formUrl} target="_blank" rel="noopener noreferrer">
            {`Demonstração descartável do formulário ${props.formOriginalName}`}
          </a>
        </p>
      </dd>
    </dl>
  </>
)
