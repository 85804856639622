import React, { useEffect } from 'react'
import { Card, Col, Row, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useSubheader } from '../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle } from '../../../routes/Helpers'
import { AlertGT, InputSearch } from '../../../components'
import { AlertMessage } from '../components/services/AlertMessage'
import ListBigSausage from '../components/solicitation/ListBigSausage'
import {
  deleteSolicitation,
  getHasServicesEnabled,
  getMySolicitations,
  selectSolicitation,
  selectIntangibleAsset,
  clearStateSolicitation,
  selectSolicitationInProgress,
} from '../_redux/solicitation/solicitationActions'

function SolicitationsPage() {
  const { mySolicitations, incompleteSolicitations, listLoading, hasServicesEnabled } = useSelector(
    (state) => state.solicitations
  )
  const user = useSelector((state) => state.auth.user)
  const [myAllSolicitations, setMyAllSolicitations] = React.useState(mySolicitations)
  const [myFilterSolicitations, setMyFilterSolicitations] = React.useState([])
  const [search, setSearch] = React.useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })
  const pathname = window.location.pathname
  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(clearStateSolicitation())
    function getSolicitions() {
      dispatch(getMySolicitations())
      dispatch(getHasServicesEnabled())
    }
    getSolicitions()
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    setMyAllSolicitations(mySolicitations)
  }, [mySolicitations])

  useEffect(() => {
    refreshSolicitations()
    // eslint-disable-next-line
  }, [myAllSolicitations])

  useEffect(() => {
    function filterSolicitations() {
      setMyFilterSolicitations([])
      const solicitationsFiltered = mySolicitations.filter((solicitation) => {
        const description = solicitation.description || ''
        const code = solicitation.code || ''
        const moment = solicitation.moment || ''
        const stage = solicitation.stage.stage || ''
        const intangibleAssetCode = solicitation.intangibleAssetOriginParent
          ? solicitation.intangibleAssetOriginParent.code
          : ''
        const intangibleAssetTitle = solicitation.intangibleAssetOriginParent
          ? solicitation.intangibleAssetOriginParent.title
          : ''
        const serviceCode = solicitation.service.code || ''
        const solicitationOrganizationInitials = solicitation.solicitation_organization.initials || ''
        const serviceTitle = solicitation.service.title || ''
        const solicitationParentCode = solicitation.solicitationParent ? solicitation.solicitationParent.code : ''
        const solicitationParentDescription = solicitation.solicitationParent
          ? solicitation.solicitationParent.description
          : ''

        return (
          description.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          code.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          moment.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          stage.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          solicitationOrganizationInitials.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          intangibleAssetCode.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          intangibleAssetTitle.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          serviceCode.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          serviceTitle.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          solicitationParentCode.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          solicitationParentDescription.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
      })
      setMyAllSolicitations(solicitationsFiltered)
    }
    filterSolicitations()
  }, [search, mySolicitations])

  const subheader = useSubheader()

  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)

  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  if (user.roles.includes('solicitante') && user.roles.includes('admin-unidade')) {
    subheader.setSubtitle(
      'Solicitações criadas pelo Solicitante. Lembre-se de que as solicitações que você cria como Administrador de Unidade, ficam disponíveis em Administração de Ativos e Solicitações, na aba Solicitações.'
    )
  } else {
    subheader.setSubtitle('Solicitações criadas pelo solicitante.')
  }

  function refreshSolicitations() {
    const limit = myFilterSolicitations.length + 5
    setMyFilterSolicitations(myAllSolicitations.slice(0, limit))
  }

  function setSolicitation(solicitation) {
    if (incompleteSolicitations && incompleteSolicitations.id && solicitation.stage.slug !== 'incompleta') {
      const content = `
        <p>Você possui uma solicitação incompleta utilizando o ativo intangível <strong>${incompleteSolicitations.intangibleAssetOriginParent.code} - ${incompleteSolicitations.intangibleAssetOriginParent.title}</strong>
        e o serviço <strong>${incompleteSolicitations.service.code} - ${incompleteSolicitations.service.title}</strong>.
        Se você prosseguir com a criação de uma nova solicitação, a solicitação incompleta será descartada.</p>
        <p>Deseja prosseguir?</p>
      `
      setConfigAlert({
        show: true,
        title: null,
        text: content,
        icon: 'question',
        cancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
        confirm: () => confirmSolicitation(solicitation),
        toastSuccess: null,
      })
    } else {
      confirmSolicitation(solicitation)
    }
  }

  function discardSolicitation(solicitation) {
    setConfigAlert({
      show: true,
      title: null,
      text: `Deseja descartar sua solicitação incompleta?</p><p> (todos os dados informados serão perdidos)</p>`,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        confirmRemoveSolicitation(solicitation)
        setConfigAlert({ ...configAlert, show: false })
      },
      toastSuccess: 'Sucesso! Solicitação descartada.',
    })
  }

  function confirmSolicitation(solicitation) {
    dispatch(selectSolicitation(solicitation))
    dispatch(selectIntangibleAsset(solicitation.intangibleAssetOriginParent))
    if (incompleteSolicitations && incompleteSolicitations.id && solicitation.stage.slug !== 'incompleta')
      confirmRemoveSolicitation(incompleteSolicitations)

    if (solicitation.stage.slug !== 'incompleta') {
      history.push('/solicitacao/novo/etapa-dois')
    } else {
      dispatch(selectSolicitationInProgress(solicitation))
      history.push('/solicitacao/novo/etapa-tres')
    }
  }

  function confirmRemoveSolicitation(solicitation) {
    dispatch(deleteSolicitation(solicitation))
  }

  function handleSearch(e) {
    setSearch(e.target.value)
  }

  function handleManageSolicitation(solicitation) {
    dispatch(selectSolicitation(solicitation))
    history.push(`/solicitacao/${solicitation.id}/gerenciamento`)
  }

  function handleHistorySolicitation(solicitation) {
    history.push(`/solicitacao/${solicitation.id}/historico`)
  }

  function handleForumSolicitation(solicitation) {
    history.push(`/solicitacao/${solicitation.id}/forum-da-solicitacao`)
  }

  const buttonCreateSolicitation = () => {
    if (hasServicesEnabled) {
      history.push('/solicitacao/novo/etapa-um')
    }
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <Card border="secondary">
        <Card.Body>
          {!hasServicesEnabled && (
            <Row className="mb-5 mt-3">
              <Col sm={12}>
                <AlertMessage
                  variant="alert alert-custom alert-light-primary alert-dismissible"
                  message="Não existem serviços habilitados no momento. Caso queira criar uma solicitação, por favor,
                entre em contato com o administrador do sistema."
                />
              </Col>
            </Row>
          )}

          <Row className="mb-5">
            <Col className="col-9">
              <InputSearch placeholder="Digite aqui o alvo da sua busca" value={search} onChange={handleSearch} />
            </Col>
            <Col className="col-3">
              <Button
                variant={hasServicesEnabled ? 'primary' : 'light-primary'}
                onClick={() => buttonCreateSolicitation()}
                className="btn-block font-weight-bold"
                disabled={!hasServicesEnabled}
              >
                Criar Solicitação
              </Button>
            </Col>
          </Row>
          {myAllSolicitations.length === 0 && (
            <Row className="mb-5 mt-3">
              <Col sm={12}>
                <AlertMessage
                  variant="alert alert-custom alert-light-primary alert-dismissible"
                  message={
                    search !== ''
                      ? `Não foram encontrados resultados para o termo ${search}.`
                      : `Não existem solicitações criadas com o seu perfil de Solicitante.`
                  }
                />
              </Col>
            </Row>
          )}

          <ListBigSausage
            itens={myFilterSolicitations}
            totalItens={myAllSolicitations.length}
            onNext={refreshSolicitations}
            loading={listLoading}
            continueSolicitation={(solicitation) => setSolicitation(solicitation)}
            discardSolicitation={(solicitation) => discardSolicitation(solicitation)}
            manageSolicitation={(solicitation) => handleManageSolicitation(solicitation)}
            historySolicitation={(solicitation) => handleHistorySolicitation(solicitation)}
            forumSolicitation={(solicitation) => handleForumSolicitation(solicitation)}
          />
        </Card.Body>
        <Card.Footer className="text-right">
          <Button variant="secondary" className="mr-5" onClick={() => history.push('/solicitacoes/canceladas')}>
            Solicitações Canceladas
          </Button>
          <Button variant="secondary" onClick={() => history.goBack()}>
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default SolicitationsPage
