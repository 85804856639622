import React, { useEffect } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { AlertGT, Table, Button } from '../../../../components'
import { Formik } from 'formik'
import { getSolicitationManagement } from '../../_redux/attendances/attendanceActions'
import { getAttendantsQualifiedForServiceToSolicitation } from '../../_redux/attendances/attendanceActions'
import { validationSchemaForwardDescription } from './validationSchema'
import { forwardingToNewAttendentAction } from '../../_redux/attendances/attendanceActions'
import HeaderSolicitationManagement from '../../components/attendances/HeaderSolicitationManagement'
import { toast } from 'react-toastify'

const orderByOptions = [
  { value: 'attendant', text: 'Nome do Atendente' },
  { value: 'assistanceInThisService', text: 'Atendimentos em Andamento neste Serviço' },
  { value: 'generalAssistance', text: 'Atendimentos em Andamento em todos os Serviços' },
  { value: 'initialsUnit', text: 'Sigla da Unidade do Atendente' },
  { value: 'unit', text: 'Nome da Unidade do Atendente' },
]

export function SolicitationForwardingPage() {
  const dispatch = useDispatch()
  const { id1: solicitationId, id2 } = useParams() //id1 = id da solicitação,  id2 = id do servico
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('id')
  const [ordering, setOrdering] = React.useState('desc')
  const { attendantsList, listLoading, solicitationSelected } = useSelector((state) => state.attendance)

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const arrBreadCrumb = [
    {
      pathname: '/solicitacao/atendimentos/admin_servico/solicitacoes/meus_servicos',
      title: 'Solicitações dos Meus Serviços',
      subTitle: 'Solicitações dos serviços que você administra.',
      icon: '',
    },
  ]

  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
    closeToast: false,
  })

  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getSolicitationManagement(solicitationId))
    // eslint-disable-next-line
  }, [solicitationId])

  useEffect(() => {
    dispatch(
      getAttendantsQualifiedForServiceToSolicitation(id2, solicitationId, perPage, page, search, sortField, ordering)
    )
    // eslint-disable-next-line
  }, [id2, solicitationId, perPage, page, search, sortField, ordering])

  const handlerOngoingAttendances = (attendantId) => {
    history.push(`/solicitacao/atendimentos/atendente/${attendantId}/em_curso`)
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-search fa-5x"></i>,
      title: 'Consultar Atendimentos em Andamento',
      onClick: (row) => handlerOngoingAttendances(row.attendant_id),
      disabled: () => null,
    },
    {
      text: <i className="fas fa-arrow-right"></i>,
      title: 'Encaminhar para Atendente',
      onClick: (row) => handleForward(row),
      disabled: () => null,
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'attendant',
      text: 'Atendente',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.attendant}
          <br />
          <span style={{ fontSize: '0.85rem' }}>{row.email}</span>
        </>
      ),
      headerStyle: { width: '50%', textAlign: 'center', verticalAlign: 'middle' },
    },
    {
      dataField: 'assistanceInThisService',
      text: 'Atendimento em andamento neste Serviço',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { verticalAlign: 'middle' },
    },
    {
      dataField: 'generalAssistance',
      text: 'Atendimento em Geral',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { verticalAlign: 'middle' },
    },
    {
      dataField: 'initialsUnit',
      text: 'Unidade',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { verticalAlign: 'middle' },
    },
  ]

  const handleForward = (attendant) => {
    const submitForwardForm = (formik) => {
      var event = new Event('submit', {
        bubbles: true,
        cancelable: true,
      })
      formik.handleSubmit(event)
    }
    const content = () => (
      <div>
        <p className="text-left">
          Preencha o campo abaixo com a justificativa para o encaminhamento da solicitação{' '}
          <strong>{solicitationSelected && solicitationSelected.code}</strong> para o(a) atendente{' '}
          <strong>{attendant.attendant}</strong>.
        </p>
        <Formik
          validationSchema={validationSchemaForwardDescription}
          onSubmit={(values) => {
            if (values.forwardDescription.length > 2) {
              setConfigAlert({ ...configAlert, closeToast: true })
              dispatch(
                forwardingToNewAttendentAction(solicitationId, {
                  attendant_id: attendant.attendant_id,
                  message: values.forwardDescription,
                })
              )
                .then((response) => {
                  history.push(`/solicitacao/gerencia-da-solicitacao/${solicitationId}`)
                })
                .catch((error) => {
                  error.response
                    ? toast.error(error.response.data.message)
                    : toast.error('Estamos com alguma indisponibilidade. Tente novamente em instantes.')
                })
            }
          }}
          initialValues={{
            forwardDescription: '',
          }}
        >
          {(formik) => (
            <Form id="forwardForm" noValidate style={{ width: '98%' }}>
              <Row>
                <Col sm={12}>
                  <Form.Group md="12">
                    <Form.Control
                      as="textarea"
                      rows="5"
                      name="forwardDescription"
                      onChange={formik.handleChange}
                      maxLength="1000"
                      {...formik.getFieldProps('forwardDescription')}
                      className={
                        formik.touched.forwardDescription && formik.errors.forwardDescription ? 'is-invalid' : null
                      }
                    />
                    {formik.touched.forwardDescription && formik.errors.forwardDescription && (
                      <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                        {formik.errors.forwardDescription}
                      </Form.Control.Feedback>
                    )}
                    <Form.Text className="text-right">
                      {formik.values.forwardDescription.length !== 0
                        ? `${formik.values.forwardDescription.length}/1000 caracteres`
                        : 'Máximo de 1000 caracteres.'}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-0">
                <Col className="text-right">
                  <Button type="submit" className="font-weight-bold" onClick={() => submitForwardForm(formik)}>
                    Encaminhar Solicitação
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    type="button"
                    variant="secondary"
                    className="font-weight-bold"
                    onClick={() => {
                      setConfigAlert({ ...configAlert, closeToast: true })
                    }}
                  >
                    Cancelar
                  </Button>{' '}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    )
    setConfigAlert({
      show: true,
      title: 'Encaminhamento da Solicitação',
      text: content(),
      width: 800,
      icon: null,
      cancelButton: true,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {
        console.log('')
      },
      toastSuccess: null,
      showConfirmButton: false,
    })
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />

      <Card border="secondary">
        <Card.Body>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row className="m-0" style={{ width: '100%' }}>
            {solicitationSelected && (
              <>
                <HeaderSolicitationManagement
                  code={solicitationSelected.code}
                  stage={solicitationSelected.stage}
                  actual_attendant={solicitationSelected.actual_attendant}
                  organizationsInitials={solicitationSelected.solicitation_organization.initials}
                  itemsLittleSausage={[
                    solicitationSelected.parentType === 'Solicitation'
                      ? {
                          type: 'solicitation',
                          code: solicitationSelected.parent.code,
                          title:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description.length > 70
                                ? `${solicitationSelected.parent.description.substring(0, 70)} ...`
                                : solicitationSelected.parent.description
                              : solicitationSelected.parent.title.length > 70
                              ? `${solicitationSelected.parent.title.substring(0, 70)}...`
                              : solicitationSelected.parent.title,
                          titleModal:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.title,
                          description:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.description
                              : solicitationSelected.parent.description,
                          serviceCode: solicitationSelected.service.code,
                          serviceTitle: solicitationSelected.service.title,
                          solicitationSlug:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.slug
                              : '',
                          solicitationStage:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.stage.stage
                              : '',
                          solicitationOrganizationInitials:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.organization.initials
                              : '',
                          momentByUser:
                            solicitationSelected.parentType === 'Solicitation'
                              ? solicitationSelected.parent.momentByUser
                              : '',
                          solicitationDescription: solicitationSelected.description,
                        }
                      : {
                          type: 'intangible-asset',
                          code: solicitationSelected.intangibleAssetOriginParent.code,
                          title: solicitationSelected.intangibleAssetOriginParent.title,
                          titleModal: solicitationSelected.service.title,
                          description: solicitationSelected.intangibleAssetOriginParent.description,
                          momentByUser: solicitationSelected.intangibleAssetOriginParent.moment_by_user,
                          organizationInitials: solicitationSelected.intangibleAssetOriginParent.organization_initials,
                        },

                    {
                      type: 'service',

                      code: solicitationSelected.service.code,
                      title:
                        solicitationSelected.service.title.length > 70
                          ? `${solicitationSelected.service.title.substring(0, 70)}...`
                          : solicitationSelected.service.title,
                      serviceCode: solicitationSelected.service.code,
                      serviceTitle: solicitationSelected.service.title,
                      titleModal: solicitationSelected.service.title,
                      serviceDescription: solicitationSelected.service.description,
                    },
                  ]}
                />
              </>
            )}
          </Row>
          <Row>
            <Col>
              <p>
                Atendentes habilitados para o Serviço{' '}
                <strong>
                  {solicitationSelected &&
                    `${solicitationSelected.service.code} - ${solicitationSelected.service.title} `}
                </strong>
              </p>
            </Col>
          </Row>

          <Table
            data={attendantsList.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Não existem atendentes habilitados para este serviço.`
            }
            columns={columns}
            page={attendantsList.current_page}
            perPage={perPage}
            totalSize={attendantsList.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="text-right">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
