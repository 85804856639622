/* eslint-disable no-restricted-imports */
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from 'react-redux'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth)
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light',
    }
  }, [uiService])

  const perfisArray = user.roles_fullNames

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div className={'btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto'}>
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Olá,</span>{' '}
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user.name.split(' ')[0]}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"></span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-start pl-8 pr-8 pb-1 pt-5 rounded-top">
                <div
                  className="symbol-md bg-light-primary mr-3 flex-shrink-0 rounded"
                  style={{ width: '6rem', height: '6rem' }}
                >
                  <img
                    src={toAbsoluteUrl(user.pic)}
                    className="rounded"
                    alt=""
                    style={{ width: '6rem', height: '6rem' }}
                  />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3  mb-3 font-size-h4 pb-0">
                  {user.name.length > 25 ? `${user.name.slice(0, 25)} ...` : user.name}
                  <br />
                  <span style={{ fontSize: '1rem' }}>{user.email}</span>
                  <br />
                  <span className="font-weight-bold mb-5" style={{ fontSize: '1rem' }}>
                    {user.department}
                  </span>
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {/* user.firstname[0] */ user.name}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstname} {user.lastname}
              </div>
              <span className="label label-success label-lg font-weight-bold label-inline">3 mensagens</span>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/administracao/usuario/view" className="navi-item px-8">
            <div className="navi-link cursor-none mb-2">
              <div className="navi-icon mr-2">
                <i className="flaticon-avatar text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bolder">Meus Dados</div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3 mb-3"></div>
          <div className="navi-item px-8">
            <div className="navi-link d-flex align-items-start " style={{ textDecoration: 'none !important' }}>
              <div className="navi-icon mr-2">
                <i className="flaticon-users-1 text-info"></i>
              </div>
              <div className="navi-text">
                <div className="text-bold">
                  <p className="font-weight-bolder" style={{ color: 'black' }}>
                    Meus Perfis:
                  </p>
                  {Object.values(perfisArray).map((item, index) => (
                    <p className="mb-0" style={{ color: 'black' }} key={index}>
                      {item} <br />
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="navi-separator mt-2"></div>

          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">
              Sair do Sistema
            </Link>
            <a href="mailto:administradores.sisgestec@fiocruz.br" className="btn btn-clean font-weight-bold">
              Obter Suporte
            </a>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
