
import React from 'react'
import { Alert, Row, Col } from "react-bootstrap";

export const AlertMessage = (props) => (
        <Row>
            <Col>
                {/* alert alert-custom alert-light-primary alert-dismissible */}
                <Alert variant={props.variant}>
                    {props.message}
                </Alert>
            </Col>
        </Row>
) 