import styled from 'styled-components'

export const SelectWrapper = styled.div`
  position: relative;
`

export const SelectInput = styled.input`
  width: 100%;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  height: 35px;
  padding: 0px 0px 0px 10px;
`
export const ArrowButtonWrapper = styled.span`
  font-weight: 400;
  color: #1f1f1f;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 14px;
  position: absolute;
  line-height: 1.5;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  z-index: 4;
  right: 0;
`

export const ArrowButton = styled.button`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;

  i {
    color: #333;
  }
`

export const OptionsList = styled.div`
  width: 100%;
  position: absolute;
  max-height: 200px;
  padding: 0;
  list-style: none;
  z-index: 90;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  li {
    cursor: pointer;
    font-size: 13px;
    color: #1f1f1f;
  }
`

//// Option component
export const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid hsl(0deg 0% 80%);
`

export const SeeDetails = styled.a`
  text-align: right;
  display: block;
  cursor: pointer;
`
