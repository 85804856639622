import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap'
import { actions } from '../../../Auth/_redux/authRedux'
import { Formik } from 'formik'
import Modal from 'react-bootstrap/Modal'

import { useSubheader } from '../../../../../_metronic/layout'

import { useDispatch, useSelector } from 'react-redux'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { validationSchemaChangePasswordUser, validationSchemaUserPhone } from './validationSchema'
import {
  actionUserChangePassword,
  actionUserChangePhone,
  cleanSaveStatus,
  clearSaveError,
  prepState,
} from '../../_redux/admin/adminActions'
import { toast } from 'react-toastify'

function UserOwnView() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { saveStatus, statusMessage, listLoading, saveError } = useSelector((state) => state.admin)
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
      dispatch(cleanSaveStatus())
      dispatch(clearSaveError())
      handleChangePasswordClose()
      handleChangePhoneClose()
    }
    // eslint-disable-next-line
  }, [saveStatus, statusMessage])
  useEffect(() => {
    dispatch(prepState())
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (saveError && saveError.message) {
      toast.error(() => <div dangerouslySetInnerHTML={{ __html: saveError.message }} />)
      dispatch(cleanSaveStatus())
      dispatch(clearSaveError())
    }
    // eslint-disable-next-line
  }, [saveError])

  const [smShow, setSmShow] = useState(false)
  const [lgShow, setLgShow] = useState(false)
  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const handleChangePasswordClose = () => setLgShow(false)
  const handleChangePasswordShow = () => setLgShow(true)

  const handleChangePhoneClose = () => setSmShow(false)
  const handleChangePhoneShow = () => setSmShow(true)

  const handleFormChangePassword = () => {
    handleChangePasswordShow()
  }
  const handleFormChangePhone = (e) => {
    e.preventDefault()
    handleChangePhoneShow()
  }

  const handleSubmit = (values) => {
    const keys = Object.keys(values)

    if (keys.includes('phone')) {
      dispatch(actionUserChangePhone({ ...values })).then(() => {
        dispatch(actions.requestUser(user))
      })
    } else {
      dispatch(actionUserChangePassword({ ...values }))
    }
  }

  return (
    <>
      <>
        <Modal size="lg" show={lgShow} onHide={handleChangePasswordClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Alteração de Senha</Modal.Title>
          </Modal.Header>
          <Formik
            validationSchema={validationSchemaChangePasswordUser}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
            initialValues={{
              actual_password: '',
              password: '',
              c_password: '',
            }}
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Form.Group md="12">
                        <Form.Label>Informe sua senha atual:</Form.Label>
                        <Form.Control
                          as="input"
                          name="actual_password"
                          type="password"
                          maxLength="100"
                          {...formik.getFieldProps('actual_password')}
                          className={
                            (formik.touched.actual_password && formik.errors.actual_password) ||
                            (saveError.length > 0 && saveError[0].actual_password)
                              ? 'is-invalid'
                              : null
                          }
                          onChange={formik.handleChange}
                        />
                        {((formik.touched.actual_password && formik.errors.actual_password) ||
                          (saveError.length > 0 && saveError[0].actual_password)) && (
                          <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                            {formik.errors.actual_password || saveError[0].actual_password[0]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group md="6">
                        <Form.Label>Digite a nova senha:</Form.Label>
                        <Form.Control
                          as="input"
                          name="password"
                          type="password"
                          maxLength="255"
                          {...formik.getFieldProps('password')}
                          className={
                            (formik.touched.password && formik.errors.password) ||
                            (saveError.length > 0 && saveError[0].password)
                              ? 'is-invalid'
                              : null
                          }
                          onChange={formik.handleChange}
                        />
                        {((formik.touched.password && formik.errors.password) ||
                          (saveError.length > 0 && saveError[0].password)) && (
                          <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                            {formik.errors.password || saveError[0].password[0]}
                          </Form.Control.Feedback>
                        )}
                        {/* <Form.Text className="text-right">Máximo de 100 caracteres.</Form.Text> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group md="6">
                        <Form.Label>Confirme a nova senha:</Form.Label>
                        <Form.Control
                          as="input"
                          name="c_password"
                          type="password"
                          maxLength="255"
                          {...formik.getFieldProps('c_password')}
                          className={
                            (formik.touched.c_password && formik.errors.c_password) ||
                            (saveError.length > 0 && saveError[0].c_password)
                              ? 'is-invalid'
                              : null
                          }
                          onChange={formik.handleChange}
                        />
                        {((formik.touched.c_password && formik.errors.c_password) ||
                          (saveError.length > 0 && saveError[0].c_password)) && (
                          <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                            {formik.errors.c_password || saveError[0].c_password[0]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button type="submit" className="font-weight-bold" active={!listLoading} disabled={listLoading}>
                    Confirmar
                  </Button>
                  <Button variant="secondary" onClick={handleChangePasswordClose}>
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
        {/* -------------- */}

        <Modal show={smShow} onHide={handleChangePhoneClose} dialogClassName="modal-50w">
          <Formik
            validationSchema={validationSchemaUserPhone}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
            initialValues={{
              phone: '',
            }}
          >
            {(formikPhone) => (
              <Form noValidate onSubmit={formikPhone.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title className="text-center">Atualização do Número de Telefone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Form.Group md="6">
                        <Form.Label>Informe abaixo o número de telefone:</Form.Label>
                        <Form.Control
                          as="input"
                          name="phone"
                          type="text"
                          maxLength="20"
                          {...formikPhone.getFieldProps('phone')}
                          className={
                            (formikPhone.touched.phone && formikPhone.errors.phone) ||
                            (saveError.length > 0 && saveError[0].phone)
                              ? 'is-invalid'
                              : null
                          }
                          onChange={formikPhone.handleChange}
                        />
                        {((formikPhone.touched.phone && formikPhone.errors.phone) ||
                          (saveError.length > 0 && saveError[0].phone)) && (
                          <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                            {formikPhone.errors.phone || saveError[0].phone[0]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" className="font-weight-bold" active={!listLoading} disabled={listLoading}>
                    {listLoading && <Spinner animation="border" variant="light" />}
                    {!listLoading && 'Confirmar'}
                  </Button>

                  <Button variant="secondary" onClick={handleChangePhoneClose}>
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
      <Card border="secondary">
        <div className="py-9 p-10">
          <div className="mt-4">
            <span className="font-weight-bolder mr-2" style={{ fontSize: '19px' }}>
              Dados Pessoais
            </span>
          </div>
          <hr />
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2 mb-0">Nome Completo:</span>
            </Col>
            <Col sm={7}>
              <span>{user.name}</span>
            </Col>
            <Col className="text-right" sm={2}>
              <Button
                variant="primary"
                type="button"
                onClick={() => handleFormChangePassword()}
                className="font-weight-bold btn-block"
              >
                Alterar Senha
              </Button>{' '}
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">E-mail:</span>
            </Col>
            <Col sm={9}>
              <span>{user.email}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">CPF:</span>
            </Col>
            <Col sm={9}>
              <span>{user.cpf_formated}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Unidade de Alocação:</span>
            </Col>
            <Col sm={9}>
              <span>
                {user.department} ({user.departmentInitials})
              </span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Telefone:</span>
            </Col>
            <Col sm={9}>
              <span>{user.phone}</span>&nbsp;&nbsp;
              <a href="/" variant="secondary" onClick={handleFormChangePhone}>
                Atualizar Número de Telefone
              </a>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Data de Nascimento:</span>
            </Col>
            <Col sm={9}>
              <span>{user.dateOfBirth}</span>
            </Col>
          </Row>
          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col sm={3} className="text-right">
              <span className="font-weight-bolder mr-2">Primeiro acesso:</span>
            </Col>
            <Col sm={9}>
              <span>{user.dateOfCreateOnSystem}</span>
            </Col>
          </Row>

          <div style={{ fontSize: '19px' }} className="mt-10">
            <div className="mt-4">
              <span className="font-weight-bolder mr-2">Perfis</span>
            </div>
          </div>
          <hr />

          <Row style={{ fontSize: '15px' }} className="pl-15 pt-5">
            <Col>
              <span>{user.list_roles_fullName}</span>
            </Col>
          </Row>
        </div>
        <Card.Footer>
          <Row className="mt-0">
            <Col className="text-right">
              <Button
                variant="secondary"
                onClick={() => {
                  history.goBack()
                }}
              >
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}

export default UserOwnView
