import React from 'react'
import { Stage } from './Stage.style'

const Typography = ({ variant, children, className }) => {
  if (variant === 'stage') return <Stage className={className}>{children}</Stage>
  else if (variant === 'h1') return <h1 className={className}>{children}</h1>
  else if (variant === 'h2') return <h2 className={className}>{children}</h2>
  else if (variant === 'h3') return <h3 className={className}>{children}</h3>
  else if (variant === 'h4') return <h4 className={className}>{children}</h4>
  else if (variant === 'h5') return <h5 className={className}>{children}</h5>
  else if (variant === 'h6') return <h6 className={className}>{children}</h6>
  else return <p>{children}</p>
}

export default Typography
