import { createSlice } from '@reduxjs/toolkit'

const initialServiceState = {
  listLoading: true,
  lastError: null,
  userList: [],
  usersBlockedList: [],
  lastBlockedUser: [],
  lastEnabledUser: [],
  saveStatus: null,
  statusMessage: null,
  saveError: [],
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const userQualificationSlice = createSlice({
  name: 'admin',
  initialState: initialServiceState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
    },

    getUsersBlocked: (state, action) => {
      state.usersBlockedList = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },

    blockUser: (state, action) => {
      state.lastBlockedUser = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },
    enableUser: (state, action) => {
      state.lastEnabledUser = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    setStatusMessage: (state, action) => {
      state.saveStatus = null
      state.statusMessage = action.payload
    },

    clearLastError: (state) => {
      state.lastError = null
    },

    enableUserCandidateToUsers: (state, action) => {
      state.hash = action.payload
      state.listLoading = false
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },
  },
})
