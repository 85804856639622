import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { SubTitleItem, TitleItem } from './RelatedItem.styles'
import { Col, Row } from 'react-bootstrap'

import Icon from '@mdi/react'
import { mdiArrowRightBottomBold } from '@mdi/js'

const useStyles = makeStyles({
  root: {
    '&.Mui-expanded': {
      marginTop: 0,
    },
  },
})

const RelatedAccordion = ({ title, subTitle, body, id, ariaControls, showIdent, backgroundColor }) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  return (
    <div className="w-100 d-flex">
      {showIdent && <Icon path={mdiArrowRightBottomBold} title="Relacionado" size={2} color="#8f8f8f" />}
      <Accordion
        onChange={(e, expanded) => setOpen(expanded)}
        style={{ width: showIdent ? 'calc( 100% - 3rem )' : '100%', backgroundColor: backgroundColor || 'transparent' }}
        square
        elevation={0}
        variant="outlined"
        className={classes.root}
      >
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls={ariaControls} id={id}>
          <Row className="w-100">
            <Col className="col-2">
              <TitleItem>{title}</TitleItem>
            </Col>
            <Col className="col-10">
              <SubTitleItem>
                {subTitle && subTitle.length > 120 && !open ? subTitle.substring(0, 120) + '...' : subTitle}
              </SubTitleItem>
            </Col>
          </Row>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </Accordion>
    </div>
  )
}

export default RelatedAccordion
