import styled from 'styled-components'

export const CardIcon = styled.span`
  position: absolute;
  > * {
    color: #333;
    font-size: 3rem;
  }
`

export const CardContent = styled.span`
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 3rem;
  font-weight: 600;
  width: 100%;
`

export const CardLabel = styled.span`
  font-size: 1rem;
  font-weight: 400;
`
