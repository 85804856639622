import styled from 'styled-components'

export const AccordionWrapper = styled.div`
  margin-bottom: 30px;
`
export const HeaderIcon = styled.span`
  padding-right: 10px;
`

export const HeaderWrapper = styled.div`
  padding: 5px 15px;
  color: #0e100f;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  border-bottom: 1px solid hsl(0deg 0% 80%);
`
export const ChildrenContent = styled.div`
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`
export const HeaderArrowIconWrapper = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  i {
    color: #333;
  }
`

export const ArrowIcon = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-left: 10px;
`

export const BoxAnswered = styled.div`
  border-radius: 5px;
  text-align: center;
  color: #fff;
  width: 120px;
  height: 28px;
  background-color: ${(props) => (props.isAnswared ? '#004D06' : '#c11600')};
  justify-content: center;
  font-weight: 600;
  padding: 4px 0px;
`
