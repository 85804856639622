import styled from 'styled-components'

export const ItemListWrapper = styled.div`
  td{
    vertical-align: ${(props) => (props.textVerticalAlign ? props.textVerticalAlign : 'top')};
  }

  .react-bootstrap-table{
    margin-bottom: 30px;
  }
`

