import { all } from 'redux-saga/effects'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunkMiddleware from 'redux-thunk'
import * as auth from '../app/modules/Auth/_redux/authRedux'
import { adminSlice } from '../app/modules/Admin/_redux/admin/adminSlice'
import { adminUnitSlice } from '../app/modules/Admin/_redux/adminUnit/adminUnitSlice'
import { userQualificationSlice } from '../app/modules/Admin/_redux/userQualification/userQualificationSlice'
import { intangibleAssetSlice } from '../app/modules/Solicitation/_redux/intangible_asset/intangibleAssetSlice'
import { serviceSlice } from '../app/modules/Solicitation/_redux/services/serviceSlice'
import { requirementSlice } from '../app/modules/Solicitation/_redux/services/requirement/requirementSlice'
import { solicitationSlice } from '../app/modules/Solicitation/_redux/solicitation/solicitationSlice'
import { attendanceSlice } from '../app/modules/Solicitation/_redux/attendances/attendanceSlice'
import { dashboardSlice } from '../app/modules/Solicitation/_redux/dashboard/dashboardSlice'
import { tasksTypesSlice } from '../app/modules/Tasks/_redux/tasks_types/tasksTypesSlice'
import { taskSlice } from '../app/modules/Tasks/_redux/tasks/tasksSlice'
import { auditSlice } from '../app/modules/Admin/_redux/audit/auditSlice'
import { systemSlice } from '../app/modules/Admin/_redux/system/systemSlice'
import { faqSlice } from '../app/modules/Faq/_redux/faqSlice'
import { reportSlice } from '../app/modules/Reports/_redux/reports/reportSlice'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

export const rootReducer = combineReducers({
  admin: adminSlice.reducer,
  adminUnit: adminUnitSlice.reducer,
  attendance: attendanceSlice.reducer,
  auth: auth.reducer,
  audit: auditSlice.reducer,
  dashboard: dashboardSlice.reducer,
  faq: faqSlice.reducer,
  intangibleAssets: intangibleAssetSlice.reducer,
  reports: reportSlice.reducer,
  services: serviceSlice.reducer,
  solicitations: solicitationSlice.reducer,
  system: systemSlice.reducer,
  requirements: requirementSlice.reducer,
  tasksTypes: tasksTypesSlice.reducer,
  tasks: taskSlice.reducer,
  userQualification: userQualificationSlice.reducer,
})

export const store = createStore(rootReducer, composedEnhancer)

export function* rootSaga() {
  yield all([auth.saga()])
}
