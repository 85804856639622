import * as requestFromServer from './attendanceEndpoints'
import { attendanceSlice } from './attendanceSlice'

const { actions } = attendanceSlice

/**
 * Pegar dados da basicos da solicitação
 */
export const getSolicitation = (solicitationId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .showSolicitation(solicitationId)
    .then((response) => {
      dispatch(actions.setSolicitationSelected(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Pegar dados da solicitação para gerência da solicitação
 */
export const getSolicitationManagement = (solicitationId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolicitationManagement(solicitationId)
    .then((response) => {
      dispatch(actions.setSolicitationSelected(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Action para acolher a solicitação
 */
export const receiveSolicitation = (solicitationId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .receiveSolicitation(solicitationId)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError([error.response.data]))
      dispatch(actions.setListLoading(false))
      throw error
    })
}

/**
 * Action para iniciar o atendimento de uma solicitação
 */
export const startAttendance = (solicitationId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .startAttendance(solicitationId)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError([error.response.data]))
      dispatch(actions.setListLoading(false))
      throw error
    })
}

/**
 * Action para cancelar solicitação
 */
export const cancelSolicitation = (solicitationId, cancelMessage) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .cancelSolicitation(solicitationId, cancelMessage)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError([error.response.data]))
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Action para abandono de atendimento
 */
export const abandonAttendance = (solicitationId, cancelMessage) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .abandonAttendance(solicitationId, cancelMessage)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      throw error
    })
}
/**
 * Action para completatar solicitação
 */
export const completeSolicitation = (solicitationId, object) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .completeSolicitation(solicitationId, object)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      getAttendancesOpened(null, null, null, null, null)
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Action Aprovar atendimento
 */
export const approveAttendanceSolicitation = (solicitationId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .approveAttendanceSolicitation(solicitationId)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Action Reprovar atendimento (Atendimento com pendência)
 */
export const rejectAttendanceSolicitation = (solicitationId, rejectMessage) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .rejectAttendanceSolicitation(solicitationId, rejectMessage)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationManagement(solicitationId))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/** */
export const getAttendancesOpened = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getAttendancesOpened(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getByStatus(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/** */
export const getAttendancesByStatus = (slug_stage, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getAttendancesByStatus(slug_stage, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getByStatus(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}
/**
 * DOCUMENTOS DE UMA SOLICITAÇÃO
 */
export const getSolcitationDocuments = (solicitationId, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolcitationDocuments(solicitationId, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getDocuments(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}
export const getAttendanceOngoing = (attendantId, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getAttendancesOngoing(attendantId, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getAttendances(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

export const getSolicitationsMyServices = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolicitationsMyServices(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getSolicitationsMyServicesObj(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

export const getSolicitationForumMessages = (solicitationId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolicitationForumMessages(solicitationId)
    .then((response) => {
      dispatch(actions.getSolicitationForumMessages(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

export const getAttendantsQualifiedForService = (id, per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getAttendantsQualifiedForService(id, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getAttendants(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.getMyServicesAttendant(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

export const getAttendantsQualifiedForServiceToSolicitation = (
  id,
  id_solicitation,
  per_page,
  page,
  search,
  sort_field,
  ordering
) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getAttendantsQualifiedForServiceToSolicitation(id, id_solicitation, per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getAttendants(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.getMyServicesAttendant(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Encaminhar Atendimento
 * @param {*} attendantObj
 * @returns
 */
export const forwardingToNewAttendentAction = (solicitationId, attendantObj) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .forwardingToNewAttendent(solicitationId, attendantObj)
    .then((response) => {
      dispatch(actions.successForwardingAttendent(response.data))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
      throw error
    })
}

/**
 * Solicitações disponíveis na unidade
 * @param {*} attendantObj
 * @returns
 */
export const getSolicitationsAwaitingAttendantUnit = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getSolicitationsAwaitingAttendantUnit(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getSolicitationsAwaitingAttendantUnit(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

export const cleanSolicitationSelected = () => (dispatch) => {
  dispatch(actions.setSolicitationSelected(null))
}

export const cleanLastError = () => (dispatch) => {
  dispatch(actions.setLastError(null))
}

export const cleanSaveStatus = () => (dispatch) => {
  dispatch(actions.cleanSaveStatus())
}

export const clearSaveError = () => (dispatch) => {
  dispatch(actions.clearSaveError())
}

/**
 * Action para criar mensagem de forum solicitação
 */
export const createForumMessage = (solicitationId, object) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .createForumMessage(solicitationId, object)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationForumMessages(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      return error
    })
}

/**
 * Action para criar resposta de mensagem de forum da solicitação
 */
export const createForumMessageReply = (solicitationId, solicitationForumMessageId, object) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .createForumMessageReply(solicitationId, solicitationForumMessageId, object)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationForumMessages(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      return error
    })
}

/**
 * Action para deletar mensagem de forum solicitação
 */
export const deleteForumMessage = (solicitationId, solicitationForumMessageId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .deleteForumMessage(solicitationId, solicitationForumMessageId)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationForumMessages(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      return error
    })
}

/**
 * Action para deletar resposta de mensagem de forum da solicitação
 */
export const deleteForumMessageReply = (solicitationId, solicitationForumMessageId) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .deleteForumMessageReply(solicitationId, solicitationForumMessageId)
    .then((response) => {
      dispatch(actions.successMessage(response.data.message))
      dispatch(getSolicitationForumMessages(solicitationId))
      dispatch(actions.setListLoading(false))
      return response
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
      return error
    })
}
