import styled from 'styled-components'

export const AreaButton = styled.div`
  height: 0px;
  border-left: 45px solid transparent;
  border-bottom: 48px solid ${(props) => (props.color === 'secondary' ? '#B47214' : '#144486')};
  line-height: 36px;
  width: max-content;
  color: #ffffff;
  padding-right: 24px;
  min-width: 250px;

  span {
    top: -55px;
    position: relative;
  }

  &::before {
    content: '';
    width: 1px;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    left: -21px;
    transform: rotate(44.5deg);
    height: 62px;
    display: block;
    top: -7px;
  }

  a {
    color: #ffffff;
    font-weight: 500;
    top: -55px;
    position: relative;
  }

  a:hover {
    text-decoration: underline !important;
  }
`
