import React, { useCallback, useEffect } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import { Notice } from '../../../../_metronic/_partials/controls'
import { Spinner, Jumbotron, Alert } from 'react-bootstrap'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const MailConfirmationPage = () => {
  const { hash } = useParams()

  const [status, setStatus] = React.useState({ valstatus: null, message: null, message_two: null, message_code: null })

  const validateMailUser = useCallback(
    async (valHash) => {
      const authAxios = axios.create()
      await authAxios
        .get(`${API_BASE_URL}/api/auth/prvmail/${valHash}`)
        .then((response) => {
          setStatus({
            valstatus: 'success',
            message: response.data.message,
            message_two: response.data.message_two,
            message_code: response.data.message_code,
          })
        })
        .catch((error) => {
          setStatus({
            valstatus: 'error',
            message: error.response.data.message,
            message_two: error.response.data.message_two,
            message_code: error.response.data.message_code,
          })
        })
    },
    // eslint-disable-next-line
    [hash]
  )

  /**
   * Executar a função validateMailUser
   */
  useEffect(
    (token) => {
      if (token !== '') {
        validateMailUser(hash)
      }
    },
    [validateMailUser, hash]
  )
  return (
    <>
      <div className="container">
        {/* status.message_code === 'expired' && (
          <>
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">Não tem uma conta ainda?</span>
              <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                Cadastre-se!
              </Link>
            </div>
          </>
        ) */}
        <div className="row mt-5">
          <div className="col-xl-12">
            <Notice icon="flaticon-warning font-danger mt-5">
              <span>Confirmação de e-mail</span>
            </Notice>
            {status === null && (
              <>
                <Jumbotron>
                  <h1>Aguarde...</h1>
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" size="lg" />
                  </div>
                </Jumbotron>
              </>
            )}

            {status.valstatus === 'success' && (
              <>
                <Alert variant="success">
                  <Alert.Heading>Sucesso!</Alert.Heading>
                  <p>Seu e-mail foi validado.</p>
                  <p>Você poderá efetuar login no sistema assim que um administrador liberar seu acesso.</p>
                  <hr />
                  <p className="mb-0">
                    <strong>Atenção!</strong>
                    <p>{status.message_two}</p>
                  </p>
                </Alert>
              </>
            )}
            {status.valstatus === 'error' && (
              <>
                <Alert variant="danger">
                  <Alert.Heading>Ops! Não foi possível validar seu e-mail.</Alert.Heading>
                  <p>{status.message}</p>
                  {(status.message_code === 'confirmed' || status.message_code === 'expired') && (
                    <>
                      <hr />
                    </>
                  )}
                  <p>{status.message_two}</p>
                </Alert>
              </>
            )}
            {(status.message_code === 'expired' || status.message_code === 'invalid_link') && (
              <>
                <p className="mt-10">
                  <Link
                    to="/auth/login"
                    className="font-weight-bold ml-2"
                    style={{ textDecorationLine: 'undeline !important' }}
                    id="kt_login_signup"
                  >
                    Ir para a tela de login!
                  </Link>
                </p>
              </>
            )}
            {(status.message_code === 'confirmed' || status.message_code === 'has_already_been_confirmed') && (
              <>
                <p className="mt-10">
                  <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">
                    Ir para tela de login.
                  </Link>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MailConfirmationPage
