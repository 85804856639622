import React, { useState, memo } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { validationSchemaCreateTaskInteraction } from '../../pages/validationSchema'
import Dropzone from '../../../../components/Dropzone'

const CreateTaskInteractionForm = ({ configAlert, setConfigAlert, task, handleDispatch }) => {
  const [attachments, setAttachments] = useState([])
  const [description, setDescription] = useState('')
  const [invalidFiles, setInvalidFiles] = useState([])

  return (
    <div style={{ textAlign: 'left', width: '99%', marginLeft: '5px', overflow: 'hidden' }}>
      <h3>Interação com a Tarefa {task.task_code}</h3>
      <p>Descreva abaixo de sua interação</p>
      <Formik
        validationSchema={validationSchemaCreateTaskInteraction}
        onSubmit={(values) => {
          if (values.description.length >= 3) {
            setConfigAlert({ ...configAlert, closeToast: true })
            handleDispatch({ taskId: task.task_id, description: values.description, attachments })
          }
        }}
        initialValues={{
          description: description,
        }}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit} style={{ width: '99%' }}>
            <Row>
              <Col sm={12}>
                <Form.Group md="12">
                  <Form.Label>Mensagem</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    name="description"
                    maxLength="1000"
                    {...formik.getFieldProps('description')}
                    className={
                      formik.touched.description && (formik.errors.description || description.length <= 3)
                        ? 'is-invalid'
                        : null
                    }
                    defaultValue={description}
                    value={description}
                    onChange={(e) => {
                      formik.handleChange(e)
                      setDescription(e.target.value)
                      localStorage.setItem('description', e.target.value)
                    }}
                  />
                  {formik.touched.description && (formik.errors.description || description.length <= 3) && (
                    <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  )}
                  <Form.Text className="text-right">
                    {formik.values.description.length !== 0
                      ? `${formik.values.description.length}/1000 caracteres`
                      : 'Máximo de 1000 caracteres.'}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Dropzone
                  attachments={attachments}
                  setAttachments={setAttachments}
                  maxAmountFiles={5}
                  formByFile="UPLOAD_IN_LIGHTBOX"
                  maxSizeFileMB={20}
                  getInValidFiles={(files) => setInvalidFiles(files)}
                />
              </Col>
            </Row>
            <Row className="mt-0">
              <Col className="text-right">
                <Button
                  type="submit"
                  className="font-weight-bold"
                  disabled={description.length < 3 || invalidFiles.length > 0}
                >
                  Confirmar Interação
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    setConfigAlert({ ...configAlert, closeToast: true })
                  }}
                  className="font-weight-bold"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default memo(CreateTaskInteractionForm)
