import React from 'react'
import { LoadingWrapper, Loading } from './LoadingSpinner.styles'

const LoadingSpinner = ({ description }) => {
  return (
    <LoadingWrapper>
      <Loading className="overlay-layer bg-transparent mb-5">
        <div className="spinner spinner-lg spinner-success"> </div>
      </Loading>
      <div>
        <p className="h4 mt-10">{description ? description : 'Carregando ...'}</p>
      </div>
    </LoadingWrapper>
  )
}

export default LoadingSpinner
