import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Alert, Col, Row, Button, Container } from 'react-bootstrap'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { toast } from 'react-toastify'
import {
  getSolicitationForumMessages,
  createForumMessage,
  createForumMessageReply,
  deleteForumMessage,
  deleteForumMessageReply,
} from '../../_redux/attendances/attendanceActions'
import HeaderSolicitationManagement from '../../components/attendances/HeaderSolicitationManagement'
import { AlertGT } from '../../../../components'
import LoadingSpinner from '../../../../components/LoadingSpinner'

import ForumMessageForm from '../../components/attendances/ComponentsModalForms/ForumMessageForm'
import { clearSaveError } from '../../_redux/solicitation/solicitationActions'
import CardForumMessages from '../../components/attendances/CardForumMessages'

const SolicitationForumPage = () => {
  const { id1: solicitationId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSolicitationForumMessages(solicitationId))
    // eslint-disable-next-line
  }, [])

  const { user } = useSelector((state) => state.auth)
  const { saveError, listLoading, solicitationForumMessages } = useSelector((state) => state.attendance)

  const subheader = useSubheader()
  const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  const [configAlert, setConfigAlert] = useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
    closeToast: false,
  })

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(clearSaveError())
    localStorage.setItem('forumMessage', '')
    // eslint-disable-next-line
  }, [])

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  const handleCreateForumMessage = () => {
    const handleDispatch = (objectCompleteSolicitation) => {
      dispatch(createForumMessage(solicitationId, objectCompleteSolicitation))
        .then(() =>
          toast.success(() => (
            <>
              <strong>Sucesso!</strong> Mensagem criada.
            </>
          ))
        )
        .catch(() => toast.error('Não foi possível criar a mensagem.'))
    }

    const content = () => (
      <ForumMessageForm
        configAlert={configAlert}
        setConfigAlert={setConfigAlert}
        solicitationSelected={solicitationForumMessages}
        handleDispatch={handleDispatch}
      />
    )

    setConfigAlert({
      show: true,
      title: 'Nova Mensagem',
      text: content(),
      width: 900,
      icon: null,
      cancelButton: true,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {},
      toastSuccess: null,
      showConfirmButton: false,
    })
  }

  const handleCreateForumMessageReply = (message) => {
    const handleDispatch = (objectCompleteSolicitation) => {
      dispatch(createForumMessageReply(solicitationId, message.idForumMessage, objectCompleteSolicitation))
        .then(() =>
          toast.success(() => (
            <>
              <strong>Sucesso!</strong> Mensagem respondida.
            </>
          ))
        )
        .catch(() => toast.error('Não foi possível responder a mensagem.'))
    }

    const content = () => (
      <ForumMessageForm
        configAlert={configAlert}
        setConfigAlert={setConfigAlert}
        solicitationSelected={solicitationForumMessages}
        handleDispatch={handleDispatch}
        typeMessage="update"
      />
    )

    setConfigAlert({
      show: true,
      title: `Resposta à Mensagem ${message.numMessage}`,
      text: content(),
      width: 900,
      icon: null,
      cancelButton: true,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {},
      toastSuccess: null,
      showConfirmButton: false,
    })
  }

  const handleADeleteForumMessage = (message) => {
    const content = `<p>Deseja realmente excluir a <strong>Mensagem ${message.numMessage}</strong> da solicitação <strong>${solicitationForumMessages.solicitationCode}</strong> ?</p>`
    setConfigAlert({
      show: true,
      title: null,
      text: content,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        dispatch(deleteForumMessage(solicitationId, message.idForumMessage))
          .then(() =>
            toast.success(() => (
              <>
                <strong>Sucesso!</strong> Mensagem excluída.
              </>
            ))
          )
          .catch(() => toast.error('Não foi possível remover a mensagem.'))
      },
      toastSuccess: null,
    })
  }

  const handleADeleteForumMessageReply = (answer, numMessage) => {
    const content = `<p>Deseja realmente excluir esta resposta à <strong>Mensagem ${numMessage}</strong> da solicitação <strong>${solicitationForumMessages.solicitationCode}</strong> ?</p>`
    setConfigAlert({
      show: true,
      title: null,
      text: content,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        dispatch(deleteForumMessageReply(solicitationId, answer.idForumMessage))
          .then(() =>
            toast.success(() => (
              <>
                <strong>Sucesso!</strong> Resposta excluída.
              </>
            ))
          )
          .catch(() => toast.error('Não foi possível remover a mensagem.'))
      },
      toastSuccess: null,
    })
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      {solicitationForumMessages && (
        <Card border="secondary">
          <Card.Body>
            <Container>
              {saveError.length > 0 && (
                <Alert variant="danger">
                  {Object.values(saveError[0]).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                  ))}
                </Alert>
              )}
              <Row>
                <Col className="text-right"></Col>
              </Row>
              <Row className="m-0" style={{ width: '100%' }}>
                <HeaderSolicitationManagement
                  code={solicitationForumMessages.solicitationCode}
                  stage={{ stage: solicitationForumMessages.stage, slug: solicitationForumMessages.stageSlug }}
                  actual_attendant={solicitationForumMessages.actualAttendantName}
                  itemsLittleSausage={[
                    solicitationForumMessages.solicitationOriginType === 'Solicitation'
                      ? {
                          type: 'solicitation',
                          code: solicitationForumMessages.solicitationOriginCode,
                          title:
                            solicitationForumMessages.solicitationOriginType === 'Solicitation'
                              ? solicitationForumMessages.solicitationOriginDescription.length > 60
                                ? `${solicitationForumMessages.solicitationOriginDescription.substring(0, 60)} ...`
                                : solicitationForumMessages.solicitationOriginDescription
                              : solicitationForumMessages.solicitationOriginTitle.length > 60
                              ? `${solicitationForumMessages.solicitationOriginTitle.substring(0, 60)}...`
                              : solicitationForumMessages.solicitationOriginTitle,
                          serviceCode: solicitationForumMessages.serviceCode,
                          serviceTitle: solicitationForumMessages.originServiceTitle,
                          description: solicitationForumMessages.solicitationOriginDescription,
                          solicitationSlug: solicitationForumMessages.solicitationOriginStageSlug,
                          solicitationStage:
                            solicitationForumMessages.solicitationOriginType === 'Solicitation'
                              ? solicitationForumMessages.solicitationOriginStage
                              : '',
                          solicitationOrganizationInitials: solicitationForumMessages.originOrganizationInitials,
                          solicitationOrganizationName: solicitationForumMessages.originOrganizationName,
                          momentByUser: solicitationForumMessages.originMomentByUser,
                          solicitationDescription: solicitationForumMessages.solicitationDescription,
                        }
                      : {
                          type: 'intangible-asset',
                          code: solicitationForumMessages.solicitationOriginCode,
                          title: solicitationForumMessages.solicitationOriginTitle,
                          titleModal: solicitationForumMessages.solicitationOriginTitle,
                          description: solicitationForumMessages.solicitationOriginDescription,
                          momentByUser: solicitationForumMessages.originMomentByUser,
                          organizationInitials: solicitationForumMessages.originOrganizationInitials,
                        },

                    {
                      type: 'service',
                      code: solicitationForumMessages.serviceCode,
                      title: solicitationForumMessages.serviceTitle,
                      serviceCode: solicitationForumMessages.serviceCode,
                      serviceTitle: solicitationForumMessages.serviceTitle,
                      titleModal: solicitationForumMessages.serviceTitle,
                      serviceDescription: solicitationForumMessages.serviceDescription,
                    },
                  ]}
                  organizationsInitials={solicitationForumMessages.solicitationOrganizationInitials}
                />
              </Row>
              <Row>
                <Col sm={12}>
                  <p>{solicitationForumMessages.solicitationDescription}</p>
                  <div className="d-flex justify-content-between">
                    <strong>
                      <p>{solicitationForumMessages.solicitationMomentByUser}</p>
                    </strong>
                    <Button type="button" size="sm" variant="secondary" onClick={() => scrollToBottom()}>
                      Ir para o fim da conversa
                    </Button>
                  </div>
                  <hr />
                </Col>
              </Row>
              <Row ref={messagesEndRef}>
                <Col>
                  {listLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <CardForumMessages
                      messages={solicitationForumMessages.messages}
                      onAnswering={(message) => handleCreateForumMessageReply(message)}
                      onDelete={(message) => handleADeleteForumMessage(message)}
                      onDeleteAnswer={(answer, numMessage) => handleADeleteForumMessageReply(answer, numMessage)}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-0">
                <Col className="text-right">
                  {solicitationForumMessages.enabledForum && (
                    <>
                      <Button
                        type="button"
                        onClick={() => {
                          handleCreateForumMessage()
                        }}
                        className="font-weight-bold"
                      >
                        Criar Mensagem
                      </Button>{' '}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col className="text-right">
                <Button variant="secondary" onClick={() => history.goBack()}>
                  <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </>
  )
}

export default SolicitationForumPage
