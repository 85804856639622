import { createSlice } from '@reduxjs/toolkit'

const initialReportState = {
  listLoading: true,
  lastError: null,
  service: [],
  serviceList: [],
  intangibleAssetList: [],
  unitList: [],
  saveStatus: null,
  statusMessage: null,
  countRegisterTabularReport: null,
  saveError: [],
  errorOnUpdate: false,
  errorOnDeleteAttendant: null,
  errorOnDelete: null,
  successOnDelete: null,
  reportRead: false,
  buildingReport: false,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const reportSlice = createSlice({
  name: 'services',
  initialState: initialReportState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.errorOnUpdate = true
    },

    setListLoading: (state, action) => {
      state.listLoading = action.payload
    },

    getIntangibleAssets: (state, action) => {
      state.intangibleAssetList = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },
    getServices: (state, action) => {
      state.serviceList = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    getUnits: (state, action) => {
      state.unitList = action.payload
      state.listLoading = false
      state.saveStatus = null
      state.error = null
    },

    setCountRegisterTabularReport: (state, action) => {
      state.countRegisterTabularReport = action.payload
    },
    clearCountTabularReportExport: (state, action) => {
      state.countRegisterTabularReport = null
    },

    getService: (state, action) => {
      state.service = action.payload
      state.error = null
    },

    setStatusMessage: (state, action) => {
      //state.saveStatus = null
      state.statusMessage = action.payload
    },

    removeAdministratorFromService: (state, action) => {
      state.lastRemovedAdministratorService = action.payload
      state.saveStatus = 'success'
      state.error = null
    },
    setAttendantToService: (state, action) => {
      state.lastAddedAttendantService = action.payload
      state.saveError = []
      state.saveStatus = 'success'
      state.error = null
    },

    removeAttendantFromService: (state, action) => {
      state.lastRemovedAttendantService = action.payload
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.errorOnDeleteAttendant = null
    },

    saveServiceStatusSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.statusMessage = action.payload
    },

    updatedService: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.saveError = []
      state.saveStatus = 'success'
      state.lastUpdatedService = action.payload
      state.errorOnUpdate = false
    },

    editService: (state, action) => {
      state.actionsLoading = false
      state.error = null
    },

    setBuildingTheReport: (state) => {
      state.buildingReport = true
      state.error = null
    },

    setBuildingTheReportRead: (state) => {
      state.buildingReport = false
      state.error = null
    },

    setBuiltReport: (state) => {
      state.reportRead = true
    },

    readyReport: (state) => {
      state.reportRead = false
    },

    setPendingConstructionReport: (state) => {
      state.reportRead = false
      state.error = null
    },

    getMyServicesAdmin: (state, action) => {
      state.myServicesAdmin = action.payload
      state.error = null
      //state.saveError = []
      //state.saveStatus = null
      state.error = null
    },
  },
})
