import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Row, Card, Container } from 'react-bootstrap'
import { useSubheader } from '../../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'
import { SolicitationsServicePanel } from '../../components/services/SolicitationsServicePanel'
import { ListRequirementsService } from '../../components/services/ListRequirementsService'
import { allServices } from '../../_redux/services/serviceActions'

export function ServiceViewPage() {
  const { id1 } = useParams()
  const history = useHistory()

  const pathname = window.location.pathname
  const dispatch = useDispatch()

  /**
   * Verifica se há permissão de acesso
   */
  const { user } = useSelector((state) => state.auth)
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  const { serviceList } = useSelector((state) => state.services)

  //Recuperar o serviço para edição:
  const searchService = serviceList.find((item) => item.id === parseInt(id1))
  const service = typeof searchService !== 'undefined' ? searchService : null

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(allServices())
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container fluid="lg">
            <Row>
              <Col>
                <h4>Informações</h4>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <dl className="row">
                  <dt className="col-sm-3 text-right">Código</dt>
                  <dd className="col-sm-9 font-weight-bold">{service !== null && service.code}</dd>

                  <dt className="col-sm-3  text-right">Título</dt>
                  <dd className="col-sm-9">
                    <p>{service !== null && service.title}</p>
                  </dd>

                  <dt className="col-sm-3  text-right">Habilitado?</dt>
                  <dd className="col-sm-9">{service !== null && service.enable ? 'SIM' : 'NÃO'}</dd>

                  <dt className="col-sm-3  text-right">Descrição</dt>
                  <dd className="col-sm-9">
                    <p className="text-justify">{service !== null && service.description}</p>
                  </dd>
                </dl>
              </Col>
              <Col sm={4} className="d-flex justify-content-end" style={{ minWidth: 300 }}>
                {service !== null && (
                  <SolicitationsServicePanel
                    SolicitationsServicesWaitingAttendance={
                      service.countServicesWaitingAttendance !== null ? service.countServicesWaitingAttendance : '0'
                    }
                    SolicitationsServicesInAttendance={
                      service.countServicesInAttendance !== null ? service.countServicesInAttendance : '0'
                    }
                    SolicitationsServiceCompleted={
                      service.countServiceCompleted !== null ? service.countServiceCompleted : '0'
                    }
                    SolicitationsDisapprovedService={
                      service.countDisapprovedService !== null ? service.countDisapprovedService : '0'
                    }
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="mt-10 mb-3">
                <h4>Requisitos</h4>
                <hr />
              </Col>
            </Row>
            <ListRequirementsService requirements={service !== null && service.requirements} service={service} />
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col className="mt-10 mb-3">
                <h4>Administradores</h4>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col className="ml-10 mb-3">
                {service !== null &&
                  service.administrators.map((administrator, i) => (
                    <p key={i} className="font-weight-bold">
                      {' '}
                      - {administrator.name} ({administrator.email})
                    </p>
                  ))}
                {service !== null && (
                  <>
                    {service.administrators.length === 0 && (
                      <p className="font-weight-bold"> Não há administradores para o serviço.</p>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <h4>Atendentes</h4>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col className="ml-10">
                {service !== null &&
                  service.attendants.map((attendant, i) => (
                    <p key={i} className="font-weight-bold">
                      {' '}
                      - {attendant.name} ({attendant.email})
                    </p>
                  ))}
                {service !== null && (
                  <>
                    {service.attendants.length === 0 && (
                      <p className="font-weight-bold"> Não há atendentes para o serviço.</p>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-0">
              <Col className="text-right">
                <Button variant="secondary" onClick={() => history.goBack()} className="font-weight-bold">
                  <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}
