import * as requestFromServer from './userQualificationEndpoints'
import { userQualificationSlice } from './userQualificationSlice'

const { actions } = userQualificationSlice

/**
 * Retorna da api um objeto com todos
 * usuarios bloqueado no sistema
 * @returns
 */
export const getAllBlockedUsersAction = () => (dispatch) => {
  dispatch(actions.setStatusMessage('Usuário bloqueados'))
  return requestFromServer
    .getAllBlockedUsers()
    .then((response) => {
      dispatch(actions.getUsersBlocked(response.data))
      //dispatch(allSimplifiedUserList())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Bloquear Usuário no Sistema
 * @param {*} id
 * @param {*} userQualificationObj
 * @returns
 */
export const blockUserAction = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Bloqueando Acesso do Usuário', '[', id, ']'))
  return requestFromServer
    .blockUser(id)
    .then((response) => {
      dispatch(actions.blockUser(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Habilitar Usuário no Sistema
 * @param {*} id
 * @param {*} userQualificationObj
 * @returns
 */
export const enableUserAction = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Habilitando Acesso do Usuário', '[', id, ']'))
  return requestFromServer
    .enableUserCandidateToUser(id)
    .then((response) => {
      dispatch(actions.enableUser(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Limpa a propriedade lastError
 * @returns
 */
export const clearLastError = () => (dispatch) => {
  return dispatch(actions.clearLastError())
}
/**
 * Habilitar Usuário no Sistema
 * @param {*} id
 * @param {*} userQualificationObj
 * @returns
 */
export const enableUserCandidateToUsersAction = (id, userHashMail) => (dispatch) => {
  dispatch(actions.setStatusMessage('Habilitando Acesso do Usuário', '[', userHashMail, ']'))
  return requestFromServer
    .enableUserCandidateToUser(id, userHashMail)
    .then((response) => {
      dispatch(actions.enableUserCandidateToUsers(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
