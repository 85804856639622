import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    description: yup
      .string()
      // .required('O campo "Descrição da Solicitação" é de preenchimento obrigatório.')
      .min(3, 'O campo "Descrição da Solicitação" deve ter no mínimo 03 caracteres.')
      .max(1000, 'O campo "Descrição da Solicitação" deve ter no máximo 1000 caracteres.'),
  })

export default validationSchema
