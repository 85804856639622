import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

const API_BASE_FORMS_URL = process.env.REACT_APP_API_FORMS_URL

export const getRequirementsObj = async (idService) =>
  await axios.get(`${API_BASE_URL}/api/service/${idService}/requirement`)
//Requisito do Serviço
export const getRequirementObj = async (idService, idRequirement) =>
  await axios.get(`${API_BASE_URL}/api/service/${idService}/requirement/${idRequirement}`)
//Suporte para o requisito do Serviço (dados de formulário)
export const getRequirementSupport = async (idService) =>
  await axios.get(`${API_BASE_URL}/api/service/${idService}/support`)

//Requisito do Serviço (Versão específica)
export const getRequirementVersionObj = async (idService, idRequirementVersion) =>
  await axios.get(
    `${API_BASE_URL}/api/service/${idService}/requirements/requirement_version/${idRequirementVersion}/obsolete`
  )

//Arquivar Requisito
export const archiveRequirement = async (idRequirement, userId) =>
  await axios.post(`${API_BASE_URL}/api/requirement/${idRequirement}/archive_requirement`, { user_id: userId })

//Excluir Requisito
export const deleteRequirement = async (idService, idRequirement) =>
  await axios.delete(`${API_BASE_URL}/api/service/${idService}/requirement/${idRequirement}`)

//Excluir Versão do anexo do Requisito
export const deleteAttachmentVersion = async (idService, idAttachmentVersion) =>
  await axios.delete(
    `${API_BASE_URL}/api/service/${idService}/requirements/version_attachment/${idAttachmentVersion}/delete`
  )

//Dados para Edição do Requerimento
export const getRequirementToEdit = async (idService, idRequirement) =>
  await axios.get(`${API_BASE_URL}/api/service/${idService}/requirement/requirement/${idRequirement}/to_edit`)

//Editar requerimento (versionamento)
export const newRequirement = async (idService, newRequirementObj) => {
  await axios.post(`${API_BASE_URL}/api/service/${idService}/requirement`, {})
}

//Cadastrar novo requerimento
export const editRequirement = async (idService, idRequiriment, newRequirementObj) => {
  await axios.post(`${API_BASE_URL}/api/service/${idService}/requirement/${idRequiriment}/new_version`, {})
}

// INÍCIO REQUISITOS ARQUIVADOS| REQUIREMENT DISCONTINUED.

/**
 * Objeto Json com a lista de requisitos arquivados.
 * @param {*} idService // identificador do serviço
 * @returns json
 */
export const getAllRequirementsDiscontinued = async (idService) =>
  await axios.get(`${API_BASE_URL}/api/service/${idService}/requirements/discontinued`)

/**
 * Arquivar requisitos.
 * @param {*} idRequirement // identificador do requerimento
 * @returns json
 */
export const unarchiveRequirement = async (idRequirement) =>
  await axios.post(`${API_BASE_URL}/api/requirement/${idRequirement}/unarchive_requirement`)

/**
 * Excluir requisito arquivado.
 * @param {*} idService // identificador do serviço
 * @param {*} idRequirement // identificador do requerimento
 * @returns json
 */
export const deleteDiscontinuedRequirement = async (idService, idRequirement) =>
  await axios.delete(`${API_BASE_URL}/api/service/${idService}/requirements/${idRequirement}`)

// FIM REQUISITOS ARQUIVADOS| REQUIREMENT DISCONTINUED.

export async function systemFormsLoginEndpoint(email, password) {
  return await axios.post(`${API_BASE_FORMS_URL}/api/login`, { email, password })
}

//Lista de formularios

export const getAllForms = async (hash, token) => {
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return await authAxios.get(`${API_BASE_FORMS_URL}/api/forms/${hash}`)
}

export const getHashForm = async (object, token) => {
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  let formData = new FormData()
  formData.append('object', JSON.stringify(object))

  return await authAxios.post(`${API_BASE_FORMS_URL}/api/form/get-encrypted-hash`, formData)
}

// await axios.get(`${API_BASE_FORMS_URL}/api/forms/${hash}`, null,  {headers: {"Authorization" : `Bearer ${token}`}}
// )
