import styled from 'styled-components'

export const LoadingWrapper = styled.div`
  padding: 40px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
`

export const Loading = styled.div`
  justify-content: center;
  margin-right: 25px;
`
