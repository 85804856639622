import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Alert, Col, Row, Button, Form, Container } from 'react-bootstrap'
import { Formik } from 'formik'
import validationSchema from './validationSolicitationStepTwoSchema'
import { useSubheader } from '../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../app/routes/Helpers'
import SelectService from '../components/solicitation/SelectService'
import BasicSausegeCard from '../../../components/Card/BasicSausegeCard'
import FormMessageError from '../../../components/FormMessageError'
import {
  createSolicitationStepTwo,
  editSolicitationStepTwo,
  getServicesForRef,
  clearSaveStatus,
  clearSaveError,
} from '../_redux/solicitation/solicitationActions'

import { getAdminUnitsByUser } from '../../Admin/_redux/adminUnit/adminUnitActions'
import { Typography } from '../../../components'

const SolicitationStepTwoPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = props.location

  const { user } = useSelector((state) => state.auth)
  const {
    saveError,
    saveStatus,
    // statusMessage,
    serviceList,
    intangibleAssetSelected,
    solicitationSelected,
    solicitationInProgress,
  } = useSelector((state) => state.solicitations)

  const { adminUnits } = useSelector((state) => state.adminUnit)

  const handleChangeOrganization = (selectedOption) => {
    setOrganizationSelected(selectedOption)
  }

  if (
    intangibleAssetSelected &&
    !intangibleAssetSelected.id &&
    solicitationSelected &&
    !solicitationSelected.id &&
    solicitationInProgress &&
    !solicitationInProgress.id
  ) {
    history.push(`/solicitacao/novo/etapa-um`)
  }

  // Quando for um "Admin de Unidades"
  let authUserIsUnitsAdmin = false

  if (pathname.split('/').pop() === 'admin-unidade') {
    if (!user.roles.includes('admin-unidade')) {
      history.push(`/401-unauthorized`)
    } else {
      authUserIsUnitsAdmin = true
    }
  }

  const linkStepThree = authUserIsUnitsAdmin
    ? '/solicitacao/novo/etapa-tres/admin-unidade'
    : '/solicitacao/novo/etapa-tres'

  const subheader = useSubheader()
  //const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  const [organizationSelected, setOrganizationSelected] = useState(null)
  const [serviceIdSelected, setServiceIdSelected] = useState()
  const [triedSendForm, setTriedSendForm] = useState(false)
  const [options, setOptions] = useState()

  if (saveStatus === 'success') {
    history.push(linkStepThree)
    //toast.success(`Sucesso! ${statusMessage}`, {})
    dispatch(clearSaveStatus())
  }

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    dispatch(getServicesForRef())
    dispatch(clearSaveError())
    dispatch(getAdminUnitsByUser())
    if (solicitationInProgress.service) {
      setServiceIdSelected(solicitationInProgress.service.id)
    } else if (solicitationInProgress.service_id) {
      setServiceIdSelected(solicitationInProgress.service_id)
    }

    if (solicitationInProgress.solicitation_organization) {
      const organization = solicitationInProgress.solicitation_organization

      setOrganizationSelected({
        label: organization.initials + ' - ' + organization.name,
        value: organization.id,
      })
    }

    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setOptions(
      serviceList.map((service) => {
        return {
          id: service.id,
          label: `${service.code} - ${service.title}`,
          description: service.description,
        }
      })
    )
    // eslint-disable-next-line
  }, [serviceList.length > 0])

  let subTextSousegeCard
  let titleTextSousegeCard
  let infoOnesubTextInfoOne
  let infoTwosubTextInfoTwo

  const hasSolicitationInProgress = Object.keys(solicitationInProgress).length > 0
  const hasSolicitationSelected = Object.keys(solicitationSelected).length > 0

  if (hasSolicitationInProgress) {
    const hasSolicitationParent = solicitationInProgress.parentType === 'Solicitation'

    subTextSousegeCard = hasSolicitationParent ? 'Solicitação' : 'Ativo Intangível'
    titleTextSousegeCard =
      solicitationInProgress.parent &&
      `${solicitationInProgress.parent.code} - ${
        hasSolicitationParent ? solicitationInProgress.parent.description : solicitationInProgress.parent.title
      }`

    infoOnesubTextInfoOne =
      solicitationInProgress.parent &&
      `${solicitationInProgress.parent.user.name} - (${solicitationInProgress.parent.user.email})`

    infoTwosubTextInfoTwo = solicitationInProgress.parent && `${solicitationInProgress.parent.organization.initials}`
  } else {
    subTextSousegeCard = hasSolicitationSelected ? 'Solicitação' : 'Ativo Intangível'
    titleTextSousegeCard = hasSolicitationSelected
      ? `${solicitationSelected.code} - ${solicitationSelected.description}`
      : `${intangibleAssetSelected.code} - ${intangibleAssetSelected.title}`

    infoOnesubTextInfoOne = hasSolicitationSelected
      ? `${solicitationSelected.user.name} (${solicitationSelected.user.email})`
      : `${intangibleAssetSelected.user.name} (${intangibleAssetSelected.user.email})`

    infoTwosubTextInfoTwo = hasSolicitationSelected
      ? `${solicitationSelected.solicitation_organization.initials}`
      : `${intangibleAssetSelected.organization.initials}`
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            {saveError && saveError.length !== 0 && (
              <Alert variant="danger" className="pt-5">
                {Object.values(saveError[0]).map((item, i) => (
                  <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                ))}
              </Alert>
            )}
            <Row>
              <Col className="text-right">
                <Typography variant="stage">Etapa 2 de 3</Typography>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <BasicSausegeCard
                  subText={subTextSousegeCard}
                  titleText={titleTextSousegeCard}
                  subTextInfoOne={infoOnesubTextInfoOne}
                  subTextInfoTwo={infoTwosubTextInfoTwo}
                />
              </Col>
            </Row>
            <Formik
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (authUserIsUnitsAdmin) {
                  values.organizationIdSelected = organizationSelected.value
                }
                values.serviceIdSelected = serviceIdSelected
                values.solicitationSelectedId = solicitationSelected.id
                values.solicitationInProgressId = solicitationInProgress.id
                values.intangibleAssetSelected = intangibleAssetSelected.id
                  ? intangibleAssetSelected.id
                  : solicitationInProgress.intangibleAssetOriginParent.id

                values.parant = solicitationSelected && !solicitationSelected.id ? 'intangible_asset' : 'solicitation'

                if (solicitationInProgress.service || solicitationInProgress.service_id) {
                  dispatch(editSolicitationStepTwo(values))
                } else {
                  dispatch(createSolicitationStepTwo(values))
                }
              }}
              initialValues={{
                serviceIdSelected: '',
                organizationIdSelected: '',
                solicitationInProgressId: '',
                intangibleAssetSelected: '',
                description:
                  solicitationInProgress && Object.keys(solicitationInProgress).length > 0
                    ? solicitationInProgress.description
                    : '',
              }}
            >
              {(formik) => (
                <Form noValidate onSubmit={formik.handleSubmit}>
                  {authUserIsUnitsAdmin ? (
                    <Row>
                      <Col sm={12}>
                        <Form.Group md="12">
                          <Form.Label>Selecione a Unidade de Origem da Nova Solicitação</Form.Label>
                          {options && options.length > 0 && (
                            <Select
                              name="organization_id"
                              value={organizationSelected}
                              onChange={(item) => handleChangeOrganization(item)}
                              options={adminUnits}
                              placeholder=""
                            />
                          )}
                          {triedSendForm && !organizationSelected && (
                            <FormMessageError>A escolha de uma "Unidade de Origem" é obrigatória.</FormMessageError>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col sm={12}>
                      <Form.Group md="12">
                        <Form.Label>Selecione um Serviço</Form.Label>
                        {options && options.length > 0 && (
                          <SelectService
                            options={options}
                            serviceIdSelected={serviceIdSelected}
                            handlerChange={(value) => {
                              setServiceIdSelected(value)
                            }}
                          />
                        )}
                        {triedSendForm && !serviceIdSelected && (
                          <FormMessageError>A escolha de um "Serviço" é obrigatório.</FormMessageError>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group md="12">
                        <Form.Label>Descrição da Solicitação</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="5"
                          name="description"
                          onChange={formik.handleChange}
                          maxLength="1000"
                          {...formik.getFieldProps('description')}
                          className={formik.touched.description && formik.errors.description ? 'is-invalid' : null}
                        />
                        {formik.touched.description && formik.errors.description && (
                          <Form.Control.Feedback type="invalid" className="float-left" style={{ width: '70%' }}>
                            {formik.errors.description}
                          </Form.Control.Feedback>
                        )}
                        <Form.Text className="text-right">
                          {formik.values.description.length !== 0
                            ? `${formik.values.description.length}/1000 caracteres`
                            : 'Máximo de 1000 caracteres.'}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col className="text-left">
                      <Button type="submit" className="font-weight-bold" onClick={() => setTriedSendForm(true)}>
                        Salvar e Prosseguir
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() =>
                          authUserIsUnitsAdmin
                            ? history.push('/administracao/unidades/gerencia')
                            : history.push('/solicitacao/novo/etapa-um')
                        }
                        className="font-weight-bold"
                      >
                        <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                      </Button>{' '}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}

export default SolicitationStepTwoPage
