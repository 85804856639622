import React, { useEffect } from 'react'
import axios from 'axios'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { Table, Button } from '../../../../components'
import { getSolcitationDocuments } from '../../_redux/attendances/attendanceActions'
import HeaderSolicitationManagement from '../../components/attendances/HeaderSolicitationManagement'

const orderByOptions = [
  { value: 'original_name', text: 'Nome do Arquivo' },
  { value: 'fileDescription', text: 'Descrição do Arquivo' },
  { value: 'originFile', text: 'Origem do Arquivo' },
  { value: 'sendBy', text: 'Enviado por' },
  { value: 'sendIn', text: 'Data do Envio' },
  { value: 'organizationInitials', text: 'Sigla da Unidade' },
  { value: 'organizationName', text: 'Nome da Unidade' },
]

export function SolicitationDocuments() {
  const { id1 } = useParams()
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('id')
  const [ordering, setOrdering] = React.useState('desc')
  const { solicitationDocuments, listLoading } = useSelector((state) => state.attendance)

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle}`)

  useEffect(() => {
    dispatch(getSolcitationDocuments(id1, perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  const handleDownload = (idDownload, original_name) => {
    const API_BASE_URL =
      `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_API_URL_HOMOL}`
        : `${process.env.REACT_APP_API_URL_PROD}`

    axios({
      url: `${API_BASE_URL}/api/attachment/${idDownload}/download`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', original_name)
      document.body.appendChild(link)
      link.click()
    })
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fas fa-file-download fa-5x"></i>,
      title: 'Baixar Arquivo',
      onClick: (row) => handleDownload(row.id, row.original_name),
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'original_name',
      text: 'Arquivo',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <strong style={{ fontSize: '0.85rem' }}>{row.original_name}</strong>
          <p className="text-justify">{row.fileDescription}</p>
        </>
      ),
      headerStyle: () => {
        return { width: '35%' }
      },
    },
    {
      dataField: 'originFile',
      text: 'Origem do Arquivo',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '25%', textAlign: 'center' },
    },
    {
      dataField: 'sendBy',
      text: 'Enviado por',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '30%', textAlign: 'center' },
    },
    {
      dataField: 'sendIn',
      text: 'Enviado em',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      headerStyle: { width: '10%', textAlign: 'center' },
    },
  ]
  const hasSolicitationDocuments = Object.keys(solicitationDocuments).length > 0

  return (
    <Card border="secondary">
      <Card.Body>
        <Row className="m-0" style={{ width: '100%' }}>
          {hasSolicitationDocuments && (
            <>
              <HeaderSolicitationManagement
                code={solicitationDocuments.code}
                stage={solicitationDocuments.stage}
                actual_attendant={solicitationDocuments.actual_attendant}
                organizationsInitials={solicitationDocuments.solicitation_organization.initials}
                itemsLittleSausageORIGINAL={[
                  {
                    code: solicitationDocuments.parent.code,
                    title:
                      solicitationDocuments.parentType === 'Solicitation'
                        ? solicitationDocuments.parent.description.length > 80
                          ? `${solicitationDocuments.parent.description.substring(0, 80)} ...`
                          : solicitationDocuments.parent.description
                        : solicitationDocuments.parent.title.length > 80
                        ? `${solicitationDocuments.parent.title.substring(0, 80)} ...`
                        : solicitationDocuments.parent.title,
                    titleModal:
                      solicitationDocuments.parentType === 'Solicitation'
                        ? solicitationDocuments.parent.description
                        : solicitationDocuments.parent.title,

                    description: solicitationDocuments.parent.description,
                  },
                  {
                    code: solicitationDocuments.service.code,
                    title:
                      solicitationDocuments.service.title.length > 80
                        ? `${solicitationDocuments.service.title.substring(0, 80)} ...`
                        : solicitationDocuments.service.title,
                    titleModal: solicitationDocuments.service.title,
                    description: solicitationDocuments.service.description,
                  },
                ]}
                itemsLittleSausage={[
                  solicitationDocuments.parentType === 'Solicitation'
                    ? {
                        type: 'solicitation',
                        code: solicitationDocuments.parent.code,
                        title:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.description.length > 70
                              ? `${solicitationDocuments.parent.description.substring(0, 70)} ...`
                              : solicitationDocuments.parent.description
                            : solicitationDocuments.parent.title.length > 70
                            ? `${solicitationDocuments.parent.title.substring(0, 70)}...`
                            : solicitationDocuments.parent.title,
                        titleModal:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.description
                            : solicitationDocuments.parent.title,
                        description:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.description
                            : solicitationDocuments.parent.description,
                        serviceCode: solicitationDocuments.service.code,
                        serviceTitle: solicitationDocuments.service.title,
                        solicitationSlug:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.stage.slug
                            : '',
                        solicitationStage:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.stage.stage
                            : '',
                        solicitationOrganizationInitials:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.organization.initials
                            : '',
                        momentByUser:
                          solicitationDocuments.parentType === 'Solicitation'
                            ? solicitationDocuments.parent.momentByUser
                            : '',
                        solicitationDescription: solicitationDocuments.description,
                      }
                    : {
                        type: 'intangible-asset',
                        code: solicitationDocuments.intangibleAssetOriginParent.code,
                        title: solicitationDocuments.intangibleAssetOriginParent.title,
                        titleModal: solicitationDocuments.service.title,
                        description: solicitationDocuments.intangibleAssetOriginParent.description,
                        momentByUser: solicitationDocuments.intangibleAssetOriginParent.moment_by_user,
                        organizationInitials: solicitationDocuments.intangibleAssetOriginParent.organization_initials,
                      },

                  {
                    type: 'service',

                    code: solicitationDocuments.service.code,
                    title:
                      solicitationDocuments.service.title.length > 70
                        ? `${solicitationDocuments.service.title.substring(0, 70)}...`
                        : solicitationDocuments.service.title,
                    serviceCode: solicitationDocuments.service.code,
                    serviceTitle: solicitationDocuments.service.title,
                    titleModal: solicitationDocuments.service.title,
                    serviceDescription: solicitationDocuments.service.description,
                  },
                ]}
              />
            </>
          )}
        </Row>
        <Table
          data={solicitationDocuments.data}
          noDataMessage={
            typeof search !== 'undefined'
              ? `Não foram encontrados resultados para o termo ${search}.`
              : `Esta solicitação não possui documentos anexados.`
          }
          columns={columns}
          page={solicitationDocuments.current_page}
          perPage={perPage}
          totalSize={solicitationDocuments.total}
          orderByOptions={orderByOptions}
          orderByOnChange={(field) => setSortField(field)}
          orderByDirectionChange={(direction) => setOrdering(direction)}
          onSizePerPageChange={(perPage) => {
            setPerPage(perPage)
            setPage(1)
          }}
          onPageChange={(page) => setPage(page)}
          onTableChange={() => null}
          searchPlaceholder="Pesquise aqui"
          perPagePosition="top"
          actions={actionsTable}
          onSearch={(term) => setSearch(term)}
          searchValue={search}
          loading={listLoading}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md={8}></Col>
          <Col md={4} className="text-right">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <i className="fa fa-chevron-left"></i>&nbsp;Voltar
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}
