import React from 'react'
import { useHistory } from 'react-router'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { Button, Col, Container, Row } from 'react-bootstrap'

const ErrorWithoutPermission = () => {
  const history = useHistory()
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/error/bg5.jpg')})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">Oops!</h1>
          <p className="font-weight-boldest display-4">Desculpe.</p>
          <p className="font-size-h3">
            Você não tem permissão para acessar esta área. <br />
            Por favor, entre em contato com o administrador do sistema.
            <br />
            <br />
          </p>

          <Container>
            <Row className="text-right" style={{ marginTop: '200px' }}>
              <Col className="text-right">
                <Button onClick={() => history.push('/dashboard')}>
                  <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Voltar
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default ErrorWithoutPermission
