import styled from 'styled-components'

export const ActionButtonsWrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: space-around;
  button {
    margin-top: 5px;
  }
`
export const LinkMailTo = styled.a`
  margin-right: 5px;
  text-decoration: underline;
  color: #333;

  :hover {
    color: #333;
    opacity: 0.8;
    text-decoration: underline;
  }
`
export const BlockSpace = styled.div`
  display: block;
  width: 100%;
  margin-top: 30px;
`
export const ListTwoColumns = styled.div`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;

  .form-check {
    margin: 5px 0px;
  }
`

export const TextLink = styled.a`
  cursor: pointer;
`

export const GroupUserInfo = styled.div`
  //text-align: left;

  span {
    text-transform: uppercase;
  }

  i {
    color: #333;
    margin-right: 5px;
  }
`

export const SearchWrapper = styled.div`
  display: block;

  .search-label {
    width: 80%;
  }
`
