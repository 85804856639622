import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'
import './styles.css'

const SweetAlert = withReactContent(Swal)

const AlertGT = ({
  show,
  title,
  text,
  icon,
  width = 600,
  showCancelButton = true,
  showConfirmButton = true,
  confirmButtonText = 'Sim',
  cancelButtonText = 'Não',
  toastSuccess,
  onConfirm,
  onDismissed,
  onDenied,
  showCloseButton = false,
  closeToast = false,
}) => {
  if (show) {
    SweetAlert.fire({
      title: title,
      html: text,
      icon: icon,
      width: width,
      showCancelButton: showCancelButton,
      showConfirmButton: showConfirmButton,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      showCloseButton: showCloseButton,
      customClass: { content: 'lineHeight' },
    }).then((result) => {
      if (result.isConfirmed) {
        if (onConfirm) onConfirm()
        if (toastSuccess) toast.success(toastSuccess, {})
      }
      if (result.isDismissed) onDismissed()
      if (result.isDenied) onDenied()
      SweetAlert.close()
    })
  }

  if (closeToast) {
    SweetAlert.close()
  }
  return null
}

export default AlertGT
