import React, { useEffect } from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { Row, Col, Button, InputGroup, Form, Spinner } from 'react-bootstrap'
import TooltipCustom from '../TooltipCustom'
import { AlertMessage } from '../../modules/Solicitation/components/services/AlertMessage'
import paginationFactory from 'react-bootstrap-table2-paginator'
import BootstrapTable from 'react-bootstrap-table-next'
import './table.css'
import ActionsButton from './ActionsButton'

const { SearchBar } = Search

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Exibindo registros de {from} a {to} de um total de {size} registros
  </span>
)

/**
 * Função actionFormatter,
 *
 * Esta função altera o conteúdo de uma celula da tabela (Ações)
 * provida por <BootstrapTable />.  Neste caso,  incrementa
 * botões de ação.
 * @param {*} cell
 * @param {*} row //obj com dados da linha atual
 * @returns
 */
const actionsFormatter = (cell, row, actions) => {
  return (
    <div className="d-flex justify-content-center">
      {actions.map((item, index) => (
        <ActionsButton
          key={index}
          title={typeof item.title === 'function' ? item.title(row) : item.title}
          visible={typeof item.visible === 'function' ? item.visible(row) : item.visible}
          /* disableHoverListener={
            typeof item.disabled === 'function' ? Boolean(item.disabled(row)) : Boolean(item.disabled)
          } */
          id={`action_row_${row.id || item.id}`}
          classButton={`mt-1 mb-1 ${actions.length > index + 1 ? 'mr-2' : ''}`}
          disabled={typeof item.disabled === 'function' ? item.disabled(row) : item.disabled ? item.disabled : null}
          text={item.text}
          onClick={() => item.onClick(row)}
        />
      ))}
    </div>
  )
}

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
  <div className="btn-group" role="group">
    {options.map((option, index) => (
      <button
        key={index}
        type="button"
        onClick={() => onSizePerPageChange(option.page)}
        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-light-primary' : 'btn-default'}`}
        style={{ height: 30, paddingTop: 5 }}
      >
        {option.text}
      </button>
    ))}
  </div>
)

const Table = ({
  data = [],
  keyField = 'id',
  noDataMessage = 'Não há registros.',
  columns,
  orderInitial = 'asc',
  orderByOptions,
  orderByOnChange,
  orderByDirectionChange,
  page = 1,
  onPageChange,
  perPage = 10,
  perPagePosition = 'bottom',
  totalSize = 10,
  onSizePerPageChange,
  onBackButtonClick,
  onSearch,
  searchValue,
  onTableChange,
  searchPlaceholder = 'Digite o alvo de sua busca',
  actions,
  loading,
  remote = {
    filter: true,
    pagination: true,
    sort: true,
  },
}) => {
  const qtd = data.length
  const [orderUp, setOrderUp] = React.useState(orderInitial === 'asc')
  const [cols, setCols] = React.useState(null)

  const paginationOptions = {
    page,
    sizePerPage: perPage,
    nextPageText: '>',
    prePageText: '<',
    totalSize,
    showTotal: true,
    firstPageText: 'Primeira',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Última página',
    hideSizePerPage: perPagePosition === 'bottom' ? false : true,
    onPageChange,
    onSizePerPageChange,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todas',
        value: data.length,
      },
    ],
    sizePerPageRenderer,
  }

  useEffect(() => {
    orderByDirectionChange && orderByDirectionChange(orderUp ? 'asc' : 'desc')
    //eslint-disable-next-line
  }, [orderUp])

  useEffect(() => {
    if (searchValue === '') onSearch()
    //eslint-disable-next-line
  }, [searchValue])

  useEffect(() => {
    const colls = columns
    if (actions !== null) {
      colls.push({
        dataField: 'acoes',
        text: 'Ações',
        isDummyField: true,
        csvExport: false,
        classes: 'align-middle',
        headerStyle: () => {
          return { verticalAlign: 'middle' }
        },
        formatter: (cells, row) => actionsFormatter(cells, row, actions),
      })
    }
    setCols(colls)
    //eslint-disable-next-line
  }, [columns])

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data || []}
      columns={cols || columns}
      search={{
        searchFormatted: true,
        clearSearch: true,
      }}
    >
      {(props) => (
        <>
          <Row className="mb-4">
            {onSearch && (
              <Col sm={12} md={orderByOptions && perPagePosition ? 6 : orderByOptions || perPagePosition ? 9 : 12}>
                <InputGroup>
                  <InputGroup.Text
                    className="input-group-prepend"
                    style={{
                      height: 36,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </InputGroup.Text>
                  <SearchBar
                    {...props.searchProps}
                    placeholder={searchPlaceholder}
                    className="input-group-prepend"
                    style={{
                      height: 36,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    onSearch={onSearch}
                    searchText={searchValue}
                    delay={1000}
                  />
                </InputGroup>
              </Col>
            )}

            {orderByOptions && (
              <Col sm={12} md={3}>
                <div className="d-flex justify-content-between">
                  <Form.Control as="select" onChange={(e) => orderByOnChange(e.target.value)} className="mr-5">
                    <option>Ordenar por...</option>
                    {orderByOptions.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.text}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <TooltipCustom title="Inverter Ordem dos Registros" placement="top" arrow>
                    <Button variant="outline-secondary" onClick={() => setOrderUp(!orderUp)}>
                      {orderUp ? (
                        <i className="fas fa-sort-alpha-up pr-0"></i>
                      ) : (
                        <i className="fas fa-sort-alpha-down pr-0"></i>
                      )}
                    </Button>
                  </TooltipCustom>
                </div>
              </Col>
            )}
            {perPagePosition === 'top' && (
              <Col sm={12} md={3}>
                <Form.Control as="select" onChange={(e) => onSizePerPageChange(e.target.value)} className="mr-5">
                  <option value="5">Exibir 5 registros</option>
                  {totalSize > 5 && <option value="10">Exibir 10 registros</option>}
                  {totalSize > 10 && <option value="15">Exibir 15 registros</option>}
                  {totalSize > 15 && <option value="20">Exibir 20 registros</option>}
                  {totalSize > 20 && <option value="25">Exibir 25 registros</option>}
                  {totalSize > 25 && <option value="50">Exibir 50 registros</option>}
                  {totalSize > 50 && <option value="100">Exibir 100 registros</option>}
                </Form.Control>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {loading && (
                <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: 400 }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {qtd === 0 && !loading && (
                <>
                  <AlertMessage
                    variant="alert alert-custom alert-light-primary alert-dismissible"
                    message={noDataMessage}
                  />
                </>
              )}

              {qtd > 0 && cols && !loading && (
                <BootstrapTable
                  {...props.baseProps}
                  classes="bordered"
                  keyField={keyField}
                  data={data}
                  columns={cols}
                  rowStyle={{ color: 'black' }}
                  headerClasses="text-center bg-light"
                  rowClasses="text-center"
                  bordered={true}
                  hover
                  noDataIndication={noDataMessage}
                  striped
                  pagination={paginationFactory(paginationOptions)}
                  remote={remote}
                  onTableChange={onTableChange}
                />
              )}
            </Col>
          </Row>
          <Row className="mt-0">
            <Col className="text-right">
              {onBackButtonClick && (
                <Button variant="secondary" type="button" onClick={onBackButtonClick} className="font-weight-bold">
                  <i className="fa fa-chevron-left"></i>
                  &nbsp;&nbsp;Voltar
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}
    </ToolkitProvider>
  )
}

export default Table
