import * as requestFromServer from './IntangibleAssetEndpoints'

import { intangibleAssetSlice } from './intangibleAssetSlice'

const { actions } = intangibleAssetSlice

/**
 * Objeto com os Ativos Intangíveis de um Usuario
 * @param {*} id
 * @returns
 */
export const getActionIntangibleAssetsByUser = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Lista de Ativos Intangíveis'))
  return requestFromServer
    .getIntangibleAssetsByUser(id)
    .then((response) => {
      dispatch(actions.getIntangibleAssetsbyUser(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto de Ativos Intangíveis
 * @param {*} id
 * @returns
 */
export const getActionIntangibleAsset = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Ativo Intangível carregado'))
  return requestFromServer
    .getIntangibleAsset(id)
    .then((response) => {
      dispatch(actions.getIntangibleAsset(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto de versões de um Ativo Intangivel
 * @param {*} id
 * @returns
 */
export const getActionIntangibleAssetVersions = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Versões de um Ativo Intangível'))
  return requestFromServer
    .getAllIntangibleAssetVersions(id)
    .then((response) => {
      dispatch(actions.getIntangibleAssetVersions(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Criar Ativo intangível
 * @param {*} newIntangibleAssetObj
 * @returns
 */
export const createIntangibleAsset = (userId, newIntangibleAssetObj) => (dispatch) => {
  return requestFromServer
    .newIntangibleAsset(userId, newIntangibleAssetObj)
    .then((response) => {
      dispatch(actions.newIntangibleAsset(response.data))
      dispatch(actions.setStatusMessage(response.data))
      return response.data
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      throw error
    })
}
/**
 * Editar Ativo intangível
 * @param {*} editIntangibleAssetObj
 * @returns
 */
export const editIntangibleAsset = (id, editIntangibleAssetObj) => (dispatch) => {
  return requestFromServer
    .editIntangibleAsset(id, editIntangibleAssetObj)
    .then((response) => {
      dispatch(actions.editIntangibleAsset(response.data))
      dispatch(actions.setStatusMessage(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Remoção de Ativo Intangível (delete)
 * @param {*} id
 * @returns
 */
export const removeIntangibleAsset = (id) => (dispatch) => {
  return requestFromServer
    .deleteIntangibleAsset(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('Excluindo Ativo Intangível'))
      dispatch(actions.deleteIntangibleAsset(response.data))
      dispatch(actions.setSuccessOnDelete(response.data))
      dispatch(actions.clearSuccessOnDelete())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setErrorOnDelete(error.response.data.message))
      dispatch(actions.clearErrorOnDelete())
    })
}
