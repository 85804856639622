import axios from 'axios'

const API_BASE_URL =
  `${process.env.NODE_ENV}` === 'development'
    ? `${process.env.REACT_APP_API_URL_HOMOL}`
    : `${process.env.REACT_APP_API_URL_PROD}`

/**
 * Retorna objeto com os usuarios atualmente bloqueados no sistema.
 * @returns
 */
export async function getAllBlockedUsers() {
  return axios.get(`${API_BASE_URL}/api/user_candidate/blocked_users`)
}

/**
 * Grava o bloqueio do usuario no sistema, passando id do usuario e justificativa
 * @param {*} id
 * @param {*} obj
 * @returns
 */
export function blockUser(id) {
  return axios.post(`${API_BASE_URL}/api/user_candidate/disable/to/users`, {
    pre_candidate_id: id,
  })
}
/**
 * Grava o bloqueio do usuario no sistema, passando id do usuario e justificativa
 * @param {*} id
 * @param {*} obj
 * @returns
 */
export function enableUserCandidateToUser(id) {
  return axios.post(`${API_BASE_URL}/api/user_candidate/enable/to/users`, {
    pre_candidate_id: id,
  })
}
