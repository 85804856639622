import React from 'react'
import { Card, Accordion } from 'react-bootstrap'
import { HeaderIcon, AccordionWrapper } from './Accordion.styles'
import CustomToggle from './CustomToggle'

const AccordionComponent = ({ defaultFirstCollapseOpen = false, items }) => {
  const defaultPropsAccordion = defaultFirstCollapseOpen && {
    defaultActiveKey: 0,
  }

  return (
    <AccordionWrapper>
      <Accordion {...defaultPropsAccordion}>
        {items.map((item, index) => (
          <Card key={`card-acordion-${index}`}>
            <Accordion.Toggle as={Card.Header} eventKey={index}>
              <CustomToggle
                eventKey={index}
                isAnswared={item.isAnswared}
                showBoxAnswered={item.showBoxAnswered}
                callback={item.handleCick}
              >
                {item.headerIcon && <HeaderIcon>{item.headerIcon}</HeaderIcon>}
                <strong>{item.headerText}</strong>
              </CustomToggle>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body>
                {item.bodyText && item.bodyText}
                {item.bodyComponent && item.bodyComponent()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </AccordionWrapper>
  )
}

export default AccordionComponent
