import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import TooltipCustom from '../../../components/TooltipCustom'
import { toast } from 'react-toastify'
import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../routes/Helpers'
import { ReadMore, Table, Button, BadgeGT, AlertGT } from '../../../components'
import {
  cleanLastError,
  cleanSolicitationSelected,
  getSolicitationsAwaitingAttendantUnit,
  receiveSolicitation,
} from '../_redux/attendances/attendanceActions'

const orderByOptions = [
  { value: 'code', text: 'Código da Solicitação' },
  { value: 'description', text: 'Descrição da Solicitação' },
  { value: 'service_code', text: 'Código do Serviço' },
  { value: 'service_title', text: 'Título do Serviço' },
  { value: 'created_at', text: 'Data de Criação da Solicitação' },
  { value: 'requester_name', text: 'Nome do Solicitante' },
  { value: 'requester_organization_unit', text: 'Sigla da Unidade' },
  { value: 'requester_organization', text: 'Nome da Unidade' },
]

export function SolicitationAwaitingAttendantUnit() {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('created_at')
  const [ordering, setOrdering] = React.useState('asc')
  const { solicitations, listLoading } = useSelector((state) => state.attendance)
  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: null,
  })

  const history = useHistory()
  const arrBreadCrumb = [
    {
      pathname: '/dashboard',
      title: 'Home',
      subTitle: '',
      icon: '',
    },
  ]

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(`${getCurrentPageTitleSubTitle(pathname).title} ${solicitations.userUnit || '...'}`)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(cleanSolicitationSelected())
    dispatch(cleanLastError())
    // eslint-disable-next-line
  }, [history])

  useEffect(() => {
    dispatch(getSolicitationsAwaitingAttendantUnit(perPage, page, search, sortField, ordering))
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  const receivingSolicitation = (row) => {
    setConfigAlert({
      show: true,
      title: null,
      text: `Deseja realmente acolher a solicitação <strong>${row.code}</strong>?`,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        dispatch(receiveSolicitation(row.solicitation_id))
          .then(() => {
            setConfigAlert({ ...configAlert, show: false })
            history.push(`/solicitacao/gerencia-da-solicitacao/${row.solicitation_id}`)
          })
          .catch((error) => {
            toast.error(
              error.response
                ? error.response.data.message
                : 'Não foi possível acolher a solicitação. Tente novamente em alguns minutos.'
            )
          })
      },
    })
  }

  const disabledButton = (row) => {
    return row.attendant.is_attendant_in_service === false
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fa fa-search"></i>,
      title: 'Consultar Solicitação',
      onClick: (row) => history.push(`/solicitacao/gerencia-da-solicitacao/${row.solicitation_id}`),
    },
    {
      text: <i className="fas fa-cart-arrow-down"></i>,
      title: 'Acolher Solicitaçao',
      onClick: (row) => receivingSolicitation(row),
      disabled: (row) => disabledButton(row),
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'description',
      text: 'Dados da Solicitação',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.description} maxCharacters={70} />
          <strong style={{ fontSize: '0.85rem' }}>
            {row.service_code}-{row.service_title}
          </strong>
        </>
      ),
      headerStyle: { width: '100ch', textAlign: 'left' },
    },
    {
      dataField: 'created_at',
      text: 'Criação/Solicitante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.created_at}
          <br />
          <span style={{ fontSize: '0.85rem' }}>
            {row.requester_name} ({row.requester_organization_unit})
          </span>
        </>
      ),
      headerStyle: { width: 300, textAlign: 'center' },
    },
    {
      dataField: 'solicitation_stage',
      text: 'Estágio',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.solicitation_stage} placement="top" arrow>
          <div>
            <BadgeGT variant={row.solicitation_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: 40, textAlign: 'center' },
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <Card border="secondary">
        <Card.Body>
          <Table
            data={solicitations.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Não existem solicitações aguardando acolhimento na unidade.`
            }
            columns={columns}
            page={solicitations.current_page}
            perPage={perPage}
            totalSize={solicitations.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="text-right">
              <Button variant="primary" className="mr-5" onClick={() => history.push('/solicitacao/atendimentos')}>
                Meus Atendimentos
              </Button>
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
