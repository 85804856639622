import styled from 'styled-components'

export const CheckWrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-bottom: 20px;

  .checkbox-confirm input {
    margin-top: 7px;
  }
`
