import AuditPage from '../modules/Admin/pages/Audit/AuditPage'
import OperationData from '../modules/Admin/pages/Audit/OperationData'

export default [
  /**
   * Auditoria
   * pathname principal: /auditoria
   */
  {
    pathName: '/auditoria',
    exact: true,
    title: 'Apoio à Auditoria',
    subTitle: 'Lista de atividades de todos os usuários no sistema.',
    icon: '',
    component: AuditPage,
    permission: ['admin'],
  },
  {
    pathName: '/auditoria/:id1',
    exact: true,
    title: 'Dados da Operação',
    subTitle: 'Dados da operação realizada pelo usuário do sistema.',
    icon: '',
    component: OperationData,
    //component: DashboardPage,
    permission: ['admin', 'admin-servico'],
  },
]
