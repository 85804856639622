import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    description: yup
      .string()
      .required('O campo "Descrição do Requisito" é de preenchimento obrigatório.')
      .min(3, 'O campo "Descrição do Requisito" deve ter no mínimo 03 caracteres.')
      .max(300, 'O campo "Descrição do Requisito" deve ter no máximo 300 caracteres.'),
    requirementType: yup.string().required('A escolha de um "Tipo de Requisito" é obrigatória.'),
    info_message: yup.string().when('requirementType', {
      is: (value) => value === 'informative',
      then: yup
        .string()
        .required('O campo "Mensagem Informativa" é de preenchimento obrigatório.')
        .min(3, 'O campo "Mensagem Informativa" deve ter no mínimo 03 caracteres.')
        .max(1000, 'O campo "Mensagem Informativa" deve ter no máximo 1000 caracteres.'),
    }),
    radioReadInformative: yup.string().when('requirementType', {
      is: (value) => value === 'informative',
      then: yup.string().required('O campo "Confirmar leitura do informativo?" é de preenchimento obrigatório.'),
    }),
    msgInformative: yup.string().when('requirementType', {
      is: (value) => value === '1',
      then: yup
        .string()
        .required('O campo "Mensagem Informativa" é de preenchimento obrigatório.')
        .min(3, 'O campo "Mensagem Informativa" deve ter no mínimo 03 caracteres.')
        .max(1000, 'O campo "Mensagem Informativa" deve ter no máximo 1000 caracteres.'),
      otherwise: yup.string().nullable(),
    }),
    maxAmountFiles: yup.string().when('requirementType', {
      is: (requirementType) => requirementType === '4',
      then: yup
        .string()
        .ensure()
        .required('O campo "Quantidade máxima de arquivos" é de preenchimento obrigatório.'),
      otherwise: yup.string().nullable(),
    }),
    /* fileTypes: yup.string().when('requirementType', {
      is: (requirementType) => requirementType === '4',
      then: yup
        .string()
        //.ensure()
        .required('O campo "Tipos de Arquivos Aceitáveis" é de preenchimento obrigatório.'),
    }), */
  })

export default validationSchema
