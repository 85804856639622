import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SweetAlert = withReactContent(Swal)

const AlertDotStage = ({ show, onConfirm, onDismissed, onDenied }) => {
  if (show) {
    SweetAlert.fire({
      title: 'Legenda dos Estágios da Tarefa',
      html: `
    <div class="container">

      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #fffc03"></i></div>
        <div class="col-8 text-left"><strong>Em Andamento</strong></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: #1753c3"></i></div>
        <div class="col-8 text-left"><strong>Realizada</strong></div>
      </div>

      <div class="row">
        <div class="col-4 text-right"><i class="fas fa-circle" style="color: black"></i></div>
        <div class="col-8 text-left"><strong>Cancelada</strong></div>
      </div>

    </div>
      `,
      icon: 'info',
      width: 600,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) if (onConfirm) onConfirm()
      if (result.isDismissed) onDismissed()
      if (result.isDenied) onDenied()
      SweetAlert.close()
    })
  }
  return null
}

export default AlertDotStage
