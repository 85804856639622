import * as requestFromServer from './reportEndpoints'
import { reportSlice } from './reportSlice'

const { actions } = reportSlice

/**
 * Objeto com todos os Ativos Intangíveis.
 */
export const allIntangibleAssets = () => (dispatch) => {
  return requestFromServer
    .getAllIntangibleAssets()
    .then((response) => {
      dispatch(actions.getIntangibleAssets(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto com todos os serviços.
 */
export const allServices = () => (dispatch) => {
  return requestFromServer
    .getAllServices()
    .then((response) => {
      dispatch(actions.getServices(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Objeto com todos as Unidades da Fiocruz.
 */
export const allFiocruzUnits = () => (dispatch) => {
  return requestFromServer
    .getAllUnits()
    .then((response) => {
      dispatch(actions.getUnits(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Action para gerar quantitativo de registros do relatório a ser gerado (excel)
 */
export const tabularReportExportCount = (arrUnits, arrServices, startDate, endDate) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .reportTabularCount(arrUnits, arrServices, startDate, endDate)
    .then((response) => {
      dispatch(actions.setCountRegisterTabularReport(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Action para gerar relatório tabulado (excel)
 */
export const tabularReportExport = (arrUnits, arrServices, startDate, endDate) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .reportTabular(arrUnits, arrServices, startDate, endDate)
    .then(() => {
      dispatch(actions.clearCountTabularReportExport())
      dispatch(actions.setBuiltReport())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response))
      dispatch(actions.setListLoading(false))
      dispatch(actions.setPendingConstructionReport())
    })
}
/**
 * Action para gerar relatório dossie Ativo Intangível (pdf)
 */
export const reportDossierIntangibleAssetExport = (idIntangibleAsset, iacode) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .reportDossierIntangibleAsset(idIntangibleAsset, iacode)
    .then((response) => {
      dispatch(actions.setBuiltReport())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response))
      dispatch(actions.setPendingConstructionReport())
    })
}

/**
 * limpa error on state
 * @returns
 */
export const clearErrorsOnDelete = () => (dispatch) => {
  return dispatch(actions.clearErrorOnDelete())
}

export const setReportBuildFalse = () => (dispatch) => {
  return dispatch(actions.setPendingConstructionReport())
}

export const setLoadingReportBuild = () => (dispatch) => {
  return dispatch(actions.setBuildingTheReport())
}
export const setLoadReportBuildRead = () => (dispatch) => {
  return dispatch(actions.setBuildingTheReportRead())
}
