import React, { useEffect } from 'react'
import { useSubheader } from '../../../_metronic/layout'
import { Tabs, Tab, Col, Row, Card } from 'react-bootstrap'
import { Notice } from '../../../_metronic/_partials/controls'
import { getCurrentPageTitleSubTitle, getAllBreadcrumbs } from '../../routes/Helpers'

export function PresentationPage() {
  //const { user } = useSelector((state) => state.auth);

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    // eslint-disable-next-line
  }, [])

  // const { id, id2 } = useParams()

  return (
    <>
      {/* begin::PresentationPage */}

      <Notice icon="flaticon-warning font-primary">
        <span>
          Para solicitar um serviço, obedeça as regras de atendimento aplicadas a cada tipo de serviço. Seu
          Administrador de serviços também poderá te ajudar na estolha de um atendente.
        </span>{' '}
      </Notice>

      <Card border="secondary">
        <Card.Body>
          <Card.Title>Validação de campos</Card.Title>
          <Card.Text>
            <span className="h2">Teste Page</span>
          </Card.Text>

          <Row>
            <Col>
              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title="Home">
                  <p>
                    fasfj aslfd asl klasjflkajs lfkjaskl jdflkasjklfja skldfj lkasj flk ajsklfj lkasjflkjaslkfj laksdf
                    asdlfkjasdlkfaslfj lkasfj afkadsjlçf aslkfj alfdj asdfl a
                  </p>
                </Tab>
                <Tab eventKey="profile" title="Profile">
                  <p>
                    fasfj aslfd asl klasjflkajs lfkjaskl jdflkasjklfja skldfj lkasj flk ajsklfj lkasjflkjaslkfj laksdf
                    asdlfkjasdlkfaslfj lkasfj afkadsjlçf aslkfj alfdj asdfl a
                  </p>
                </Tab>
                <Tab eventKey="contact" title="Contact" disabled>
                  <p>
                    fasfj aslfd asl klasjflkajs lfkjaskl jdflkasjklfja skldfj lkasj flk ajsklfj lkasjflkjaslkfj laksdf
                    asdlfkjasdlkfaslfj lkasfj afkadsjlçf aslkfj alfdj asdfl a
                  </p>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* end::PresentationPage */}
    </>
  )
}
