import React from 'react'
import { Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import TooltipCustom from '../../../../components/TooltipCustom'

import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'

import { Button } from '../../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { getAudit } from '../../_redux/audit/auditActions'
import handleDownload from '../../../../Utils/downloadFile'

function OperationData() {
  const { id1 } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const { auditSelected } = useSelector((state) => state.audit)

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  React.useEffect(() => {
    function thisAudit() {
      dispatch(getAudit(id1))
    }
    thisAudit()
    // eslint-disable-next-line
  }, [])

  if (typeof auditSelected.audit_new_values === 'object')
    console.log(Object.keys(auditSelected.audit_old_values).length)

  return (
    <>
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <table className="table table-bordered">
            <thead className="table-active">
              <tr>
                <td>Legenda</td>
                <td>Informação</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align={'right'}>
                  <strong>Momento</strong>
                </td>
                <td>{auditSelected.audit_formated_date}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Usuário</strong>
                </td>
                <td>{auditSelected.audit_user_name_email}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Unidade</strong>
                </td>
                <td>{auditSelected.audit_unit_formated}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Ação Realizada</strong>
                </td>
                <td>{auditSelected.audit_event}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Entidade (BD)</strong>
                </td>
                <td>{auditSelected.audit_friendly_entity}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>IP</strong>
                </td>
                <td>{auditSelected.audit_ip_address}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Navegador e S.O.</strong>
                </td>
                <td>{auditSelected.audit_user_agent}</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Dados Antigos</strong>
                </td>
                <td>
                  {typeof auditSelected.audit_old_values === 'object' &&
                    Object.keys(auditSelected.audit_old_values).length === 0 &&
                    '--'}
                  {typeof auditSelected.audit_old_values === 'object' &&
                    Object.keys(auditSelected.audit_old_values).map((key, index) => {
                      return (
                        <p key={index}>
                          <strong>{key}:</strong> {auditSelected.audit_old_values[key]}
                        </p>
                      )
                    })}
                </td>
              </tr>
              <tr>
                <td align={'right'}>
                  <strong>Dados Novos</strong>
                </td>
                <td>
                  {typeof auditSelected.audit_new_values === 'object' &&
                    Object.keys(auditSelected.audit_new_values).length === 0 &&
                    '--'}
                  {typeof auditSelected.audit_new_values === 'object' &&
                    Object.keys(auditSelected.audit_new_values).map((key, index) => {
                      return (
                        <p key={index}>
                          <strong>{key}:</strong> {auditSelected.audit_new_values[key]}
                        </p>
                      )
                    })}
                  {auditSelected.audit_attachment_from_model && auditSelected.audit_new_values.original_name && (
                    <div>
                      <strong>Attachment:</strong>
                      <TooltipCustom title={auditSelected.audit_new_values.original_name} placement="top" arrow>
                        <Button
                          size="sm"
                          variant="file"
                          type="button"
                          className="ml-2 mt-2"
                          onClick={() =>
                            handleDownload(
                              auditSelected.audit_attachment_from_model,
                              auditSelected.audit_new_values.original_name
                            )
                          }
                        >
                          {auditSelected.audit_new_values.original_name}
                        </Button>
                      </TooltipCustom>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Card.Body>

        <Card.Footer className="text-right">
          <Button
            variant="secondary"
            onClick={() => {
              history.goBack()
            }}
          >
            <i className="fa fa-chevron-left"></i>&nbsp;Voltar
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default OperationData
