import React, { useEffect } from 'react'
import { Card, Col, Row, NavLink } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import TooltipCustom from '../../../../components/TooltipCustom'
import { toast } from 'react-toastify'
import { useSubheader } from '../../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../../routes/Helpers'
import { ReadMore, Table, Button, BadgeGT, AlertGT, AlertDotStage } from '../../../../components'
import { getAttendancesOpened, startAttendance, cleanSaveStatus } from '../../_redux/attendances/attendanceActions'

const orderByOptions = [
  { value: 'code', text: 'Código da Solicitação' },
  { value: 'description', text: 'Descrição da Solicitação' },
  { value: 'solicitation_stage', text: 'Estágio da Solicitação' },
  { value: 'service_code', text: 'Código do Serviço' },
  { value: 'service_title', text: 'Título do Serviço' },
  { value: 'created_at', text: 'Data de Criação da Solicitação' },
  { value: 'requester_name', text: 'Nome do Solicitante' },
  { value: 'requester_organization_unit', text: 'Sigla da Unidade' },
]

export function AttendancesOpened() {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState('')
  const [ordering, setOrdering] = React.useState('')
  const [showAlertDotStage, setShowAlertDotStage] = React.useState(false)

  const { myAttendance, listLoading, saveStatus, statusMessage } = useSelector((state) => state.attendance)
  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
  })

  const arrBreadCrumb = [
    {
      pathname: '/dashboard',
      title: 'Home',
      subTitle: '',
      icon: '',
    },
  ]

  const history = useHistory()

  const pathname = window.location.pathname
  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(`${getCurrentPageTitleSubTitle(pathname).subTitle} ${myAttendance.userName || '...'}.`)

  useEffect(() => {
    subheader.setBreadcrumbs(arrBreadCrumb)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getAttendancesOpened(perPage, page, search, sortField, ordering)).then(() => {
      /**
       * Forçar atualização do estado por conta de anexos que podem estar em processamento.
       * TODO:: Analisar outra forma de realizar esta atualização!!!
       */
      setTimeout(() => {
        dispatch(getAttendancesOpened(perPage, page, search, sortField, ordering))
      }, 2000)
    })
    // eslint-disable-next-line
  }, [perPage, page, search, sortField, ordering])

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success(statusMessage, {})
      dispatch(cleanSaveStatus())
    }
    // eslint-disable-next-line
  }, [saveStatus])

  const startingAttendance = (row) => {
    setConfigAlert({
      show: true,
      title: null,
      text: `Deseja ${
        row.solicitation_stage_slug === 'atendimento-com-pendencia' ? 'retomar' : 'realmente iniciar'
      }  o atendimento da solicitação <strong>${row.code}</strong>?`,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => {
        dispatch(startAttendance(row.solicitation_id))
          .then(() => {
            setConfigAlert({ ...configAlert, show: false })
            history.push(`/solicitacao/gerencia-da-solicitacao/${row.solicitation_id}`)
          })
          .catch((error) => {
            toast.error(
              error.response
                ? error.response.data.message
                : 'Não foi possível iniciar o atendimento da solicitação. Tente novamente em alguns minutos.'
            )
          })
      },
    })
  }

  const getTitleButton = (stage) => {
    const stagesToStart = ['aguardando-atendimento']
    const stagesToRestart = ['atendimento-com-pendencia']
    const blockButon = ['atendimento-concluido']
    if (stagesToStart.indexOf(stage) > -1) return 'Iniciar Atendimento'
    else if (stagesToRestart.indexOf(stage) > -1) return 'Retomar Atendimento'
    else if (blockButon.indexOf(stage) > -1) return null
    else return 'Atendimento em andamento'
  }

  const disabledButton = (row) => {
    const stagesToStart = ['aguardando-atendimento', 'atendimento-com-pendencia']
    return stagesToStart.indexOf(row.solicitation_stage_slug) > -1 ? null : 'disabled'
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsTable = [
    {
      text: <i className="fa fa-search"></i>,
      title: 'Consultar Solicitação',
      onClick: (row) => history.push(`/solicitacao/gerencia-da-solicitacao/${row.solicitation_id}`),
    },
    {
      text: <i className="fas fa-play"></i>,
      title: (row) => getTitleButton(row.solicitation_stage_slug),
      onClick: (row) => startingAttendance(row),
      disabled: (row) => disabledButton(row),
    },
  ]

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'code',
      text: 'Código',
      sort: false,
      align: 'left',
      classes: 'align-middle',
      style: { fontWeight: 600 },
      headerStyle: () => {
        return { width: '5%' }
      },
    },
    {
      dataField: 'description',
      text: 'Dados da Solicitação',
      align: 'left',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          <ReadMore text={row.description} maxCharacters={70} />
          <strong style={{ fontSize: '0.85rem' }}>
            {row.service_code}-{row.service_title}
          </strong>
        </>
      ),
      headerStyle: { width: '100ch', textAlign: 'left' },
    },
    {
      dataField: 'created_at',
      text: 'Criação/Solicitante',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <>
          {row.created_at}
          <br />
          <span style={{ fontSize: '0.85rem' }}>
            {row.requester_name} ({row.requester_organization_unit || row.requester_organization})
          </span>
        </>
      ),
      headerStyle: { width: 300, textAlign: 'center' },
    },
    {
      dataField: 'solicitation_stage',
      text: 'Estágio',
      align: 'center',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => (
        <TooltipCustom title={row.solicitation_stage} placement="top" arrow>
          <div>
            <BadgeGT variant={row.solicitation_stage_slug} circle />
          </div>
        </TooltipCustom>
      ),
      headerStyle: { width: 40, textAlign: 'center' },
    },
  ]

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        toastSuccess={configAlert.toastSuccess}
      />
      <AlertDotStage
        show={showAlertDotStage}
        onConfirm={() => setShowAlertDotStage(false)}
        onDismissed={() => setShowAlertDotStage(false)}
      />
      <Card border="secondary">
        <Card.Body>
          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <NavLink to="#" className="pl-5" onClick={() => setShowAlertDotStage(true)}>
                Legenda dos Estágios da Solicitação
              </NavLink>
            </Col>
          </Row>
          <Table
            data={myAttendance.data}
            noDataMessage={
              typeof search !== 'undefined'
                ? `Não foram encontrados resultados para o termo ${search}.`
                : `Você não possui solicitações em andamento.`
            }
            columns={columns}
            page={myAttendance.current_page}
            perPage={perPage}
            totalSize={myAttendance.total}
            orderByOptions={orderByOptions}
            orderByOnChange={(field) => setSortField(field)}
            orderByDirectionChange={(direction) => setOrdering(direction)}
            onSizePerPageChange={(perPage) => {
              setPerPage(perPage)
              setPage(1)
            }}
            onPageChange={(page) => setPage(page)}
            onTableChange={() => null}
            searchPlaceholder="Pesquise aqui"
            perPagePosition="top"
            actions={actionsTable}
            onSearch={(term) => setSearch(term)}
            searchValue={search}
            loading={listLoading}
          />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={8}>
              <Button
                variant="secondary"
                className="mr-5"
                onClick={() => history.push('/solicitacao/atendimentos/encerrados')}
              >
                Solicitações Encerradas
              </Button>
              <Button
                variant="secondary"
                className="mr-5"
                onClick={() => history.push('/solicitacao/atendimentos/cancelados')}
              >
                Solicitações Canceladas
              </Button>
              <Button variant="primary" className="mr-5" onClick={() => history.push('/solicitacao/disponiveis')}>
                Solicitações Disponíveis em {myAttendance.userUnit}
              </Button>
            </Col>
            <Col md={4} className="text-right">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>&nbsp;Voltar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  )
}
