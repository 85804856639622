import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RequirementViewDataTypeInfo } from '../../components/services/requirements/RequirementViewDataTypeInfo'
import { RequirementViewDataTypeForm } from '../../components/services/requirements/RequirementViewDataTypeForm'
import { RequirementViewDataTypeFile } from '../../components/services/requirements/RequirementViewDataTypeFile'
import { RequirementViewDataAttachmentsDownload } from '../../components/services/requirements/RequirementViewDataAttachmentsDownload'

import { getRequirement } from '../../_redux/services/requirement/requirementActions'

import BootstrapTable from 'react-bootstrap-table-next'

import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import TooltipCustom from '../../../../components/TooltipCustom'
import { useSubheader } from '../../../../../_metronic/layout'
import { AlertMessage } from '../../components/services/AlertMessage'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../../app/routes/Helpers'

export function RequirementViewPage() {
  const history = useHistory()
  const dispatch = useDispatch()

  const pathname = window.location.pathname

  const { id1, id2, id3 } = useParams()
  const { user } = useSelector((state) => state.auth)
  const { systemFormsList, encryptedListFormHashDemo, viewRequirement } = useSelector((state) => state.requirements)

  if (systemFormsList.length === 0 || encryptedListFormHashDemo.length === 0) {
    history.push(`/dashboard`)
  }

  const form_hash =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.form_hash : null

  const subheader = useSubheader()
  subheader.setTitle(getCurrentPageTitleSubTitle(pathname).title)
  subheader.setSubtitle(getCurrentPageTitleSubTitle(pathname).subTitle)

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(getRequirement(id1, id2))
    // eslint-disable-next-line
  }, [])

  if (typeof viewRequirement === 'undefined') {
    history.push(`/servico`)
  }

  const versions = viewRequirement.otherVersions
  const requirement_code = viewRequirement.code
  const requirement_description =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.description : null
  const requirement_service_code =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.service.code : null
  const requirement_service_description =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.service.description : null
  const requirement_type =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.type.type : null
  const requirement_type_slug =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.type.slug : null

  const requirement_info_message =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.info_message : null

  const requirement_read_confirmation =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.read_confirmation : null

  const requirement_max_amount_files =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.max_amount_files : null

  const requirement_acceptable_file_types =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.acceptable_file_types : null

  const requirement_attachments =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.attachments : []

  const requirement_discontinued =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.discontinued : false

  const requirement_discontinued_moment =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.discontinued_moment : null

  const requirement_discontinueBy =
    typeof viewRequirement.currentVersion !== 'undefined' ? viewRequirement.currentVersion.discontinueBy : null

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  /**
   * Função actionFormatter,
   *
   * Esta função altera o conteúdo de uma celula da tabela (Ações)
   * provida por <BootstrapTable />.  Neste caso,  incrementa
   * botões de ação.
   * @param {*} cell
   * @param {*} row //obj com dados da linha atual
   * @returns
   */
  const actionsFormatter = (cell, row) => (
    <>
      {!row.actualVersion && (
        <TooltipCustom title="Consultar Dados da Versão" placement="top" arrow>
          <Button
            className="mt-1 mb-1"
            variant="outline-secondary"
            size="sm"
            id={row.id}
            onClick={() => history.push(`/servico/${id1}/requisitos/versao/${row.id}/consulta`)}
          >
            <i className="fa fa-search"></i>
          </Button>
        </TooltipCustom>
      )}
    </>
  )

  /**
   * Definição das colunas da tabela
   * dataField é o campo da tabela do banco
   */
  const columns = [
    {
      dataField: 'moment',
      text: 'Momento de Cadastro',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '20%', textAlign: 'center' }
      },
    },
    {
      dataField: 'operation',
      text: 'Operação',
      align: 'center',
      sort: true,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '15%', textAlign: 'center', overflowWrap: 'anywhere' }
      },
    },
    {
      dataField: 'user.name',
      text: 'Usuário',
      align: 'left',
      sort: true,
      classes: 'align-middle',

      headerStyle: () => {
        return { width: '55%', textAlign: 'left' }
      },
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      isDummyField: false,
      classes: 'align-middle',
      headerStyle: () => {
        return { width: '10%' }
      },
      formatter: actionsFormatter,
    },
  ]

  const rowStyle2 = (row, rowIndex) => {
    const style = {}
    if (row.actualVersion) {
      style.backgroundColor = '#e1e9ff'
    } else {
      style.backgroundColor = '#FFF'
    }

    return style
  }

  let urlDemoForm = ''
  if (typeof encryptedListFormHashDemo.find((item) => item.form_hash === form_hash) !== 'undefined') {
    urlDemoForm = encryptedListFormHashDemo.find((item) => item.form_hash === form_hash)
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            <Row>
              <Col>
                {requirement_discontinued && (
                  <AlertMessage
                    variant="alert alert-custom alert-light-warning alert-dismissible"
                    message={`Este requisito foi arquivado em ${requirement_discontinued_moment} pelo usuário: ${requirement_discontinueBy}.`}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col className="mt-5">
                <h4>Informações</h4>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <dl className="row">
                  <dt className="col-sm-3 text-right">Código</dt>
                  <dd className="col-sm-9">{requirement_code}</dd>

                  <dt className="col-sm-3 text-right">Descrição</dt>
                  <dd className="col-sm-9">{requirement_description}</dd>

                  <dt className="col-sm-3 text-right">Serviço</dt>
                  <dd className="col-sm-9">{`${requirement_service_code} - ${requirement_service_description}`}</dd>

                  <dt className="col-sm-3 text-right">Tipo de Requisito</dt>
                  <dd className="col-sm-9">{requirement_type}</dd>
                </dl>
                {requirement_type_slug === 'informativo' && (
                  <RequirementViewDataTypeInfo
                    messageInfo={requirement_info_message}
                    readConfirmation={requirement_read_confirmation ? 'SIM' : 'NÃO'}
                  />
                )}
                {requirement_type_slug === 'formulario-eletronico' && (
                  <RequirementViewDataTypeForm
                    formName={`${requirement_code} - ${requirement_description} `}
                    formDescription={requirement_description}
                    formUrl={urlDemoForm.linkFormDemo}
                    formOriginalName={''}
                  />
                )}
                {requirement_type_slug === 'envio-arquivos' && (
                  <RequirementViewDataTypeFile
                    qtdMaxFileUpload={requirement_max_amount_files}
                    acceptablefileType={requirement_acceptable_file_types}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="mt-5">
                <h4>Arquivos anexados</h4>
                <hr />
                <RequirementViewDataAttachmentsDownload attachments={requirement_attachments} />
              </Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={11}>{/* attachments aqui */}</Col>
            </Row>
            <Row>
              <Col className="mt-5">
                <h4>Versões do Registro do Requisito</h4>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col>
                <>
                  <Row>
                    <Col sm={9}></Col>
                    <Col sm={3}>
                      <div className="d-grid"></div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BootstrapTable
                        classes="bordered"
                        keyField="id"
                        data={typeof versions !== 'undefined' ? versions : []}
                        columns={columns}
                        rowStyle={rowStyle2}
                        headerClasses="text-center bg-light"
                        rowClasses="text-center"
                        bordered={true}
                        hover
                        noDataIndication="Não existem requisitos para o serviço"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col className="text-right">
                      {typeof id3 !== 'undefined' && (
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() => history.push(`/servico/${id1}/consulta`)}
                          className="font-weight-bold"
                        >
                          <i className="fa fa-chevron-left"></i>
                          &nbsp;&nbsp;Voltar para o Serviço
                        </Button>
                      )}
                      {typeof id3 === 'undefined' && (
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() => history.push(`/servico/${id1}/requisitos`)}
                          className="font-weight-bold"
                        >
                          <i className="fa fa-chevron-left"></i>
                          &nbsp;&nbsp;Voltar
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}
