import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Alert, Col, Row, Button, Form, Container } from 'react-bootstrap'
import { Formik } from 'formik'
import validationSchema from './validationSolicitationStepTwoSchema'
import { useSubheader } from '../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../app/routes/Helpers'
import { toast } from 'react-toastify'
import { Typography } from '../../../components'
import BasicSausegeCard from '../../../components/Card/BasicSausegeCard'
import {
  stepThree,
  setUploadFiles,
  deleteUploadedFileSolicitation,
  updateFileDescription,
  finishStepThree,
  solicitationWasNotCompleted,
  clearSaveError,
  setRequirementRead,
} from '../_redux/solicitation/solicitationActions'
import Accordion from '../../../components/Accordion'
import Dropzone from '../../../components/Dropzone'
import RequirementsContentForm from '../components/solicitation/RequirementsContentForm'
import { cleanFormsHash, getHash, systemFormsLogin } from '../_redux/services/requirement/requirementActions'
import formObject from '../../../Utils/formObject'

const SolicitationStepThreePage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = props.location

  const { user, authToken } = useSelector((state) => state.auth)

  const { saveError, saveStatus, statusMessage, solicitationSelected, solicitationInProgress } = useSelector(
    (state) => state.solicitations
  )

  const { systemFormsToken } = useSelector((state) => state.requirements)

  const subheader = useSubheader()
  //const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  if (solicitationSelected && !solicitationSelected.id && solicitationInProgress && !solicitationInProgress.id) {
    history.push('/solicitacao/novo/etapa-um')
  }

  const [alertError, setAlertError] = React.useState(saveError)

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  // Quando for um "Admin de Unidades"
  let authUserIsUnitsAdmin = false

  if (pathname.split('/').pop() === 'admin-unidade') {
    if (!user.roles.includes('admin-unidade')) {
      history.push(`/401-unauthorized`)
    } else {
      authUserIsUnitsAdmin = true
    }
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(clearSaveError())
    if (solicitationInProgress && solicitationInProgress.id) {
      dispatch(stepThree(solicitationInProgress.id))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (typeof solicitationInProgress.requirements !== 'undefined' && systemFormsToken !== null) {
      if (solicitationInProgress.requirements.length > 0) {
        dispatch(cleanFormsHash())
        solicitationInProgress.requirements.map((item) => {
          if (item.type.slug === 'formulario-eletronico') {
            dispatch(
              getHash(
                formObject(
                  solicitationInProgress.user.email,
                  solicitationInProgress.id,
                  solicitationInProgress.code,
                  item.code,
                  'http://localhost:3000',
                  item.form_hash,
                  solicitationInProgress.intangibleAssetOriginParent.code,
                  solicitationInProgress.service.code
                ),
                systemFormsToken,
                item.code
              )
            )
          }
          return true
        })
      }
    }
    // eslint-disable-next-line
  }, [solicitationInProgress])

  useEffect(() => {
    setAlertError(saveError)
    // eslint-disable-next-line
  }, [saveError])

  useEffect(() => {
    if (systemFormsToken == null) {
      dispatch(
        systemFormsLogin(
          process.env.REACT_APP_API_FORMS_LOGIN_USEREMAIL,
          process.env.REACT_APP_API_FORMS_LOGIN_PASSWORD
        )
      )
    }
    // eslint-disable-next-line
  }, [saveError])

  if (saveStatus === 'finish') {
    toast.success(`Sucesso! ${statusMessage}`, {})
    if (solicitationInProgress.solicitation_admin_units_user_id === null) {
      history.push('/solicitacoes')
    } else {
      history.push('/administracao/unidades/gerencia')
    }
  }
  // eslint-disable-next-line
  let intangibleTitleText
  let serviceTitleText
  let solicitationDescription
  let requirements

  if (solicitationInProgress && solicitationInProgress.intangibleAssetOriginParent) {
    intangibleTitleText = `${solicitationInProgress.intangibleAssetOriginParent.code} - ${solicitationInProgress.intangibleAssetOriginParent.title}`
    serviceTitleText = `${solicitationInProgress.service.code} - ${solicitationInProgress.service.title}`
    solicitationDescription = solicitationInProgress.description
    requirements = solicitationInProgress.requirements
  }

  useEffect(() => {
    function cleanAlertMessage() {
      if (solicitationInProgress.requirements && solicitationInProgress.requirements.length > 0) {
        let allRequirementsAnswered = true
        solicitationInProgress.requirements.forEach((requirement) => {
          if (!requirement.requirement_was_answered) {
            allRequirementsAnswered = false
          }
        })
        if (allRequirementsAnswered) setAlertError(null)
      }
    }

    cleanAlertMessage()
  }, [solicitationInProgress.requirements])

  const [attachments, setAttachments] = useState([])

  const renderRequirementsComponent = (requirement, solicitationId) => {
    switch (requirement.type.slug) {
      case 'informativo': {
        return <RequirementsContentForm requirement={requirement} solicitationId={solicitationId} />
      }

      case 'envio-arquivos': {
        return (
          <>
            <Dropzone
              setAttachments={setAttachments}
              formByFile="FILE_DESCRIPTION"
              uploadedFiles={requirement.uploadedRequirementFiles}
              onAutoSave={(file, fileDescription, fileIsSaved) => {
                if (fileIsSaved) {
                  dispatch(
                    updateFileDescription({
                      description: fileDescription,
                      solicitationUploadFileId: file.solicitation_upload_files_id,
                    })
                  )
                } else {
                  dispatch(
                    setUploadFiles({
                      solicitationId: solicitationId,
                      requirementVersionId: requirement.requirement_version_id,
                      fileDescription: fileDescription,
                      attachment: file,
                    })
                  )
                }
              }}
              onDeleteFile={(file) => {
                dispatch(
                  deleteUploadedFileSolicitation(file.solicitation_upload_files_id, requirement.requirement_version_id)
                )
              }}
              acceptableFileTypes={requirement.acceptable_file_types.split(',')}
              maxAmountFiles={requirement.max_amount_files}
              filesInfoMessage={[
                `Tipos de arquivos válidos: ${requirement.acceptable_file_types.split(',').join(', ')}`,
                `Quantidade máxima de arquivos: ${requirement.max_amount_files}`,
                `Tamanho máximo de cada arquivo: 15MB`,
              ]}
            />
            <RequirementsContentForm requirement={requirement} solicitationId={solicitationId} />
          </>
        )
      }

      case 'somente-anexos': {
        return <RequirementsContentForm requirement={requirement} solicitationId={solicitationId} />
      }

      case 'formulario-eletronico': {
        return (
          <RequirementsContentForm
            requirement={requirement}
            solicitationId={solicitationId}
            solicitationCode={solicitationInProgress.code}
            userEmail={user.email}
            hashForm={requirement.form_hash}
            token={authToken}
            formToken={systemFormsToken}
            route={pathname}
            intangibleAsset={`${solicitationInProgress.intangibleAssetOriginParent.code} - ${solicitationInProgress.intangibleAssetOriginParent.title}`}
            serviceData={`${solicitationInProgress.service.code} - ${solicitationInProgress.service.title}`}
          />
        )
      }

      default:
        return <></>
    }
  }

  const mountAccordionRequirements = () => {
    if (!requirements) {
      return
    }

    const hasUnsavedFile = attachments.some((file) => !file.isSaved)

    return requirements.map((requirement) => {
      let isAnswared
      let handleCick

      if (requirement.type.slug === 'envio-arquivos') {
        const hasAtLeastOneFile = requirement.uploadedRequirementFiles.length > 0
        const hasFileWithoutDescription = requirement.uploadedRequirementFiles.some(
          (file) => !file.description || file.description === ''
        )
        const hasFileMinAllowedCharCount = requirement.uploadedRequirementFiles.some(
          (file) => file.description && file.description.length < 3
        )

        isAnswared =
          requirement.requirement_was_answered &&
          !hasUnsavedFile &&
          hasAtLeastOneFile &&
          !hasFileWithoutDescription &&
          !hasFileMinAllowedCharCount
      } else if (requirement.type.slug === 'informativo' || requirement.type.slug === 'somente-anexos') {
        if (!requirement.requirement_read) {
          handleCick = () => {
            dispatch(
              setRequirementRead({
                solicitationId: solicitationInProgress.id,
                requirementVersionId: requirement.requirement_version_id,
              })
            )
          }
        }

        isAnswared = requirement.requirement_was_answered
      } else {
        isAnswared = requirement.requirement_was_answered
      }

      return {
        isAnswared: isAnswared,
        showBoxAnswered: true,
        headerText: requirement.description,
        bodyComponent: () => renderRequirementsComponent(requirement, solicitationInProgress.id),
        handleCick: handleCick,
      }
    })
  }

  const handleCreateSolicitation = () => {
    const hasUnsavedFile = attachments.some((file) => !file.isSaved)
    const haveUnmetRequirement = mountAccordionRequirements().some((requirement) => !requirement.isAnswared)

    if (hasUnsavedFile) {
      dispatch(
        solicitationWasNotCompleted([
          {
            fileDescription: ['O campo "<strong>Descrição do Arquivo</strong>" é de preenchimento obrigatório.'],
          },
        ])
      )
    } else if (!solicitationInProgress.all_requirements_was_answered || haveUnmetRequirement) {
      dispatch(
        solicitationWasNotCompleted([
          {
            finish: ['Todos os requisitos precisam ser atendidos.'],
          },
        ])
      )
    } else {
      dispatch(finishStepThree(solicitationInProgress.id))
    }
  }

  let subTextSousegeCard
  let titleTextSousegeCard
  let infoOnesubTextInfoOne
  let infoTwosubTextInfoTwo

  const hasSolicitationInProgress = Object.keys(solicitationInProgress).length > 0
  const sourceOrganization = solicitationInProgress.solicitation_organization

  if (hasSolicitationInProgress) {
    const hasSolicitationParent = solicitationInProgress.parentType === 'Solicitation'

    subTextSousegeCard = hasSolicitationParent ? 'Solicitação de Origem' : 'Ativo Intangível de Origem'
    titleTextSousegeCard =
      solicitationInProgress.parent &&
      `${solicitationInProgress.parent.code} - ${
        hasSolicitationParent ? solicitationInProgress.parent.description : solicitationInProgress.parent.title
      }`

    infoOnesubTextInfoOne =
      solicitationInProgress.parent &&
      `${solicitationInProgress.parent.user.name} (${solicitationInProgress.parent.user.email})`

    infoTwosubTextInfoTwo = solicitationInProgress.parent && `${solicitationInProgress.parent.organization.initials}`
  }

  return (
    <>
      <Card border="secondary">
        <Card.Body>
          <Container>
            {alertError && alertError.length > 0 && (
              <Alert variant="danger">
                {Object.values(alertError[0]).map((item, i) => (
                  <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
                ))}
              </Alert>
            )}
            {alertError && alertError.message && (
              <Alert variant="danger">
                {alertError.message && <div dangerouslySetInnerHTML={{ __html: alertError.message }}></div>}
              </Alert>
            )}
            <Row>
              <Col className="text-right">
                <Typography variant="stage">Etapa 3 de 3</Typography>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {sourceOrganization ? (
                  <BasicSausegeCard
                    subText={'Unidade de Origem da Nova Solicitação'}
                    titleText={`${sourceOrganization.initials} - ${sourceOrganization.name}`}
                  />
                ) : (
                  '<></>'
                )}

                <BasicSausegeCard
                  subText={subTextSousegeCard}
                  titleText={titleTextSousegeCard}
                  subTextInfoOne={infoOnesubTextInfoOne}
                  subTextInfoTwo={infoTwosubTextInfoTwo}
                />

                <BasicSausegeCard subText={'Serviço'} titleText={serviceTitleText} />

                <Accordion
                  defaultFirstCollapseOpen
                  items={[
                    {
                      headerIcon: <i className="far fa-list-alt"></i>,
                      headerText: 'Descrição de Solicitação',
                      bodyText: solicitationDescription,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h6>Requisitos</h6>
                {requirements && requirements.length > 0 ? (
                  <Accordion items={mountAccordionRequirements()} />
                ) : (
                  <Alert variant="alert alert-custom alert-light-primary alert-dismissible">
                    Não existem requisitos para este serviço.
                  </Alert>
                )}
              </Col>
            </Row>

            <Formik
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log('')
              }}
              initialValues={{}}
            >
              {(formik) => (
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col sm={12}></Col>
                  </Row>
                  <Row className="mt-0">
                    <Col className="text-left">
                      <Button type="submit" className="font-weight-bold" onClick={() => handleCreateSolicitation()}>
                        Criar Solicitação
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                          const linkBack = authUserIsUnitsAdmin
                            ? '/solicitacao/novo/etapa-dois/admin-unidade'
                            : '/solicitacao/novo/etapa-dois'
                          history.push(linkBack)
                        }}
                        className="font-weight-bold"
                      >
                        <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                      </Button>{' '}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}

export default SolicitationStepThreePage
