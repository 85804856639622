import React from 'react'
import { Card } from 'react-bootstrap'

export const SolicitationsServicePanel = (props) => (
  <Card border="secondary" bg="light-secondary" style={{ width: '280px', minWidth: '300px', height: '200px' }}>
    <Card.Body className="">
      <h4>Solicitações do Serviço</h4>
      <dl className="row">
        <dt className="col-sm-2">
          <span className="text-primary font-weight-bold">{props.SolicitationsServicesWaitingAttendance}</span>
        </dt>
        <dd className="col-sm-10">Aguardando atendimento</dd>

        <dt className="col-sm-2">
          <span className="text-primary font-weight-bold">{props.SolicitationsServicesInAttendance}</span>
        </dt>
        <dd className="col-sm-10">Em Atendimento</dd>

        <dt className="col-sm-2">
          <span className="text-info font-weight-bold">{props.SolicitationsServiceCompleted}</span>
        </dt>
        <dd className="col-sm-10">Atendimento Concluído</dd>

        <dt className="col-sm-2">
          <span className="text-info font-weight-bold">{props.SolicitationsDisapprovedService}</span>
        </dt>
        <dd className="col-sm-10">Atendimento com pendência</dd>
      </dl>
    </Card.Body>
  </Card>
)
