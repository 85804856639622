import React from 'react'
import { Form } from 'react-bootstrap'

import { RequiredDownloadWrapper } from './FormByFile.styles'

const RequiredDownload = ({ requiredDownloadsAtt, setRequiredDownloadsAtt, file, fileIndex }) => {
  const setRequiredDownloadHanlder = (attachmentUuid, newValue) => {
    const index = requiredDownloadsAtt.findIndex((e) => e.uuid === attachmentUuid)
    const item = {
      uuid: attachmentUuid,
      value: newValue,
    }
    if (index !== -1) {
      requiredDownloadsAtt.splice(index, 1, item)
      setRequiredDownloadsAtt([...requiredDownloadsAtt])
    } else {
      setRequiredDownloadsAtt([...requiredDownloadsAtt, item])
    }
  }

  const requiredDownloadIsChecked = (fileUuid) => {
    const requiredDownload = requiredDownloadsAtt.find((element) => element.uuid === fileUuid)
    return requiredDownload ? requiredDownload.value : null
  }

  return (
    <RequiredDownloadWrapper key={`requiredDownload-${file.uuid}`}>
      <Form.Group>
        <Form.Label>
          <strong>Download Obrigatório?</strong>
        </Form.Label>
        &nbsp;&nbsp;
        <Form.Check
          id={`radio-read-informative${fileIndex}`}
          label={'Sim'}
          name={`requiredDownload${fileIndex}`}
          type="radio"
          value={1}
          onChange={(e) => {
            setRequiredDownloadHanlder(file.uuid, true)
          }}
          checked={requiredDownloadIsChecked(file.uuid)}
          inline
        />
        <Form.Check
          id={`radio-not-read-informative${fileIndex}`}
          label={'Não'}
          name={`requiredDownload${fileIndex}`}
          type="radio"
          value={0}
          onChange={(e) => {
            setRequiredDownloadHanlder(file.uuid, false)
          }}
          checked={requiredDownloadIsChecked(file.uuid) != null && !requiredDownloadIsChecked(file.uuid)}
          inline
        />
      </Form.Group>
    </RequiredDownloadWrapper>
  )
}

export default RequiredDownload
