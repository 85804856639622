import styled from 'styled-components'

export const RelatedItemContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 1.2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  height: 30px;
  width: 100%;
`

export const TitleItem = styled.span`
  font-size: 1.2rem;
  font-weight: bolder;
`

export const SubTitleItem = styled.span`
  font-size: 1rem;
`
