import React, { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useSubheader } from '../../../../_metronic/layout'
import { getCurrentPageTitleSubTitle } from '../../../routes/Helpers'
import AlertWelcome from '../../../components/AlertWelcome'
import Cards from '../components/dashboard/Cards'
import Functionalities from '../components/dashboard/Functionalities'
import { stepOne } from '../_redux/solicitation/solicitationActions'
import { getDashboard } from '../_redux/dashboard/dashboardActions'
import {
  cleanFormsHash,
  getEletronicForms,
  getHashDemo,
  setReloadForms,
  systemFormsLogin,
} from '../_redux/services/requirement/requirementActions'
import formObjectDemo from '../../../Utils/formObjectDemo'

function SolicitationsDashboard() {
  const dispatch = useDispatch()
  const { user, authToken } = useSelector((state) => state.auth)

  const CLIENT_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_CLIENT_URL_HOMOL}`
      : `${process.env.REACT_APP_CLIENT_URL_PROD}`
  const { first_user_login, temporary_block } = useSelector((state) => state.dashboard)
  const {
    systemFormsToken,
    systemFormsHash,
    systemFormsList,
    encryptedListFormHashDemo,
    reloadListOfElectronicForms,
  } = useSelector((state) => state.requirements)

  const [show, setShow] = React.useState(first_user_login ? first_user_login : temporary_block)

  React.useEffect(() => setShow(first_user_login ? first_user_login : temporary_block), [
    first_user_login,
    temporary_block,
  ])

  const pathname = window.location.pathname
  const subheader = useSubheader()
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  useEffect(() => {
    dispatch(stepOne())
    if (show === false) {
      dispatch(getDashboard())
      dispatch(cleanFormsHash())
    }
    if (systemFormsToken === null) {
      dispatch(
        systemFormsLogin(
          process.env.REACT_APP_API_FORMS_LOGIN_USEREMAIL,
          process.env.REACT_APP_API_FORMS_LOGIN_PASSWORD
        )
      )
    }
    if (systemFormsHash !== null && typeof user.roles.find((item) => item === 'admin') !== 'undefined') {
      if (systemFormsList.length === 0 && reloadListOfElectronicForms) {
        dispatch(getEletronicForms(systemFormsHash, systemFormsToken))
        dispatch(setReloadForms(false)) //Define que o carregamento de lista de formulários eletrônicos não é necessário
      }
    }
    // eslint-disable-next-line
  }, [dispatch, show])

  useEffect(() => {
    /**
     * Verifica se a lista de formulários do estado foi carregada e se a lista
     * descartáveis de demonstração esta vazia.
     */
    if (systemFormsList.length > 0 && encryptedListFormHashDemo.length === 0) {
      systemFormsList.map((item) => {
        /**
         * Dispara a action getFormDemo para cada item de formulário eletrônico.
         * Isso vai criar o estado um array de links para exemplo de formulários (encryptedListFormHashDemo)
         */
        let randomNumber = parseInt(Math.random() * 100)
        let randomString = String.fromCharCode(65 + Math.floor(Math.random() * 26))
        let baseUrlString = String(CLIENT_BASE_URL)
        dispatch(
          getHashDemo(
            formObjectDemo(`teste_${randomString}@fiocruz.br`, randomNumber, baseUrlString, item.hash, true),
            systemFormsToken,
            item.hash
          )
        )
        return true
      })
    }
    // eslint-disable-next-line
  }, [systemFormsList])

  return (
    <>
      <Card border="secondary" className="pt-5 pr-5">
        <Card.Body>
          <Container>
            <Cards />
            <hr />
            <Functionalities />
          </Container>
        </Card.Body>
      </Card>
      <AlertWelcome
        show={show}
        onConfirm={() => {
          dispatch(getDashboard())
          setShow(false)
        }}
        onDismissed={() => setShow(false)}
        userName={user.info.firstname}
        messageType={temporary_block ? 'form' : 'message'}
        idUser={user.id}
        objUser={user}
        stateToken={authToken}
      />
    </>
  )
}

export default SolicitationsDashboard
