import React from 'react'
import { Link, Switch, Redirect } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { ContentRoute } from '../../../../_metronic/layout'
import Login from './Login'
import Registration from './Registration'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import PageRedirectLogin from './PageRedirectLogin'
import PageQuickLogin from './PageQuickLogin'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import UserFinish from '../../Admin/pages/user/UserFinish'
import MailConfirmationPage from './MailConfirmationPage'

export function AuthPage() {
  const API_BASE_API_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  const API_BASE_CLIENT_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_CLIENT_URL_HOMOL}`
      : `${process.env.REACT_APP_CLIENT_URL_PROD}`

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4-new.jpg')})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl('/media/logos/logo-letter-1.png')} />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">Bem-vindo ao SISGESTEC!</h3>
                <p className="font-weight-lighter text-white opacity-80">
                  Sistema de Gestão Tecnológica da GESTEC.
                  <br />
                  Desenvolvido em parceria com o CDTS/Fiocruz.
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">&copy; 2024 CDTS/Fiocruz</div>
                <div className="d-flex">
                  <Link to="/auth/login" className="text-white ml-10">
                    Versão: 0.16.2 - dev <small>(26/03/2024)</small>
                    <br />
                    Amb.: {`${process.env.NODE_ENV}`}
                  </Link>
                  {/* <Link to="/terms" className="text-white ml-10">
                    Contato
                  </Link> */}
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/*
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">Não tem uma conta ainda?</span>
              <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                Cadastre-se!
              </Link>
            </div>
          */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login/:userstatus?" component={Login} />
                <ContentRoute path="/auth/registration" component={Registration} />
                <ContentRoute path="/acesso-direto/:hash" component={UserFinish} />
                <ContentRoute path="/auth/forgot-password/:pageStatus?" component={ForgotPassword} />
                <ContentRoute path="/auth/rsp/:token" component={ResetPassword} />
                <ContentRoute path="/adm/rsp/:token" component={ResetPassword} />
                <ContentRoute path="/auth/login-api/:sessionid/:token" component={PageRedirectLogin} />
                <ContentRoute path="/auth/quick-login/:hash" component={PageQuickLogin} />
                <ContentRoute path="/auth/mail_confirmation/:hash" component={MailConfirmationPage} />
                <ContentRoute
                  exact
                  path="/terms"
                  render={() => {
                    window.location.href = `${API_BASE_API_URL}/api/attachment/download_term`
                    setTimeout(() => {
                      return (window.location.href = API_BASE_CLIENT_URL)
                    }, 100)
                  }}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">&copy; 2021 CDTS/Fiocruz</div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/auth/login" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link to="/auth/login" className="text-dark-75 text-hover-primary ml-4">
                  Jurídico
                </Link>
                <Link to="/auth/login" className="text-dark-75 text-hover-primary ml-4">
                  Contato
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
