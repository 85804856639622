import * as requestFromServer from './adminEndpoints'
import { adminSlice } from './adminSlice'

const { actions } = adminSlice

/**
 * Retorna da api um objeto com todos
 * usuarios (não deletado logicamente) do sistema
 * @returns
 */
export const allUsers = () => (dispatch) => {
  dispatch(actions.setStatusMessage('Carregando lista de usuarios'))
  return requestFromServer
    .getAllUsersFull()
    .then((response) => {
      dispatch(actions.getUsers(response.data))
      //dispatch(allSimplifiedUserList())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Retorna da api um objeto com todos os usuarios do sistema com seu perfil,
 * todos os serviços e aqueles que estão marcado como atendente e
 * todos os serviços e aqueles que estão marcados como administradores
 *
 * @param {*} id
 * @returns
 */
export const allRolesAccessServicesByUser = (id) => (dispatch) => {
  dispatch(actions.setStatusMessage('Carregando permissões de acesso e serviços .....'))
  return requestFromServer
    .getAccessRoleAndServicesByUser(id)
    .then((response) => {
      dispatch(actions.getPermissionsRolesAndService(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Atualizar dados de permissão, perfil e serviços como atendente e como administrador
 * @param {*} id
 * @param {*} allPermissionsServicesRolesObj
 * @returns
 */
export const editAllRolesAccessServicesByUser = (id, allPermissionsServicesRolesObj) => (dispatch) => {
  dispatch(actions.setStatusMessage('Atualizando permissões, acessos, e serviços...'))
  return requestFromServer
    .editPermissions(id, allPermissionsServicesRolesObj)
    .then((response) => {
      dispatch(actions.setStatusMessage(response.data.message))
      dispatch(actions.editUserPermissionsRolesServiceUser(response.data.obj))
      dispatch(actions.setStatusMessage('Permissões, acessos, e Serviços atualizados.'))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/**
 * Habilita um usuario no sistema, passado seu id
 * @param {*} id
 * @returns
 */
export const enableUser = (id) => (dispatch) => {
  return requestFromServer
    .enableUser(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('userEnabled'))
      dispatch(actions.enabledUser(response.data))
      dispatch(allUsers())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Desabilita um usuário no sistema
 * @param {*} id
 * @returns
 */
export const disableUser = (id) => (dispatch) => {
  return requestFromServer
    .disableUser(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('disableEnabled'))
      dispatch(actions.disabledUser(response.data))
      dispatch(allUsers())
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}

/** */
export const getTasksVisibleAdmin = (per_page, page, search, sort_field, ordering) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getTasksVisibleAdmin(per_page, page, search, sort_field, ordering)
    .then((response) => {
      dispatch(actions.getTaks(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Limpa a propriedade lastError
 * @returns
 */
export const clearLastError = () => (dispatch) => {
  return dispatch(actions.clearLastError())
}

/**
 * Limpa a propriedade saveErro
 * @returns
 */
export const clearSaveError = () => (dispatch) => {
  return dispatch(actions.clearSaveError())
}

/**
 * Limpa o status
 * @returns
 */
export const cleanSaveStatus = () => (dispatch) => {
  return dispatch(actions.setStatusMessage({ status: null, message: null }))
}

/**
 * Carga inicial dos elementos do formulário de Cadastro de Usuários.
 * @returns
 */
export const getCreateFormData = () => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getCreateFormData()
    .then((response) => {
      dispatch(actions.setFormData(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Carga inicial dos elementos do formulário de atualização Cadastro de Usuários.
 * @returns
 */
export const getUpdateFormData = (id) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .getUpdateFormData(id)
    .then((response) => {
      dispatch(actions.setFormData(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Prepara estado global para altualização de senha do usuáirio.
 * @returns
 */
export const prepState = (id) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  dispatch(actions.setListLoading(false))
  dispatch(actions.clearSaveError())
}

/**
 * Cadastro de novo usuário.
 * @returns
 */
export const createUser = ({ name, email, cpf, date_of_birth, phone, organization }) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .createUser({ name, email, cpf, date_of_birth, phone, organization })
    .then((response) => {
      dispatch(actions.setListLoading(false))
      dispatch(actions.setStatusMessage({ status: response.data.status, message: response.data.message }))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response && error.response.data && !error.response.data.exception
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Atualizar senha do Usuári atualmente logado.
 * @returns
 */
export const actionUserChangePassword = ({ actual_password, password }) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .userChangePassword({ actual_password, password })
    .then((response) => {
      dispatch(actions.setListLoading(false))
      dispatch(actions.setStatusMessage({ status: response.data.status, message: response.data.message }))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}
export const actionUserChangePhone = ({ phone }) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .userChangePhone({ phone })
    .then((response) => {
      dispatch(actions.setListLoading(false))

      dispatch(actions.setStatusMessage({ status: response.data.status, message: response.data.message }))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Atualização de usuário.
 * @returns
 */
export const updateUser = ({ id, name, email, cpf, date_of_birth, phone, organization }) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .updateUser({ id, name, email, cpf, date_of_birth, phone, organization })
    .then((response) => {
      dispatch(actions.setListLoading(false))
      dispatch(actions.setStatusMessage({ status: response.data.status, message: response.data.message }))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response && error.response.data && !error.response.data.exception
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Atualização de usuário.
 * @returns
 */
export const updateFinishUser = (hash, password) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .updateFinishUser(hash, password)
    .then((response) => {
      dispatch(actions.setStatusMessage({ status: response.data.status, message: response.data.message }))
      dispatch(actions.setFormData({}))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(
        actions.catchError(
          error.response && error.response.data
            ? error.response.data
            : { message: 'Estamos com alguma indisponibilidade. Tente novamente em instantes.', status: 'error', error }
        )
      )
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Carga inicial dos elementos do formulário de atualização Cadastro de Usuários.
 * @returns
 */
export const getFinishUser = (hash) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .finishUser(hash)
    .then((response) => {
      dispatch(actions.setFormData(response.data))
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}
/**
 * Validação de hashmail - reset password (logged).
 * @returns
 */
export const verifyHashMail = (hashmail) => (dispatch) => {
  dispatch(actions.setListLoading(true))
  return requestFromServer
    .verifyHashmailUrl(hashmail)
    .then(() => {
      dispatch(actions.clearPassResetTokenErrorr())
      dispatch(actions.setListLoading(false))
    })
    .catch((error) => {
      dispatch(actions.catchResetPassTokenError(error.response.data))
      dispatch(actions.setListLoading(false))
    })
}

/**
 * Histórico de Unidades que um usuário passou
 * @param {*} id
 * @returns
 */
export const userOrganizationHistoryList = (id) => (dispatch) => {
  return requestFromServer
    .userOrganizationHistory(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('Lista carregada'))
      dispatch(actions.getUsersOrganizations(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
/**
 * Dados de consulta de Usuário
 * @param {*} id
 * @returns
 */
export const userViewDataAction = (id) => (dispatch) => {
  return requestFromServer
    .userDataViewEndpoint(id)
    .then((response) => {
      dispatch(actions.setStatusMessage('Lista carregada'))
      dispatch(actions.setUserViewData(response.data))
    })
    .catch((error) => {
      dispatch(actions.catchError(error.response.data))
    })
}
