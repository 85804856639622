import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  auditSelected: {},
  lastError: null,
  auditList: [],
  lastPermissionsChange: [],
  saveStatus: null,
  statusMessage: null,
  saveError: [],
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const auditSlice = createSlice({
  name: 'audit',
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.actionsLoading = false
      state.saveStatus = 'error'
      state.lastError = action.payload
      state.saveError = action.payload
      state.statusMessage = action.payload ? action.payload.message : null
    },

    clearLastError: (state) => {
      state.lastError = null
    },

    clearSaveError: (state) => {
      state.saveError = null
    },

    setStatusMessage: (state, action) => {
      state.saveStatus = action.payload ? action.payload.status : null
      state.statusMessage = action.payload ? action.payload.message : null
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setAuditSelected: (state, action) => {
      state.auditSelected = action.payload
    },

    setAuditList: (state, action) => {
      state.auditList = action.payload
      state.loading = false
      state.saveError = []
      state.saveStatus = null
      state.error = null
    },
  },
})
