import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Col, Row, Button, Container } from 'react-bootstrap'
import { useSubheader } from '../../../../_metronic/layout'
import { getAllBreadcrumbs, getCurrentPageTitleSubTitle, hasPermission } from '../../../../app/routes/Helpers'
import { toast } from 'react-toastify'
import {
  clearSaveError,
  cleanSaveStatus,
  getTaskInteractionsData,
  createTaskInteraction,
  deleteTaskInteraction,
  finishTask,
  cancelTask,
} from '../_redux/tasks/tasksActions'
import { AlertGT, BadgeGT, BlockInfo, Button as ButtonGT } from '../../../components'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { ContentRow } from '../../Solicitation/components/attendances/HeaderSolicitationManagement/HeaderSolicitationManagement.styles'
import TooltipCustom from '../../../components/TooltipCustom'
import axios from 'axios'
import CancelTaskForm from '../components/CancelTaskForm'
import CreateTaskInteractionForm from '../components/CreateTaskInteractionForm'
import SolicitationDataModal from '../components/SolicitationDataModal'
import IntangibleAssetDataModal from '../components/IntangibleAssetDataModal'
import { Link } from '../../../components/Card/BasicSausegeCard/BasicSausegeCard.styles'
import CardInterectionsMessages from '../components/CardInterectionsMessages'
import { truncate } from 'lodash'

const handleDownload = (idDownload, original_name) => {
  const API_BASE_URL =
    `${process.env.NODE_ENV}` === 'development'
      ? `${process.env.REACT_APP_API_URL_HOMOL}`
      : `${process.env.REACT_APP_API_URL_PROD}`

  axios({
    url: `${API_BASE_URL}/api/attachment/${idDownload}/download`,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', original_name)
    document.body.appendChild(link)
    link.click()
  })
}

const TaskInteractionsPage = () => {
  const { id1: taskId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTaskInteractionsData(taskId))
    // eslint-disable-next-line
  }, [taskId])

  const { user } = useSelector((state) => state.auth)

  const { saveError, listLoading, statusMessage, taskData } = useSelector((state) => state.tasks)

  const subheader = useSubheader()
  const pathname = window.location.pathname
  const currentPage = getCurrentPageTitleSubTitle(pathname)
  subheader.setTitle(currentPage.title)
  subheader.setSubtitle(currentPage.subTitle)

  const [configAlert, setConfigAlert] = useState({
    show: false,
    title: '',
    text: '',
    icon: null,
    width: 600,
    cancelButton: null,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirm: null,
    toastSuccess: '',
    closeToast: false,
  })

  /**
   * Verifica se há permissão de acesso
   */
  const arrRoles = user.roles
  if (!hasPermission(pathname, arrRoles)) {
    history.push(`/403-forbidden`)
  }

  useEffect(() => {
    subheader.setBreadcrumbs(getAllBreadcrumbs(pathname))
    dispatch(clearSaveError())
    localStorage.setItem('forumMessage', '')
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (statusMessage) {
      toast.success(() => <div dangerouslySetInnerHTML={{ __html: statusMessage }} />)
    }
    dispatch(cleanSaveStatus())
    // eslint-disable-next-line
  }, [statusMessage])

  React.useEffect(() => {
    if (typeof saveError === 'object' && Object.entries(saveError).length > 0) {
      for (const key in saveError) {
        saveError[key].forEach((error) => {
          toast.error(() => <div dangerouslySetInnerHTML={{ __html: error }} />)
        })
      }
      dispatch(clearSaveError())
      dispatch(getTaskInteractionsData(taskId))
    }
    // eslint-disable-next-line
  }, [saveError])

  const handleCreateTaskInteractionModal = () => {
    const handleDispatch = (taskInteractionData) => {
      dispatch(createTaskInteraction(taskInteractionData))
    }
    const content = () => (
      <CreateTaskInteractionForm
        className="p-5"
        configAlert={configAlert}
        setConfigAlert={setConfigAlert}
        task={taskData}
        handleDispatch={handleDispatch}
      />
    )
    setConfigAlert({
      show: true,
      title: null,
      text: content(),
      width: 800,
      icon: null,
      cancelButton: true,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {},
      toastSuccess: null,
      showConfirmButton: false,
    })
  }

  const handleDeleteTaskInteractionModal = ({ interaction_id, interaction_code }) => {
    const content = `<p>Deseja realmente excluir a interação  <strong>${interaction_code}</strong> da tarefa <strong>${taskData.task_code}</strong>?</p>`
    setConfigAlert({
      show: true,
      title: null,
      text: content,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => dispatch(deleteTaskInteraction(taskId, interaction_id)),
      toastSuccess: null,
    })
  }

  const handleCancelTaskModal = () => {
    const handleDispatch = (description) => {
      dispatch(
        cancelTask({
          taskId: taskId,
          description: description,
        })
      )
      if (user.roles.indexOf('admin') !== -1 && user.id !== taskData.task_user_criator_id) {
        history.push(`/tarefas/visiveis/admin`)
      } else {
        history.push(`/tarefas/criadas/${user.id}`)
      }
    }
    const content = () => (
      <CancelTaskForm
        configAlert={configAlert}
        setConfigAlert={setConfigAlert}
        task={taskData}
        handleDispatch={handleDispatch}
      />
    )
    setConfigAlert({
      show: true,
      title: 'Cancelamento da Tarefa',
      text: content(),
      width: 700,
      icon: null,
      cancelButton: true,
      confirmButtonText: null,
      cancelButtonText: null,
      confirm: () => {},
      toastSuccess: null,
      showConfirmButton: false,
    })
  }

  const handleFinishTaskModal = () => {
    const content = `<p>Deseja realmente definir a tarefa <strong>${taskData.task_code}</strong> como <strong>Realizada</strong>?</p>`
    setConfigAlert({
      show: true,
      title: null,
      text: content,
      width: 600,
      icon: 'question',
      cancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirm: () => dispatch(finishTask(taskId)),
      toastSuccess: null,
    })
  }

  const showAlertSolicitationData = () => {
    const content = () => (
      <SolicitationDataModal
        solicitationCode={taskData.task_obj_solicitation.solicitation_code}
        serviceCode={taskData.task_obj_solicitation.solicitation_service_code}
        serviceTitle={taskData.task_obj_solicitation.solicitation_service_title}
        solicitationSlug={taskData.task_obj_solicitation.solicitation_stage_slug}
        solicitationStage={taskData.task_obj_solicitation.solicitation_stage}
        description={taskData.task_obj_solicitation.solicitation_description}
        organizationInitials={taskData.task_obj_solicitation.solicitation_organization_initials}
        createdIn={taskData.task_obj_solicitation.solicitation_created_in}
      />
    )
    setConfigAlert({
      show: true,
      title: null,
      text: content(),
      width: 800,
      confirmButtonText: 'Fechar',
      confirm: () => {},
      toastSuccess: null,
    })
  }
  const showAlertIntangibleAssetData = () => {
    const content = () => (
      <IntangibleAssetDataModal
        intangibleAssetCode={taskData.task_obj_solicitation.solicitation_intangible_asset_code}
        intangibleAssetTitle={taskData.task_obj_solicitation.solicitation_intangible_asset_title}
        intangibleAssetDescription={taskData.task_obj_solicitation.solicitation_intangible_asset_description}
        intangibleAssetUserMomentCreate={taskData.task_obj_solicitation.solicitation_intangible_asset_moment_by_user}
        intangibleAssetUnitInitInitials={
          taskData.task_obj_solicitation.solicitation_intangible_asset_organization_initials
        }
      />
    )
    setConfigAlert({
      show: true,
      title: null,
      text: content(),
      width: 800,
      confirmButtonText: 'Fechar',
      confirm: () => {},
      toastSuccess: null,
    })
  }

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        icon={configAlert.icon}
        width={configAlert.width}
        onConfirm={configAlert.confirm}
        onDismissed={() => {
          setConfigAlert({ ...configAlert, show: false })
        }}
        confirmButtonText={configAlert.confirmButtonText}
        cancelButtonText={configAlert.cancelButtonText}
        showCancelButton={Boolean(configAlert.cancelButtonText)}
        showConfirmButton={Boolean(configAlert.confirmButtonText)}
        toastSuccess={configAlert.toastSuccess}
        closeToast={configAlert.closeToast}
      />
      {taskData && (
        <Card border="secondary">
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <h2>Tarefa {taskData.task_code}</h2>
                  <ContentRow>
                    <BadgeGT
                      variant={taskData.task_stage_slug}
                      text={taskData.task_stage}
                      className={taskData.task_stage === 'Em Andamento' ? 'text-dark' : ''}
                    />
                  </ContentRow>
                </Col>
              </Row>

              {taskData.task_associated_with_solicitation && (
                <>
                  <Row>
                    <Col>
                      <span>Tarefa associada à</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="text-left">
                      <BlockInfo className="align-items-end mb-3" style={{ fontSize: '1rem' }}>
                        <Col md={9} className="text-left">
                          Solicitação {'\u00A0'}
                          <strong>
                            <Link onClick={() => showAlertSolicitationData()}>
                              {taskData.task_obj_solicitation.solicitation_code}
                            </Link>
                          </strong>
                        </Col>

                        <Col md={3} className="text-right">
                          <TooltipCustom title="Mais informações" placement="top" arrow>
                            <i
                              style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                              className="fas fa-info-circle"
                              onClick={() => showAlertSolicitationData()}
                            ></i>
                          </TooltipCustom>
                        </Col>
                      </BlockInfo>
                    </Col>
                    <Col md={6}>
                      <BlockInfo className="align-items-start mb-3" style={{ fontSize: '1rem' }}>
                        <Col md={9} className="text-left">
                          Ativo Intangível {'\u00A0'}{' '}
                          <strong>
                            {taskData.task_obj_solicitation.solicitation_intangible_asset_code} -{' '}
                            {truncate(taskData.task_obj_solicitation.solicitation_intangible_asset_title, 15)}
                          </strong>
                          <strong>
                            <Link onClick={() => showAlertIntangibleAssetData()}></Link>
                          </strong>
                        </Col>

                        <Col md={3} className="text-right">
                          <TooltipCustom title="Mais informações" placement="top" arrow>
                            <i
                              style={{ fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                              className="fas fa-info-circle"
                              onClick={() => showAlertIntangibleAssetData()}
                            ></i>
                          </TooltipCustom>
                        </Col>
                      </BlockInfo>
                    </Col>
                  </Row>
                </>
              )}
              {!taskData.task_associated_with_solicitation && (
                <Row className="mt-3">
                  <Col>
                    <BlockInfo className="flex-column align-items-start" style={{ fontSize: '1rem' }}>
                      Esta tarefa não está associada a nenhuma solicitação.
                    </BlockInfo>
                  </Col>
                </Row>
              )}

              <Row className="mt-5">
                <Col>
                  <div>
                    <strong>Descrição da Tarefa</strong>
                    <p>{taskData.task_description}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <strong>Tipo da Tarefa</strong>
                    <p>{taskData.task_type}</p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 mb-4">
                {taskData.task_user_criator && (
                  <Col md={4}>
                    <BlockInfo className="flex-column align-items-start" style={{ fontSize: '1rem' }}>
                      Criador <br />
                      <strong>{taskData.task_user_criator}</strong> <br />
                      <a href={`mailto:${taskData.task_user_criator_email}`}>{taskData.task_user_criator_email}</a>
                    </BlockInfo>
                  </Col>
                )}
                {taskData.task_user_executant && (
                  <Col md={4}>
                    <BlockInfo className="flex-column align-items-start" style={{ fontSize: '1rem' }}>
                      Executante <br />
                      <strong>{taskData.task_user_executant}</strong> <br />
                      <a href={`mailto:${taskData.task_user_executant_email}`}>{taskData.task_user_executant_email}</a>
                    </BlockInfo>
                  </Col>
                )}
                {(taskData.task_associated_with_solicitation || taskData.task_created) && (
                  <Col md={4} className="d-flex flex-column justify-content-between">
                    {/* {taskData.task_associated_with_solicitation && (
                      <BlockInfo className="align-items-start mb-3" style={{ fontSize: '1rem' }}>
                        Associação {'\u00A0'}
                        <strong>
                          <Link onClick={() => showAlertSolicitationData()}>
                            {taskData.task_obj_solicitation.solicitation_code}
                          </Link>
                        </strong>
                      </BlockInfo>
                    )} */}
                    {taskData.task_created && (
                      <BlockInfo className="flex-column align-items-start" style={{ fontSize: '1rem' }}>
                        Criado em <br />
                        <strong>{taskData.task_created}</strong>
                      </BlockInfo>
                    )}
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <div>
                    {taskData.task_attachments.length > 0 && (
                      <>
                        <strong>Arquivos da Tarefa</strong>
                      </>
                    )}
                    {taskData.task_attachments.length === 0 && (
                      <>
                        <span>Não existem arquivos associados a esta Tarefa.</span>
                      </>
                    )}
                    <div>
                      {typeof taskData.task_attachments !== 'undefined' && (
                        <>
                          {taskData.task_attachments.map((taskAttachment, index) => (
                            <TooltipCustom
                              key={`taskAttachments-${index}`}
                              title={taskAttachment.original_name_tooltip}
                              placement="top"
                              arrow
                            >
                              <ButtonGT
                                size="sm"
                                variant="file"
                                type="button"
                                className="mr-2 mt-2"
                                onClick={() =>
                                  handleDownload(taskAttachment.task_attachment_id, taskAttachment.original_name)
                                }
                              >
                                {taskAttachment.original_name}
                              </ButtonGT>
                            </TooltipCustom>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {(listLoading || !taskData) && <LoadingSpinner />}
                  {!listLoading && taskData && (
                    <CardInterectionsMessages
                      messages={taskData.task_interactions}
                      onDelete={(interaction) => handleDeleteTaskInteractionModal(interaction)}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="text-right">
                  {taskData.button_interact_with_task && (
                    <>
                      <Button
                        type="button"
                        onClick={() => handleCreateTaskInteractionModal()}
                        className="font-weight-bold mr-2"
                        disabled={listLoading}
                      >
                        Interagir com a Tarefa
                      </Button>
                    </>
                  )}
                  {taskData.button_set_as_done && (
                    <>
                      <Button
                        type="button"
                        variant="success"
                        onClick={() => handleFinishTaskModal()}
                        className="font-weight-bold mr-2"
                        disabled={listLoading}
                      >
                        Definir como Realizada
                      </Button>
                    </>
                  )}
                  {taskData.button_cancel_task && (
                    <>
                      <Button variant="secondary" onClick={() => handleCancelTaskModal()} disabled={listLoading}>
                        Cancelar Tarefa
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer>
            <Container>
              <Row>
                <Col md={6} className="text-right"></Col>
                <Col md={6} className="text-right">
                  <Button variant="secondary" onClick={() => history.goBack()}>
                    <i className="fa fa-chevron-left"></i>&nbsp;Voltar
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Footer>
        </Card>
      )}
    </>
  )
}

export default TaskInteractionsPage
