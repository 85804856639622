import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FileStatusBar, FileErrorMessage, FileRemove, FileName } from './FileBar.styles'

const FileBarDefault = ({ file, index, handleClickRemoveFiles }) => {
  return (
    <FileStatusBar key={`file-bar-default-${index}`}>
      <Row>
        <Col md={file.errorMessage ? 4 : 10}>
          <FileName hasErrorMessage={file.errorMessage} className={`file-name ${file.invalid ? 'file-error' : ''}`}>
            <strong>Arquivo:</strong> {file.name}
          </FileName>
        </Col>
        {/* <FileSize>({fileSize(file.size)})</FileSize> */}
        <Col
          md={file.errorMessage ? 6 : 0}
          className="d-flex align-items-center justify-content-center"
          style={{ lineHeight: 1.5 }}
        >
          {file.invalid && <FileErrorMessage>({file.errorMessage})</FileErrorMessage>}
        </Col>
        <Col md="2" className="text-right">
          <FileRemove variant="outline-dark" onClick={() => handleClickRemoveFiles(file)} size="sm" className="mr-2">
            Excluir
          </FileRemove>
        </Col>
      </Row>
    </FileStatusBar>
  )
}

export default FileBarDefault
