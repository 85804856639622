import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import TooltipCustom from '../../../../../components/TooltipCustom'
import { AlertGT, Button, BadgeGT, BigSausage, ReadMore } from '../../../../../components'
import { BlockInfo, Code, Moment } from './ListBigSausage.styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import IntangibleAssetDataModal from '../../../../Tasks/components/IntangibleAssetDataModal'
import ServiceDataModal from '../../../../Tasks/components/ServiceDataModal'
import SolicitationDataModal from '../../../../Tasks/components/SolicitationDataModal'

const Info = ({ data, icon, title }) => {
  const [configAlert, setConfigAlert] = React.useState({
    show: false,
    title: '',
    text: '',
  })

  return (
    <>
      <AlertGT
        show={configAlert.show}
        title={configAlert.title}
        text={configAlert.text}
        width={800}
        onConfirm={() => setConfigAlert({ ...configAlert, show: false })}
        onDismissed={() => setConfigAlert({ ...configAlert, show: false })}
        confirmButtonText="Fechar"
        showCancelButton={false}
      />
      <div className="d-flex align-items-center mb-3">
        <BlockInfo>
          <TooltipCustom title={title} placement="left" arrow>
            {icon}
          </TooltipCustom>{' '}
          <strong>{data.code}</strong>&nbsp;-{' '}
          {(data.title && data.title.length > 80 ? `${data.title.substring(0, 80)}...` : data.title) ||
            data.description.substring(0, 80) + '...'}
        </BlockInfo>
        <div style={{ width: 32, textAlign: 'center' }}>
          <TooltipCustom title="Mais Informações" placement="top" arrow>
            <i
              style={{ fontSize: '1.5rem' }}
              className="fas fa-info-circle"
              onClick={() => {
                if (data.type === 'intangibleAsset') {
                  setConfigAlert({
                    // title: <div className="text-left">{data.code + (data.title ? ' ->>>>>>> ' + data.title : '')}</div>,
                    text: (
                      <IntangibleAssetDataModal
                        intangibleAssetCode={data.code}
                        intangibleAssetTitle={data.title}
                        intangibleAssetDescription={data.description}
                        intangibleAssetUserMomentCreate={data.moment_by_user}
                        intangibleAssetUnitInitInitials={data.organization_initials}
                      />
                    ),
                    show: true,
                  })
                } else if (data.type === 'service') {
                  setConfigAlert({
                    // title: <div className="text-left">{data.code + (data.title ? ' ->>>>>>> ' + data.title : '')}</div>,
                    text: (
                      <ServiceDataModal
                        ServiceCode={data.code}
                        ServiceTitle={data.title}
                        ServiceDescription={data.description}
                        ServiceUserMomentCreate={null}
                      />
                    ),
                    show: true,
                  })
                } else {
                  setConfigAlert({
                    // title: <div className="text-left">{data.code + (data.title ? ' ->>>x>>> ' + data.title : '')}</div>,
                    text: (
                      <SolicitationDataModal
                        solicitationCode={data.code}
                        serviceCode={data.service.code}
                        serviceTitle={data.service.title}
                        solicitationSlug={data.stage.slug}
                        solicitationStage={data.stage.stage}
                        description={data.description}
                        organizationInitials={data.solicitation_organization.initials}
                        createdIn={data.moment_by_user}
                      />
                    ),
                    show: true,
                  })
                }
              }}
            ></i>
          </TooltipCustom>
        </div>
      </div>
    </>
  )
}

const Header = ({ code, stage, moment, solicitation_organization }) => {
  return (
    <Row className="m-0" style={{ width: '100%' }}>
      <Col className="col-9 d-flex align-items-center pl-0">
        {code && <Code>{code}</Code>}
        <TooltipCustom title="Unidade de Origem da Solicitação" placement="top" arrow>
          <span>
            <BadgeGT variant={'secondary'} text={solicitation_organization.initials} />
          </span>
        </TooltipCustom>
        &nbsp;&nbsp;
        <BadgeGT variant={stage.slug} text={stage.stage} />
      </Col>
      <Col className="col-3 pr-0 text-right">
        <Moment>{moment}</Moment>
      </Col>
    </Row>
  )
}

const Body = ({
  intangibleAsset,
  service,
  parent,
  stage,
  description,
  continueSolicitation,
  discardSolicitation,
  manageSolicitation,
  historySolicitation,
  forumSolicitation,
  userIsAdminThisService,
  userIsAttendentThisService,
  userIsRequesterThisSolicitation,
}) => {
  const { hasServicesEnabled } = useSelector((state) => state.solicitations)

  return (
    <>
      <Row className="m-0" style={{ width: '100%' }}>
        <Col className="col-9 pl-0">
          {intangibleAsset && (
            <Info data={intangibleAsset} icon={<i className="fas fa-atom"></i>} title="Ativo Intangível" />
          )}
          {service && (
            <Info
              data={{ ...service, type: 'service' }}
              icon={<i className="fas fa-concierge-bell"></i>}
              title="Serviço Solicitado"
            />
          )}
          {parent && <Info data={parent} icon={<i className="fas fa-project-diagram"></i>} title="Origem" />}
          {description && <ReadMore text={description} maxCharacters={180} />}
        </Col>
        <Col className="col-3 pr-0 d-flex">
          {stage.slug === 'incompleta' && (
            <div className="d-flex align-items-baseline justify-content-between" style={{ width: '100%' }}>
              <Button className="btn btn-success btn-block mr-2" onClick={continueSolicitation}>
                Continuar
              </Button>
              <Button className="btn btn-danger btn-block" onClick={discardSolicitation}>
                Descartar
              </Button>
            </div>
          )}
          {stage.slug !== 'incompleta' && (
            <div
              className={`d-flex flex-column ${
                stage.slug === 'cancelada'
                  ? 'd-flex justify-content-start align-items-start'
                  : 'align-items-baseline justify-content-start'
              }`}
              style={{ width: '100%' }}
            >
              {stage.slug !== 'cancelada' && (
                <div className="d-flex align-items-baseline justify-content-between mb-3" style={{ width: '100%' }}>
                  <Button
                    className="btn btn-secondary btn-block"
                    onClick={continueSolicitation}
                    disabled={!hasServicesEnabled}
                  >
                    Criar Solicitação a partir desta
                  </Button>
                </div>
              )}
              <div>
                <Button
                  className="btn btn-outline-secondary mr-3"
                  onClick={manageSolicitation}
                  title="Gerência da Solicitação"
                >
                  <i className="fas fa-pen-square pr-0"></i>
                </Button>
                <Button
                  className="btn btn-outline-secondary mr-3"
                  disabled={stage.slug === 'aguardando-acolhimento'}
                  title="Fórum da Solicitação"
                  onClick={forumSolicitation}
                >
                  <i className="fas fa-comment pr-0"></i>
                </Button>
                <Button
                  className="btn btn-outline-secondary mr-3"
                  onClick={historySolicitation}
                  title="Histórico da Solicitação"
                >
                  <i className="fas fa-history pr-0"></i>
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

function ListBigSausage({
  itens = [],
  totalItens,
  loading,
  onNext,
  continueSolicitation,
  discardSolicitation,
  manageSolicitation,
  historySolicitation,
  forumSolicitation,
}) {
  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  else
    return (
      <InfiniteScroll
        dataLength={itens.length}
        next={onNext}
        hasMore={!loading && totalItens > itens.length}
        loader={<h4>Carregando...</h4>}
      >
        {itens.map(
          (item, index) =>
            item.solicitation_admin_units_user_id === null && (
              <BigSausage
                key={index}
                header={
                  <Header
                    code={item.code}
                    stage={item.stage}
                    moment={item.moment}
                    solicitation_organization={item.solicitation_organization}
                  />
                }
                body={
                  <Body
                    intangibleAsset={item.intangibleAssetOriginParent}
                    service={item.service}
                    parent={item.solicitationParent}
                    description={item.description}
                    stage={item.stage}
                    userIsAdminThisService={item.userIsAdminThisService}
                    userIsAttendentThisService={item.userIsAttendentThisService}
                    userIsRequesterThisSolicitation={item.userIsRequesterThisSolicitation}
                    continueSolicitation={() => continueSolicitation(item)}
                    discardSolicitation={() => discardSolicitation(item)}
                    manageSolicitation={() => manageSolicitation(item)}
                    historySolicitation={() => historySolicitation(item)}
                    forumSolicitation={() => forumSolicitation(item)}
                  />
                }
              />
            )
        )}
      </InfiniteScroll>
    )
}

export default ListBigSausage
