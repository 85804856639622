import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
import { getUserByToken, userLogout } from './authCrud'

export const actionTypes = {
  Login: '[Login] Action',
  LoginByToken: '[Login By Token] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[SetUser] Action',
}

const initialAuthState = {
  loginType: 'Sistema Interno',
  user: undefined,
  authToken: undefined,
  sessionId: null,
}

export const reducer = persistReducer(
  { storage, key: 'CDTS-GESTECV2', whitelist: ['user', 'authToken', 'loginType', 'sessionId'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload
        return {
          authToken,
          user: undefined,
          loginType: 'Sistema nativo',
          sessionId: authToken.split('.')[1],
          sessionStartIn: new Date(),
        }
      }

      case actionTypes.LoginByToken: {
        const { auth } = action.payload
        return auth
      }

      case actionTypes.Register: {
        //const { authToken } = action.payload;
        const { user } = action.payload
        return { ...state, user }
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return { ...state }
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const { user } = action.payload
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  loginByToken: (auth) => ({ type: actionTypes.LoginByToken, payload: { auth } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),

  requestUser: (user) => {
    return {
      type: actionTypes.UserRequested,
      payload: { user },
    }
  },
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    //yield put(actions.requestUser());
  })

  yield takeLatest(actionTypes.Logout, function* registerSaga() {
    yield userLogout()
    const CLIENT_BASE_URL =
      `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_CLIENT_URL_HOMOL}`
        : `${process.env.REACT_APP_CLIENT_URL_PROD}`

    window.location = CLIENT_BASE_URL + '/auth/login'
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
